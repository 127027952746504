import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FinancialEntryFilter, { IFinancialEntryFilterParams, INITIAL_FILTER_PARAMS } from "./FinalcialEntryFilter";
import { mapFinanceStatusToSeverity } from "../customerFinanceUtils";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { CustomerFinancialEntryResponse, FinancialEntryOrigin } from "../../../services/types/customerFinance";
import CustomerFinancesService from "../../../services/customerFinancesService";
import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import fileDownload from "js-file-download";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import useAuth from "../../../hooks/useAuth";
import numbro from "numbro";
import { CURRENT_DATE_TIME_FULL_FORMAT } from "../../../i18n";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const FinancesReleases: React.FC = () => {
    const { t } = useTranslation();

    const [filterParams, setFilterParams] = useState<IFinancialEntryFilterParams>(INITIAL_FILTER_PARAMS);
    const tableRef = React.useRef<MaterialTableProps<CustomerFinancialEntryResponse>>();
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
    const { user } = useAuth();

    const loadData = (query: Query<CustomerFinancialEntryResponse>) => {
        return new Promise<QueryResult<CustomerFinancialEntryResponse>>((resolve, reject) => {
            CustomerFinancesService.getCustomerFinancialEntries(
                filterParams.createdAtAfter, filterParams.createdAtBefore, filterParams.type,
                [], PageableParams.fromQuery(query))
                .then(response => {
                    resolve({
                        data: response.data,
                        page: query.page,
                        totalCount: response.count,
                    })
                })
        });
    }

    const refresh = () => {
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({ page: 0 } as Query<CustomerFinancialEntryResponse>);
        }
    }

    const applyFilter = (params: IFinancialEntryFilterParams) => {
        setFilterParams(params);
        refresh();
    }

    const exportDataCustomerOperations = () => {
        showLoadingDialog(true);
        CustomerFinancesService.exportCustomerFinancialEntries(
            Number(user?.customer?.id), filterParams.createdAtAfter, filterParams.createdAtBefore, 
            filterParams.type
        ).then(res => {
            console.log(res.data);
            fileDownload(res.data, "lancamentos.xlsx");
        }).catch(err => {
        }).finally(() => {
            showLoadingDialog(false);
        });
    };

    return (
        <React.Fragment>
            <LoadingOverlay open={isShowLoadingDialog} />
            <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                    <Typography variant="h1">Lançamentos</Typography>
                </Grid>
                <Hidden smDown>
                    <Grid item>
                    </Grid>
                </Hidden>
            </Grid>

            <FinancialEntryFilter onFilterChanged={applyFilter} />

            <Grid container direction="row" spacing={1} justify="flex-end" style={{ marginBottom: "10px" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppOutlinedIcon />}
                    onClick={exportDataCustomerOperations}
                    disabled={tableRef.current?.totalCount === 0}
                >
                    Exportar
                </Button>
            </Grid>
            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 160 },
                    {
                        field: "created_at", title: "Data", defaultSort: "desc",
                        render: (rowData, type) =>
                        format(rowData.created_at, CURRENT_DATE_TIME_FULL_FORMAT, {locale: ptBR}),
                    },
                    { field: "request_number", title: "Solicitação", emptyValue: "---" },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "courier_value", title: "Valor do Entregador", emptyValue: "---",
                        render: (rowData, type) => {
                            if ((rowData.origin === FinancialEntryOrigin.INCENTIVE) 
                            || (rowData.courier_value === null)
                            || (rowData.courier_value === 0)) {
                                return "---";
                            } else {
                                return numbro(rowData.courier_value).formatCurrency();
                            }
                        }
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "speedy_value", title: "Taxa da Speedy", emptyValue: "---",
                        render: (rowData, type) => {
                            if ((rowData.origin === FinancialEntryOrigin.INCENTIVE) 
                            || (rowData.speedy_value === null)
                            || (rowData.speedy_value === 0)) {
                                return "---";
                            } else {
                                return numbro(rowData.speedy_value).formatCurrency();
                            }
                        }
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "value", title: "Valor", emptyValue: "---"
                    },
                    {
                        field: "type", title: "Tipo",
                        render: (rowData, type) => t("financial_entry_type." + rowData.type)
                    },
                    {
                        field: "status", title: "Situação",
                        render: (rowData, type) => (
                            <Alert severity={mapFinanceStatusToSeverity(rowData.status)} icon={false}>
                                {t("financial_entry_status." + rowData.status)}
                            </Alert>
                        )
                    },
                ]}
                data={loadData}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={{
                    ...DEFAULT_TABLE_OPTIONS,
                    draggable: false
                }}
            />
        </React.Fragment>
    )
}

export default FinancesReleases;