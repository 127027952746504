import { apiAxios as axios } from "../utils/axios";
import { format, formatISO, formatRFC3339 } from "date-fns";
import { getURL, urls } from "../utils/urls";
import { IPageableParams, PagedResponse } from "./types/common";
import { Address, CreateDeliveryRequest, DeliveryPrices, DeliveryRequest, TripCourier, DeliveryRequestLog, DeliveryRequestResponse, DeliveryRequestReturnType, DeliveryValues, FinishedDeliveryRequest, Incentive, PendingDeliveryRequest, ScheduleDelivery, DeliveryRequestStatus, RequestCourierResponse, VehiclesValues, EstimatedValues, OnlineDedicatedCourierDetails } from "./types/deliveryRequest";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";

export interface IDeliveryRequestService {
  createDeliveryRequest: (deliveryRequest: CreateDeliveryRequest) => Promise<any>;
  scheduleDeliveryRequest: (deliveryRequest: ScheduleDelivery) => Promise<any>;
  createFinishedDeliveryRequest: (deliveryRequest: FinishedDeliveryRequest) => Promise<any>;

  finishDeliveryRequestById: (id: number) => Promise<any>;
  cancelDeliveryRequestById: (id: number) => Promise<any>;
  releaseDeliveryRequestById: (id: number) => Promise<any>;
  resendDeliveryRequest: (id: number, courierId: number | null) => Promise<any>;
  finishCanceledDeliveryRequestById: (id: number) => Promise<any>;
  discardDeliveryRequestById: (id: number) => Promise<any>;
  requestCourierToDeliveryRequestById: (id: number, courierId?: number | null) => Promise<RequestCourierResponse>;

  getDeliveryRequestById: (id: number) => Promise<DeliveryRequest>;
  getLogsByDeliveryRequestId: (id: number) => Promise<DeliveryRequestLog[]>;
  getPendingDeliveryRequests: (
    storeId: number | null,
    status: DeliveryRequestStatus[] | null,
    is_scheduled: boolean | null,
    estimated_delivery_time_after: Date | null,
    estimated_delivery_time_end: Date | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<PendingDeliveryRequest>>;

  getDeliveryRequestHistory: (
    requestNumber: string | null,
    orderNumber: string | null,
    requestedTimeAfter: Date | null,
    requestedTimeBefore: Date | null,
    courierName: string | null,
    storeName: string | null,
    status: string | null,
    manualCreation: boolean | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<DeliveryRequestResponse>>;

  getNewDeliveries: (
    storeId: number | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<PendingDeliveryRequest>>;

  getNewAndSearchingCourierDeliveries: (
    storeId: number | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<PendingDeliveryRequest>>;

  getDeliveryValues: (storeId: number, returnType: DeliveryRequestReturnType, latitude: number, longitude: number) => Promise<DeliveryValues>;

  getOnlineDedicatedCouriers: (storeId: number) => Promise<OnlineDedicatedCourierDetails[]>;

}

const DeliveryRequestService: IDeliveryRequestService = {
  getPendingDeliveryRequests: (
    storeId: number | null,
    status: DeliveryRequestStatus[] | null,
    is_scheduled: boolean | null,
    estimated_delivery_time_after: Date | null,
    estimated_delivery_time_end: Date | null,
    pageParams: IPageableParams
  ) => {
    return new Promise((resolve, reject) => {
      axios.post(
        `${urls.DELIVERY_REQUESTS}pending_deliveries/?page=${pageParams.page}&page_size=${pageParams.page_size}`,
        {
          ordering: !Array.isArray(pageParams.ordering) ? [pageParams.ordering] : pageParams.ordering,
          store: storeId,
          is_scheduled: is_scheduled !== null ? is_scheduled : undefined,
          estimated_delivery_time_after: estimated_delivery_time_after,
          estimated_delivery_time_end: estimated_delivery_time_end,
          status: status,
          with_trip: false
        }
      ).then((response) => {
        const data: PendingDeliveryRequest[] = response.data.results.map((item: any, index: number) => {
          const destinationAddress = {
            ...item.destination_address,
            latitude: convertToNumber(item.destination_address.latitude),
            longitude: convertToNumber(item.destination_address.longitude)
          };


          return {
            ...item,
            id: convertToNumber(item.id),
            created_at: convertToDate(item.created_at),
            requested_time: convertToDate(item.requested_time),
            customer_id: convertToNumber(item.customer_id),
            store_id: convertToNumber(item.store_id),
            courier_id: convertToNumber(item.courier_id),
            estimated_delivery_time: convertToDate(item.estimated_delivery_time),
            distance: convertToNumber(item.distance),
            destination_address: destinationAddress,
            is_scheduled: convertToBoolean(item.is_scheduled),
            canceled: convertToBoolean(item.canceled),

          };
        });
        const result: PagedResponse<PendingDeliveryRequest> = {
          data: data,
          count: response.data.count,
        };
        resolve(result);
      })
        .catch((error) => reject(error));
    });
  },

  getNewDeliveries: (
    storeId: number | null,
    pageParams: IPageableParams
  ) => {
    return DeliveryRequestService.getPendingDeliveryRequests(storeId, [DeliveryRequestStatus.NEW], null, null, null, pageParams);
  },

  getNewAndSearchingCourierDeliveries: (
    storeId: number | null,
    pageParams: IPageableParams
  ) => {
    return DeliveryRequestService.getPendingDeliveryRequests(storeId,
      [DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER],
      null, null, null, pageParams);
  },

  createDeliveryRequest: (deliveryRequest: CreateDeliveryRequest) => {
    return axios.post(urls.DELIVERY_REQUESTS,
      {
        ...deliveryRequest,
        order_value: 0,
        order_date: format(new Date(), "yyyy-MM-dd H:m:s"),
        auto_search: false,
        current_courier: null,
        is_scheduled: false
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  scheduleDeliveryRequest: (deliveryRequest: ScheduleDelivery) => {
    return axios.post(urls.DELIVERY_REQUESTS,
      {
        ...deliveryRequest,
        order_value: 0,
        order_date: format(new Date(), "yyyy-MM-dd H:m:s"),
        auto_search: false,
        current_courier: null,
        is_scheduled: true
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  createFinishedDeliveryRequest: (deliveryRequest: FinishedDeliveryRequest) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}manual_creation/`, {
        ...deliveryRequest,
        consignee_phone_number: "0",
        order_date: formatISO(deliveryRequest.order_date),
        custom_courier: true,
        estimated_delivery_time: null,
        order_value: null
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  requestCourierToDeliveryRequestById: (id: number, courierId?: number | null) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urls.DELIVERY_REQUESTS}${id}/look_for_courier/`, { current_courier: courierId })
        .then((response) => {
          resolve({
            ...response.data,
            id: convertToNumber(response.data.id),
            trip: convertToNumber(response.data.trip)
          });
        }).catch((error) => reject(error));
    });
  },

  releaseDeliveryRequestById: (id: number) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/release_order/`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  finishDeliveryRequestById: (id: number) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/finish/`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  finishCanceledDeliveryRequestById: (id: number) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/finish_cancel/`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  cancelDeliveryRequestById: (id: number) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/cancel/`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  discardDeliveryRequestById: (id: number) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/discard/`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getDeliveryRequestById: (id: number) => {

    return new Promise((resolve, reject) => {
      axios
        .get(`${urls.DELIVERY_REQUESTS}${id}/full_detail/`)
        .then((response) => {
          let total_value = 0;
          const delivery_value = convertToNumber(response.data.delivery_value) || 0;
          const return_value = convertToNumber(response.data.return_value) || 0;
          const additional_km_value = convertToNumber(response.data.additional_km_value) || 0; 
          const minimum_value = convertToNumber(response.data.minimum_value) || 0; 

          const originAddress: Address = {
            ...response.data.origin_address,
            latitude: convertToNumber(response.data.origin_address.latitude),
            longitude: convertToNumber(response.data.origin_address.longitude)
          };

          const destinationAddress: Address = {
            ...response.data.destination_address,
            latitude: convertToNumber(response.data.destination_address.latitude),
            longitude: convertToNumber(response.data.destination_address.longitude)
          };

          let courier: TripCourier | null = null;
          if (response.data.current_courier) {
            courier = {
              ...response.data.current_courier,
              ranking_points: convertToNumber(response.data.current_courier.ranking_points)
            };
          }

          let incentive: Incentive | null = null;
          if (response.data.incentive_json) {
            let total = 0;
            const customerValue = convertToNumber(response.data.incentive_json.customer_value, 0);
            if (customerValue) {
              total += customerValue;
            }
            const speedyValue = convertToNumber(response.data.incentive_json.speedy_value, 0);
            if (speedyValue) {
              total += speedyValue;
            }
            incentive = {
              ...response.data.incentive_json,
              customer_value: customerValue,
              speedy_value: speedyValue,
              total: total
            }
          }
          const incentive_costumer = incentive?.customer_value || 0;
          const PerVehicle: VehiclesValues = {
            ...response.data.estimated_values.per_vehicle,
            BIKE: convertToNumber(response.data.estimated_values.per_vehicle.BIKE),
            CAR: convertToNumber(response.data.estimated_values.per_vehicle.CAR),
            MOTORCYCLE: convertToNumber(response.data.estimated_values.per_vehicle.MOTORCYCLE),
            VAN: convertToNumber(response.data.estimated_values.per_vehicle.VAN),
          }

          const estimatedVehicleValues: EstimatedValues = {
            ...response.data.estimated_values,
            already_has_commercial_plan: convertToBoolean(response.data.estimated_values.already_has_commercial_plan),
            per_vehicle: PerVehicle
          }
          total_value = minimum_value + incentive_costumer + additional_km_value + return_value;
          const deliveryRequest: DeliveryRequest = {
            ...response.data,
            origin_address: originAddress,
            destination_address: destinationAddress,
            current_courier: courier,
            distance: convertToNumber(response.data.distance),
            delivery_value: delivery_value,
            additional_km_value: additional_km_value,
            return_value: return_value,
            speedy_value: convertToNumber(response.data.speedy_value),
            courier_value: convertToNumber(response.data.courier_value),
            courier_cancel_value: convertToNumber(response.data.courier_cancel_value),
            acceptation_distance: convertToNumber(response.data.acceptation_distance),
            time_to_arrive_store: convertToNumber(response.data.time_to_arrive_store),
            estimated_delivery_time: convertToDate(response.data.estimated_delivery_time),
            manual_creation: convertToBoolean(response.data.manual_creation),
            created_at: convertToDate(response.data.created_at),
            requested_time: convertToDate(response.data.requested_time),
            incentive_json: incentive,
            canceled: convertToBoolean(response.data.canceled),
            estimated_values: estimatedVehicleValues,
            minimum_value: minimum_value,
            value_total: convertToNumber(total_value),
          }
          resolve(deliveryRequest);
        })
        .catch((error) => reject(error));
    });
  },

  getLogsByDeliveryRequestId: (id: number) => {
    const url = `${urls.DELIVERY_REQUESTS}${id}/logs/`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const data: DeliveryRequestLog[] = response.data.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              courier: item.courier ? Number(item.courier) : null,
              manual_action: String(item.manual_action).toLowerCase() === "true" ? true : false,
              is_courier_action: String(item.is_courier_action).toLowerCase() === "true" ? true : false,
              created_at: convertToDate(item.created_at)
            };
          });
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  getDeliveryRequestHistory: (
    requestNumber: string | null,
    orderNumber: string | null,
    requestedTimeAfter: Date | null,
    requestedTimeBefore: Date | null,
    courierName: string | null,
    storeName: string | null,
    status: string | null,
    manualCreation: boolean | null,
    pageParams: IPageableParams
  ) => {
    const url = `${urls.DELIVERY_REQUESTS}get_closed_deliveries/?page=${pageParams.page}&page_size=${pageParams.page_size}`;

    return new Promise((resolve, reject) => {
      axios.post(url, {
        request_number: requestNumber,
        order_number: orderNumber,
        requested_time_after: requestedTimeAfter ? formatRFC3339(requestedTimeAfter) : null,
        requested_time_before: requestedTimeBefore ? formatRFC3339(requestedTimeBefore) : null,
        courier_name: courierName,
        store_name: storeName,
        status: status,
        manual_creation: manualCreation,
        ordering: pageParams.ordering,
      })
        .then((response) => {
          const data: DeliveryRequestResponse[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: convertToDate(item.created_at),
              requested_time: convertToDate(item.requested_time),
              manual_creation: String(item.manual_creation).toLowerCase() === "true" ? true : false
            };
          });
          const result: PagedResponse<DeliveryRequestResponse> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  resendDeliveryRequest: (id: number, courierId: number | null) => {
    return axios
      .post(`${urls.DELIVERY_REQUESTS}${id}/resend/`, { current_courier: courierId })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getDeliveryValues: (storeId: number, returnType: DeliveryRequestReturnType, latitude: number, longitude: number) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urls.DELIVERY_REQUESTS}get_prices/`, {
          store: storeId,
          latitude: latitude,
          longitude: longitude,
          return_type: returnType
        })
        .then((response) => {
          const prices: DeliveryPrices = {
            ...response.data.prices,
            BIKE: convertToNumber(response.data.prices.BIKE),
            MOTORCYCLE: convertToNumber(response.data.prices.MOTORCYCLE),
            CAR: convertToNumber(response.data.prices.CAR),
            VAN: convertToNumber(response.data.prices.VAN)
          };

          let incentive: Incentive | null = null;
          if (response.data.incentive) {
            let total = 0;
            const customerValue = convertToNumber(response.data.incentive.customer_value, 0);
            if (customerValue) {
              total += customerValue;
            }
            const speedyValue = convertToNumber(response.data.incentive.speedy_value, 0);
            if (speedyValue) {
              total += speedyValue;
            }
            incentive = {
              ...response.data.incentive,
              customer_value: customerValue,
              speedy_value: speedyValue,
              total: total
            }
          }

          const result: DeliveryValues = {
            ...response.data,
            distance: convertToNumber(response.data.distance),
            prices: prices,
            incentive: incentive
          };

          resolve(result);
        })
        .catch((error) => reject(error));
    })
  },
  getOnlineDedicatedCouriers: (storeId: number) => {
    const url = getURL(`${urls.DELIVERY_REQUESTS}dedicated_couriers/`, {
      store: storeId
    });
    return new Promise((resolve, reject) => {
      axios.get(url)
      .then((response) => {
        const result: OnlineDedicatedCourierDetails[] = response.data.results.map((item: any) => {
          const slot_info = {
            finished_deliveries_count: convertToNumber(item.slot_info.finished_deliveries_count),
            finished_deliveries_value: convertToNumber(item.slot_info.finished_deliveries_value),
            slot_end_datetime: convertToDate(item.slot_info.slot_end_datetime),
            slot_start_datetime: convertToDate(item.slot_info.slot_start_datetime),
            turn_value: convertToNumber(item.slot_info.turn_value),
          }
          return {
            ...item,
            last_update: convertToDate(item.last_update),
            delivery_request_id: convertToNumber(item.delivery_request_id),
            latitude: convertToNumber(item.latitude),
            longitude: convertToNumber(item.longitude),
            slot_info: slot_info,
          }
        });
        resolve(result);
      })
      .catch((error) => reject(error));
    })
  },
};

export default DeliveryRequestService;
