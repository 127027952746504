import { apiAxios as axios } from "../utils/axios";
import { urls } from "../utils/urls";
import { IncentiveResponse, Store, StoreDynamicTariff } from "./types/store";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { QueueCourier } from "./types/courier";

export interface IStoreService {
    getStoresByCustomerId: (id: number) => Promise<Store[]>;
    getStoreCourierQueue: (id: number) => Promise<QueueCourier[]>;
    getStoreIsInDynamic: (id: number) => Promise<StoreDynamicTariff>;
    removeCourierFromQueue: (
        storeId: number,
        courierId: number
    ) => Promise<any>;
}

const StoreService: IStoreService = {
    getStoresByCustomerId: (id: number) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.STORE}?customer=${id}`)
                .then(response => {
                    const result: Store[] = response.data.results.map(
                        (store: any) => {
                            return {
                                ...store,
                                id: Number(store.id),
                                created_at: convertToDate(store.created_at),
                                updated_at: convertToDate(store.updated_at),
                                latitude: convertToNumber(store.latitude),
                                longitude: convertToNumber(store.longitude),
                                city: convertToNumber(store.city),
                                region: convertToNumber(store.region),
                            };
                        }
                    );
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getStoreCourierQueue: (id: number) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.STORE}${id}/detail_queue/`)
                .then((response) => {
                    const result = response.data.map((item: any) => {
                        const courierStatusData = {
                            ...item.courier_status,
                            courier_id: convertToNumber(item.courier_status.courier_id),
                            created_at: convertToDate(item.courier_status.created_at),
                            updated_at: convertToDate(item.courier_status.updated_at),
                            online: convertToBoolean(item.courier_status.online),
                            latitude: convertToNumber(item.courier_status.latitude),
                            longitude: convertToNumber(item.courier_status.longitude),
                            delivery_request_id: convertToNumber(item.courier_status.delivery_request_id),
                            trip_id: convertToNumber(item.courier_status.trip_id),
                            suspended: convertToBoolean(item.courier_status.suspended)
                        };

                        const courier: QueueCourier = {
                            ...item,
                            position: convertToNumber(item.position),
                            courier_status: courierStatusData
                        };

                        return courier;
                    });

                    resolve(result);
                })
                .catch((error) => reject(error));
        })
    },

    removeCourierFromQueue: (storeId: number, courierId: number) => {
        return axios.post(`${urls.STORE}${storeId}/remove_from_queue/`, {
            courier: courierId,
        });
    },

    getStoreIsInDynamic: (id: number) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.STORE}${id}/is_in_dynamic/`)
                .then(response => {
                    const incentive: IncentiveResponse = {
                        ...response.data.dynamic,
                        customer_value: convertToNumber(response.data.dynamic?.customer_value),
                        start_date: convertToDate(response.data.dynamic?.start_date),
                        end_date: convertToDate(response.data.dynamic?.end_date),
                    };
                    const result: StoreDynamicTariff = {
                        ...response.data,
                        is_dynamic_active: convertToBoolean(response.data.is_dynamic_active),
                        incentive: incentive
                    };
                    return resolve(result);
                })
                .catch(error => reject(error));
        });
    },
};

export default StoreService;
