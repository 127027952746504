import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { ImgLoader } from "./ImgLoader";
import { QueueCourier } from "../services/types/courier";
import { useTranslation } from "react-i18next";

export interface CourierQueueAction {
  label: string;
  action: (courier: QueueCourier) => Promise<boolean> | boolean | void;
}

interface CourierQueueDialogProps {
  open: boolean;
  isRefreshing: boolean;
  courierQueue: QueueCourier[];
  title?: string;
  action?: CourierQueueAction;
  onCloseRequested: () => void;
}

export const CourierQueueDialog: React.FC<CourierQueueDialogProps> = ({ open, isRefreshing, courierQueue, title, action, onCloseRequested }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onCloseRequested}
      aria-labelledby="dialog-row-title"
      aria-describedby="dialog-row-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="dialog-row-title">
        <Grid container spacing={1} className={classes.dialogTitle}>
          <Typography variant="h1">
            {title || "Fila de Entregadores"}
            {isRefreshing && (
              <CircularProgress size={22} style={{ marginLeft: "16px" }} />
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseRequested}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="flex-start"
          >
            {courierQueue.map((courier, index) => (
              <Grid item xs={12} key={index}>
                <Paper className={classes.card} variant="outlined">
                  <Grid container spacing={2} direction="row" wrap="nowrap" justify="space-between" alignItems="center">
                    <Grid item container wrap="nowrap" spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar className={classes.large} alt={courier.name}>
                          <ImgLoader src={String(courier.picture)} className={classes.large} />
                        </Avatar>
                      </Grid>
                      <Grid item zeroMinWidth>
                        <Typography noWrap variant="subtitle2" className={classes.cardTitle} title={courier.name}>
                          {courier.name}
                        </Typography>

                        <Typography variant="subtitle1" className={classes.cardSubtitle}>
                          {t("vehicle_type." + courier.vehicle_type)}
                          {courier.vehicle_load_compartment_type && (` | ${t("vehicle_load_compartment_type." + courier.vehicle_load_compartment_type)}`)}
                        </Typography>

                        <Typography variant="body2" className={classes.chip}>
                          Posição #{courier.position}
                        </Typography>
                      </Grid>
                    </Grid>
                    {action && (
                      <Grid item>
                        <Button color="primary" onClick={() => { action.action(courier); }}>
                          {action.label}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "500px",
  },
  card: {
    padding: theme.spacing(1),
  },
  cardTitle: {
    fontWeight: "bold",
    color: "#526272",
    lineHeight: "1",
  },
  cardSubtitle: {
    color: "#526272",
  },
  chip: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0080FF",
    color: "#fff",
    fontWeight: "bold",
    padding: "0 8px",
    borderRadius: "10px",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

export default CourierQueueDialog;
