import {useRef, useEffect } from 'react';
import type { MutableRefObject } from 'react';

const useIsMountedRef = (): MutableRefObject<boolean> => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return (() => { isMounted.current = false });
  }, []);

  return isMounted;
};

export default useIsMountedRef;
