import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ResponsiveBar, BarTooltipProps, BarDatum } from "@nivo/bar";
import { useTheme } from '@nivo/core'
import { DeliveriesPerDayResponse } from "../../../services/types/statistics";
import { useTranslation } from "react-i18next";
import { endOfDay, format, startOfDay } from "date-fns";
import { CURRENT_DATE_FORMAT } from "../../../i18n";

interface ICustomerExtraDetailStores {
  deliveriesPerDay: DeliveriesPerDayResponse[];
  dateAfter: Date;
  dateBefore: Date;
};

const BAR_COLORS = ["#0080FF", "#f44336", "#673ab7", "#ff9800", "#ffc107", "#4caf50",
  "#0080FF", "#f44336", "#673ab7", "#ff9800", "#ffc107", "#4caf50",
  "#0080FF", "#f44336", "#673ab7", "#ff9800", "#ffc107", "#4caf50"];

const BarTotalsLayer = (props: any) => {
  const labelOffset = 5;
  const labelFontSize = 14;
  if (props.bars.length === 0) return null;
  // compute totals for each index/bar
  const totals: any = {};
  const bandwidth = props.bars[0].width;
  props.bars.forEach((bar: any) => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });
  // place text elements above the bars
  const labels = Object.keys(totals).map((indexValue) => {
    const x = props.xScale(indexValue) + bandwidth / 2;
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={"total." + indexValue}
        x={x}
        y={y}
        textAnchor={"middle"}
        fontWeight={"bold"}
        fontSize={labelFontSize}
      >
        {totals[indexValue]}
      </text>
    );
  });
  return <>{labels}</>;
};

export const QuantityDeliveries: React.FC<ICustomerExtraDetailStores> = ({
  deliveriesPerDay,
  dateAfter,
  dateBefore
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [titles, setTitles] = React.useState<string[]>([]);
  const [chartData, setChartData] = React.useState<any[]>([]);
  const [averageValue, setAverageValue] = React.useState<number>(0);

  const buildChartData = React.useCallback((deliveries: DeliveriesPerDayResponse[]) => {
    if (deliveries) {
      const date_after = startOfDay(dateAfter);
      const date_before = endOfDay(dateBefore);
      const chartData: any[] = [];

      for (let day = date_after; date_after <= date_before; day.setDate(day.getDate() + 1)) {
        chartData.push({ "day": format(new Date(day), CURRENT_DATE_FORMAT) });
      }

      const titlesData: string[] = [];
      let total: number = 0;
      if (deliveries.length > 0) {
        deliveries.forEach((item) => {
          let title = item.name;
          if (!t("delivery_stats." + item.name).startsWith("delivery_stats.")) {
            title = t("delivery_stats." + item.name);
          }
          titlesData.push(title);

          item.dates.forEach((dateQtd) => {
            const chartDataItem = chartData.find((chartItem) => chartItem.day === format(new Date(dateQtd.date), CURRENT_DATE_FORMAT));
            if (chartDataItem) {
              chartDataItem[title] = dateQtd.quantity || 0;
              total += chartDataItem[title];
            }
          });
        });
      }
      if (chartData.length > 0) {
        setAverageValue(Math.round(total / chartData.length));
      } else {
        setAverageValue(0);
      }
      setChartData(chartData);
      setTitles(titlesData.sort());
    }
  }, [dateAfter, dateBefore, t]);

  React.useEffect(() => {
    if (deliveriesPerDay) {
      buildChartData(deliveriesPerDay);
    }
  }, [deliveriesPerDay, buildChartData]);

  const BarTooltip: React.FunctionComponent<BarTooltipProps<BarDatum>> = (props) => {
    const theme = useTheme();
    //console.log(props);
    let total: number = 0;
    Object.keys(props.data).forEach(key => {
      if (key !== 'day') {
        total += Number(props.data[key]);
      }
    });

    return (
      <div style={theme.tooltip.container}>
        <div style={theme.tooltip.basic}>
          {props.indexValue}
        </div>
        {Object.keys(props.data).map((key, index) => {
          return key !== 'day' ? (
            <div key={index} style={theme.tooltip.basic}>
              <span style={{ display: 'block', width: "12px", height: "12px", background: BAR_COLORS[titles.indexOf(key)], marginRight: "8px" }} />
              <span>
                {key}: <strong>{`${props.data[key]}`}</strong>
              </span>
            </div>
          ) : <></>
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Typography variant="h3">Entregas por dia</Typography>
        <div className={classes.chartContainer}>
          <ResponsiveBar
            margin={{ top: 50, right: 30, bottom: 55, left: 40 }}
            minValue={0}
            valueFormat=">-.0d"
            data={chartData}
            indexBy={"day"}
            keys={titles}
            padding={0.1}
            labelTextColor={"inherit:darker(0.1)"}
            labelSkipWidth={16}
            labelSkipHeight={16}
            tooltip={BarTooltip}
            colors={BAR_COLORS}
            //colors={{ scheme: 'nivo' }}
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              BarTotalsLayer
            ]}
            markers={averageValue > 0 ? [
              {
                axis: 'y',
                value: averageValue,
                lineStyle: { stroke: 'orange', strokeWidth: 2 },
                legend: 'Média: ' + averageValue,
                legendPosition: 'right',
                legendOrientation: 'vertical',
                textStyle: { fontFamily: 'Nunito Sans, sans-serif' }
              },
            ] : undefined}
            enableGridX={false}
            animate={true}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -45,
                itemWidth: 150,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                toggleSerie: false,
                itemOpacity: 1,
                symbolSize: 20,
              }
            ]}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -40,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  chartContainer: {
    fontFamily: 'Nunito Sans, sans-serif',
    height: "400px",
    width: "100%",
    paddingTop: '16px',
  }
});
