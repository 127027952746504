
import { apiAxios as axios } from "../utils/axios";
import { formatRFC3339, parseISO } from "date-fns";
import { getURL, urls } from "../utils/urls";
import { IPageableParams, PagedResponse } from "./types/common";
import { CustomerBalance, CustomerFinancialStats, CustomerFinancialEntryResponse, FinancialEntryOrigin, FinancialEntryType, CreditCardCreate, FinancialTaxResponse } from "./types/customerFinance";
import { formatISO } from "date-fns";
import { convertToDate, convertToNumber } from "../utils/utils";

export interface ICustomerFinancesService {
  getCustomerBalance: () => Promise<CustomerBalance>;
  getCustomerStats: (customerId: number, storeId: number | null) => Promise<CustomerFinancialStats>;

  getCustomerFinancialEntries: (
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    type: FinancialEntryType | null,
    origin: FinancialEntryOrigin[] | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<CustomerFinancialEntryResponse>>;

  exportCustomerFinancialEntries: (
    customerId: number,
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    type: FinancialEntryType | null,
  ) => Promise<any>;

  getBankSlipDocument: (financialEntryId: number) => Promise<string>;
  addCredit: (customerId: number, value: number, paymentMethod: FinancialEntryOrigin, cardId: number | null, card: CreditCardCreate) => Promise<any>;
  updateTermsUserRequest: (idUser: number, terms: any) => Promise<any>;
  getFinancialTax: () => Promise<FinancialTaxResponse>;

}

const CustomerFinancesService: ICustomerFinancesService = {
  getCustomerBalance: () => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.CUSTOMER_FINANCES}`)
        .then((response) => {
          const result: CustomerBalance = {
            customer: Number(response.data.customer),
            updatedAt: parseISO(response.data.updated_at),
            currentBalance: Number(response.data.current_balance),
            creditLimit: Number(response.data.waste_limit)
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCustomerStats: (customerId: number, storeId: number | null) => {
    return new Promise((resolve, reject) => {
      axios.post(`${urls.CUSTOMER}${customerId}/extra_details/`, { store: storeId })
        .then((response) => {
          const result: CustomerFinancialStats = {
            average_ticket: Number(response.data.average_ticket),
            average_weekly_deliveries: Number(response.data.average_weekly_deliveries),
            average_weekly_expenses: Number(response.data.average_weekly_expenses),
            current_balance: Number(response.data.current_balance),
            today_deliveries: Number(response.data.today_deliveries),
            today_expenses: Number(response.data.today_expenses),
            weekly_canceled_deliveries: Number(response.data.weekly_canceled_deliveries),
            weekly_deliveries: Number(response.data.weekly_deliveries),
            weekly_expenses: Number(response.data.weekly_expenses),
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  updateTermsUserRequest: async (idUser: number, terms: any) => {
    return axios
      .patch(`${urls.CUSTOMER_USERS}${idUser}/accept_terms/`, terms)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getCustomerFinancialEntries: (
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    type: FinancialEntryType | null,
    origin: FinancialEntryOrigin[] | null,
    pageParams: IPageableParams
  ) => {
    const url = getURL(urls.CUSTOMER_FINANCES_ENTRIES, {
      ...pageParams,
      created_at_after: createdAtAfter ? formatRFC3339(createdAtAfter) : null,
      created_at_before: createdAtBefore ? formatRFC3339(createdAtBefore) : null,
      type: type,
      origin: origin
    });

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          const data: CustomerFinancialEntryResponse[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: convertToDate(item.created_at),
              updated_at: convertToDate(item.updated_at),
              closed_date: convertToDate(item.closed_date),
              confirmation_date: convertToDate(item.confirmation_date),
              payment_date: convertToDate(item.payment_date),
              canceled_date: convertToDate(item.canceled_date),
              courier_value: convertToNumber(item.courier_value),
              speedy_value: convertToNumber(item.speedy_value),
              value: convertToNumber(item.value),
              tax: convertToNumber(item.tax),
              total: convertToNumber(item.total),
              account: convertToNumber(item.account),
              delivery_request: convertToNumber(item.delivery_request)
            };
          });
          const result: PagedResponse<CustomerFinancialEntryResponse> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  exportCustomerFinancialEntries: async (
    customerId: number,
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    type: FinancialEntryType | null,
  ) => {
    const filter = {
      customer: customerId,
      start_date: createdAtAfter ? formatISO(createdAtAfter, { representation: "date" }) : null,
      end_date: createdAtBefore ? formatISO(createdAtBefore, { representation: "date" }) : null,
      type: type
    }

    return axios.post(
      `${urls.CUSTOMER_FINANCES}export_customer_operations/`,
      {
        ...filter,
      },
      {
        responseType: "blob",
      }
    );
  },

  getBankSlipDocument: (financialEntryId: number) => {
    return new Promise((resolve, reject) => {
      const url = `${urls.CUSTOMER_FINANCES_ENTRIES}${financialEntryId}/bank_slip/`;
      axios.get(url)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  addCredit: (customerId: number, value: number, paymentMethod: FinancialEntryOrigin, cardId: number | null, card: CreditCardCreate) => {
    let url = "";
    let dataPost: any;
    switch (paymentMethod) {
      case FinancialEntryOrigin.BANK_SLIP:
        url = urls.CUSTOMER_FINANCES_PAY_CREDITS;
        dataPost = { 
          customer: customerId,
          value: value
        }
        break;
      case FinancialEntryOrigin.PIX:
        url = urls.CUSTOMER_FINANCES_PAY_CREDITS_PIX;
        dataPost = { 
          customer: customerId,
          value: value
        }
        break;   
      case FinancialEntryOrigin.CREDIT_CARD:
        url = urls.CUSTOMER_FINANCES_PAY_CREDITS_CARD;
        if(cardId){
           dataPost = { 
            customer: customerId,
            value: value,
            card_id: cardId
          }
        }else{
          dataPost = { 
            customer: customerId,
            value: value,
            card_data: card
          }
        }
       
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      axios.post(url, 
        dataPost
      )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getFinancialTax: () => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.FINANCIAL_SETTINGS}get_settings/`)
        .then((response) => {
          const result: FinancialTaxResponse = {
            credit_card_percentage_tax: convertToNumber(response.data.credit_card_percentage_tax) || 0,
            bank_slip_tax: convertToNumber(response.data.bank_slip_tax) || 0,
            pix_tax: convertToNumber(response.data.pix_tax) || 0,
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

};

export default CustomerFinancesService;
