import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useAuth from "../../../hooks/useAuth";
import useGlobalSearch from "../../../hooks/useGlobalSearch";
import {
    AppBar,
    Button,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ReactComponent as Logo } from "../../../assets/img/logo-branca.svg";
import ChangeOwnPassword from "../../../components/ChangeOwnPassword";
import CreateDeliveryRequestDialog from "../../../components/CreateDeliveryRequest";
import { USER_DATA_STORAGE_KEY } from "../../../contexts/AuthContext";
import { LoginUser } from "../../../services/types/user";
import AcceptTermsRequest from "../../../pages/Requests/acceptTermsRequest";
import ShowIf from "../../../components/ShowIf";
import SearchInput from "../../../components/SearchInput";
import theme from "../../../styles/theme";
import useAppContext from "../../../hooks/useAppContext";
import useStoreContext from "../../../hooks/useStoreContext";
import Chat from "../../../components/Chat";

interface TopbarProps {
    children?: any;
}

const Topbar: React.FC<TopbarProps> = ({ children }) => {
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { user, logout } = useAuth();
    const { toggleMainMenu } = useAppContext();
    const { globalSearchIsVisible, globalSearchValue, globalSearchPlaceholder, setGlobalSearchValue } = useGlobalSearch();
    const { activeStore, selectStore, showCourierQueue } = useStoreContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showChangeOwnPasswordDialog, setShowChangeOwnPasswordDialog] = React.useState(false);
    const [showCreateDeliveryRequestDialog, setShowCreateDeliveryRequestDialog] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            await logout();
        } catch (err) {
            console.error(err);
        } finally {
            history.push("/");
        }
    };

    let isOpenTerms: boolean = false;

    let userData = localStorage.getItem(USER_DATA_STORAGE_KEY);
    if (userData) {
        let userLocal: LoginUser | null = null;
        userLocal = JSON.parse(userData);
        if (userLocal?.accepted_terms === false) {
            isOpenTerms = true;
        }
    }

    const [showAcceptTermsDialog, setShowAcceptTermsDialog] = React.useState(isOpenTerms);

    const openChangeOwnPasswordDialog = () => {
        handleClose();
        setShowChangeOwnPasswordDialog(true);
    };

    const openCreateDeliveryRequestDialog = () => {
        if (activeStore?.id) {
            handleClose();
            setShowCreateDeliveryRequestDialog(true);
        } else {
            enqueueSnackbar(
                "Você só pode Cadastrar novas Entregas após informar a Loja em que está trabalhando.",
                {
                    variant: "error",
                }
            );
        }
    };

    const openChangeStoreDialog = () => {
        handleClose();
        selectStore();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDetailQueue = () => {
        showCourierQueue();
    };

    return (
        <React.Fragment>
            <Chat user={user} activeStore={activeStore} />
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                {children}
                <Toolbar>
                    <Logo className={classes.logo} />
                    <IconButton
                        color="inherit"
                        className={classes.menuButton}
                        onClick={toggleMainMenu}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <ShowIf condition={globalSearchIsVisible} type="displayNone">
                        <SearchInput
                            id="global_search_value"
                            placeholder={globalSearchPlaceholder}
                            style={{ marginLeft: theme.spacing(9), width: "500px" }}
                            onChange={(e) => {
                                setGlobalSearchValue(e.currentTarget.value);
                            }}
                            value={globalSearchValue}
                        />
                    </ShowIf>
                    <div className={classes.grow} />
                    <Hidden smDown>
                        <Button className={classes.buttonRow} onClick={handleOpenDetailQueue}>
                            Ver Fila
                        </Button>
                        <Button
                            className={classes.buttonRequest}
                            variant="contained"
                            onClick={openCreateDeliveryRequestDialog}
                        >
                            Nova Entrega
                        </Button>
                    </Hidden>
                    <IconButton color="inherit" onClick={handleClick}>
                        <AccountCircleIcon />
                        <div className={classes.menuButton}>
                            <Typography className={classes.flex}>
                                {user?.name}
                            </Typography>
                            <Typography
                                className={classes.flex}
                                variant="caption"
                            >
                                {activeStore?.name}
                            </Typography>
                        </div>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Hidden smUp>
                            <MenuItem onClick={openCreateDeliveryRequestDialog}>
                                Solicitar Entrega
                            </MenuItem>
                        </Hidden>
                        {!user?.store && (
                            <MenuItem onClick={openChangeStoreDialog}>
                                Selecionar Loja
                            </MenuItem>
                        )}
                        <MenuItem onClick={openChangeOwnPasswordDialog}>
                            Alterar Senha
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Sair</MenuItem>
                    </Menu>
                </Toolbar>
                <ChangeOwnPassword
                    modal={{
                        open: showChangeOwnPasswordDialog,
                        setOpen: setShowChangeOwnPasswordDialog,
                    }}
                />
                <CreateDeliveryRequestDialog
                    modal={{
                        open: showCreateDeliveryRequestDialog,
                        setOpen: setShowCreateDeliveryRequestDialog,
                    }}
                />
                <AcceptTermsRequest
                    open={showAcceptTermsDialog}
                    setOpen={setShowAcceptTermsDialog}
                />
            </AppBar>
        </React.Fragment>
    );
};

export default Topbar;

const useStyles = makeStyles((theme: Theme) => ({
    grow: {
        flexGrow: 1,
    },
    logo: {
        width: "120px",
    },
    menuButton: {
        marginLeft: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        flex: 1,
        textAlign: "left",
    },
    buttonRequest: {
        backgroundColor: theme.palette.background.paper,
        marginRight: theme.spacing(1),
    },
    buttonRow: {
        color: theme.palette.background.paper,
        marginRight: theme.spacing(2),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));
