import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../../styles/theme";
import { makeStyles } from "@material-ui/core/styles";


interface IStatsContainerProps {
    availableCouriers: number;
    busyCouriers: number;
}

export const StatsContainer: React.FC<IStatsContainerProps> = ({ availableCouriers, busyCouriers }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.statsContainer}>
            <Grid container spacing={2}>
                <Grid item md={6} className={classes.legend}>
                    <div className={classes.stats}>
                        <Avatar className={`${classes.statsAvatar} ${classes.availableCouriers}`} alt={String(availableCouriers)}>{availableCouriers}</Avatar>
                    </div>
                    <div className={classes.stats}>
                        <Typography variant="body2">Entregadores<br />disponíveis</Typography>
                    </div>
                </Grid>
                <Grid item md={6} className={classes.legend}>
                    <div className={classes.stats}>
                        <Avatar className={`${classes.statsAvatar} ${classes.busyCouriers}`} alt={String(busyCouriers)}>{busyCouriers}</Avatar>
                    </div>
                    <div className={classes.stats}>
                        <Typography variant="body2">Entregadores<br />indisponíveis</Typography>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

const useStyles = makeStyles({
    statsContainer: {
        position: "absolute",
        zIndex: 601,
        padding: theme.spacing(2),
        left: theme.spacing(2),
        bottom: theme.spacing(2),
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    legend: {
        display: "flex",
        alignItems: "center",
    },
    stats: {
        display: "inline-block",
    },
    statsAvatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginRight: theme.spacing(1),
    },
    availableCouriers: {
        backgroundColor: theme.palette.primary.main
    },
    busyCouriers: {
        backgroundColor: theme.palette.secondary.main
    },
});