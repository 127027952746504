import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import DeliveryRequestService from "../../services/deliveryRequestService";
import TripService from "../../services/tripService";
import {
  DeliveryRequest,
  Trip,
} from "../../services/types/deliveryRequest";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { HistoryTimelineTrip } from "./timeLineTrip";
import { MapTripDetail } from "./mapTripDetail";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </div>
  );
}
interface IParams {
  tripId: string;
}
const REFRESH_INTERVAL = 20000;

const RequestsTrip: React.FC = () => {
  const { tripId } = useParams<IParams>();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [openLoadingOverlay, showLoadingOverlay] = React.useState(false);
  const [deliveryList, setDeliveryList] = React.useState<DeliveryRequest[]>();
  const [trip, setTrip] = React.useState<Trip>({} as Trip);

  const loadData = useCallback(async (tripId: number) => {
    try {
      showLoadingOverlay(true);
      const trip = await TripService.getTripById(tripId);
      setTrip(trip);

      const promises = trip.deliveries.map((delivery) => {
        return DeliveryRequestService.getDeliveryRequestById(delivery.id);
      });
      const deliveryList = await Promise.all(promises);
      setDeliveryList(deliveryList);
    } catch (error) {
      alert(error);
    } finally {
      showLoadingOverlay(false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (trip) {
        loadData(Number(tripId));
      }
    }, REFRESH_INTERVAL);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [tripId, trip, loadData]);

  useEffect(() => {
    loadData(Number(tripId));
  }, [tripId, loadData]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  return (
    <React.Fragment>
      <LoadingOverlay open={openLoadingOverlay} />
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h1">Detalhes da Viagem</Typography>
        </Grid>
      </Grid>
      <Box my={1}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          className="bordered-tab"
        >
          <Tab label="Informações" />
          <Tab label="Linha do Tempo" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <MapTripDetail tripId={Number(tripId)} trip={trip} deliveryList={deliveryList} loadData={loadData} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <HistoryTimelineTrip trip={trip} />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};


export default RequestsTrip;
