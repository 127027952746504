import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

export interface BannerMessage {
  severity: "error" | "warning";
  content: string | React.ReactNode;
}

interface BannerProps {
  messages: BannerMessage[];
  interval?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      borderRadius: "0px",
    },
  })
);

export const Banner: React.FC<BannerProps> = ({ messages, interval = 10000 }) => {
  const classes = useStyles();

  const [visibleMessage, setVisibleMessage] = React.useState<BannerMessage>();
  const [messageIndex, setMessageIndex] = React.useState<number>(-1);

  const incrementIndex = React.useCallback((index: number) => {
    if (messages && (messages.length > 0)) {
      if (messages.length > (index + 1)) {
        setMessageIndex(index + 1);
      } else {
        setMessageIndex(0);
      }
    } else {
      setMessageIndex(-1);
    }
  }, [messages]);

  React.useEffect(() => {
    if ((visibleMessage === undefined) && messages?.length) {
      incrementIndex(messageIndex);
    }
  }, [messages, incrementIndex, messageIndex, visibleMessage]);

  React.useEffect(() => {
    if (messageIndex >= 0) {
      setVisibleMessage(messages[messageIndex]);
    } else {
      setVisibleMessage(undefined);
    }
  }, [messageIndex, messages]);

  React.useEffect(() => {
    const clock = setTimeout(() => {
      incrementIndex(messageIndex);
    }, interval);

    return () => {
      if (clock) {
        clearTimeout(clock);
      }
    };
  }, [messageIndex, incrementIndex, interval]);

  return (
    <React.Fragment>
      {visibleMessage && (
        <Alert
          variant="filled"
          severity={visibleMessage.severity}
          className={classes.banner}
        >
          {visibleMessage.content}
        </Alert>
      )}
    </React.Fragment>
  );
};
