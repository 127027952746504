import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY, TOKEN_EXP_STORAGE_KEY } from "../contexts/AuthContext";
import AuthService from "../services/authService";
import { createBrowserHistory } from "history";


function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
}

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
}

export const apiAxios = axios.create({
  withCredentials: false,
  timeout: 30 * 1000, //30 seconds
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

// Request interceptor
apiAxios.interceptors.request.use((request) => {
  const token = getAccessToken();
  if (token) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
  return request;
}, (error) => {
  const status = error.response.status;
  if (status === 403) {
    //TODO mostrar mensagem de erro dizendo que o usuário não tem permissão para isso
  }
  return Promise.reject(error);
});

//const { logout } = useAuth() as any;
const history = createBrowserHistory();

const handleDisconnect = () => {
  localStorage.clear();
  window.location.reload();
  history.push("/");
}

// Response interceptor
apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorCode = error.code;
    if (errorCode === "ECONNABORTED") {
      console.warn(error.message);
    }
    const status = error.response?.status;
    if (status === 403) {
      //TODO mostrar mensagem de erro dizendo que o usuário não tem permissão para isso
    }
    if(status === 401){    
      const codeType = error.response?.data?.code;
      //Token expirado
      if(codeType && codeType === 'token_not_valid'){      
        configExpiredAccessTokenInterceptor(handleDisconnect);
      }
    }   
    return Promise.reject(error);
});

export const configExpiredAccessTokenInterceptor = (disconnect: Function) => {
  const refreshAuthLogic = (failedRequest: any) => AuthService.getNewToken(getRefreshToken()).then(tokenRefreshResponse => {
    const jwtDecoded: any = jwtDecode(tokenRefreshResponse.data.access);

    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, tokenRefreshResponse.data.refresh);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, tokenRefreshResponse.data.access);
    localStorage.setItem(TOKEN_EXP_STORAGE_KEY, jwtDecoded.exp || 0);

    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access;
    return Promise.resolve();
  }).catch(error => {
    disconnect();
    return Promise.reject(error);
  });

  createAuthRefreshInterceptor(apiAxios, refreshAuthLogic);
}
