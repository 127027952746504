import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import StatisticsService from "../../services/statisticsService";
import { DeliveriesPerDayResponse, DeliveriesPerDistrictResponse, StatisticsExtraDetalsResponse } from "../../services/types/statistics";
import { endOfDay, startOfDay } from "date-fns";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { INITIAL_FILTER_PARAMS, IStatisticsFilterParams, StatisticsFilter } from "./statisticsFilter";
import { CustomerExtraDetailsPanel } from "./customComponents/customerExtraDetailsPanel";
import { QuantityDeliveries } from "./customComponents/quantityDeliveries";
import useStoreContext from "../../hooks/useStoreContext";
import { DeliveriesPerDistrict } from "./customComponents/deliveriesPerDistrict";
import { LoginStore } from "../../services/types/store";

const defaultDays = 15;
var today = new Date();
//var lastDay = new Date(today.getFullYear(), today.getMonth(), 1); // last day month
var defaultDaySearch = new Date(today.setDate(today.getDate() - defaultDays)); //default 15 days before

export const Statistics: React.FC = ({       
    }) => {
    const [filterParams, setFilterParams] = useState<IStatisticsFilterParams>(INITIAL_FILTER_PARAMS);
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
    const { activeStore } = useStoreContext();
    const [extraDetails, setExtraDetails] = useState<StatisticsExtraDetalsResponse[]>([]);
    const [deliveriesPerDay, setDeliveriesPerDay] = useState<DeliveriesPerDayResponse[]>([]);
    const [deliveriesPerDistrict, setDeliveriesPerDistrict] = useState<DeliveriesPerDistrictResponse[]>([]);
 
    const applyFilter = (params: IStatisticsFilterParams) => {
        setFilterParams(params);
        if(activeStore){
            loadAll(params.date_after, params.date_before, activeStore);
        }
    };

    const loadAll = useCallback(async (dateAfter: Date, dateBefore: Date, store: LoginStore) => {
        const storeId = store.id;
        if (storeId) {
            showLoadingDialog(true);
            Promise.all([StatisticsService.getExtraDetailsPanel(
                storeId,
                endOfDay(dateAfter),
                startOfDay(dateBefore)
            ), StatisticsService.getQuantityDeliveriesPanel(
                storeId,
                endOfDay(dateAfter),
                startOfDay(dateBefore)
            ), StatisticsService.getDeliveriesPerDistrictPanel(
                storeId,
                endOfDay(dateAfter),
                startOfDay(dateBefore)
            )
            ]).then((response) => {
                setExtraDetails(response[0]);
                setDeliveriesPerDay(response[1]);
                setDeliveriesPerDistrict(response[2]);
                showLoadingDialog(false);

            }).catch(() => {
                showLoadingDialog(false);
            });
        }
    }, []);

    const refreshLoadAll = React.useCallback((store: LoginStore) => {
        loadAll(defaultDaySearch, new Date(), store);        
    }, []);

    React.useEffect(() => {
        if(activeStore){
            refreshLoadAll(activeStore);
        }
    }, [activeStore]);

    return (
        <div>
            <LoadingOverlay open={isShowLoadingDialog} />

            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Estatísticas</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
            <Grid md={12}>
                <StatisticsFilter onFilterChanged={applyFilter} />
            </Grid>

            <Grid md={12} style={{ marginTop: "30px" }}>
                <CustomerExtraDetailsPanel extraDetails={extraDetails} />
            </Grid>

            <Grid md={12} style={{ marginTop: "30px" }}>
                <QuantityDeliveries deliveriesPerDay={deliveriesPerDay} dateAfter={filterParams.date_after} dateBefore={filterParams.date_before} />
            </Grid>

            <Grid md={12} style={{ marginTop: "30px" }}>
                <DeliveriesPerDistrict deliveriesPerDistrict={deliveriesPerDistrict} />
            </Grid>
        </div>
    );
};
export default Statistics;

