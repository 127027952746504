import {
  DirectionsBikeOutlined,
  DirectionsCarOutlined,
  MotorcycleOutlined,
  Storefront,
  HouseOutlined,
} from "@material-ui/icons";

interface ICoord {
  lat: number;
  lng: number;
}

export interface IMarkerCoord extends ICoord {
  title: string;
  type: string;
}

export const ICON_MAP = [
  {
    type: "CAR",
    icon: <DirectionsCarOutlined />,
    color: "#8E02F5",
  },
  {
    type: "BIKE",
    icon: <DirectionsBikeOutlined />,
    color: "#8E02F5",
  },
  {
    type: "MOTORCYCLE",
    icon: <MotorcycleOutlined />,
    color: "#8E02F5",
  },
  {
    type: "STORE",
    icon: <Storefront />,
    color: "#0080FF",
  },
  {
    type: "HOUSE",
    icon: <HouseOutlined />,
    color: "#0080FF",
  },
  {
    type: "HOUSE_FINISH",
    icon: <HouseOutlined />,
    color: "#4caf50",
  },
  {
    type: "HOUSE_CANCELED",
    icon: <HouseOutlined />,
    color: "#d74545",
  },
  {
    type: "HOUSE_RETURNING",
    icon: <HouseOutlined />,
    color: "#eb7405",
  }

];
