import axios from "axios";
import { formatISO } from "date-fns";
import { getURL, urls } from "../utils/urls";
import { convertToDate } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { DebitNotesResponse } from "./types/debitNotes";

export interface IDebitNotesServiceService {
    getDebitNotes: (
        customerId: number | null,
        date_after: Date | null,
        date_before: Date | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<DebitNotesResponse>>;

    downloadNoteFiscal: (url: string) => Promise<any>;

    downloadDebitNote: (debitNoteId: number) => Promise<any>;
}

const DebitNotesService: IDebitNotesServiceService = {
    getDebitNotes: async (
        customerId: number | null,
        date_after: Date | null,
        date_before: Date | null,
        pageParams: IPageableParams
    ) => {
        let dateAfter = date_after ? formatISO(date_after, { representation: 'date' }) : null;
        let dateBefore = date_before ? formatISO(date_before, { representation: 'date' }) : null;

        const url = getURL(urls.DEBIT_REPORTS, {
            ...pageParams,
            customer: customerId,
            reference_date_after: dateAfter,
            reference_date_before: dateBefore,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: DebitNotesResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            id: Number(item.id),
                            created_at: convertToDate(item.created_at),
                            emission_date: convertToDate(item.emission_date),
                            reference_date: convertToDate(item.reference_date),
                            updated_at: convertToDate(item.emission_date),
                        };
                    });
                    const result: PagedResponse<DebitNotesResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    downloadNoteFiscal: async (urlNote: string) => {
        const url = urlNote;

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    responseType: "blob",
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },

    downloadDebitNote: (debitNoteId: number) => {
        const url = `${urls.DEBIT_REPORTS}${debitNoteId}/pdf_report/`;

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    responseType: "blob",
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },

};

export default DebitNotesService;
