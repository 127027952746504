export const CityList: string[] = ["Natal - RN", "Parnamirim - RN", "Cabedelo - PB", "João Pessoa - PB"];

export const DistrictList = new Map<string, string[]>();

DistrictList.set("Natal - RN", ["Alecrim", "Areia Preta", "Barro Vermelho", "Bom Pastor", "Candelária", "Capim Macio", "Cidade Alta", "Cidade da Esperança", "Cidade Nova", "Dix-Sept Rosado", "Felipe Camarão", "Guarapes", "Igapó", "Lagoa Azul", "Lagoa Nova", "Lagoa Seca", "Mãe Luíza", "Neópolis", "Nordeste", "Nossa Senhora da Apresentação", "Nossa Senhora de Nazaré", "Nova Descoberta", "Pajuçara", "Petrópolis", "Pitimbu", "Planalto", "Ponta Negra", "Potengi", "Praia do Meio", "Quintas", "Redinha", "Ribeira", "Rocas", "Salinas", "Santos Reis", "Tirol"]);
DistrictList.set("Parnamirim - RN", ["Água Vermelha", "Antônio Fernandes", "Bela Parnamirim", "Boa Esperança", "Cajupiranga", "Centro", "Cohabinal", "Cotovelo", "Cotovelo - Distrito Litoral", "Cotovelo Distrito Litoral", "Distrito Industrial", "Eduardo Gomes", "Emaús", "Jardim América", "Jardim Cohabinal", "Jardim Planalto", "Jockey", "Liberdade", "Loteamento Taborda", "Marília", "Marina", "Monte Castelo", "Nova Esperança", "Nova Parnamirim", "Parnamirim", "Parque das Árvores", "Parque das Nações", "Parque de Exposições", "Parque do Jiqui", "Parque do Pitimbu", "Parque Exposições", "Parque Industrial", "Parque Nações", "Passagem de Areia", "Pirangi Dentro", "Pirangi do Norte", "Pirangi N Distrito Litoral", "Pirangi Norte", "Pium", "Pium - Distrito Litoral", "Pium Distrito Litoral", "Primavera", "Rosa dos Ventos", "Santa Tereza", "Santos Reis", "Vale do Sol", "Vida Nova", "Vila Sol"]);
DistrictList.set("Cabedelo - PB", ["Amazônia Park", "Areia Dourada", "Camalaú", "Camboinha", "Centro", "Formosa", "Intermares", "Jacaré", "Jardim Camboinha", "Poço", "Ponta de Campina", "Ponta de Matos", "Portal do Poço", "Recanto do Poço", "Santa Catarina ", "Morada Nova", "Parque Esperança", "Renascer"]);
DistrictList.set("João Pessoa - PB", ["Água Fria", "Aeroclube", "Altiplano", "Alto do Céu", "Alto do Mateus", "Anatólia", "Bairro das Indústrias", "Bairro dos Estados", "Bairro dos Ipês", "Bairro dos Novais", "Bancários", "Barra de Gramame", "Bessa", "Brisamar", "Cabo Branco", "Castelo Branco", "Centro", "Cidade dos Colibris", "Costa do Sol", "Costa e Silva", "Cristo Redentor", "Cruz das Armas", "Cuiá", "Distrito Industrial", "Ernesto Geisel", "Ernâni Sátiro", "Expedicionários", "Funcionários II", "Funcionários III", "Gramame", "Grotão", "Ilha do Bispo", "Jaguaribe", "Jardim 13 de Maio", "Jardim Cidade Universitária", "Jardim Esther", "Jardim Luna", "Jardim Mangueira", "Jardim Oceania", "Jardim Planalto", "Jardim São Paulo", "Jardim Veneza", "José Américo", "João Agripino", "João Paulo II", "Loteamento Panorâmica I e II", "Manaíra", "Mandacaru", "Mangabeira", "Mata do Buraquinho", "Miramar", "Mumbaba", "Mussuré", "Muçumagro", "Oitizeiro", "Padre Zé", "Paratibe", "Pedro Gondim", "Penha", "Planalto da Boa Esperança", "Ponta dos Seixas", "Portal do Sol", "Quadramares[4]", "Rangel", "Róger", "São José", "Tambaú", "Tambauzinho", "Tambiá", "Torre", "Trincheiras", "Valentina Figueiredo", "Varadouro", "Varjão"]);

/*
{
    "Natal - RN": ["Alecrim", "Areia Preta", "Barro Vermelho", "Bom Pastor", "Candelária", "Capim Macio", "Cidade Alta", "Cidade da Esperança", "Cidade Nova", "Dix-Sept Rosado", "Felipe Camarão", "Guarapes", "Igapó", "Lagoa Azul", "Lagoa Nova", "Lagoa Seca", "Mãe Luíza", "Neópolis", "Nordeste", "Nossa Senhora da Apresentação", "Nossa Senhora de Nazaré", "Nova Descoberta", "Pajuçara", "Petrópolis", "Pitimbu", "Planalto", "Ponta Negra", "Potengi", "Praia do Meio", "Quintas", "Redinha", "Ribeira", "Rocas", "Salinas", "Santos Reis", "Tirol"],
    "Parnamirim - RN": ["Água Vermelha", "Antônio Fernandes", "Bela Parnamirim", "Boa Esperança", "Cajupiranga", "Centro", "Cohabinal", "Cotovelo", "Cotovelo - Distrito Litoral", "Cotovelo Distrito Litoral", "Distrito Industrial", "Eduardo Gomes", "Emaús", "Jardim América", "Jardim Cohabinal", "Jardim Planalto", "Jockey", "Liberdade", "Loteamento Taborda", "Marília", "Marina", "Monte Castelo", "Nova Esperança", "Nova Parnamirim", "Parnamirim", "Parque das Árvores", "Parque das Nações", "Parque de Exposições", "Parque do Jiqui", "Parque do Pitimbu", "Parque Exposições", "Parque Industrial", "Parque Nações", "Passagem de Areia", "Pirangi Dentro", "Pirangi do Norte", "Pirangi N Distrito Litoral", "Pirangi Norte", "Pium", "Pium - Distrito Litoral", "Pium Distrito Litoral", "Primavera", "Rosa dos Ventos", "Santa Tereza", "Santos Reis", "Vale do Sol", "Vida Nova", "Vila Sol"],
    "Cabedelo - PB": ["Amazônia Park", "Areia Dourada", "Camalaú", "Camboinha", "Centro", "Formosa", "Intermares", "Jacaré", "Jardim Camboinha", "Poço", "Ponta de Campina", "Ponta de Matos", "Portal do Poço", "Recanto do Poço", "Santa Catarina ", "Morada Nova", "Parque Esperança", "Renascer"],
    "João Pessoa - PB": ["Água Fria", "Aeroclube", "Altiplano", "Alto do Céu", "Alto do Mateus", "Anatólia", "Bairro das Indústrias", "Bairro dos Estados", "Bairro dos Ipês", "Bairro dos Novais", "Bancários", "Barra de Gramame", "Bessa", "Jardim Brisamar", "Cabo Branco", "Castelo Branco", "Centro", "Cidade dos Colibris", "Costa do Sol", "Costa e Silva", "Cristo Redentor", "Cruz das Armas", "Cuiá", "Distrito Industrial", "Ernesto Geisel", "Ernâni Sátiro", "Expedicionários", "Funcionários II", "Funcionários III", "Gramame", "Grotão", "Ilha do Bispo", "Jaguaribe", "Jardim 13 de Maio", "Jardim Cidade Universitária", "Jardim Esther", "Jardim Luna", "Jardim Mangueira", "Jardim Oceania", "Jardim Planalto", "Jardim São Paulo", "Jardim Veneza", "José Américo", "João Agripino", "João Paulo II", "Manaíra", "Mandacaru", "Mangabeira", "Mata do Buraquinho", "Miramar", "Mumbaba", "Mussuré", "Muçumagro", "Oitizeiro", "Padre Zé", "Paratibe", "Pedro Gondim", "Penha", "Planalto da Boa Esperança", "Ponta dos Seixas", "Portal do Sol", "Quadramares[4]", "Rangel", "Róger", "São José", "Tambaú", "Tambauzinho", "Tambiá", "Torre", "Trincheiras", "Valentina Figueiredo", "Varadouro", "Varjão"]
}
*/
