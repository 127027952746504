import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";
import QRCode from "react-qr-code";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import RechargeFilter, { INITIAL_FILTER_PARAMS, IRechargeFilterParams } from "./RechargeFilter";
import InsertCredits from "./insertCredits";
import MaterialTable, {
  MaterialTableProps,
  Query,
  QueryResult,
} from "@material-table/core";
import {
  CustomerFinancialEntryResponse,
  FinancialEntryOrigin,
  FinancialEntryStatus,
  FinancialEntryType,
} from "../../../services/types/customerFinance";
import CustomerFinancesService from "../../../services/customerFinancesService";
import {
  CURRENCY_COLUMN_DEFAULTS,
  DATE_TIME_COLUMN_DEFAULTS,
  DEFAULT_TABLE_COMPONENTS,
  DEFAULT_TABLE_OPTIONS,
  PageableParams,
  TABLE_L10N_PTBR,
} from "../../../components/TableTrinkets";
import { mapFinanceStatusToSeverity } from "../customerFinanceUtils";
import { Description, Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { BarcodeIcon, PixIcon } from "../../../components/Icons";
import  ContentCopyIcon  from "@material-ui/icons/FileCopy";

//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const FinancesRecharge: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [pixDetails, setPixDetails] = useState<any>();
  const [copyPix, setCopyPix] = useState<boolean>(false);

  const [filterParams, setFilterParams] = useState<IRechargeFilterParams>(
    INITIAL_FILTER_PARAMS
  );
  const tableRef =
    React.useRef<MaterialTableProps<CustomerFinancialEntryResponse>>();

  const [showInsertCredits, setShowInsertCredits] = useState(false);

  const loadData = (query: Query<CustomerFinancialEntryResponse>) =>
    new Promise<QueryResult<CustomerFinancialEntryResponse>>(
      (resolve, reject) => {
        CustomerFinancesService.getCustomerFinancialEntries(
          filterParams.createdAtAfter,
          filterParams.createdAtBefore,
          FinancialEntryType.CREDIT,
          [
            FinancialEntryOrigin.BANK_SLIP,
            FinancialEntryOrigin.CREDIT_CARD,
            FinancialEntryOrigin.TRANSFER,
            FinancialEntryOrigin.PIX,
          ],
          PageableParams.fromQuery(query)
        ).then(response => {
          console.error(response);
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          });
        });
      }
    );

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<CustomerFinancialEntryResponse>);
    }
  };

  const applyFilter = (params: IRechargeFilterParams) => {
    setFilterParams(params);
    refresh();
  };

  const handleShowInsertCredits = () => {
    setShowInsertCredits(true);
  };

  const downloadFile = async (entry: CustomerFinancialEntryResponse) => {
    try {
      const bankSlip = await CustomerFinancesService.getBankSlipDocument(
        entry.id
      );

      const newWindow: any = window.open("about:blank");
      newWindow.document.open();
      newWindow.document.write(bankSlip);
      newWindow.document.close();
    } catch (error) {
      enqueueSnackbar("Não foi possível fazer download do documento.", {
        variant: "error",
      });
    }
  };

  function copyBarcode(item: CustomerFinancialEntryResponse) {
    if (item.bank_slip_barcode) {
      let input = document.createElement("textarea");
      input.innerHTML = item.bank_slip_barcode || "";
      document.body.appendChild(input);
      input.select();
      let result = document.execCommand("copy");
      document.body.removeChild(input);
      
      if(result){
        enqueueSnackbar("Código de barra copiado com sucesso.", {
          variant: "success",
        });
      }else{
        enqueueSnackbar("Falha ao copiar Código de Barra.", {
          variant: "error",
        });
      }
      return result;
    }
  };


  const copyPixKey = async (pix: string) => {
    if (pix) {     
      navigator.clipboard.writeText(pix);         
      setInterval(() => {
        setCopyPix(false);
      }, 3000);        
    }  
  };

  return (
    <React.Fragment>
      <InsertCredits
        open={showInsertCredits}
        setOpen={setShowInsertCredits}
        updateFinances={refresh}
        setPixDetails={setPixDetails}
        setOpenDialog={setOpenDialog}
      />

      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h1">Recarga</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowInsertCredits}
            >
              Adicionar Créditos
            </Button>
          </Grid>
        </Hidden>
      </Grid>

      <RechargeFilter onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 160 },
          {
            ...DATE_TIME_COLUMN_DEFAULTS,
            field: "created_at",
            title: "Data da Recarga",
            defaultSort: "desc",
          },
          {
            ...CURRENCY_COLUMN_DEFAULTS,
            field: "value",
            title: "Valor",
          },
          {
            field: "origin",
            title: "Forma de Pagamento",
            render: (rowData, type) =>
              t("financial_entry_origin." + rowData.origin),
          },
          {
            field: "status",
            title: "Situação",
            render: (rowData, type) => (
              <Alert
                severity={mapFinanceStatusToSeverity(rowData.status)}
                icon={false}
              >
                {t("financial_entry_status." + rowData.status)}
              </Alert>
            ),
          },
        ]}
        data={loadData}
        actions={[
          (rowData) => rowData && rowData.origin === FinancialEntryOrigin.PIX ?
          {
            icon: () => <PixIcon />,
            tooltip: "Visualizar QRCode",
            onClick: (event, rowData) => {
              setPixDetails(rowData);
              setOpenDialog(true);
            },
            hidden: [
              FinancialEntryStatus.PAID,
              FinancialEntryStatus.CANCELED,
            ].includes(rowData.status),
          } : {
            icon: () => <BarcodeIcon />,
            tooltip: "Copiar código de barras",
            onClick: (event, rowData) =>
              copyBarcode(rowData as CustomerFinancialEntryResponse),
            hidden: [
              FinancialEntryStatus.PAID,
              FinancialEntryStatus.CANCELED,
            ].includes(rowData.status),
          },

          (rowData) => rowData && rowData.origin === FinancialEntryOrigin.BANK_SLIP ?
          {
            icon: () => <Description />,
            tooltip: "Visualizar Documento",
            onClick: (event, rowData) =>
              downloadFile(rowData as CustomerFinancialEntryResponse),
            hidden: [
              FinancialEntryStatus.PAID,
              FinancialEntryStatus.CANCELED,
            ].includes(rowData.status),
          } : {
            icon: () => <Description style={{ display: "none" }} />,
            onClick: () => {},
          },
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          draggable: false
        }}
      />

      <Dialog
        open={openDialog}
        maxWidth="md"
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h1">Pagamento</Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpenDialog(false)}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: "320px" }}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="dark"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <strong>Valor do pix</strong>
                <strong>{numbro(pixDetails?.value).formatCurrency()}</strong>
              </Typography>
              <Typography
                variant="body1"
                className="dark"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <strong>Taxa</strong>
                <strong>{numbro(pixDetails?.tax).formatCurrency()}</strong>
              </Typography>
              <hr />
              <Typography
                variant="body1"
                className="dark"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <strong>Total</strong>
                <strong>{numbro(pixDetails?.total).formatCurrency()}</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} style={{position: "relative", textAlign: "center"}}>
              <QRCode value={pixDetails?.bank_slip_barcode || ""} />
            </Grid>
            <Grid item xs={12}>
            <Typography
                variant="body1"
                className="dark"
              >
                <strong>Pix Copia e Cola</strong>
                <Grid container spacing={1}>
                  <Grid item >
                    <TextField
                      id="corporate_name"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={pixDetails?.bank_slip_barcode || ""}
                      disabled
                      onClick={(e) => {
                        setCopyPix(true); 
                        copyPixKey(pixDetails?.bank_slip_barcode || "")}
                      }
                    />
                  </Grid>
                  <Grid item style={{paddingTop: "8px"}}>
                    <Button variant="outlined" onClick={(e) => {
                      setCopyPix(true); 
                      copyPixKey(pixDetails?.bank_slip_barcode || "")
                      }} startIcon={<ContentCopyIcon />}>
                      Copiar
                    </Button>
                  </Grid>
                </Grid>
                
               
               
                {copyPix ? <strong><span style={{color: 'green'}}>Copiado.</span></strong> : null}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => setOpenDialog(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FinancesRecharge;
