import React, { createContext, useCallback, useReducer } from "react";
import type { FC, ReactNode } from "react";

interface GlobalSearchState {
  globalSearchPlaceholder: string;
  globalSearchIsVisible: boolean;
  globalSearchValue: string;
}

interface GlobalSearchValue extends GlobalSearchState {
  setGlobalSearchValue: (value: string) => void;
  showGlobalSearch: (placeholder: string) => void;
  hideGlobalSearch: () => void;
}

interface GlobalSearchProviderProps {
  children: ReactNode;
}

enum GlobalSearchActions {
  SHOW,
  HIDE,
  CHANGE
}

type ShowAction = {
  type: GlobalSearchActions.SHOW;
  payload: {
    placeholder: string;
  };
};

type HideAction = {
  type: GlobalSearchActions.HIDE;
};

type ChangeTextAction = {
  type: GlobalSearchActions.CHANGE;
  payload: {
    globalSearchValue: string;
  };
};

type Action = ShowAction | HideAction | ChangeTextAction;

const initialGlobalSearchState: GlobalSearchState = {
  globalSearchPlaceholder: "",
  globalSearchIsVisible: false,
  globalSearchValue: "",
};

const reducer = (state: GlobalSearchState, action: Action): GlobalSearchState => {
  switch (action.type) {
    case GlobalSearchActions.CHANGE: {
      const { globalSearchValue } = action.payload;
      return {
        ...state,
        globalSearchValue: globalSearchValue
      };
    }
    case GlobalSearchActions.SHOW: {
      const { placeholder } = action.payload;
      return {
        ...state,
        globalSearchIsVisible: true,
        globalSearchPlaceholder: placeholder,
      };
    }
    case GlobalSearchActions.HIDE: {
      return {
        ...state,
        globalSearchIsVisible: false,
        globalSearchValue: ""
      };
    }
    default: {
      return { ...state };
    }
  }
};

const GlobalSearchContext = createContext<GlobalSearchValue>({
  ...initialGlobalSearchState,
  setGlobalSearchValue: (value: string) => { },
  showGlobalSearch: (placeholder: string) => { },
  hideGlobalSearch: () => { }
});

export const GlobalSearchProvider: FC<GlobalSearchProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialGlobalSearchState);

  const setGlobalSearchValue = useCallback(async (value: string) => {
    dispatch({
      type: GlobalSearchActions.CHANGE,
      payload: {
        globalSearchValue: value,
      },
    });
  }, []);

  const showGlobalSearch = useCallback((placeholder: string) => {
    dispatch({ 
      type: GlobalSearchActions.SHOW,
      payload: {
        placeholder: placeholder
      }
    });
  }, []);

  const hideGlobalSearch = useCallback(() => {
    dispatch({ type: GlobalSearchActions.HIDE });
  }, []);

  return (
    <GlobalSearchContext.Provider
      value={{
        ...state,
        setGlobalSearchValue,
        showGlobalSearch,
        hideGlobalSearch
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};

export default GlobalSearchContext;
