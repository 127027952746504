import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { DeliveryRequestEvent, DeliveryRequestLog } from "../../services/types/deliveryRequest";
import { TimelineOppositeContent } from "@material-ui/lab";

interface IProps {
  data: DeliveryRequestLog[];
}

export const HistoryTimeline = ({ data }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [logs, setLogs] = React.useState<DeliveryRequestLog[]>([]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setLogs(data.filter((item) => {
        return [
          DeliveryRequestEvent.CREATED,
          DeliveryRequestEvent.COURIER_SELECTED,
          DeliveryRequestEvent.COURIER_IGNORED_REQUEST,
          DeliveryRequestEvent.COURIER_REFUSED_REQUEST,
          DeliveryRequestEvent.COURIER_ACCEPTED_REQUEST,
          DeliveryRequestEvent.COURIER_CHECK_IN,
          DeliveryRequestEvent.STORE_RELEASED_ORDER,
          DeliveryRequestEvent.COURIER_DELIVERED_ORDER,
          DeliveryRequestEvent.COURIER_CHECK_OUT,
          DeliveryRequestEvent.COURIER_ARRIVED_DESTINATION,
          DeliveryRequestEvent.CUSTOMER_CANCELED_ORDER,
          DeliveryRequestEvent.COURIER_CHECK_IN_FINISH,
          DeliveryRequestEvent.DELIVERY_FINISHED
        ].includes(item.event);
      }));
    } else {
      setLogs([]);
    }
  }, [data]);

  return (
    <Timeline align="right">
      {logs
        .sort((a, b) => b.id - a.id)
        .map(
          (item) => (
            <TimelineItem>
              <TimelineContent>
                {!item.is_courier_action && (
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="caption">
                        {format(item.created_at, "dd/MM/yyyy 'às' HH:mm")}
                      </Typography>
                      <Typography className={classes.title}>
                        {t("delivery_request_event." + item.event)}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </TimelineContent>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent>
                {item.is_courier_action && (
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="caption">
                        {format(item.created_at, "dd/MM/yyyy 'às' HH:mm")}
                      </Typography>
                      <Typography className={classes.title}>
                        {t("delivery_request_event." + item.event)}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </TimelineOppositeContent>
            </TimelineItem>
          )
        )
      }
    </Timeline>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "1rem",
    },
  })
);
