import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { DRAWER_WIDTH } from "../../constants";
import theme from "../../styles/theme";
import { GlobalSearchProvider } from "../../contexts/GlobalSearchContext";
import { Banner } from "../../components/Banner";
import useAppContext from "../../hooks/useAppContext";
import { StoreContextProvider } from "../../contexts/StoreContext";
interface AuthenticatedLayoutProps {
    children?: React.ReactNode;
}

const drawerWidth = DRAWER_WIDTH;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: theme.palette.background.paper,
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
    },
    content: {
        flexGrow: 1,
        width: "100%",
        overflow: "auto",
        padding: theme.spacing(2),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop: "64px",
    },
    contentDynamic: {
        flexGrow: 1,
        width: "100%",
        overflow: "auto",
        padding: theme.spacing(2),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop: "110px",
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ children }) => {
    const classes = useStyles();
    const { isMainMenuVisible, appAlerts, containAppAlerts } = useAppContext();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className={classes.root}>
            <StoreContextProvider>
                <GlobalSearchProvider>
                    <Topbar>
                        <Banner messages={appAlerts}></Banner>
                    </Topbar>
                    <Sidebar containAppAlerts={containAppAlerts} />
                    <main
                        style={{ display: "flex", flexDirection: "column" }}
                        className={
                            containAppAlerts
                                ? clsx(classes.contentDynamic, {
                                    [classes.contentShift]:
                                        smallScreen || (isMainMenuVisible && !smallScreen),
                                })
                                : clsx(classes.content, {
                                    [classes.contentShift]:
                                        smallScreen || (isMainMenuVisible && !smallScreen),
                                })
                        }
                    >
                        {children}
                    </main>
                </GlobalSearchProvider>
            </StoreContextProvider>
        </div>
    );
};

AuthenticatedLayout.propTypes = {
    children: PropTypes.node,
};

export default AuthenticatedLayout;
