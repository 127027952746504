import React, { useEffect, useMemo } from "react";
import cep, { AvailableProviders, CEP } from "cep-promise";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { $enum } from "ts-enum-util";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from "@material-ui/core/styles";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { locationByAddress } from "../../utils/getLocation";
import { PostalCodeInput } from "../../components/InputMasks";
import { DeliveryRequestReturnType, FinishedDeliveryRequest } from "../../services/types/deliveryRequest";
import * as Yup from "yup";
import { postalCodeMaskRegex, noSpecialCharactersRegex } from "../../utils/masks";
import { Autocomplete } from "@material-ui/lab";
import CourierService from "../../services/courierService";
import { debounce } from "lodash";
import { ImgLoader } from "../../components/ImgLoader";
import ShowIf from "../../components/ShowIf";
import { CityList, DistrictList } from "../../data/bairros";
import usePlacesAutocomplete, { getDetails, Suggestion } from "use-places-autocomplete";
import RegionService from "../../services/regionService";
import { calcNeSwBounds, clearStreetName } from "../../utils/utils";
import useStoreContext from "../../hooks/useStoreContext";
import { formatPhoneNumber } from "../../utils/formatters";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../i18n";

export const INITIAL_VALUES: FinishedDeliveryRequest = {
  order_number: "",
  order_date: new Date(),
  consignee: "",
  consignee_phone_number: "",
  return_type: "none",
  postal_code: "",
  street: "",
  address_number: "",
  district: "",
  city: "",
  state: "",
  latitude: "",
  longitude: "",
  store: "",
  current_courier: "",
  addressType: "complete"
};

export const createFinishedDeliveryRequestValidation = Yup.object().shape({
  order_number: Yup.string()
    .max(8, "Máximo de 8 caracteres excedido")
    .matches(noSpecialCharactersRegex, "Número do pedido inválido")
    .required("Campo obrigatório"),
  order_date: Yup.date()
    .required("Campo obrigatório"),
  consignee: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(noSpecialCharactersRegex, "O nome não pode ter caracteres especiais")
    .optional(),
  return_type: Yup.string().required("Campo obrigatório"),
  postal_code: Yup.string()
    .when("addressType", {
      is: (value) => value === "complete",
      then: Yup.string().nullable().optional()
        .matches(postalCodeMaskRegex, "CEP inválido"),
      otherwise: Yup.string().nullable().optional(),
    }),
  street: Yup.string()
    .when("addressType", {
      is: (value) => value === "complete",
      then: Yup.string().nullable()
        .max(100, "Máximo de 100 caracteres excedido")
        .required("Campo obrigatório"),
      otherwise: Yup.string().nullable().optional(),
    }),
  address_number: Yup.string()
    .max(10, "Máximo de 10 caracteres excedido"),
  district: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  city: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  state: Yup.string()
    .min(2, "Mínimo de 2 caracteres")
    .max(2, "Máximo de 2 caracteres excedido")
    .required("Campo obrigatório"),
  current_courier: Yup.number()
    .required("Campo obrigatório"),
});

interface ICreateDeliveryRequestProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onCreated?: () => void;
}

export const CreateFinishedDeliveryRequest: React.FC<ICreateDeliveryRequestProps> = ({ open, setOpen, onCreated = undefined }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { activeStore } = useStoreContext();

  const [courierSearchValue, setCourierSearchValue] = React.useState<string>("");
  const [courierList, setCourierList] = React.useState<any[]>([]);
  const [selectedCourier, setSelectedCourier] = React.useState<any>();
  const [createAnother, setCreateAnother] = React.useState<boolean>(false);
  const [selectedCity, setSelectedCity] = React.useState<string>("");
  const [districtList, setDistrictList] = React.useState<string[]>([]);
  const [regionBounds, setRegionBounds] = React.useState<google.maps.LatLngBounds>();
  const [gMapsSessionToken, setGMapsSessionToken] = React.useState<google.maps.places.AutocompleteSessionToken>();
  const [selectedAddrSuggestion, setSelectedAddrSuggestion] = React.useState<Suggestion | string | null>(null);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      try {
        if (activeStore) {
          let addressNumber = "S/N";
          let postalCode = values.postal_code;
          let street = values.street;

          let fullAddress = {};
          if (values.addressType === "reduced") {
            postalCode = "00000000";
            street = "---";

            fullAddress = {
              district: values.district,
              city: values.city,
              state: values.state,
            };
          } else {
            if ((values.address_number !== null)
              && (values.address_number !== undefined)
              && values.address_number.trim().length !== 0) {
              addressNumber = values.address_number.trim();
            }

            if ((values.postal_code !== null)
              && (values.postal_code !== undefined)
              && (values.postal_code.trim().length === 0)) {
              values.postal_code = "00000000";
              postalCode = values.postal_code;
            }

            fullAddress = {
              cep: postalCode,
              street: values.street,
              number: addressNumber,
              complement: values.address_complement,
              district: values.district,
              city: values.city,
              state: values.state,
            };
          }

          const formattedAddress = getFormattedAddress(fullAddress);
          const coords = await locationByAddress(formattedAddress);

          await DeliveryRequestService.createFinishedDeliveryRequest({
            ...values,
            consignee: values.consignee && values.consignee.trim() !== "" ? values.consignee : "---",
            postal_code: postalCode,
            street: street,
            latitude: coords.lat,
            longitude: coords.lng,
            address_number: addressNumber,
            store: activeStore.id
          });

          if (onCreated) {
            onCreated();
          }
          enqueueSnackbar("Entrega registrada com sucesso!", {
            variant: "success",
          });

          if (createAnother) {
            formik.resetForm();
            formik.setFieldValue("current_courier", values.current_courier);
            formik.setFieldValue("city", values.city);
            formik.setFieldValue("addressType", values.addressType);
            formik.setFieldValue("state", values.state);
          } else {
            handleClose();
          }
        }
      } catch (error: any) {
        const { status, data } = error.response;
        const errorMsgs: string[] = [];
        let errorMsg = "Ocorreu um erro ao registrar a entrega";
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            if (key in INITIAL_VALUES) {
              formik.setFieldError(key, value);
            } else {
              errorMsgs.push(value);
            }
          }

          if (errorMsgs.length > 0) {
            errorMsg = errorMsgs.join(". \n");
          }
        }
        enqueueSnackbar(errorMsg, {
          variant: "error",
        });
      }
    },
    onReset: values => {
      setSelectedAddrSuggestion(null);
      clearAddrSuggestions();
    },
    validationSchema: createFinishedDeliveryRequestValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    suggestions: { data: addressSuggestions },
    setValue: setAddrSearchValue,
    clearSuggestions: clearAddrSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'br' },
      bounds: regionBounds,
      types: ["address"],
      sessionToken: gMapsSessionToken
    },
    debounce: 300
  });

  useEffect(() => {
    if (open && activeStore) {
      RegionService.getRegion(activeStore.region)
        .then(response => {
          const bounds = calcNeSwBounds(response.coordinates);
          if (bounds) {
            const swPoint = new google.maps.LatLng(bounds.sw.latitude, bounds.sw.longitude);
            const nePoint = new google.maps.LatLng(bounds.ne.latitude, bounds.ne.longitude);
            setRegionBounds(new google.maps.LatLngBounds(swPoint, nePoint));
          }
        });
    }
  }, [open, activeStore]);

  const handleCepChange = (cepRequested: string) => {
    if (cepRequested?.trim()) {
      cep(cepRequested, { providers: ["brasilapi", "viacep"] as AvailableProviders[] })
        .then((cepResponse: CEP) => {
          setSelectedAddrSuggestion({ description: cepResponse.street } as Suggestion);
          formik.setFieldError("postal_code", "");
          formik.setFieldValue("street", clearStreetName(cepResponse.street));
          formik.setFieldValue("district", cepResponse.neighborhood);
          formik.setFieldValue("city", cepResponse.city);
          formik.setFieldValue("state", cepResponse.state);
        })
        .catch((error: any) => {
          formik.setFieldError("postal_code", "Preencha o campo corretamente");
          formik.setFieldValue("street", "");
          formik.setFieldValue("district", "");
          formik.setFieldValue("city", "");
          formik.setFieldValue("state", "");
        });
    }
  };

  const getFormattedAddress = (address: any): string => {
    const addressArray: string[] = [];

    if (address.street && address.street.trim().length) {
      addressArray.push(address.street.trim())
    }
    if (address.number && address.number.trim().length) {
      addressArray.push(address.number.trim());
    }
    if (address.complement && address.complement.trim().length) {
      // Complemento retirado por preenchimento errado pelos usuários e consequente erro de localização do Google
      //addressArray.push(address.complement.trim());
    }
    if (address.district && address.district.trim().length) {
      addressArray.push(address.district.trim());
    }
    if (address.city && address.city.trim().length) {
      addressArray.push(address.city.trim());
    }

    let result = addressArray.join(", ");
    if (address.state && address.state.trim().length) {
      result += ` - ${address.state}`;
    }
    return result + ", Brasil";
  };

  const handleClose = () => {
    formik.resetForm();
    setCourierSearchValue("");
    setSelectedCity("");
    setDistrictList([]);
    setSelectedCourier(null);
    setCreateAnother(false);
    setOpen(false);
  };

  const updateCourierList = useMemo(() => debounce((name: string) => {
    if ((name.trim().length > 3) && activeStore) {
      CourierService.getActiveCouriersByNameAndRegion(name, activeStore.region)
        .then((data) => {
          setCourierList(data);
        });
    } else {
      setCourierList([]);
    }
  }, 300, { trailing: true }), [setCourierList, activeStore]);

  useEffect(() => {
    updateCourierList(courierSearchValue);
  }, [courierSearchValue, updateCourierList]);


  const handleAddressChange = (address: string) => {
    formik.setFieldValue("street", address);
    if (address && address.length > 3) {
      if (open && !gMapsSessionToken) {
        setGMapsSessionToken(new google.maps.places.AutocompleteSessionToken())
      }
      setAddrSearchValue(address);
    }
  };

  const clearAddressFields = () => {
    formik.setFieldValue("postal_code", "");
    formik.setFieldValue("district", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("state", "");
  }

  const setSelectedPlaceId = (placeId: string) => {
    clearAddressFields();
    getDetails({
      fields: ["address_components"],
      placeId: placeId,
      sessionToken: gMapsSessionToken
    }).then((response) => {
      if ((typeof response !== "string") && response.address_components) {
        response.address_components.forEach(addressComponent => {
          addressComponent.types.forEach(type => {
            switch (type) {
              case "route":
                formik.setFieldValue("street", addressComponent.long_name);
                break;
              case "postal_code":
                formik.setFieldValue("postal_code", addressComponent.long_name);
                break;
              case "administrative_area_level_1":
                formik.setFieldValue("state", addressComponent.short_name);
                break;
              case "administrative_area_level_2":
                formik.setFieldValue("city", addressComponent.long_name);
                break;
              case "sublocality_level_1" || "sublocality":
                formik.setFieldValue("district", addressComponent.long_name);
                break;
            }
          })
        })
      }
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setGMapsSessionToken(undefined);
    });
  }

  const filterAddrSuggestions = (item: Suggestion): boolean => {
    if (item.distance_meters !== undefined) {
      return item.distance_meters < 50000;
    }
    return true;
  }

  const sortAddrSuggestions = (a: Suggestion, b: Suggestion): number => {
    let result = 0;

    if (a.distance_meters !== undefined && b.distance_meters !== undefined) {
      result = a.distance_meters - b.distance_meters;
    }

    return result;
  }

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={false}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogTitle id="form-dialog-title">Entrega Realizada</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    id="current_courier"
                    size="small"
                    value={selectedCourier}
                    inputValue={courierSearchValue}
                    options={courierList}
                    getOptionLabel={(courier) => `${courier.name} - ${formatPhoneNumber(courier.phonenumber)}`}
                    loadingText="Aguarde ..."
                    noOptionsText="Sem opções"
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSelectedCourier(newValue);
                        formik.setFieldValue("current_courier", newValue.id);
                      } else {
                        setSelectedCourier(null);
                        formik.setFieldValue("current_courier", null);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setCourierSearchValue(newInputValue);
                    }}
                    renderOption={(courier) => (
                      <React.Fragment>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <ImgLoader src={courier.photo} width={40} height={40} />
                          </Grid>
                          <Grid item>
                            {`${courier.name} - ${formatPhoneNumber(courier.phonenumber)}`}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Entregador"
                        helperText={formik.errors.current_courier || "Digite pelo menos 4 letras para realizar a consulta"}
                        error={!!formik.errors.current_courier}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ required: true }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="order_number"
                    label="Pedido"
                    variant="outlined"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.order_number}
                    error={!!formik.errors.order_number}
                    helperText={formik.errors.order_number}
                    fullWidth
                    inputProps={{ maxLength: 8 }}
                    InputLabelProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ReactDatePicker
                    id="order_date"
                    name="order_date"
                    onChange={val => {
                      formik.setFieldValue("order_date", val);
                    }}
                    maxDate={new Date()}
                    selected={formik.values.order_date}
                    locale={DEFAULT_LOCALE}
                    dateFormat={CURRENT_DATE_FORMAT}
                    isClearable
                    autoComplete="off"
                    required={true}
                    wrapperClassName="MuiFormControl-fullWidth"
                    customInput={<TextField
                      label="Data"
                      variant="outlined"
                      size="small"
                      autoComplete="false"
                      error={!!formik.errors.order_date}
                      helperText={formik.errors.order_date}
                      InputLabelProps={{ required: true }}
                      fullWidth
                      margin="dense"
                      required={true} />}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    id="consignee"
                    label="Consumidor"
                    variant="outlined"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.consignee}
                    error={!!formik.errors.consignee}
                    helperText={formik.errors.consignee}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    error={!!formik.errors.return_type}
                    fullWidth
                  >
                    <InputLabel id="return_type-label">Tipo de retorno</InputLabel>
                    <Select
                      label="Tipo de retorno"
                      labelId="return_type-label"
                      id="return_type"
                      name="return_type"
                      value={formik.values.return_type}
                      onChange={formik.handleChange}
                    >
                      {$enum(DeliveryRequestReturnType).map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {t("delivery_request_return_type." + type)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formik.errors.return_type && (
                      <FormHelperText>{formik.errors.return_type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Endereço</FormLabel>
                <RadioGroup row aria-label="address" name="address" defaultValue="top">
                  <FormControlLabel
                    label="Completo"
                    control={
                      <Radio
                        color="primary"
                        value="complete"
                        checked={formik.values.addressType === "complete"}
                        onChange={(e) => formik.setFieldValue("addressType", e.target.value)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Apenas Cidade e Bairro"
                    control={
                      <Radio
                        color="primary"
                        value="reduced"
                        checked={formik.values.addressType === "reduced"}
                        onChange={(e) => formik.setFieldValue("addressType", e.target.value)}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <ShowIf condition={formik.values.addressType === "complete"}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="postal_code"
                      label="CEP"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleCepChange(e.target.value)}
                      value={formik.values.postal_code}
                      error={!!formik.errors.postal_code}
                      helperText={formik.errors.postal_code}
                      fullWidth
                      InputProps={{
                        inputComponent: PostalCodeInput,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Autocomplete
                      id="street"
                      size="small"
                      value={selectedAddrSuggestion}
                      options={addressSuggestions.filter(filterAddrSuggestions).sort(sortAddrSuggestions)}
                      getOptionLabel={suggestion => suggestion.description}
                      freeSolo={true}
                      autoComplete
                      includeInputInList
                      autoHighlight
                      onChange={(event, newValue, reason) => {
                        setSelectedAddrSuggestion(newValue);
                        if (["remove-option", "clear"].includes(reason)) {
                          handleAddressChange("");
                        }
                        if ((newValue !== null) && (typeof newValue !== "string")) {
                          setSelectedPlaceId(newValue.place_id);
                        }
                      }}
                      inputValue={formik.values.street}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          name="street"
                          label="Endereço"
                          variant="outlined"
                          fullWidth
                          onChange={(event) => {
                            handleAddressChange(event.target.value);
                          }}
                          error={!!formik.errors.street}
                          helperText={formik.errors.street}
                          InputLabelProps={{ required: true }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="address_number"
                      label="Número"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      value={formik.values.address_number}
                      error={!!formik.errors.address_number}
                      helperText={formik.errors.address_number}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      id="district"
                      label="Bairro"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      value={formik.values.district}
                      error={!!formik.errors.district}
                      helperText={formik.errors.district}
                      fullWidth
                      InputLabelProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      id="city"
                      label="Cidade"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      error={!!formik.errors.city}
                      helperText={formik.errors.city}
                      fullWidth
                      InputLabelProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="state"
                      label="UF"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      error={!!formik.errors.state}
                      helperText={formik.errors.state}
                      fullWidth
                      InputLabelProps={{ required: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ShowIf>

            <ShowIf condition={formik.values.addressType === "reduced"}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      id="city"
                      label="Cidade"
                      variant="outlined"
                      size="small"
                      select
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value) {
                          setSelectedCity(e.target.value);
                          const districts = DistrictList.get(e.target.value);
                          if (districts) {
                            setDistrictList(districts);
                          }
                          const selected = e.target.value.split("-");
                          const cityName = selected[0].trim();
                          const stateName = selected[1].trim();
                          formik.setFieldValue("city", cityName);
                          formik.setFieldValue("state", stateName);
                          formik.setFieldValue("district", "");
                        } else {
                          setSelectedCity("");
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("state", "");
                        }
                      }}
                      value={selectedCity}
                      error={!!formik.errors.city}
                      helperText={formik.errors.city}
                      InputLabelProps={{ required: true }}
                    >
                      {CityList.map((city, index) => (
                        <MenuItem key={index} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Autocomplete
                      id="district"
                      size="small"
                      value={formik.values.district}
                      options={districtList}
                      getOptionLabel={(district) => district}
                      loadingText="Aguarde ..."
                      noOptionsText="Escolha uma Cidade"
                      onChange={(e, newValue) => {
                        if (newValue) {
                          formik.setFieldValue("district", newValue);
                        } else {
                          formik.setFieldValue("district", "");
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label="Bairro"
                          helperText={formik.errors.district}
                          error={!!formik.errors.district}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ required: true }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ShowIf>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox name="createAnother" color="primary" checked={createAnother}
                onChange={(event) => {
                  setCreateAnother(event.target.checked);
                }} />
            }
            label="Continuar Cadastrando"
          />
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="primary" type="submit" disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: { maxWidth: "800px" },
  })
);

export default CreateFinishedDeliveryRequest;
