import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { LoginStore, Store } from "../services/types/store";
import * as Yup from "yup";

const selectStoreValidation = Yup.object().shape({
  store: Yup.number().required("Campo obrigatório"),
});

interface SelectStoreDialogProps {
  open: boolean;
  forceSelection: boolean;
  currentStore: LoginStore | null;
  storeList: Store[];
  onSelectStore: (store: LoginStore) => Promise<boolean> | boolean | void;
  onCloseRequested: () => void;
}

export const SelectStoreDialog: React.FC<SelectStoreDialogProps> = ({ open, forceSelection = false, currentStore, storeList, onSelectStore, onCloseRequested }) => {

  const hide = () => {
    formik.resetForm();
    onCloseRequested();
  }

  const formik = useFormik({
    initialValues: {
      store: 0
    },
    onSubmit: async (values) => {
      const selectedStore = storeList.find((store) => store.id === values.store);
      if (selectedStore) {
        onSelectStore({
          id: selectedStore.id,
          name: selectedStore.name,
          latitude: selectedStore.latitude,
          longitude: selectedStore.longitude,
          region: selectedStore.region,
          max_number_of_deliveries: selectedStore.max_number_of_deliveries
        });
        hide();
      }
    },
    validationSchema: selectStoreValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (open && currentStore?.id) {
      formik.setFieldValue("store", currentStore?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentStore]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogTitle id="form-dialog-title">Selecionar loja</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="store"
                name="store"
                label="Loja"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.store}
                error={!!formik.errors.store}
                helperText={formik.errors.store}
                InputLabelProps={{ required: true }}
                fullWidth
                select
              >
                {storeList.map((store, index) => (
                  <MenuItem key={index} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!forceSelection && (
            <Button color="secondary" onClick={hide}>
              Cancelar
            </Button>
          )}
          <Button color="primary" type="submit" disabled={formik.isSubmitting || (formik.values.store === currentStore?.id)}>
            Selecionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SelectStoreDialog;
