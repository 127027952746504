import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatisticsExtraDetalsResponse } from "../../../services/types/statistics";
import { useTranslation } from "react-i18next";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";

interface ICustomerExtraDetailStores {
    extraDetails: StatisticsExtraDetalsResponse[];
}

export const CustomerExtraDetailsPanel: React.FC<ICustomerExtraDetailStores> = ({
    extraDetails,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const tableRef = React.useRef<MaterialTableProps<StatisticsExtraDetalsResponse>>();
    const [tableData, setTableData] = React.useState<StatisticsExtraDetalsResponse[]>([]);

    React.useEffect(() => {
        setTableData(extraDetails.filter((item) => item.name !== "OTHERS"));
    }, [extraDetails]);

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.styleTitle}>Sumário</Typography>
            {extraDetails && (
                <React.Fragment>
                    <MaterialTable
                        tableRef={tableRef}
                        columns={[
                            { field: "id", hidden: true },
                            {
                                field: "name", title: "",
                                render: (rowData, type) => t("delivery_stats." + rowData.name).startsWith("delivery_stats.") ?
                                    rowData.name :
                                    t("delivery_stats." + rowData.name)
                            },
                            {
                                field: "deliveries_quantity", title: "Total de Entregas", align: "right",
                            },
                            {
                                field: "average_daily_quantity", title: "Média de Entregas Diárias", align: "right",
                            },
                            {
                                ...CURRENCY_COLUMN_DEFAULTS, field: "average_ticket", title: "Ticket Médio",
                            },
                            {
                                ...CURRENCY_COLUMN_DEFAULTS, field: "total_value", title: "Consumo Total",
                            },
                            {
                                ...CURRENCY_COLUMN_DEFAULTS, field: "average_daily_value", title: "Média de Consumo Diária",
                            },

                        ]}
                        data={tableData}
                        components={{
                            Container: (props: any) => <div {...props}>{props.children}</div>
                        }}
                        localization={TABLE_L10N_PTBR}
                        options={{
                            ...DEFAULT_TABLE_OPTIONS,
                            paging: false,
                            draggable: false
                        }}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    styleTitle: {
    }
});
