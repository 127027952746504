import React, { ReactNode } from "react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { pink } from "@material-ui/core/colors";
import { isPast } from "date-fns";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { TripDeliveryRequest, TripStatus, PendingTrip, DeliveryRequestStatus } from "../../../services/types/deliveryRequest";
import ShowIf from "../../../components/ShowIf";
import HighlightText from "../../../components/HighlightText";
import { TripCardItem } from "./tripCardItem";
import PopoverRanking from "./popoverRanking";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { SpeedyRankingType } from "../../../services/types/courier";
import clsx from "clsx";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import DeliveryRequestService from "../../../services/deliveryRequestService";
import { debounce } from "lodash";
import CloseIcon from '@material-ui/icons/Close';


interface ITripCardProps {
  trip: PendingTrip;
  highlight: string;
  action?: (trip: PendingTrip) => {};
  splitTripAction?: (trip: PendingTrip, delivery: TripDeliveryRequest) => void;
  showDeliveryStatus?: boolean;
  showTripStatus?: boolean;
  showLoading?: boolean;
  children?: ReactNode;
  refresh?: () => Promise<void>;
}

export const TripCard: React.FC<ITripCardProps> = ({
  trip,
  highlight,
  action,
  splitTripAction,
  showDeliveryStatus = true,
  showTripStatus = true,
  showLoading = false,
  children,
  refresh
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorElRanking, setAnchorElRanking] = React.useState<HTMLElement | null>(null);
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const handlePopoverRankingOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorElRanking(event.currentTarget);
  };
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handlePopoverRankingClose = () => {
    setAnchorElRanking(null);
  };
  const openPopoverRanking = Boolean(anchorElRanking);
  const determineCardClassName = (): string => {
    let className = classes.card;

    /*
      If delayed should show delayed even if "status === NO_COURIER"
    */
    for (let i = 0; i < trip.deliveries.length; i++) {
      if (isPast(new Date(trip.deliveries[i].estimated_delivery_time))) {
        className = classes.delayedCard;
        break;
      }
    }

    return className;
  };

  const handleFinishDeliveryConfirmation = (deliveryRequest: TripDeliveryRequest) => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
        "Finalizar Entrega",
        "Tem certeza que deseja finalizar essa entrega? Ao finalizar a corrida será concluída, e o valor repassado ao entregador.",
        "Finalizar",
        "Cancelar"
    ).then(async () => {
        try {
            setLoadingBtn(true);
            await handleFinishDeliveryRequest(deliveryRequest);
        } finally {
            dialog.hide();
        }
    }).catch(() => {
    });
  };

  const handleFinishDeliveryRequest = React.useMemo(() => debounce(async (deliveryRequest: TripDeliveryRequest) => {
    try {
        if(deliveryRequest.canceled){
          await DeliveryRequestService.finishCanceledDeliveryRequestById(deliveryRequest.id);
        }else{
          await DeliveryRequestService.finishDeliveryRequestById(deliveryRequest.id);
        }
        if(refresh){
          refresh();
        }      
        enqueueSnackbar("Solicitação finalizada com sucesso!", { variant: "success", action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} aria-label="close" size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )});
        setLoadingBtn(false);
    } catch (error: any) {
        const { status, data } = error.response;
        if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
                var value = data[key];
                errors.push(value);
            }
            enqueueSnackbar(errors.join(".\n"), { variant: "error" });
        } else {
            enqueueSnackbar("Não foi possível finalizar a solicitação!", { variant: "error" });
        }
        setLoadingBtn(false);
    }
  }, 500), [enqueueSnackbar, closeSnackbar, refresh]);

  return (
    <Box mb={1}>
      <ConfirmationDialog ref={confirmationDialog} />
      <Card variant="outlined" className={determineCardClassName()}>
        <Backdrop className={classes.cardBackdrop} open={showLoading}>
          <CircularProgress size="2rem" />
        </Backdrop>
        <CardContent className={classes.content}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => {
              if (action) {
                action(trip);
              }
            }}
          >
            <Grid item xs={12} sm container spacing={0} justify="space-between">
              <Grid item>
              </Grid>
              <Grid item>
                {showTripStatus && (
                  <Chip className={classes.statusChip} size="small" label={t("trip_status." + trip.status)} />
                )}
              </Grid>
            </Grid>
            <ShowIf condition={![TripStatus.NEW, TripStatus.NO_COURIER, TripStatus.SEARCHING_COURIER].includes(trip.status)}>
              <Grid item xs={12} container wrap="nowrap" spacing={1} title={trip.courier_name}>
                <Grid item>
                  <CustomAvatar size="xs" img={trip.courier_photo} />
                </Grid>
                <Grid item xs zeroMinWidth style={{display: "flex", alignItems: "center"}}>
                  <Typography variant="body2" noWrap style={{display: "flex", alignItems: "center"}}>
                    {trip.courier_id && (
                      <Typography style={{marginRight: "3px"}} aria-owns={openPopoverRanking ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverRankingOpen}
                      onMouseLeave={handlePopoverRankingClose}>                                          
                          <ShowIf condition={trip.courier_speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                            <StarBorderIcon fontSize="small" style={{paddingTop: "1px"}}/>
                          </ShowIf>
                        <ShowIf condition={trip.courier_speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                            <StarIcon fontSize="small" className={clsx(classes.advancedSpeederStar)} />
                        </ShowIf>
                      </Typography>
                    )}
                    <HighlightText text={trip.courier_name} words={[highlight]} />
                  </Typography>
                </Grid>
              </Grid>
            </ShowIf>
            <PopoverRanking openPopover={openPopoverRanking} anchorEl={anchorElRanking} 
                  speedyRanking={trip?.courier_speedy_ranking} 
                  newRankingPoints={trip?.courier_new_ranking_points} 
                  requestsCount={trip?.courier_requests_count} 
                  handlePopoverClose={handlePopoverRankingClose} 
              />
          </CardActionArea>

          {trip.deliveries.map((deliveryRequest: TripDeliveryRequest, index: number) => (
            <React.Fragment key={index}>
              <Divider></Divider>
              <TripCardItem
                trip={trip}
                deliveryRequest={deliveryRequest}
                highlight={highlight}
                action={action}
                splitTripAction={splitTripAction}
                showDeliveryStatus={showDeliveryStatus}             
              >
                <ShowIf condition={deliveryRequest.status === DeliveryRequestStatus.BACK_TO_STORE}>
                  <Grid container spacing={0} justify="center" className={classes.cardButtonArea}>
                      <Grid item>
                          <Button
                              color="primary"
                              disabled={loadingBtn}                             
                              onClick={() => {
                                  handleFinishDeliveryConfirmation(deliveryRequest);
                              }}>
                              <ShowIf condition={loadingBtn}>
                                  <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                              </ShowIf>
                              Finalizar Entrega
                          </Button>
                      </Grid>
                  </Grid>
                </ShowIf>
              </TripCardItem>
            </React.Fragment>
          ))}
        </CardContent>
        {children}
      </Card>
    </Box>
  );
};

const useStyles = makeStyles({
  order: {
    fontSize: "15px"
  },
  card: {
    position: "relative",
  },
  delayedCard: {
    position: "relative",
    borderTopColor: pink.A400,
    borderTopWidth: theme.shape.borderRadius * 1.5,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  actionArea: {
    padding: theme.spacing(1),
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#384953 !important',
    color: theme.palette.secondary.contrastText,
  },
  cardBackdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  advancedSpeederStar: {
    color: theme.palette.primary.main
  },
  cardButtonArea: {
    marginTop: (theme.spacing(1) * -1),
},
});
