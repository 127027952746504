import { VehicleType, VehicleLoadCompartmentType, SpeedyRankingType, CourierStatus } from "./courier";

export enum DeliveryRequestStatus {
    NEW = "NEW",
    NO_COURIER = "NO_COURIER",
    ACCEPTED = "ACCEPTED",
    AWAITING_RELEASE = "AWAITING_RELEASE",
    AWAITING_COLLECTION = "AWAITING_COLLECTION",
    ON_WAY = "ON_WAY",
    AT_DESTINY = "AT_DESTINY",
    DELIVERED = "DELIVERED",
    RETURNING = "RETURNING",
    BACK_TO_STORE = "BACK_TO_STORE",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    DISCARDED = "DISCARDED"
}

export enum TripStatus {
    NEW = "NEW",
    NO_COURIER = "NO_COURIER",
    SEARCHING_COURIER = "SEARCHING_COURIER",
    ACCEPTED = "ACCEPTED",
    AWAITING_RELEASE = "AWAITING_RELEASE",
    AWAITING_COLLECTION = "AWAITING_COLLECTION",
    ON_WAY = "ON_WAY",
    BACK_TO_STORE = "BACK_TO_STORE",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    PARTIALLY_CANCELED = "PARTIALLY_CANCELED",
    DISCARDED = "DISCARDED"
}

export enum DeliveryRequestReturnType {
    NONE = "none",
    POS = "pos",
    MONEY = "din",
}

export enum DeliveryRequestEvent {
    CREATED = "CREATED",
    COURIER_SELECTED = "COURIER_SELECTED",
    COURIER_IGNORED_REQUEST = "COURIER_IGNORED_REQUEST",
    COURIER_REFUSED_REQUEST = "COURIER_REFUSED_REQUEST",
    COURIER_ACCEPTED_REQUEST = "COURIER_ACCEPTED_REQUEST",
    COURIER_CHECK_IN = "COURIER_CHECK_IN",
    STORE_RELEASED_ORDER = "STORE_RELEASED_ORDER",
    COURIER_DELIVERED_ORDER = "COURIER_DELIVERED_ORDER",
    COURIER_CHECK_OUT = "COURIER_CHECK_OUT",
    COURIER_ARRIVED_DESTINATION = "COURIER_ARRIVED_DESTINATION",
    CUSTOMER_CANCELED_ORDER = "CUSTOMER_CANCELED_ORDER",
    COURIER_CHECK_IN_FINISH = "COURIER_CHECK_IN_FINISH",
    DELIVERY_FINISHED = "DELIVERY_FINISHED",
    COURIER_IGNORED_NOTIFICATION = "COURIER_IGNORED_NOTIFICATION",
}

export enum DeliveryRequestAction {
    CHECK_IN = "CHECK_IN",
    RELEASE_ORDER = "RELEASE_ORDER",
    CHECK_OUT = "CHECK_OUT",
    REGISTER_ARRIVAL = "REGISTER_ARRIVAL",
    REGISTER_DELIVER = "REGISTER_DELIVER",
    REGISTER_CHECK_IN_BACK = "REGISTER_CHECK_IN_BACK",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
}

export interface CreateDeliveryRequest {
    desired_vehicles: any;
    order_number: string;
    consignee: string;
    consignee_phone_number: string;
    return_type: DeliveryRequestReturnType;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    address_reference: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    store?: number;
}

export interface ScheduleDelivery {
    desired_vehicles: any;
    order_number: string;
    estimated_delivery_time: Date;
    consignee: string;
    consignee_phone_number: string;
    return_type: DeliveryRequestReturnType;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    address_reference: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    store?: number;
}

export interface Address {
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    address_reference: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
}

export interface DestinationAddress {
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    address_reference: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    delivery_observations: string;
}

export interface DeliveryRequestStore {
    customer_id: number;
    customer_name: string;
    store_id: number;
    store_name: string;
    phonenumber: string;
}

export interface TripCourier {
    id: number;
    name: string;
    phonenumber: string;
    photo: string;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    ranking_points: number;
    new_ranking_points: number;
    requests_count: number;
    speedy_ranking: SpeedyRankingType;
}

export interface Incentive {
    customer_value: number;
    speedy_value: number;
    total: number;
}

export interface CourierPosition {
    courier_id: number;
    latitude: number;
    longitude: number;
    delivery_request_id: number;
    delivery_requests: any;
    trip_id: number;
    updated_at: Date;
}

export interface DeliveryRequest {
    id: number;
    request_number: string;
    order_number: string;
    status: DeliveryRequestStatus;
    return_type: DeliveryRequestReturnType;
    distance: number;
    compartiment_type: VehicleLoadCompartmentType;
    integration_instance: IntegrationInstance;
    delivery_value: number;
    additional_km_value: number;
    return_value: number;
    speedy_value: number;
    courier_value: number;
    courier_cancel_value: number;
    origin_address: Address;
    destination_address: DestinationAddress;
    courier_position: CourierPosition;
    consignee: string;
    consignee_phone_number: string;
    current_courier: TripCourier;
    customer: DeliveryRequestStore;
    acceptation_distance: number;
    time_to_arrive_store: number;
    estimated_delivery_time: Date;
    route: any;
    manual_creation: boolean;
    trip_id: number;
    created_at: Date;
    requested_time: Date;
    incentive_json: Incentive;
    canceled: boolean;
    estimated_values: EstimatedValues;
    minimum_value: number;
    value_total: number;
}

export interface DeliveryRequestResponse {
    id: number;
    request_number: string;
    order_number: string;
    courier_name: string;
    created_at: Date;
    requested_time: Date;
    manual_creation: boolean;
    status: DeliveryRequestStatus;
}

export interface FinishedDeliveryRequest {
    order_number: string;
    order_date: Date;
    consignee: string;
    consignee_phone_number: string;
    return_type: string;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement?: string;
    district: string;
    city: string;
    state: string;
    latitude: any;
    longitude: any;
    store: string | number;
    current_courier: string | number;
    addressType: string;
}

export interface DeliveryRequestLog {
    id: number;
    courier?: number;
    event: DeliveryRequestEvent;
    reason: string;
    manual_action: boolean;
    is_courier_action: boolean;
    created_at: Date;
}

export interface PendingDeliveryRequest {
    id: number;
    request_number: string;
    status: DeliveryRequestStatus;
    created_at: Date;
    requested_time: Date;
    order_number: string;
    customer_id: number;
    customer_name: string;
    store_id: number;
    store_name: string;
    courier_id: number;
    courier_name: string;
    courier_phonenumber: string;
    courier_photo: string;
    consignee: string;
    consignee_phone_number: string;
    estimated_delivery_time: Date;
    destination_address: Address;
    distance: number;
    integration_instance: IntegrationInstance;
    source: IntegrationSource;
    is_scheduled: boolean;
    canceled: boolean;
    estimated_values: EstimatedValues;
    return_type: string;
}

export interface EstimatedValues {
    already_has_commercial_plan: boolean;
    per_vehicle: VehiclesValues;
}
export interface VehiclesValues {
    BIKE: string;
    CAR: string;
    MOTORCYCLE: string;
    VAN: string;
}

export interface TripDeliveryRequest {
    id: number;
    request_number: string;
    order_number: string;
    created_at: Date;
    requested_time: Date;
    is_scheduled: boolean;
    estimated_delivery_time: Date;
    status: DeliveryRequestStatus;
    destination_address: Address;
    source: IntegrationSource;
    integration_instance: IntegrationInstance;
    return_type: string;
    estimated_values: EstimatedValues;
    canceled: boolean;
}

export interface Trip {
    id: number;
    created_at: Date;
    updated_at: Date;
    number: string;
    start_date: Date;
    end_date: Date;
    status: TripStatus;
    store: number;
    courier: TripCourier;
    deliveries: TripDeliveryRequest[];
    total_value: number;
    courier_value: number;
    courier_incentive_value: number;
    customer_incentive_value: number;
    courier_position: CourierPosition;
}

export interface PendingTrip {
    id: number;
    deliveries: TripDeliveryRequest[];
    courier_id: number;
    courier_name: string;
    courier_phonenumber: string;
    courier_photo: string;
    created_at: Date;
    updated_at: Date;
    number: string;
    start_date: Date;
    end_date: Date;
    status: TripStatus;
    store: number;
    courier_new_ranking_points: number;
    courier_requests_count: number;
    courier_speedy_ranking: SpeedyRankingType;
}

export interface DeliveryPrices {
    BIKE: number;
    MOTORCYCLE: number;
    CAR: number;
    VAN: number;
}

export interface DeliveryValues {
    prices: DeliveryPrices;
    distance: number;
    route: any;
    incentive: Incentive;
}

export enum IntegrationSource {
    SPEEDY = "SPEEDY",
    IFOOD = "IFOOD",
    MEU_XODO = "MEU_XODO",
    DELIVERY_DIRETO = "DELIVERY_DIRETO",
    NEEMO = "NEEMO",
    OTHERS = "OTHERS"
}

export interface IntegrationInstance {
    id: number;
    name: string;
    integration_source: IntegrationSource;
    initials: string;
}

export interface RequestCourierResponse {
    id: number;
    distance: number;
    desired_vehicles: VehicleType;
    vehicle_compartment: VehicleLoadCompartmentType;
    created_at: Date;
    updated_at: Date;
    request_number: string;
    requested_time: Date;
    estimated_delivery_time: Date;
    estimated_delivery_time_after_release: Date;
    delivery_value: null,
    accept_date: Date;
    closed_date: Date;
    status: DeliveryRequestStatus;
    order_number: string;
    order_date: Date;
    consignee: string
    consignee_phone_number: string;
    return_type: DeliveryRequestReturnType;
    order_value: number;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    address_reference: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    minimum_value: number;
    additional_km_value: number;
    return_value: number;
    speedy_value: number;
    courier_value: number;
    courier_cancel_value: number;
    cancel_value: number;
    courier_score: number;
    acceptation_distance: number;
    time_to_arrive_store: Date;
    request_status: "PROCESSING",
    confirmation_date: Date;
    manual_creation: boolean;
    custom_courier: boolean;
    auto_search: boolean;
    refunded: boolean;
    source: string;
    external_store_id: string;
    external_id: string;
    is_scheduled: boolean;
    notification_url: string;
    canceled: boolean;
    store: number;
    current_courier: number;
    trip: number;
    integration_instance: IntegrationSource;
}

export interface OnlineDedicatedCourierDetails {
    id: number;
    courier_status: CourierStatus;
    last_update: Date;
    name: string;
    phonenumber: string;
    photo: string;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    delivery_request_id: number | null;
    latitude: number;
    longitude: number;
    new_ranking_points: number,
    speedy_ranking: SpeedyRankingType,
    requests_count: number,
    position: number,
    trip_id: number,
    slot_info: SlotInfoDedicatedCourier
}

export interface SlotInfoDedicatedCourier {
    finished_deliveries_count: number;
    finished_deliveries_value: number;
    slot_end_datetime: Date;
    slot_start_datetime: Date;
    turn_value: number
}