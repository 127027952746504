export enum AccountStatus {
    ACTIVE = "ACTIVE",
    SUSP_INACTIVE = "SUSP_INACTIVE",
    SUSP_DECLINE = "SUSP_DECLINE",
    SUSP_OTHERS = "SUSP_OTHERS",
    SUSP_DATA_UPDATED = "SUSP_DATA_UPDATED",
    BANNED = "BANNED",
    REGISTRATION_ANALYSIS = "REGISTRATION_ANALYSIS",
    REGISTRATION_DENIED = "REGISTRATION_DENIED",
    DATA_UPDATED = "DATA_UPDATED",
}

export enum CourierStatus {
    AVAILABLE = "AVAILABLE",
    BUSY = "BUSY",
    IN_STORE = "IN_STORE",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    AT_DESTINATION = "AT_DESTINATION",
    UNAVAILABLE = "UNAVAILABLE",
    
}

export enum VehicleType {
    BIKE = "BIKE",
    MOTORCYCLE = "MOTORCYCLE",
    CAR = "CAR",
    VAN = "VAN",
}

export enum VehicleLoadCompartmentType {
    BAG = "BAG",
    TRUNK = "TRUNK",
}

export interface CourierStatusData {
    pk: string;
    _id: string;
    courier_id: number;
    created_at: Date;
    updated_at: Date;
    online: boolean;
    latitude: number;
    longitude: number;
    courier_status: CourierStatus;
    delivery_request_id: number;
    delivery_requests: any[];
    trip_id: number;
    suspended: boolean;
}

export interface QueueCourier {
    id: number;
    name: string;
    picture: string;
    position: number;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    courier_status: CourierStatusData;
}

export enum SpeedyRankingType {
    INITIATE_SPEEDER = "INITIATE_SPEEDER",
    ADVANCED_SPEEDER = "ADVANCED_SPEEDER"
}