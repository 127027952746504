import React from "react";
import { Box, Grid, TextField, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../../i18n";
import { endOfDay, startOfDay } from "date-fns";

export interface IRechargeFilterParams {
    createdAtAfter: Date | null;
    createdAtBefore: Date | null;
}

export const INITIAL_FILTER_PARAMS: IRechargeFilterParams = {
    createdAtAfter: null,
    createdAtBefore: null
};

interface IRechargeFilterProps {
    params?: IRechargeFilterParams;
    onFilterChanged: (params: IRechargeFilterParams) => void;
}

const RechargeFilter: React.FC<IRechargeFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    onFilterChanged
}) => {
    const materialTheme = useTheme();
    const smallScreen = useMediaQuery(materialTheme.breakpoints.down("md"));

    const formik = useFormik({
        initialValues: params,
        onSubmit: (values) => {
            const newValues = {
                ...values,
                createdAtAfter: values.createdAtAfter ? startOfDay(values.createdAtAfter) : null,
                createdAtBefore: values.createdAtBefore ? endOfDay(values.createdAtBefore) : null
            }
            onFilterChanged(newValues);
        },
        onReset: (values) => {
            onFilterChanged(values);
        },
    });

    return (
        <Box mt={1}>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    {smallScreen ? (<>
                        <Grid item xs={12}>
                            <TextField
                                id="createdAtAfter"
                                name="createdAtAfter"
                                label="Data da Recarga Inicial"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={formik.handleChange}
                                value={formik.values.createdAtAfter}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="createdAtBefore"
                                name="createdAtBefore"
                                label="Data da Recarga Final"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={formik.handleChange}
                                value={formik.values.createdAtBefore}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                    </>) : (<>
                        <Grid item md={3}>
                            <DatePicker
                                id="createdAtAfter"
                                name="createdAtAfter"
                                onChange={date => formik.setFieldValue("createdAtAfter", date)}
                                selected={formik.values.createdAtAfter}
                                startDate={formik.values.createdAtAfter}
                                endDate={formik.values.createdAtBefore}
                                maxDate={new Date()}
                                locale={DEFAULT_LOCALE}
                                dateFormat={CURRENT_DATE_FORMAT}
                                isClearable
                                selectsStart
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                    label="Data da Recarga Inicial"
                                    variant="outlined"
                                    size="small"
                                    fullWidth />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <DatePicker
                                id="createdAtBefore"
                                name="createdAtBefore"
                                onChange={date => formik.setFieldValue("createdAtBefore", date)}
                                selected={formik.values.createdAtBefore}
                                startDate={formik.values.createdAtAfter}
                                endDate={formik.values.createdAtBefore}
                                minDate={formik.values.createdAtAfter}
                                maxDate={new Date()}
                                locale={DEFAULT_LOCALE}
                                dateFormat={CURRENT_DATE_FORMAT}
                                isClearable
                                selectsEnd
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                    label="Data da Recarga Final"
                                    variant="outlined"
                                    size="small"
                                    fullWidth />}
                            />
                        </Grid>
                    </>)}
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={false}
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" type="reset">
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default RechargeFilter;