import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('O e-mail deve ser válido')
    .max(100, "O e-mail deve ter no máximo 100 caracteres")
    .required('É necessário informar o e-mail'),
  password: Yup.string().max(32, "A senha deve ter no máximo 32 caracteres")
    .required('É necessário informar a senha')
});

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth() as any;
  const isMountedRef = useIsMountedRef();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (credentials, {
      setSubmitting
    }) => {
      try {
        await login(credentials.email, credentials.password);

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
          if (error.response.status === 401) {
            enqueueSnackbar("E-mail e/ou senha inválidos", { variant: 'error' });
          } else {
            enqueueSnackbar(`Erro desconhecido, entre em contato com o suporte e informe o código ${error.response.status}`, { variant: 'error' });
          }
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Login</Typography>
          <Typography variant="subtitle1">
            Utilize suas credenciais para fazer o login
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            type="email"
            label="E-mail"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Exibir senha"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Link href="/password/forgot">
            <Typography>Esqueci minha senha</Typography>
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login;
