import React from "react";
import MaskedInput from "react-text-mask";
import { phoneMask, postalCodeMask, cnpjNumberMask, cpfNumberMask, validThruMask, cvvCardInputMask, validRegularCreditCardNumberMask, validDinnersCreditCardNumberMask, validAmericanExpressCreditCardNumberMask } from "../utils/masks";
import { getNumberSpecs } from "../i18n";
//@ts-ignore
import CurrencyInputDef from "react-currency-input";

interface ITextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: (string | RegExp)[];
}

export const TextMaskCustom: React.FC<ITextMaskCustomProps> = (props) => {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
};

export const CpfNumberInput = (props: any) => {
  return <TextMaskCustom {...props} mask={cpfNumberMask} />;
};

export const CnpjNumberMask = (props: any) => {
  return <TextMaskCustom {...props} mask={cnpjNumberMask} />;
};

export const PhoneInput = (props: any) => {
  return <TextMaskCustom {...props} mask={phoneMask} />;
};

export const PostalCodeInput = (props: any) => (
  <TextMaskCustom {...props} mask={postalCodeMask} />
);

export const CurrencyInput = (props: any) => {
  return ( 
    <CurrencyInputDef
      maxLength={6}
      onChangeEvent={props.onChange}
      decimalSeparator={getNumberSpecs().delimiters.decimal}
      thousandSeparator={getNumberSpecs().delimiters.thousands}
      precision={getNumberSpecs().currencyFormat?.mantissa}
      {...props}
      onChange={undefined}
    />
  );
};

export const ValidThruInput = (props: any) => (
  <TextMaskCustom {...props} mask={validThruMask} />
);

export const NumberCardInput = (props: any) => {
  // remove all non digit characters
  var value = props.value.replace(/\D/g, '');  
  var maskResult: any; 

  if ((/^3[47]\d{0,13}$/).test(value)) { // american express, 15 digits
    maskResult = validAmericanExpressCreditCardNumberMask;
  } else if((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
    maskResult = validDinnersCreditCardNumberMask;
  } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
    maskResult = validRegularCreditCardNumberMask;
  }
  return(
    <TextMaskCustom {...props} mask={maskResult} />
  )
};

export const CvvCardInput = (props: any) => (
  <TextMaskCustom {...props} mask={cvvCardInputMask} />
);