import React, { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    DeliveryRequest, DeliveryRequestReturnType, DeliveryRequestStatus, PendingDeliveryRequest, PendingTrip, TripStatus
} from "../../services/types/deliveryRequest";
import TripService from "../../services/tripService";
import { useSnackbar } from "notistack";
import useStoreContext from "../../hooks/useStoreContext";
import { ICON_MAP, IMarkerCoord } from "./deliveryUtils";
import { Map } from "leaflet";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import { IPageableParams } from "../../services/types/common";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "../Dashboard/customComponents/joinDeliveriesOnTripDialog";
import { useHistory } from "react-router-dom";
import DeliveryRequestService from "../../services/deliveryRequestService";
import ResendDeliveryRequestDialog from "./ResendDeliveryRequest";
import ShowIf from "../../components/ShowIf";
import { MapContainer, TileLayer, Tooltip, ZoomControl } from "react-leaflet";
import LeafletMarker from "../../components/LeafletMarker";
import { ImgLoader } from "../../components/ImgLoader";
import { formatPhoneNumber } from "../../utils/formatters";
import { CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import { Alert } from "@material-ui/lab";
import { mapDeliveryRequestStatusToSeverity } from "./deliveryRequestUtils";
import numbro from "numbro";

interface IProps {
    requestId: number;
    deliveryRequest: DeliveryRequest;
    loadData: (requestId: number, goTrip: boolean) => any;
    changeLoading: (change: boolean) => any;
}

const DELIVERY_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 500,
    ordering: "estimated_delivery_time",
};

const TRIP_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 200,
    ordering: "created_at",
};

export const MapDetail = ({ requestId, deliveryRequest, loadData, changeLoading }: IProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [canFinalize, setCanFinalize] = React.useState<boolean>(false);
    const { selectCourierFromQueue } = useStoreContext();
    const [markerCoords, setMarkerCoords] = React.useState<IMarkerCoord[]>();
    const [mapBounds, setMapBounds] = React.useState<[number, number][]>();
    const map = React.useRef<Map>(null);
    const [openResendDialog, showResendDialog] = React.useState(false);
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const { activeStore } = useStoreContext();
    const [newDeliveryList, setNewDeliveryList] = React.useState<PendingDeliveryRequest[]>([]);
    const [tripList, setTripList] = React.useState<PendingTrip[]>([]);
    const [openJoinDelivery, setOpenJoinDelivery] = React.useState(false);
    const [noCourierList, setNoCourierList] = React.useState<PendingTrip[]>([]);
    const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();
    const history = useHistory();

    const showTripRequestDetails = async (trip: any) => {
        let param =
            typeof trip == "number"
                ? trip
                : trip.id;
        history.push(`/app/trip/${param}`);
    };

    const cancelDeliveryRequest = (deliveryRequestId: number): Promise<boolean> => {
        changeLoading(true);
        return new Promise<boolean>((resolve, reject) => {
            DeliveryRequestService.cancelDeliveryRequestById(deliveryRequestId)
                .then((response) => {
                    changeLoading(false);
                    if (loadData) {
                        loadData(deliveryRequestId, false);
                    }
                    resolve(true);
                    enqueueSnackbar("Solicitação cancelada com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    changeLoading(false);
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível cancelar a solicitação!", { variant: "error" });
                    }
                });
        })
    }

    const discardDeliveryRequest = (deliveryRequestId: number): Promise<boolean> => {
        changeLoading(true);
        return new Promise<boolean>((resolve, reject) => {
            DeliveryRequestService.discardDeliveryRequestById(deliveryRequestId)
                .then((response) => {
                    changeLoading(false);
                    if (loadData) {
                        loadData(deliveryRequestId, false);
                    }
                    resolve(true);
                    enqueueSnackbar("Solicitação descartada com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    changeLoading(false);
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível descartar a solicitação!", { variant: "error" });
                    }
                });
        })
    }

    useEffect(() => {
        if (deliveryRequest?.origin_address) {
            const bounds: [number, number][] = [
                [deliveryRequest?.origin_address?.latitude, deliveryRequest?.origin_address?.longitude],
                [deliveryRequest?.destination_address?.latitude, deliveryRequest?.destination_address?.longitude]
            ];
            let typeHouse = "HOUSE";
            if (deliveryRequest.status === DeliveryRequestStatus.FINISHED) {
                typeHouse = "HOUSE_FINISH";
            } else if (deliveryRequest.status === DeliveryRequestStatus.RETURNING) {
                typeHouse = "HOUSE_RETURNING"
            } else if (deliveryRequest.status === DeliveryRequestStatus.CANCELED
                || deliveryRequest.status === DeliveryRequestStatus.DISCARDED) {
                typeHouse = "HOUSE_CANCELED";
            } else {
                typeHouse = "HOUSE"
            }
            const _coordsMarker = [
                {
                    type: "STORE",
                    title: "Loja",
                    lat: deliveryRequest?.origin_address?.latitude,
                    lng: deliveryRequest?.origin_address?.longitude,
                },
                {
                    type: typeHouse,
                    title: "Destino",
                    lat: deliveryRequest?.destination_address?.latitude,
                    lng: deliveryRequest?.destination_address?.longitude,
                },
            ];
            if (deliveryRequest?.courier_position) {
                bounds.push([deliveryRequest?.courier_position?.latitude, deliveryRequest?.courier_position?.longitude]);
                _coordsMarker.push({
                    type: deliveryRequest?.current_courier?.vehicle_type,
                    title: "Entregador",
                    lat: deliveryRequest?.courier_position?.latitude,
                    lng: deliveryRequest?.courier_position?.longitude,
                });
            }
            setMapBounds(bounds);
            setMarkerCoords(_coordsMarker);
        }
    }, [deliveryRequest]);

    useEffect(() => {
        if (deliveryRequest.return_type !== DeliveryRequestReturnType.NONE
            && deliveryRequest.status === DeliveryRequestStatus.BACK_TO_STORE) {
            setCanFinalize(true);
        } else {
            setCanFinalize(false);
        }
    }, [deliveryRequest]);

    useLayoutEffect(() => {
        if (map?.current && mapBounds) {
            try {
                map.current.getBounds();
            } catch (error) {
                map.current.fitBounds(mapBounds, { paddingTopLeft: [20, 40], paddingBottomRight: [20, 0] });
            }
        }
    }, [map, mapBounds]);

    const handleCancelClick = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Cancelamento de Entrega",
            "Você tem certeza que deseja Cancelar esta Entrega?",
            "Confirmar",
            "Cancelar"
        ).then(async () => {
            try {
                await cancelDeliveryRequest(requestId);
            } finally {
                dialog.hide();
            }
        }).catch(() => { });
    };

    const handleDiscardClick = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Descartar Entrega",
            "Você tem certeza que deseja Descartar esta Entrega? Esta ação não poderá ser desfeita.",
            "Descartar",
            "Cancelar"
        ).then(async () => {
            try {
                await discardDeliveryRequest(requestId);
            } finally {
                dialog.hide();
            }
        }).catch(() => { });
    };

    const handleOpenResendDialog = () => {
        showResendDialog(true);
    };

    const handleReleaseDeliveryRequest = () => {
        changeLoading(true);
        DeliveryRequestService.releaseDeliveryRequestById(requestId)
            .then((response) => {
                enqueueSnackbar("Coleta liberada com sucesso!", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar("Não foi possível liberar a coleta!", {
                    variant: "error",
                });
            })
            .finally(() => {
                changeLoading(false);
                if (loadData) {
                    loadData(requestId, false);
                }
            });
    };

    const handleSearchCourierForDeliveryRequest = () => {
        changeLoading(true);
        DeliveryRequestService.requestCourierToDeliveryRequestById(requestId)
            .then((response) => {
                enqueueSnackbar("Entregador solicitado com sucesso!", {
                    variant: "success",
                });
                if (loadData) {
                    loadData(requestId, true);
                }
            })
            .catch((error) => {
                changeLoading(false);
                enqueueSnackbar("Não foi possível solicitar entregador!", {
                    variant: "error",
                });
            });
    };

    const handleSetCourierForDeliveryRequest = () => {
        selectCourierFromQueue(async (courier) => {
            changeLoading(true);
            DeliveryRequestService.requestCourierToDeliveryRequestById(requestId, courier.id)
                .then((response) => {
                    enqueueSnackbar("Entregador solicitado com sucesso!", {
                        variant: "success",
                    });
                    showTripRequestDetails(response.trip);
                })
                .catch((error) => {
                    enqueueSnackbar("Não foi possível solicitar entregador!", {
                        variant: "error",
                    });
                })
                .finally(() => {
                    if (loadData) {
                        loadData(requestId, false);
                    }
                });
        });
    };

    const handleFinishDeliveryConfirmation = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Finalizar Entrega",
            "Tem certeza que deseja finalizar essa entrega? Ao finalizar a corrida será concluída, e o valor repassado ao entregador.",
            "Finalizar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleFinishDeliveryRequest();
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleFinishDeliveryRequest = () => {
        let finishDeliveryRequest: Promise<any>;
        if (deliveryRequest.canceled) {
            finishDeliveryRequest = DeliveryRequestService.finishCanceledDeliveryRequestById(requestId);
        } else {
            finishDeliveryRequest = DeliveryRequestService.finishDeliveryRequestById(requestId);
        }
        changeLoading(true);
        finishDeliveryRequest
            .then((response) => {
                enqueueSnackbar("Solicitação finalizada com sucesso!", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar("Não foi possível finalizar a solicitação!", {
                    variant: "error",
                });
            })
            .finally(() => {
                if (loadData) {
                    loadData(requestId, false);
                }
            });
    };

    const refreshDeliveries = React.useCallback(async () => {
        const storeId = activeStore ? activeStore.id : null;
        const regionId = activeStore ? activeStore.region : null;

        if (storeId) {
            const [tripsResponse, deliveriesResponse] = await Promise.all([
                TripService.getPendingTrips(storeId, regionId, null, TRIP_DEFAULT_PAGINATION),
                DeliveryRequestService.getNewDeliveries(storeId, DELIVERY_DEFAULT_PAGINATION)
            ]);
            setTripList(tripsResponse.data);
            setNewDeliveryList(deliveriesResponse.data);
            setOpenJoinDelivery(true);
        }
    }, [activeStore]);

    const handleJoinDelivery = React.useCallback(() => {
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((trip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)) {
                availableTrips.push(trip);
            }
        })
        joinDeliveriesDialog.current?.joinDeliveries(deliveryRequest, newDeliveryList, availableTrips);
    }, [noCourierList, deliveryRequest, joinDeliveriesDialog, newDeliveryList]);

    React.useEffect(() => {
        if (openJoinDelivery) {
            handleJoinDelivery();
        }
    }, [openJoinDelivery, handleJoinDelivery]);

    useLayoutEffect(() => {
        const _noCourierList: PendingTrip[] = [];
        tripList.forEach(trip => {
            switch (trip.status) {
                case TripStatus.NEW:
                case TripStatus.NO_COURIER:
                case TripStatus.SEARCHING_COURIER:
                    _noCourierList.push(trip);
                    break;
            }
        });
        setNoCourierList(_noCourierList);
    }, [tripList]);

    const refreshSuccess = () => {
        if (deliveryRequest) {
            loadData(Number(deliveryRequest.id), true);
            setOpenJoinDelivery(false);
        }
    };

    const onClose = () => {
        setOpenJoinDelivery(false);
    };

    return (
        <React.Fragment>
            <ConfirmationDialog ref={confirmationDialog} />

            <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={refreshSuccess} onClose={onClose} />

            <ResendDeliveryRequestDialog deliveryRequest={deliveryRequest}
                modal={{
                    open: openResendDialog,
                    setOpen: showResendDialog,
                }} />
            <Grid container spacing={2} justify="flex-end">
                <ShowIf condition={[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status)}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={refreshDeliveries}
                        >
                            Juntar Entregas
                        </Button>
                    </Grid>
                </ShowIf>
                {[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status) && (
                    <React.Fragment>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSearchCourierForDeliveryRequest}
                            >
                                Solicitar Entregador
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSetCourierForDeliveryRequest}
                            >
                                Direcionar para Entregador
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDiscardClick}
                            >
                                Descartar Entrega
                            </Button>
                        </Grid>
                    </React.Fragment>
                )}
                {false && (deliveryRequest.status === DeliveryRequestStatus.AWAITING_RELEASE) && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleReleaseDeliveryRequest}
                        >
                            Liberar Coleta
                        </Button>
                    </Grid>
                )}
                {false && canFinalize && (
                    <Grid item>
                        <Button
                            onClick={handleFinishDeliveryConfirmation}
                            variant="contained"
                            color="secondary"
                        >
                            Finalizar Entrega
                        </Button>
                    </Grid>
                )}
                {false && [DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status) && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenResendDialog}
                        >
                            Reenviar
                        </Button>
                    </Grid>
                )}
                {false && ![
                    DeliveryRequestStatus.CANCELED,
                    DeliveryRequestStatus.FINISHED,
                    DeliveryRequestStatus.NEW,
                    DeliveryRequestStatus.DISCARDED,
                ].includes(deliveryRequest.status) && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancelar Solicitação
                            </Button>
                        </Grid>
                    )}
            </Grid>
            <Grid container spacing={2}>
                {![
                    DeliveryRequestStatus.NO_COURIER,
                    DeliveryRequestStatus.FINISHED,
                    DeliveryRequestStatus.CANCELED
                ].includes(deliveryRequest.status) && (
                        <Grid item xs={12}>
                            <div className={classes.mapWrapper}>
                                <MapContainer scrollWheelZoom={false} ref={map} zoomControl={false}>
                                    <ZoomControl position="bottomright" />
                                    <TileLayer
                                        attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {markerCoords?.map((marker, index) => {
                                        const iconType = ICON_MAP.find(
                                            (elem) => elem.type === marker.type
                                        );
                                        return (
                                            <LeafletMarker
                                                key={index}
                                                position={[Number(marker.lat), Number(marker.lng)]}
                                                color={iconType?.color}
                                                internalIcon={iconType?.icon}
                                                riseOnHover={true}
                                            >
                                                <Tooltip>{marker.title}</Tooltip>
                                            </LeafletMarker>
                                        );
                                    })}
                                </MapContainer>
                            </div>
                        </Grid>
                    )}
                {deliveryRequest.current_courier && (
                    <Grid item md={6} xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h2" className="title">
                                    Dados do Entregador
                                </Typography>
                                <Table className="table-bordered">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <Avatar>
                                                            <ImgLoader src={String(deliveryRequest.current_courier?.photo)} className={classes.photo} />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">Nome</Typography>
                                                        <Typography>
                                                            {deliveryRequest.current_courier?.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Tipo do Veículo
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.current_courier &&
                                                        t(
                                                            "vehicle_type." +
                                                            deliveryRequest.current_courier
                                                                ?.vehicle_type
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Tipo do Compartimento
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.current_courier
                                                        ?.vehicle_load_compartment_type &&
                                                        t("vehicle_load_compartment_type." + deliveryRequest.current_courier?.vehicle_load_compartment_type
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">Celular</Typography>
                                                <Typography>
                                                    {deliveryRequest.current_courier ? formatPhoneNumber(deliveryRequest.current_courier?.phonenumber) : ""}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Typography variant="caption">
                                                    Rank
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.current_courier
                                                        ?.speedy_ranking &&
                                                        t("speedy_ranking." + deliveryRequest.current_courier?.speedy_ranking
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography variant="caption">
                                                    Pontuação
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.current_courier.new_ranking_points}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item md={deliveryRequest.current_courier ? 6 : 12} xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h2" className="title">
                                Dados do Consumidor
                            </Typography>
                            <Table className="table-bordered">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption">Nome</Typography>
                                            <Typography>{deliveryRequest.consignee}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Celular</Typography>
                                            <Typography>
                                                {deliveryRequest.consignee_phone_number ? formatPhoneNumber(deliveryRequest.consignee_phone_number) : "---"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="caption">Endereço</Typography>
                                            <Typography>
                                                {[deliveryRequest.destination_address?.street,
                                                deliveryRequest.destination_address?.address_number,
                                                deliveryRequest.destination_address?.address_complement,
                                                deliveryRequest.destination_address?.address_reference,
                                                deliveryRequest.destination_address?.district,
                                                `${deliveryRequest.destination_address?.city} - ${deliveryRequest.destination_address?.state}`]
                                                    .filter(value => value?.trim()).join(", ")}
                                            </Typography>
                                            <Typography>
                                                {`CEP: ${deliveryRequest.destination_address?.postal_code}`}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="caption">Observação</Typography>
                                            <Typography>
                                                {deliveryRequest.destination_address?.delivery_observations ? deliveryRequest.destination_address.delivery_observations : "---"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h2" className="title">
                                Dados da Solicitação - {deliveryRequest.request_number}
                            </Typography>
                            <Table className="table-bordered">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption">Pedido</Typography>
                                            <Typography>
                                                {deliveryRequest.order_number}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">
                                                Data da Solicitação
                                            </Typography>
                                            <Typography>
                                                {deliveryRequest.requested_time ? format(deliveryRequest.requested_time, CURRENT_DATE_TIME_FORMAT) : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">
                                                Previsão de Entrega
                                            </Typography>
                                            <Typography>
                                                {deliveryRequest.estimated_delivery_time ?
                                                    format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)
                                                    : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Situação</Typography>
                                            <Alert icon={false} className={classes.alertChip}
                                                severity={mapDeliveryRequestStatusToSeverity(deliveryRequest.status)}>
                                                {t("delivery_request_status." + deliveryRequest.status)}
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption">Tipo de Solicitação</Typography>
                                            <Typography>
                                                {deliveryRequest.manual_creation ? "Manual" : "Busca"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">
                                                Tipo de Retorno
                                            </Typography>
                                            <Typography>
                                                {t("delivery_request_return_type." + deliveryRequest.return_type)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Distância</Typography>
                                            <Typography>
                                                {numbro(deliveryRequest.distance).format() + " Km"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">
                                                Valor da Entrega
                                            </Typography>
                                            <Typography>
                                                {deliveryRequest.delivery_value ? numbro(deliveryRequest.delivery_value).formatCurrency() : "---"}
                                                {deliveryRequest.incentive_json ?
                                                    ` + ${numbro(deliveryRequest.incentive_json.total).formatCurrency()} (Incentivo)`
                                                    : ""}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <ShowIf condition={[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER, DeliveryRequestStatus.ON_WAY].includes(deliveryRequest.status)}>
                    <Grid item xs={12} xl={12} lg={12} md={12} spacing={1}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h2" className="title">
                                    Valores Estimados
                                </Typography>
                                <Table className="table-bordered">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Bicicleta
                                                </Typography>
                                                <Typography>{deliveryRequest.estimated_values?.per_vehicle?.BIKE ? numbro(deliveryRequest.estimated_values?.per_vehicle.BIKE).formatCurrency() : "---"}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Moto
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.estimated_values?.per_vehicle?.MOTORCYCLE ? numbro(deliveryRequest.estimated_values?.per_vehicle.MOTORCYCLE).formatCurrency() : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Carro
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.estimated_values?.per_vehicle?.CAR ? numbro(deliveryRequest.estimated_values?.per_vehicle.CAR).formatCurrency() : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Furgão
                                                </Typography>
                                                <Typography>
                                                    {deliveryRequest.estimated_values?.per_vehicle?.VAN ? numbro(deliveryRequest.estimated_values?.per_vehicle.VAN).formatCurrency() : "---"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                </ShowIf>
            </Grid>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: "1rem",
        },
        marginBot: {
            marginBottom: "10px",
        },
        mapWrapper: {
            position: "relative",
            width: "100%",
            borderRadius: "4px",
            overflow: "hidden",
            height: "40vh",
            display: "flex",
            flexDirection: "column",
        },
        photo: {
            width: 40,
            height: 40,
        },
        alertChip: {
            marginTop: -4,
            paddingBottom: 0,
            paddingTop: 0,
            borderRadius: 16
        }
    })
);
