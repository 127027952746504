import React, { useState } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ImgLoader } from "../../components/ImgLoader";
import * as Yup from "yup";
import { QueueCourier } from "../../services/types/courier";

const validationResendDeliveryRequest = Yup.object().shape({
    courierId: Yup.number()
        .when("autoSearch", {
            is: true,
            then: Yup.number().nullable().optional(),
            otherwise: Yup.number().nullable().required("Campo obrigatório"),
        }),
});

interface FormData {
    autoSearch: boolean;
    courierId: number | null;
}

const INITIAL_VALUES: FormData = {
    autoSearch: true,
    courierId: null
};

interface SelectCourierDialogProps {
    title: string;
    acceptButtonLabel: string;
    onAccept?: (courierId: number | null) => Promise<boolean>;
}

export interface SelectCourierDialogHandle {
    selectCourier: (courierList: QueueCourier[], currentCourierId?: number | null) => void;
    hide: () => void;
}

export const SelectCourierDialog: React.ForwardRefRenderFunction<SelectCourierDialogHandle, SelectCourierDialogProps> = (props, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState<boolean>(false);

    const [selectedCourier, setSelectedCourier] = useState<QueueCourier | null>(null);
    const [courierList, setCourierList] = useState<QueueCourier[]>([]);

    React.useImperativeHandle(ref, () => ({
        selectCourier(courierList: QueueCourier[], currentCourierId?: number | null) {
            setCourierList(courierList);
            if (currentCourierId) {
                console.log("if (currentCourierId) {");
                if (courierList?.length > 0) {
                    console.log("if (courierList?.length > 0) {");
                    const currentCurrier = courierList.filter((courier) => {
                        return (courier.id === currentCourierId);
                    });
                    console.log("currentCurrier", currentCurrier);
                    if (currentCurrier && currentCurrier[0]) {
                        setSelectedCourier(currentCurrier[0]);
                        INITIAL_VALUES.courierId = currentCurrier[0].id;
                    } else {
                        setSelectedCourier(null);
                        INITIAL_VALUES.courierId = null;
                    }
                }
            }
            show();
        },

        hide() {
            hide();
        }
    }));

    const hide = () => {
        formik.resetForm();
        setSelectedCourier(null);
        setCourierList([]);
        setOpen(false);
    }

    const show = () => {
        setOpen(true);
    }

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: async values => {
            if (props.onAccept) {
                try {
                    const courierId = values.autoSearch ? null : values.courierId;
                    const shouldHide = await props.onAccept(courierId);
                    if (shouldHide) {
                        hide();
                    }
                } catch (error) {
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            if (key in formik.values) {
                                formik.setFieldError(key, value);
                            } else {
                                errors.push(value);
                            }
                        }
                        if (errors.length > 0) {
                            enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                        }
                    }
                }
            }
        },
        validationSchema: validationResendDeliveryRequest,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onClose={hide}
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <DialogTitle id="form-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                error={!!formik.errors.autoSearch}
                                fullWidth
                            >
                                <InputLabel id="auto_search-label">
                                    Busca de Entregador
                                </InputLabel>
                                <Select
                                    label="Busca de Entregador"
                                    labelId="auto_search-label"
                                    id="autoSearch"
                                    name="autoSearch"
                                    value={formik.values.autoSearch ? "true" : "false"}
                                    onChange={(e) => {
                                        if (e.target.value === "true") {
                                            formik.setFieldValue("autoSearch", true);
                                        } else {
                                            formik.setFieldValue("autoSearch", false);
                                        }
                                    }}
                                >
                                    <MenuItem value="true">
                                        Automática
                                    </MenuItem>
                                    <MenuItem value="false">
                                        Direcionada
                                    </MenuItem>
                                </Select>
                                {formik.errors.autoSearch && (
                                    <FormHelperText>
                                        {formik.errors.autoSearch}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        {!formik.values.autoSearch && (
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="courierId"
                                    size="small"
                                    value={selectedCourier}
                                    options={courierList}
                                    getOptionLabel={courier => courier.name}
                                    loadingText="Aguarde ..."
                                    noOptionsText="Sem opções"
                                    onChange={(e, newValue) => {
                                        if ((newValue !== null) && (typeof newValue !== "string")) {
                                            setSelectedCourier(newValue);
                                            formik.setFieldValue("courierId", newValue.id);
                                        } else {
                                            setSelectedCourier(null);
                                            formik.setFieldValue("courierId", null);
                                        }
                                    }}
                                    renderOption={courier => (
                                        <React.Fragment>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <ImgLoader src={courier.picture} width={40} height={40} />
                                                </Grid>
                                                <Grid item>
                                                    {`${courier.name}`}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Entregador"
                                            error={!!formik.errors.courierId}
                                            helperText={formik.errors.courierId}
                                            InputLabelProps={{ required: true }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={hide}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={(props.onAccept === undefined) || formik.isSubmitting}
                    >
                        {props.acceptButtonLabel}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.forwardRef(SelectCourierDialog);