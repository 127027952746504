import React, { useCallback, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import LinkA from "@material-ui/core/Link";

import {
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    MenuItem,
    SwipeableDrawer,
    Theme,
    Typography,
    Collapse,
    useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    DashboardOutlined,
    ListAltOutlined,
    AttachMoneyOutlined,
    ExpandLess,
    ExpandMore,
    EventAvailable,
    PersonPin,
    InsertChart,
    Help
} from "@material-ui/icons";
import { DRAWER_WIDTH } from "../../../constants";
import theme from "../../../styles/theme";
import numbro from "numbro";
import AcceptTermsRequest from "../../../pages/Requests/acceptTermsRequest";
import useAppContext from "../../../hooks/useAppContext";
import useStoreContext from "../../../hooks/useStoreContext";
import RegionService from "../../../services/regionService";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { formatPhoneNumber } from "../../../utils/formatters";

const sidebarItens = [
    {
        text: "Painel",
        icon: <DashboardOutlined />,
        link: "/app/dashboard",
        submenu: false,
        items: [],
    },
    {
        text: "Solicitações",
        icon: <ListAltOutlined />,
        link: "/app/requests",
        submenu: false,
        items: [],
    },
    {
        text: "Agendamentos",
        icon: <EventAvailable />,
        link: "/app/scheduling",
        submenu: false,
        items: [],
    },
    {
        text: "Fixos",
        icon: <PersonPin />,
        link: "/app/fixed",
        submenu: false,
        items: [],
    },
    {
        text: "Estatísticas",
        icon: <InsertChart />,
        link: "/app/statistics",
        submenu: false,
        items: [],
    },
    {
        text: "Financeiro",
        icon: <AttachMoneyOutlined />,
        link: "/app/finances",
        submenu: true,
        items: [
            {
                text: "Recarga",
                link: "/app/finances/recharge",
            },
            {
                text: "Lançamentos",
                link: "/app/finances/releases",
            },
            {
                text: "Notas de Débito",
                link: "/app/finances/debitnotes",
            },
        ],
    },
    {
        text: "Ajuda",
        icon: <Help />,
        link: "/app/tutorials",
        submenu: false,
        items: [],
    },
];

const drawerWidth = DRAWER_WIDTH;

const useStyles = makeStyles((theme: Theme) => ({
    balance: {
        padding: theme.spacing(2),
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },
    drawerContainer: {
        [theme.breakpoints.up("sm")]: {
            marginTop: "64px",
        },
        overflow: "auto",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    drawerContainerDynamic: {
        [theme.breakpoints.up("sm")]: {
            marginTop: "110px",
        },
        overflow: "auto",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    version: {
        paddingTop: theme.spacing(1),
    },
    terms: {
        paddingTop: theme.spacing(1),
    },
    support: {
    },
    footer: {
        padding: theme.spacing(2),
    },
    supportTitle: {
        marginRight: "5px",
        fontSize: "14px"
    }
}));

interface SidebarProps {
    containAppAlerts: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ containAppAlerts }) => {
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { isMainMenuVisible, showMainMenu, hideMainMenu } = useAppContext();
    const { activeStore, currentBalance, creditLimit } = useStoreContext();

    const [open, setOpen] = React.useState(false);
    const [showAcceptTermsDialog, setShowAcceptTermsDialog] = React.useState(false);
    const [phoneSupport, setPhoneSupport] = React.useState<string>();


    const handleClick = () => {
        setOpen(!open);
    };

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        if (open) {
            showMainMenu();
        } else {
            hideMainMenu();
        }
    };

    const Match = (url: string) => {
        const match = useRouteMatch(url);
        return !!match;
    };

    const determineBalanceClassName = (value: number | undefined) => {
        if (value === undefined || value === 0) {
            return "";
        } else if (value > 0) {
            return "primary";
        } else {
            return "error";
        }
    };

    const getRegion = useCallback(async (regionId: number) => {
        try {
            if (regionId) {
                const response = await RegionService.getRegion(regionId);
                if (response.support_phone) {
                    setPhoneSupport(response.support_phone);
                }

            }
        } catch (error) { }
    }, []);

    useEffect(() => {
        if (activeStore) {
            getRegion(activeStore.region);
        }
    }, [getRegion, activeStore]);

    const openWhats = () => {
        if (phoneSupport) {
            const newWindow = window.open(`https://api.whatsapp.com/send?phone=+55${phoneSupport}&text=Olá`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    const drawerContent = () => (
        <React.Fragment>
            <div
                className={
                    containAppAlerts
                        ? classes.drawerContainerDynamic
                        : classes.drawerContainer
                }
            >
                <div>
                    <div className={classes.balance}>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="subtitle2" className="bold">
                                    Saldo
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <strong className={determineBalanceClassName(currentBalance)}>
                                        {currentBalance ? numbro(currentBalance).formatCurrency() : "---"}
                                    </strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="caption">Limite</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    {creditLimit ? numbro(creditLimit).formatCurrency() : "---"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <List component="nav">
                        {sidebarItens.map((sidebarItem, index) => (
                            <React.Fragment key={index}>
                                <MenuItem
                                    button
                                    component={
                                        sidebarItem.submenu ? MenuItem : Link
                                    }
                                    to={sidebarItem.link}
                                    onClick={
                                        sidebarItem.submenu ? handleClick : () => { }
                                    }
                                    selected={Match(sidebarItem.link)}
                                >
                                    <ListItemIcon>
                                        {sidebarItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={sidebarItem.text} />
                                    {sidebarItem.submenu && (
                                        open ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )
                                    )}
                                </MenuItem>
                                {sidebarItem.items && (
                                    <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="nav" disablePadding>
                                            {sidebarItem.items.map(sidebarSubitem => (
                                                <MenuItem
                                                    button
                                                    style={{
                                                        paddingLeft: "22%",
                                                    }}
                                                    className="submenu-item"
                                                    component={Link}
                                                    to={sidebarSubitem.link}
                                                    selected={Match(
                                                        sidebarSubitem.link
                                                    )}
                                                    key={sidebarSubitem.text}
                                                >
                                                    <ListItemText primary={sidebarSubitem.text} />
                                                </MenuItem>
                                            )
                                            )}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </div>
                <div className={classes.footer}>
                    <div className={classes.support}>
                        <Typography variant="caption" className={classes.supportTitle}>
                            Suporte:
                        </Typography>
                        <LinkA
                            component="button"
                            variant="body2"
                            onClick={openWhats}
                        >
                            {phoneSupport ? formatPhoneNumber(phoneSupport) : ""} <WhatsAppIcon style={{ fontSize: "14px", color: "green" }} />
                        </LinkA>
                    </div>
                    <div className={classes.terms}>
                        <LinkA
                            component="button"
                            variant="body2"
                            onClick={() => {
                                setShowAcceptTermsDialog(true);
                            }}
                        >
                            Termos de Uso
                        </LinkA>
                    </div>
                    <div className={classes.version}>
                        <Typography variant="caption" className={classes.supportTitle}>
                            Versão {process.env.REACT_APP_VERSION}
                        </Typography>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <SwipeableDrawer
            className={classes.drawer}
            variant={smallScreen ? "temporary" : "persistent"}
            anchor="left"
            open={isMainMenuVisible}
            classes={{
                paper: classes.drawerPaper,
            }}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {drawerContent()}
            <AcceptTermsRequest
                open={showAcceptTermsDialog}
                setOpen={setShowAcceptTermsDialog}
            />
        </SwipeableDrawer>
    );
};

export default Sidebar;
