import React from 'react';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import * as Yup from "yup";
import PasswordService from '../../services/passwordService';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").max(100, "O e-mail pode ter no máximo 100 caracteres").required("Campo obrigatório"),
});

const PasswordForgot: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: async (values) => {
      try {
        await PasswordService.requestPasswordReset(values.email);
        enqueueSnackbar("Siga as instruções do e-mail que enviamos para você", { variant: 'success' });
        history.push({ pathname: "/" });
      } catch (error) {
        formik.setFieldError("email", "E-mail não encontrado");
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Esqueci minha senha</Typography>
          <Typography variant="subtitle1">
            Informe o e-mail associado a sua conta. Nós enviaremos um e-mail
            contendo um link e instruções para você redefinir sua senha.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            type="email"
            label="E-mail"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordForgot;
