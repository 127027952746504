import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
//import { useHistory } from "react-router-dom";
import {
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../../components/TableTrinkets";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { Archive, GetApp } from "@material-ui/icons";
import { DebitNotesFilter, IDebitNotesFilterParams, INITIAL_FILTER_PARAMS } from "./debitNotesFilter";
import DebitNotesService from "../../../services/debitNotesService";
import { DebitNotesResponse } from "../../../services/types/debitNotes";
import useAuth from "../../../hooks/useAuth";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CURRENT_DATE_FORMAT, CURRENT_DATE_FORMAT_TABLE_MONTH_YEAR } from "../../../i18n";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";

const DebitNotes: React.FC = () => {
    const [filterParams, setFilterParams] = useState<IDebitNotesFilterParams>(INITIAL_FILTER_PARAMS);
    //const history = useHistory();
    const { user } = useAuth();
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
    const tableRef = React.useRef<MaterialTableProps<DebitNotesResponse>>();
    const { enqueueSnackbar } = useSnackbar();
    
    const loadData = (query: Query<DebitNotesResponse>) =>
        new Promise<QueryResult<DebitNotesResponse>>((resolve, reject) => {
            DebitNotesService.getDebitNotes(Number(user?.customer?.id),
                filterParams.date_after, filterParams.date_after, PageableParams.fromQuery(query))
                .then(response => {
                    resolve({
                        data: response.data,
                        page: query.page,
                        totalCount: response.count,
                });
        });
    });

    const applyFilter = (params: IDebitNotesFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<DebitNotesResponse>);
        }
    };

    const handleDownloadDebitNote = (debitNote: DebitNotesResponse) => {
        if(debitNote){
            showLoadingDialog(true);
            DebitNotesService.downloadDebitNote(debitNote.id).then((response) => {
                fileDownload(response.data, `Nota de Débito ${capitalizeFirstLetter(format(debitNote.reference_date, CURRENT_DATE_FORMAT_TABLE_MONTH_YEAR, {locale: ptBR}))}.pdf`);
            }) 
            .catch(err => {
                enqueueSnackbar("Erro ao fazer download da Nota de Débito.", { variant: "error" });
              })
            .finally(() => {
                showLoadingDialog(false);
            });
        }
    };

    const handleDownloadFiscalNote = (debitNote: DebitNotesResponse) => {
        if(debitNote && debitNote.invoice){
            showLoadingDialog(true);
            DebitNotesService.downloadNoteFiscal(debitNote.invoice).then((response) => {
                fileDownload(response.data, `Nota Fiscal ${debitNote.number}.pdf`);
            }) 
            .catch(err => {
                enqueueSnackbar("Erro ao fazer download da Nota Fiscal.", { variant: "error" });
              })
            .finally(() => {
                showLoadingDialog(false);
            });
        }
    };

    React.useEffect(() => {
        if (user?.customer) {
            if (tableRef.current?.onQueryChange) {
                tableRef.current.onQueryChange({
                    page: 0,
                } as Query<DebitNotesResponse>);
            }
        }
      }, [user]);

    /* const showDetails = (item: DebitNotesResponse) => {
        history.push(`debitNotes/detail/${item.id}`);
    }; */

    function capitalizeFirstLetter(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    return (
        <div>
            <LoadingOverlay open={isShowLoadingDialog} />

            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Notas de Débito</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
            <DebitNotesFilter onFilterChanged={applyFilter} />
            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 160 },
                    { field: "number", title: "Número"},
                    {
                        field: "reference_date",
                        title: "Data Referente",
                        render: (rowData, type) =>
                        capitalizeFirstLetter(format(rowData.reference_date, CURRENT_DATE_FORMAT_TABLE_MONTH_YEAR, {locale: ptBR})),
                    },
                    {
                        field: "emission_date",
                        title: "Data de Emissão",
                        render: (rowData, type) =>
                        format(rowData.reference_date, CURRENT_DATE_FORMAT, {locale: ptBR}),
                    },

                ]}
                data={loadData}
                actions={[                    
                    /* {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as DebitNotesResponse),
                    }, */
                    {
                        icon: () => <GetApp />,
                        tooltip: "Baixar Nota de Débito",
                        onClick: (event, rowData) =>
                        handleDownloadDebitNote(rowData as DebitNotesResponse),
                    },

                    (rowData) => rowData && rowData.invoice ?
                        {
                            icon: () => <Archive />,
                            tooltip: "Baixar Nota Fiscal",
                            onClick: (event, rowData) =>
                            handleDownloadFiscalNote(rowData as DebitNotesResponse)
                        } : {
                            
                            icon: () => <Archive/>,
                            onClick: () => {},
                            hidden: true,                            
                        },
                    
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={{
                    ...DEFAULT_TABLE_OPTIONS, 
                    draggable: false
                }}
            />
        </div>
    );
};
export default DebitNotes;

