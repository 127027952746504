import { DeliveryRequestStatus } from "./deliveryRequest";

export enum FinancialEntryType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  BALANCE = "BALANCE",
}

export enum FinancialEntryStatus {
  PROCESSING = "PROCESSING",
  CONFIRMED = "CONFIRMED",
  PAID = "PAID",
  CANCELED = "CANCELED",
}

export enum FinancialEntryOrigin {
  BANK_SLIP = "BANK_SLIP",
  TRANSFER = "TRANSFER",
  CREDIT_CARD = "CREDIT_CARD",
  DELIVERY = "DELIVERY",
  INCENTIVE = "INCENTIVE",
  PIX = "PIX",
}

export interface CustomerBalance {
  customer: number;
  updatedAt: Date;
  currentBalance: number;
  creditLimit: number;
}

export interface CustomerFinancialStats {
  average_ticket: number;
  average_weekly_deliveries: number;
  average_weekly_expenses: number;
  current_balance: number;
  today_deliveries: number;
  today_expenses: number;
  weekly_canceled_deliveries: number;
  weekly_deliveries: number;
  weekly_expenses: number;
}

export interface CustomerFinancialEntryResponse {
  id: number;
  customer_name: string;
  store_name?: string;
  created_at: Date;
  updated_at: Date;
  closed_date?: Date;
  confirmation_date?: Date;
  payment_date?: Date;
  canceled_date?: Date;
  request_number?: string;
  delivery_status?: DeliveryRequestStatus;
  document?: any;
  courier_value?: number;
  speedy_value?: number;
  description: string;
  value: number;
  tax: number;
  total: number;
  type: FinancialEntryType;
  status: FinancialEntryStatus;
  origin: FinancialEntryOrigin;
  bank_slip_key?: string;
  bank_slip_barcode?: string;
  bank_slip_custom_number?: string;
  account: number;
  delivery_request?: number;
}

export interface CreditCardCreate {
  number: string;
  holder_name: string; 
  holder_document: string; 
  exp_month: string; 
  exp_year: string;
  cvv: string;
  save_card: boolean;
}

export interface CreditCardResponse {
  id: number;
  last_numbers: string; 
  brand: string;
}

export interface FinancialTaxResponse {
  credit_card_percentage_tax: number;
  bank_slip_tax: number; 
  pix_tax: number;
}