import React, { useCallback } from "react";
import { apiAxios as axios } from "../utils/axios";
import { cache } from "../utils/cache";

interface IImgLoaderProps {
  height?: number;
  width?: number;
  className?: string;
  useCache?: boolean;
  src: string | File;
  children?: any;
}

export const ImgLoader: React.FC<IImgLoaderProps> = ({
  height,
  width,
  className,
  useCache = true,
  src,
  children
}) => {
  const [fileSrc, setFileSrc] = React.useState<string>("");

  const download = useCallback(async () => {
    try {
      const response = await axios.get(`${src}`,
        {
          responseType: 'arraybuffer',
        });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      if (blob.type.startsWith("image")) {
        let objectURL = URL.createObjectURL(blob);
        if (useCache) {
          cache.set(String(src), objectURL);
        }
        setFileSrc(objectURL);
      }
    } catch (error) {
      setFileSrc("");
    }
  }, [src, useCache]);

  // Load data
  React.useEffect(() => {
    if (src instanceof File) {
      if (src.size > 0) {
        setFileSrc(URL.createObjectURL(src));
      }
    } else if (src.startsWith("data:image")) {
      setFileSrc(src);
    } else if (src.startsWith("data:")) {
      setFileSrc("");
    } else if (useCache && cache.has(src)) {
      setFileSrc(String(cache.get(src)));
    } else {
      setFileSrc("");
      download();
    }
  }, [src, useCache, download]);

  if (fileSrc) {
    return (
      <img src={fileSrc} alt="Foto" height={height} width={width} className={className} />
    );
  } else {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
};
