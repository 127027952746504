import React from "react";
import { Marker, MarkerProps } from "react-leaflet";
import L from "leaflet";
import { makeStyles } from "@material-ui/core/styles";
import { renderToStaticMarkup } from 'react-dom/server';

interface IProps extends MarkerProps {
  color?: string;
  className?: string;
  internalIcon?: React.ReactNode;
}

const LeafletMarker = (props: IProps) => {
  const classes = useStyles();

  const { className = "", color = "", internalIcon, children } = props;

  const iconMarkup = renderToStaticMarkup(
    <div className={`${classes.root} ${className}`} style={{ background: color }}>
      {internalIcon}
    </div>
  );
  const customMarkerIcon = L.divIcon({
    html: iconMarkup,
    iconSize: [0, 0],
    iconAnchor: [0, 0],
    popupAnchor: [0, -34],
  });

  return (
    <Marker {...props} icon={customMarkerIcon}>
      {children}
    </Marker>
  )
}

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "34px",
    height: "34px",
    background: "#4CAF50",
    borderRadius: "50% 50% 50% 0",
    transform: "rotate(-45deg)",
    marginTop: "-40px",
    marginLeft: "-17px",
    border: "2px solid rgba(0, 0, 0, .3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      color: "#FFF",
      transform: "rotate(45deg)",
    },
  },
});

export default LeafletMarker;
