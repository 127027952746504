import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { DeliveriesPerDistrictResponse } from "../../../services/types/statistics";

import { ResponsiveBar, BarTooltipProps, ComputedDatum, BarDatum } from "@nivo/bar";
import { useTheme } from '@nivo/core'

interface ICustomerExtraDetailStores {
    deliveriesPerDistrict: DeliveriesPerDistrictResponse[];
};

const BarTooltip: React.FunctionComponent<BarTooltipProps<BarDatum>> = (props) => {
    const theme = useTheme();
    return (
        <div key={props.index} style={theme.tooltip.container}>
            <div style={theme.tooltip.basic}>
                {/*<span style={{ display: 'block', width: "12px", height: "12px", background: props.color, marginRight: "8px" }} />*/}
                <span>
                    {props.label}: <strong>{`${props.formattedValue}`}</strong>
                </span>
            </div>
        </div>
    );
};

export const DeliveriesPerDistrict: React.FC<ICustomerExtraDetailStores> = ({
    deliveriesPerDistrict
}) => {
    const classes = useStyles();
    const [chartData, setChartData] = React.useState<any[]>([]);
    //const [otherDistricts, setOtherDistricts] = React.useState<any[]>([]);

    React.useEffect(() => {
        deliveriesPerDistrict.sort((a, b) => b.total - a.total);
        const topX = [];
        const others = [];
        for (let i = 0; i < deliveriesPerDistrict.length; i++) {
            if (i < 15) {
                topX.push(deliveriesPerDistrict[i]);
            } else {
                others.push(deliveriesPerDistrict[i]);
            }
        }
        setChartData(topX);
        //setOtherDistricts(others);
    }, [deliveriesPerDistrict]);

    const buildTooltipLabel = (datum: ComputedDatum<BarDatum>) => {
        return String(datum.indexValue);
    }

    return (
        <React.Fragment>
            <div>
                <Typography variant="h3">Entregas por bairro (Top 15)</Typography>
                <div className={classes.chartContainer}>
                    <ResponsiveBar
                        margin={{ top: 5, right: 30, bottom: 100, left: 40 }}
                        minValue={0}
                        valueFormat=">-.0d"
                        data={chartData}
                        indexBy={"district"}
                        keys={["total"]}
                        padding={0.1}
                        labelTextColor={"inherit:darker(0.1)"}
                        labelSkipWidth={16}
                        labelSkipHeight={16}
                        colors={["#0080FF", "#f44336", "#673ab7", "#ff9800", "#ffc107", "#4caf50"]}
                        //colors={{ scheme: 'nivo' }}
                        layers={[
                            "grid",
                            "axes",
                            "bars",
                            "markers",
                            "annotations",
                        ]}
                        tooltip={BarTooltip}
                        tooltipLabel={buildTooltipLabel}
                        enableGridX={false}
                        animate={true}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 6,
                            tickRotation: -40,
                            format: (value: string) => {
                                return value.length > 18 ? value.substring(0, 17) + "…" : value;
                            }
                        }}
                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 5,
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    chartContainer: {
        fontFamily: 'Nunito Sans, sans-serif',
        height: "400px",
        width: "100%",
        paddingTop: '16px',
    }
});
