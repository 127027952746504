import React from "react";

interface ShowIfProps {
    condition: boolean;
    children: any;
    type?: "noRender" | "visibilityHidden" | "displayNone";
}

const ShowIf: React.FC<ShowIfProps> = ({
    condition,
    children,
    type = "noRender"
}) => {
    let result;

    if (condition) {
        result = (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    } else {
        switch (type) {
            case "visibilityHidden":
                result = <span style={{ visibility: "hidden" }}>{children}</span>;
                break;
            case "displayNone":
                result = <span style={{ display: "none" }}>{children}</span>;
                break;
            default:
                result = <React.Fragment></React.Fragment>;
        }
    }

    return result;
};

export default ShowIf;