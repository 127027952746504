import { apiAxios as axios } from "../utils/axios";
import { urls } from "../utils/urls";
import { Region } from "./types/region";

export interface IRegionService {
    getRegion(id: number): Promise<Region>;
}

class RegionServiceImpl implements IRegionService {
    _region: Region | null = null;

    getRegion(id: number): Promise<Region> {
        return new Promise<Region>((resolve, reject) => {
            if (this._region && this._region.id === id) {
                resolve(this._region);
            } else {
                const url = `${urls.REGION}${id}/`;
                axios.get(url)
                    .then(response => {
                        const coordinates = response.data.coordinates.map((item: any) => {
                            return {
                                latitude: Number(item.latitude),
                                longitude: Number(item.longitude)
                            }
                        });
                        const result = {
                            ...response.data,
                            coordinates: coordinates
                        }
                        this._region = result;
                        resolve(result);
                    })
                    .catch(error => reject(error));
            }
        });
    };

}

const RegionService = new RegionServiceImpl();

export default RegionService;
