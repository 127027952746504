import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, MenuItem, Button, Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import { DeliveryRequestStatus } from "../../services/types/deliveryRequest";
import { FinancialEntryType } from "../../services/types/customerFinance";

export interface IDeliveryRequestFilterParams {
  requestNumber: string;
  orderNumber: string;
  requestedTimeAfter: Date | null;
  requestedTimeBefore: Date | null;
  courierName: string;
  storeName: string;
  status: string;
  manualCreation: string;
  type: FinancialEntryType | null;
}

export const INITIAL_FILTER_PARAMS: IDeliveryRequestFilterParams = {
  requestNumber: "",
  orderNumber: "",
  requestedTimeAfter: null,
  requestedTimeBefore: null,
  courierName: "",
  storeName: "",
  status: "FINISHED",
  manualCreation: " ",
  type: null
};

interface IDeliveryRequestFilterProps {
  params?: IDeliveryRequestFilterParams;
  onFilterChanged: (params: IDeliveryRequestFilterParams) => void;
}

export const DeliveryRequestFilter: React.FC<IDeliveryRequestFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  onFilterChanged,
}) => {
  const { t } = useTranslation();

  const materialTheme = useTheme();
  const smallScreen = useMediaQuery(materialTheme.breakpoints.down("md"));

  const formik = useFormik({
    initialValues: params,
    onSubmit: (values) => {
      onFilterChanged(values);
    },
    onReset: (values) => {
      onFilterChanged(values);
    },
  });

  return (
    <Box mt={1}>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              id="requestNumber"
              name="requestNumber"
              label="Solicitação"
              onChange={formik.handleChange}
              value={formik.values.requestNumber}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="orderNumber"
              name="orderNumber"
              label="Pedido"
              onChange={formik.handleChange}
              value={formik.values.orderNumber}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          {smallScreen ? (<>
            <Grid item xs={12}>
              <TextField
                id="requestedTimeAfter"
                name="requestedTimeAfter"
                label="Data e Hora Inicial"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                value={formik.values.requestedTimeAfter}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="requestedTimeBefore"
                name="requestedTimeBefore"
                label="Data e Hora Final"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                value={formik.values.requestedTimeBefore}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </>) : (<>
            <Grid item md={3}>
              <DatePicker
                id="requestedTimeAfter"
                name="requestedTimeAfter"
                onChange={date => formik.setFieldValue("requestedTimeAfter", date)}
                selected={formik.values.requestedTimeAfter}
                startDate={formik.values.requestedTimeAfter}
                endDate={formik.values.requestedTimeBefore}
                maxDate={new Date()}
                locale={DEFAULT_LOCALE}
                dateFormat={CURRENT_DATE_TIME_FORMAT}
                timeCaption="Hora"
                isClearable
                selectsStart
                showTimeSelect
                wrapperClassName="MuiFormControl-fullWidth"
                customInput={<TextField
                  label="Data e Hora Inicial"
                  variant="outlined"
                  size="small"
                  InputProps={{ autoComplete: "off" }}
                  fullWidth />}
              />
            </Grid>
            <Grid item md={3}>
              <DatePicker
                id="requestedTimeBefore"
                name="requestedTimeBefore"
                onChange={date => formik.setFieldValue("requestedTimeBefore", date)}
                selected={formik.values.requestedTimeBefore}
                startDate={formik.values.requestedTimeAfter}
                endDate={formik.values.requestedTimeBefore}
                minDate={formik.values.requestedTimeAfter}
                maxDate={new Date()}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport"
                  }
                }}
                locale={DEFAULT_LOCALE}
                dateFormat={CURRENT_DATE_TIME_FORMAT}
                timeCaption="Hora"
                isClearable
                selectsEnd
                showTimeSelect
                wrapperClassName="MuiFormControl-fullWidth"
                customInput={<TextField
                  label="Data e Hora Final"
                  variant="outlined"
                  size="small"
                  InputProps={{ autoComplete: "off" }}
                  fullWidth />}
              />
            </Grid>
          </>)}
          <Grid item md={4} xs={12}>
            <TextField
              label="Entregador"
              id="courierName"
              name="courierName"
              onChange={formik.handleChange}
              value={formik.values.courierName}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="manualCreation"
              name="manualCreation"
              label="Tipo de Solicitação"
              size="small"
              select
              onChange={formik.handleChange}
              value={formik.values.manualCreation}
              variant="outlined"
              fullWidth
            >
              <MenuItem value=" ">Todos</MenuItem>
              <MenuItem value="true">Manual</MenuItem>
              <MenuItem value="false">Busca</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="status"
              name="status"
              label="Situação"
              size="small"
              select
              onChange={formik.handleChange}
              value={formik.values.status}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={DeliveryRequestStatus.FINISHED}>
                {t("delivery_request_status." + DeliveryRequestStatus.FINISHED)}
              </MenuItem>
              <MenuItem value={DeliveryRequestStatus.CANCELED}>
                {t("delivery_request_status." + DeliveryRequestStatus.CANCELED)}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
