import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { debounce } from "lodash";
import React from "react";
import ShowIf from "./ShowIf";

interface ConfirmationDialogProps {
    acceptBtnText?: string;
    rejectBtnText?: string;
}

export interface ConfirmationDialogHandle {
    confirm: (
        title: string,
        message: string | React.ReactNode,
        acceptBtnText?: string,
        rejectBtnText?: string
    ) => Promise<void>;
    hide: () => void;
}

export const ConfirmationDialog: React.ForwardRefRenderFunction<ConfirmationDialogHandle, ConfirmationDialogProps> = (props, ref) => {

    const [isSubmiting, setSubmiting] = React.useState<boolean>(false);

    const [title, setTitle] = React.useState<string>();
    const [message, setMessage] = React.useState<string | React.ReactNode>();
    const [acceptBtnText, setAcceptBtnText] = React.useState<string>();
    const [rejectBtnText, setRejectBtnText] = React.useState<string>();

    const [resolveReject, setResolveReject] = React.useState<any[]>([]);
    const [resolve, reject] = resolveReject;

    React.useImperativeHandle(ref, () => ({
        confirm(
            title: string,
            message: string | React.ReactNode,
            acceptBtnText?: string,
            rejectBtnText?: string
        ) {
            setSubmiting(false);
            return show(title, message, acceptBtnText, rejectBtnText);
        },

        hide() {
            close();
        }
    }));

    const close = () => {
        setResolveReject([]);
        setSubmiting(false);
    }

    const show = (
        title: string,
        message: string | React.ReactNode,
        acceptBtnText?: string,
        rejectBtnText?: string
    ) => {
        return new Promise<void>((resolve, reject) => {
            setResolveReject([resolve, reject]);
            setTitle(title);
            setMessage(message);
            setAcceptBtnText(acceptBtnText || props.acceptBtnText);
            setRejectBtnText(rejectBtnText || props.rejectBtnText);
        });
    }

    const handleConfirmBtnClick = React.useMemo(() => debounce(() => {
        setSubmiting(true);
        resolve();
    }, 500, { leading: true, trailing: false }), [resolve]);

    const handleRejectBtnClick = React.useMemo(() => debounce(() => {
        reject();
        close();
    }, 500, { leading: true, trailing: false }), [reject]);

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={resolveReject.length === 2}
        >
            <DialogTitle id="confirmation-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {typeof message === "string" ? (
                    <Typography>
                        {message}
                    </Typography>
                ) : (
                    <React.Fragment>
                        {message}
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleRejectBtnClick()} color="secondary">
                    {rejectBtnText || "Fechar"}
                </Button>
                <Button
                    disabled={isSubmiting}
                    onClick={() => handleConfirmBtnClick()}
                    color="primary"
                >
                    <ShowIf condition={isSubmiting}>
                        <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                    </ShowIf>
                    {acceptBtnText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.forwardRef(ConfirmationDialog);