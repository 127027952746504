import React from "react";
import type { FC, ReactNode } from "react";

export interface AppAlert {
  id: number;
  severity: "error" | "warning";
  content: string | React.ReactNode;
}

interface AppState {
  nextAlertId: number;
  isMainMenuVisible: boolean;
  containAppAlerts: boolean;
  appAlerts: AppAlert[];
}

interface AppContextValue extends AppState {
  showMainMenu: () => void;
  hideMainMenu: () => void;
  toggleMainMenu: () => void;
  addAppAlert: (severity: "error" | "warning", content: React.ReactNode | string) => AppAlert;
  removeAppAlert: (alert: AppAlert) => void;
}

enum AppActions {
  SHOW_MAIN_MENU,
  HIDE_MAIN_MENU,
  TOGGLE_MAIN_MENU,
  ADD_APP_ALERT,
  REMOVE_APP_ALERT
}

type MainMenuAction = {
  type: AppActions.SHOW_MAIN_MENU | AppActions.HIDE_MAIN_MENU | AppActions.TOGGLE_MAIN_MENU;
};

type AddAppAlert = {
  type: AppActions.ADD_APP_ALERT,
  payload: { alert: AppAlert };
}

type RemoveAppAlert = {
  type: AppActions.REMOVE_APP_ALERT,
  payload: { id: number };
}

type Action = MainMenuAction | AddAppAlert | RemoveAppAlert;

const initialAppState: AppState = {
  nextAlertId: 1,
  isMainMenuVisible: true,
  containAppAlerts: false,
  appAlerts: [],
};

const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case AppActions.SHOW_MAIN_MENU: {
      return {
        ...state,
        isMainMenuVisible: true
      };
    }
    case AppActions.HIDE_MAIN_MENU: {
      return {
        ...state,
        isMainMenuVisible: false
      };
    }
    case AppActions.TOGGLE_MAIN_MENU: {
      return {
        ...state,
        isMainMenuVisible: !state.isMainMenuVisible
      };
    }
    case AppActions.ADD_APP_ALERT: {
      const { alert } = action.payload;
      alert.id = state.nextAlertId;
      const newAppAlerts = state.appAlerts.concat(alert);
      return {
        ...state,
        nextAlertId: state.nextAlertId + 1,
        appAlerts: newAppAlerts,
        containAppAlerts: true,
      };
    }
    case AppActions.REMOVE_APP_ALERT: {
      const { id } = action.payload;
      const newAppAlerts = state.appAlerts.filter((item: AppAlert) => {
        return !(item.id === id);
      });
      return {
        ...state,
        appAlerts: newAppAlerts,
        containAppAlerts: (newAppAlerts.length > 0),
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AppContext = React.createContext<AppContextValue>({
  ...initialAppState,
  showMainMenu: () => { },
  hideMainMenu: () => { },
  toggleMainMenu: () => { },
  addAppAlert: (severity: "error" | "warning", content: React.ReactNode | string): AppAlert => ({} as AppAlert),
  removeAppAlert: (alert: AppAlert) => { }
});

interface AppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialAppState);

  const showMainMenu = React.useCallback(() => {
    dispatch({ type: AppActions.SHOW_MAIN_MENU });
  }, []);

  const hideMainMenu = React.useCallback(() => {
    dispatch({ type: AppActions.HIDE_MAIN_MENU });
  }, []);

  const toggleMainMenu = React.useCallback(() => {
    dispatch({ type: AppActions.TOGGLE_MAIN_MENU });
  }, []);

  const addAppAlert = React.useCallback((severity: "error" | "warning", content: React.ReactNode | string) => {
    const alert: AppAlert = {id: 0, severity, content};
    dispatch({
      type: AppActions.ADD_APP_ALERT,
      payload: { alert: alert }
    });
    return alert;
  }, []);

  const removeAppAlert = React.useCallback((alert: AppAlert) => {
    dispatch({ type: AppActions.REMOVE_APP_ALERT, payload: { id: alert.id } });
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...state,
        showMainMenu,
        hideMainMenu,
        toggleMainMenu,
        addAppAlert,
        removeAppAlert
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
