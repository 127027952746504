import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {
  DeliveryRequestLog,
  Trip,
} from "../../services/types/deliveryRequest";
import { TimelineOppositeContent } from "@material-ui/lab";
import TripService from "../../services/tripService";

interface IProps {
  trip: Trip;
}

export const HistoryTimelineTrip = ({ trip }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [logs, setLogs] = React.useState<DeliveryRequestLog[]>([]);

  const loadLogTrip = useCallback((idLogTrip: number) => {
    TripService.getLogsByTrip(idLogTrip)
      .then((data) => {
        if(data){
          setLogs(data);
        }else{
          setLogs([]);
        };          
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    if(trip){
      loadLogTrip(trip.id);
    }   
  }, [loadLogTrip, trip]);

  return (
    <Timeline align="right">
      {logs
        .sort((a, b) => b.id - a.id)
        .map((item) => (
          <TimelineItem>
            <TimelineContent>
              {!item.is_courier_action && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">
                      {format(item.created_at, "dd/MM/yyyy 'às' HH:mm")}
                    </Typography>
                    <Typography className={classes.title}>
                      {t("trip_request_event." + item.event)}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </TimelineContent>
            <TimelineSeparator>
              <TimelineDot color="primary" variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent>
              {item.is_courier_action && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">
                      {format(item.created_at, "dd/MM/yyyy 'às' HH:mm")}
                    </Typography>
                    <Typography className={classes.title}>
                      {t("trip_request_event." + item.event)}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </TimelineOppositeContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "1rem",
    },
    marginBot: {
      marginBottom: "10px",
    },
  })
);
