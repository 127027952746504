import { convertToNumber } from './../utils/utils';
import { apiAxios as axios } from "../utils/axios";
import { parseISO } from "date-fns";
import { urls } from "../utils/urls";
import { City, Customer, Store } from "./types/customer";
import { CreditCardResponse } from "./types/customerFinance";

interface ICustomerService {
    getCustomerById: (id: number) => Promise<Customer>;

    getCreditCards: () => Promise<any>;

    deleteCard: (idCard: number) => Promise<any>;
}

export const CustomerService: ICustomerService = {
    getCustomerById: async (id: number) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.CUSTOMER}${id}/`)
                .then(response => {
                    let city: City | null = null;
                    if (response.data.fiscal_address_city) {
                        const fiscalAddressCity =
                            response.data.fiscal_address_city;
                        city = {
                            ...fiscalAddressCity,
                            id: Number(fiscalAddressCity.id),
                        };
                    }

                    const stores: Store[] = response.data.stores.map(
                        (store: any) => {
                            return {
                                ...store,
                                id: Number(store.id),
                                created_at: parseISO(store.created_at),
                                updated_at: store.updated_at
                                    ? parseISO(store.updated_at)
                                    : null,
                                latitude: Number(store.latitude),
                                longitude: Number(store.longitude),
                                city: Number(store.city),
                                additional_km_price: Number(
                                    store.additional_km_price
                                ),
                                region: Number(store.region),
                            };
                        }
                    );

                    const result: Customer = {
                        ...response.data,
                        id: Number(response.data.id),
                        created_at: parseISO(response.data.created_at),
                        updated_at: response.data.updated_at
                            ? parseISO(response.data.updated_at)
                            : null,
                        fiscal_address_city: city,
                        stores: stores,
                        credit_limit: response.data.waste_limit
                            ? Number(response.data.waste_limit)
                            : 0,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getCreditCards: async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.CREDITCARD}`)
                .then(response => {
                     const cards: CreditCardResponse[] = response.data.results.map(
                        (card: any) => {
                            return {
                                ...card,
                                id: convertToNumber(card.id),
                            };
                        }
                    );
                    resolve(cards);
                })
                .catch(error => reject(error));
        });
    },

    deleteCard: async (idCard: number) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${urls.CREDITCARD}${idCard}/`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },
};
