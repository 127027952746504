import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    DeliveryRequest,
    DeliveryRequestAction,
    DeliveryRequestStatus,
    PendingDeliveryRequest,
    PendingTrip,
    Trip,
    TripStatus,
} from "../../services/types/deliveryRequest";
import TripService from "../../services/tripService";
import { ICON_MAP, IMarkerCoord } from "./deliveryUtils";
import { Map } from "leaflet";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import SelectCourierDialog, { SelectCourierDialogHandle } from "./SelectCourierDialog";
import useStoreContext from "../../hooks/useStoreContext";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "../Dashboard/customComponents/joinDeliveriesOnTripDialog";
import { IPageableParams } from "../../services/types/common";
import DeliveryRequestService from "../../services/deliveryRequestService";
import ShowIf from "../../components/ShowIf";
import { MapContainer, TileLayer, Tooltip, ZoomControl } from "react-leaflet";
import LeafletMarker from "../../components/LeafletMarker";
import numbro from "numbro";
import { ImgLoader } from "../../components/ImgLoader";
import { SpeedyRankingType } from "../../services/types/courier";
import { formatPhoneNumber } from "../../utils/formatters";
import PopoverRanking from "../Dashboard/customComponents/popoverRanking";
import { Alert } from "@material-ui/lab";
import { mapDeliveryRequestStatusToSeverity } from "./deliveryRequestUtils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";

interface IProps {
    tripId: number;
    trip: Trip;
    deliveryList: DeliveryRequest[] | undefined;
    loadData?: (tripId: number) => Promise<void>;
};

const DELIVERY_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 500,
    ordering: "estimated_delivery_time",
};

const TRIP_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 200,
    ordering: "created_at",
};

export const MapTripDetail = ({ tripId, trip, deliveryList, loadData }: IProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const selectCourierDialog = React.createRef<SelectCourierDialogHandle>();
    const [markerCoords, setMarkerCoords] = React.useState<IMarkerCoord[]>();
    const [mapBounds, setMapBounds] = React.useState<[number, number][]>();
    const map = React.useRef<Map>(null);
    const [anchorElRanking, setAnchorElRanking] = React.useState<HTMLElement | null>(null);
    const { activeStore } = useStoreContext();
    const [newDeliveryList, setNewDeliveryList] = React.useState<PendingDeliveryRequest[]>([]);
    const [tripList, setTripList] = React.useState<PendingTrip[]>([]);
    const [openJoinDelivery, setOpenJoinDelivery] = React.useState(false);
    const [noCourierList, setNoCourierList] = React.useState<PendingTrip[]>([]);
    const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();
    const { selectCourierFromQueue } = useStoreContext();
    const [totalMinimumTrip, setTotalMinimumTrip] = React.useState<number>(0);
    const [totalIncentives, setTotalIncentives] = React.useState<number>(0);
    const [totalReturn, setTotalReturn] = React.useState<number>(0);
    const [valueTotalAddKm, setValueTotalAddKm] = React.useState<number>(0);
    const [valueTotalTrip, setValueTotalTrip] = React.useState<number>(0);
    useEffect(() => {
       let valueTotalMinimum = 0;
       let valueTotalIncentives = 0;
       let valueTotalReturn = 0;
       let valueTotalAddKm = 0;
       if(deliveryList && deliveryList.length > 0){
            deliveryList?.forEach(item => {
                valueTotalMinimum += item.minimum_value ? item.minimum_value : 0;
                if(item.incentive_json){
                    valueTotalIncentives += item.incentive_json.customer_value;
                }                
                valueTotalReturn += item.return_value ? item.return_value : 0;
                valueTotalAddKm += item.additional_km_value ? item.additional_km_value : 0;
            });
       };    
       setTotalMinimumTrip(valueTotalMinimum);
       setValueTotalAddKm(valueTotalAddKm);
       setTotalIncentives(valueTotalIncentives);
       setTotalReturn(valueTotalReturn);    
       setValueTotalTrip(valueTotalMinimum + valueTotalAddKm + valueTotalIncentives + valueTotalReturn)  
    }, [deliveryList]);


    const handlePopoverRankingOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorElRanking(event.currentTarget);
    };
    const handlePopoverRankingClose = () => {
        setAnchorElRanking(null);
    };
    const openPopoverRanking = Boolean(anchorElRanking);

    useEffect(() => {
        if (deliveryList) {
            const _bounds: [number, number][] = [];
            const _coordsMarker: IMarkerCoord[] = [];

            if (trip?.courier_position) {
                _bounds.push([trip.courier_position?.latitude, trip.courier_position?.longitude]);
                _coordsMarker.push({
                    type: trip.courier?.vehicle_type,
                    title: "Entregador",
                    lat: trip.courier_position?.latitude,
                    lng: trip.courier_position?.longitude,
                });
            }

            if (deliveryList.length > 0) {
                deliveryList.forEach((deliveryRequest) => {
                    _bounds.push([deliveryRequest.origin_address?.latitude, deliveryRequest.origin_address?.longitude])
                    _coordsMarker.push({
                        type: "STORE",
                        title: "Loja",
                        lat: deliveryRequest?.origin_address?.latitude,
                        lng: deliveryRequest?.origin_address?.longitude,
                    });

                    const titleHouse = `#${deliveryRequest?.order_number} - ${deliveryRequest?.customer.customer_name}`;
                    let typeHouse = "HOUSE";
                    if (deliveryRequest.status === DeliveryRequestStatus.FINISHED) {
                        typeHouse = "HOUSE_FINISH";
                    } else if (deliveryRequest.status === DeliveryRequestStatus.RETURNING) {
                        typeHouse = "HOUSE_RETURNING"
                    } else if (deliveryRequest.status === DeliveryRequestStatus.CANCELED
                        || deliveryRequest.status === DeliveryRequestStatus.DISCARDED) {
                        typeHouse = "HOUSE_CANCELED";
                    } else {
                        typeHouse = "HOUSE"
                    }
                    _bounds.push([deliveryRequest?.destination_address?.latitude, deliveryRequest?.destination_address?.longitude]);

                    _coordsMarker.push({
                        type: typeHouse,
                        title: titleHouse,
                        lat: deliveryRequest?.destination_address?.latitude,
                        lng: deliveryRequest?.destination_address?.longitude,
                    });
                })
            }
            setMapBounds(_bounds);
            setMarkerCoords(_coordsMarker);
        }
    }, [deliveryList, trip]);

    useLayoutEffect(() => {
        if (map.current && mapBounds) {
            try {
                map.current.getBounds();
            } catch (error) {
                map.current.fitBounds(mapBounds, { paddingTopLeft: [20, 40], paddingBottomRight: [20, 0] });
            }
        }
    }, [map, mapBounds]);

    const cancelTrip = (tripId: number) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.cancelTripById(tripId)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar("Entrega(s) cancelada(s) com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível cancelar a(s) entrega(s)!", { variant: "error" });
                    }
                });
        });
    }

    const resetTrip = (trip: Trip) => {
        let successMessage = "Entrega(s) reaberta(s) com sucesso!";
        let failedMessage = "Não foi possível reabrir a(s) entrega(s)!";
        if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
            successMessage = "O Entregador parou de ser chamado com sucesso!";
            failedMessage = "Não foi possível parar de chamar o Entregador!";
        }
        return new Promise<boolean>((resolve, reject) => {
            TripService.resetTripById(trip.id)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar(successMessage, { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar(failedMessage, { variant: "error" });
                    }
                });
        });
    }

    const releaseTrip = useCallback((tripId: number) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.releaseTripById(tripId)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar("Coleta liberada com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível liberar a coleta!", { variant: "error" });
                    }
                });
        });
    }, [enqueueSnackbar, loadData]);

    const finishTrip = useCallback((tripId: number) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.finishTripById(tripId)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar("Entrega(s) finalizada(s) com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível finalizar a(s) entrega(s)!", { variant: "error" });
                    }
                });
        });
    }, [enqueueSnackbar, loadData]);

    const resendTrip = (courierId: number | null): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.resendTrip(Number(tripId), courierId)
                .then((response) => {
                    enqueueSnackbar("Entrega(s) reenviada(s) com sucesso!", {
                        variant: "success",
                    });
                    if (loadData) {
                        loadData(tripId);
                    }
                }).catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Ocorreu um erro ao reenviar a(s) Entrega(s)!", { variant: "error" });
                    }
                });
        });
    }

    const handleReleaseTrip = () => {
        return releaseTrip(Number(tripId));
    };

    const handleFinishTrip = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Finalizar Viagem",
            "Tem certeza que deseja finalizar essa viagem? Ao finalizar a viagem será concluída, e o valor repassado ao entregador.",
            "Finalizar",
            "Cancelar"
        ).then(async () => {
            try {
                await finishTrip(Number(tripId));
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleResetTripClick = () => {
        let title = "Reabrir Entrega(s)";
        let message = "Você tem certeza que deseja reabrir esta(s) entrega(s)?";
        let acceptButtonLabel = "Reabrir";
        if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
            title = "Parar de Chamar Entregador";
            message = "Você tem certeza que deseja Parar de chamar Entregador para essa(s) entrega(s)?";
            acceptButtonLabel = "Parar";
        }
        const dialog = confirmationDialog.current;
        dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
            .then(async () => {
                try {
                    await resetTrip(trip);
                } finally {
                    dialog?.hide();
                }
            }).catch(() => { });
    }

    const handleCancelTripClick = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Cancelamento de Entregas",
            "Você tem certeza que deseja Cancelar essa(s) Entrega(s)?",
            "Confirmar",
            "Cancelar"
        ).then(async () => {
            try {
                await cancelTrip(Number(tripId));
            } finally {
                dialog.hide();
            }
        }).catch(() => { });
    };

    const handleSearchCourierForTrip = async () => {
        try {
            await TripService.searchCourierForTrip(trip.id);
            if (loadData) {
                loadData(tripId);
            }
            enqueueSnackbar("Entregador solicitado com sucesso!", {
                variant: "success",
            });
        } catch (error: any) {
            const { status, data } = error.response;
            if (status === 400) {
                let errors: string[] = [];
                for (var key in data) {
                    var value = data[key];
                    errors.push(value);
                }
                enqueueSnackbar(errors.join(".\n"), { variant: "error" });
            } else {
                enqueueSnackbar("Não foi possível solicitar entregador!", {
                    variant: "error",
                });
            }
        }
    };

    const handleSetCourierForTrip = () => {
        selectCourierFromQueue(async (courier) => {
            try {
                await TripService.searchCourierForTrip(trip.id, courier.id);
                enqueueSnackbar("Entregador solicitado com sucesso!", { variant: "success" });
                if (loadData) {
                    loadData(tripId);
                }
            } catch (error: any) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errors: string[] = [];
                    for (var key in data) {
                        var value = data[key];
                        errors.push(value);
                    }
                    enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                } else {
                    enqueueSnackbar("Não foi possível solicitar entregador!", {
                        variant: "error",
                    });
                }
            }
        });

    };

    const openDiscardConfirmationDialog = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Descartar Viagem",
            "Você tem certeza que deseja Descartar esta Viagem? Esta ação não poderá ser desfeita.",
            "Descartar",
            "Cancelar"
        ).then(async () => {
            try {
                await discardTripRequest(Number(tripId));
            } finally {
                dialog.hide();
            }
        }).catch(() => { });
    };

    const discardTripRequest = (tripRequestId: number): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.discardTrip(tripRequestId)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar("Viagem descartada com sucesso!", { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar("Não foi possível descartar a viagem!", { variant: "error" });
                    }
                });
        })
    };


    const openFinishDeliveryConfirmationDialog = () => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            `Finalizar Entrega(s) #${trip.number}`,
            `Você tem certeza que deseja Finalizar Entrega(s)? Esta ação não poderá ser desfeita.`,
            "Finalizar",
            "Cancelar"
        ).then(async () => {
            try {
                const success = "Entrega(s) finalizada(s) com sucesso!";
                const error = "Não foi possível finalizar a(s) entrega(s)!";
                return intervirInTrip(Number(tripId), DeliveryRequestAction.FINISHED, success, error);
            } finally {
                dialog.hide();
            }
        }).catch(() => { });
    };

    const intervirInTrip = React.useMemo(() => debounce(async (tripId: number, action: DeliveryRequestAction, messageSuccess: string, messageFail: string) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.interveneInTrip(Number(tripId), action)
                .then((response) => {
                    if (loadData) {
                        loadData(tripId);
                    }
                    resolve(true);
                    enqueueSnackbar(messageSuccess, { variant: "success" });
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                    } else {
                        enqueueSnackbar(messageFail, { variant: "error" });
                    }
                });
        });
    }, 500), [enqueueSnackbar, loadData]);


    const refreshDeliveryRequests = React.useCallback(async () => {
        const storeId = activeStore ? activeStore.id : null;
        const regionId = activeStore ? activeStore.region : null;

        if (storeId) {
            const [tripsResponse, deliveriesResponse] = await Promise.all([
                TripService.getPendingTrips(storeId, regionId, null, TRIP_DEFAULT_PAGINATION),
                DeliveryRequestService.getNewDeliveries(storeId, DELIVERY_DEFAULT_PAGINATION)
            ]);
            setTripList(tripsResponse.data);
            setNewDeliveryList(deliveriesResponse.data);
            setOpenJoinDelivery(true);
        }
    }, [activeStore]);

    const handleJoinTrips = useCallback(() => {
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((_trip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(_trip.status)) {
                availableTrips.push(_trip);
            }
        })
        joinDeliveriesDialog.current?.addToTrip(trip, newDeliveryList, availableTrips);
    }, [joinDeliveriesDialog, newDeliveryList, noCourierList, trip]);

    React.useEffect(() => {
        if (openJoinDelivery) {
            handleJoinTrips();
        }
    }, [openJoinDelivery, handleJoinTrips]);

    useLayoutEffect(() => {
        const _noCourierList: PendingTrip[] = [];
        tripList.forEach(trip => {
            switch (trip.status) {
                case TripStatus.NEW:
                case TripStatus.NO_COURIER:
                case TripStatus.SEARCHING_COURIER:
                    _noCourierList.push(trip);
                    break;
            }
        });
        setNoCourierList(_noCourierList);
    }, [tripList]);

    const refreshSuccess = () => {
        if (trip) {
            if (loadData) {
                loadData(tripId);
            }
            setOpenJoinDelivery(false);
        }
    };

    const onClose = () => {
        setOpenJoinDelivery(false);
    };

    const handleSplitDeliveryRequest = (tripId: number, deliveryId: number) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Separar Pedido de Entrega",
            "Você tem certeza que deseja Separar esse Pedido de Entrega?",
            "Separar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleConfirmSplitTrip(tripId, deliveryId);
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleConfirmSplitTrip = React.useMemo(() => debounce(async (tripIdSplit: number, deliveryIdSplit: number) => {
        if (tripIdSplit && deliveryIdSplit) {
            try {
                await TripService.removeDeliveryFromTrip(tripIdSplit, deliveryIdSplit);
                if (loadData) {
                    loadData(tripId);
                }
                enqueueSnackbar("Entrega separada com sucesso!", {
                    variant: "success",
                });
            } catch (error) {
                enqueueSnackbar("Não foi possível separar a entrega!", {
                    variant: "error",
                });
            }
        }
    }, 500), [enqueueSnackbar, loadData, tripId]);

    const isPossibleCancel = () => {
        let isVisibleCancel: boolean = true;
        if(trip.deliveries){
            //Corridas que não podem cancelar
            let filterDeliveries = trip.deliveries.filter(deliverie => [
                DeliveryRequestStatus.NO_COURIER,
                DeliveryRequestStatus.RETURNING,
                DeliveryRequestStatus.BACK_TO_STORE,
                DeliveryRequestStatus.CANCELED, 
                DeliveryRequestStatus.DISCARDED, 
                DeliveryRequestStatus.FINISHED].includes(deliverie.status)
                );
                
            //Verifica quantidade de corridas disponíveis  
            if(filterDeliveries.length === trip.deliveries.length){
                isVisibleCancel = false;
            };
        }
        return isVisibleCancel;
    };

    return (
        <React.Fragment>
            <ConfirmationDialog ref={confirmationDialog} />

            <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={refreshSuccess} onClose={onClose} />

            <SelectCourierDialog
                ref={selectCourierDialog}
                title="Reenvio de Entregas"
                acceptButtonLabel="Reenviar"
                onAccept={resendTrip}
            />
            <Grid container spacing={2} justify="flex-end">
                <ShowIf condition={[
                    TripStatus.NEW,
                    TripStatus.NO_COURIER,
                ].includes(trip.status)}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={refreshDeliveryRequests}
                        >
                            Juntar Entregas
                        </Button>
                    </Grid>
                </ShowIf>
                {[TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status) && (
                    <React.Fragment>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSearchCourierForTrip}
                            >
                                Solicitar Entregador
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSetCourierForTrip}
                            >
                                Direcionar para Entregador
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={openDiscardConfirmationDialog}
                            >
                                Descartar Entrega(s)
                            </Button>
                        </Grid>
                    </React.Fragment>
                )}
                {[TripStatus.AWAITING_RELEASE].includes(trip.status) && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleReleaseTrip}
                        >
                            Liberar Coleta
                        </Button>
                    </Grid>
                )}
                {[TripStatus.BACK_TO_STORE].includes(trip.status) && (
                    <Grid item>
                        <Button
                            onClick={handleFinishTrip}
                            variant="contained"
                            color="secondary"
                        >
                            Finalizar Entrega(s)
                        </Button>
                    </Grid>
                )}
                {![TripStatus.BACK_TO_STORE, 
                    TripStatus.FINISHED, 
                    TripStatus.NO_COURIER, 
                    TripStatus.SEARCHING_COURIER, 
                    TripStatus.DISCARDED,
                    TripStatus.PARTIALLY_CANCELED, 
                    TripStatus.CANCELED].includes(trip.status) && (
                    <Grid item>
                        <Button
                            onClick={openFinishDeliveryConfirmationDialog}
                            variant="contained"
                            color="secondary"
                        >
                            Finalizar Entrega(s)
                        </Button>
                    </Grid>
                )}
                {[TripStatus.SEARCHING_COURIER].includes(trip.status) && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleResetTripClick}
                        >
                            Parar de Chamar
                        </Button>
                    </Grid>
                )}
                {[TripStatus.ACCEPTED, TripStatus.AWAITING_RELEASE, TripStatus.AWAITING_COLLECTION].includes(trip.status) && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleResetTripClick}
                        >
                            Reabrir Entrega(s)
                        </Button>
                    </Grid>
                )}
                {isPossibleCancel() && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancelTripClick}
                        >
                            Cancelar Entrega(s)
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                {![
                    TripStatus.FINISHED,
                    TripStatus.CANCELED
                ].includes(trip.status) && (
                        <Grid item xs={12}>
                            <div className={classes.mapWrapper}>
                                <MapContainer scrollWheelZoom={false} ref={map} zoomControl={false}>
                                    <ZoomControl position="bottomright" />
                                    <TileLayer
                                        attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {markerCoords?.map((marker, index) => {
                                        const iconType = ICON_MAP.find(
                                            (elem) => elem.type === marker.type
                                        );
                                        return (
                                            <LeafletMarker
                                                key={index}
                                                position={[Number(marker.lat), Number(marker.lng)]}
                                                color={iconType?.color}
                                                internalIcon={iconType?.icon}
                                                riseOnHover={true}
                                            >
                                                <Tooltip>{marker.title}</Tooltip>
                                            </LeafletMarker>
                                        );
                                    })}
                                </MapContainer>
                            </div>
                        </Grid>
                    )}

                <Grid item md={12}>
                    <Card variant="outlined">
                        <CardContent style={{ paddingBottom: "16px" }}>
                            <Table className="table-bordered">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption">
                                                Valor Mínimo
                                            </Typography>
                                            <Typography>
                                                {totalMinimumTrip !== 0 ? numbro(totalMinimumTrip).formatCurrency() : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="caption">
                                                Valor Deslocamento
                                            </Typography>
                                            <Typography>
                                                {valueTotalAddKm !== 0 ? numbro(valueTotalAddKm).formatCurrency() : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Incentivos</Typography>
                                            <Typography>
                                                {totalIncentives !== 0 ? numbro(totalIncentives).formatCurrency() : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Valor de Retorno</Typography>
                                            <Typography>
                                                {totalReturn !== 0 ? numbro(totalReturn).formatCurrency() : "---"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={valueTotalTrip !== 0 ? classes.classTotal : ""}>
                                            <Typography variant="caption">Valor Total da Viagem</Typography>
                                            <Typography className={valueTotalTrip !== 0 ? classes.classTotalText : ""}>
                                                {valueTotalTrip !== 0 ? numbro(valueTotalTrip).formatCurrency() : "---"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>

                {trip.courier && (
                    <Grid item md={12} xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h2" className="title">
                                    Dados do Entregador
                                </Typography>
                                <Table className="table-bordered">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <Avatar>
                                                            <ImgLoader
                                                                src={String(trip.courier.photo)}
                                                                className={classes.photo}
                                                            />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">Nome</Typography>
                                                        <Grid zeroMinWidth style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="body2" noWrap style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography style={{ marginRight: "3px" }} aria-owns={openPopoverRanking ? 'mouse-over-popover' : undefined}
                                                                    aria-haspopup="true"
                                                                    onMouseEnter={handlePopoverRankingOpen}
                                                                    onMouseLeave={handlePopoverRankingClose}>
                                                                    <ShowIf condition={trip.courier.speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                                                                        <StarBorderIcon fontSize="small" style={{ paddingTop: "1px" }} />
                                                                    </ShowIf>
                                                                    <ShowIf condition={trip.courier.speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                                                                        <StarIcon fontSize="small" className={clsx(classes.advancedSpeederStar)} />
                                                                    </ShowIf>
                                                                </Typography>
                                                            </Typography>
                                                            <Typography>{trip.courier.name}</Typography>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Tipo do Veículo
                                                </Typography>
                                                <Typography>
                                                    {trip.courier &&
                                                        t("vehicle_type." + trip.courier.vehicle_type)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">
                                                    Tipo do Compartimento
                                                </Typography>
                                                <Typography>
                                                    {trip.courier.vehicle_load_compartment_type &&
                                                        t(
                                                            "vehicle_load_compartment_type." +
                                                            trip.courier.vehicle_load_compartment_type
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">Celular</Typography>
                                                <Typography>
                                                    {trip.courier ? formatPhoneNumber(trip.courier.phonenumber) : ""}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="caption">Ranking</Typography>
                                                <Typography>
                                                    {trip.courier && t("speedy_ranking." + trip.courier.speedy_ranking)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <PopoverRanking openPopover={openPopoverRanking} anchorEl={anchorElRanking}
                                    speedyRanking={trip?.courier.speedy_ranking}
                                    newRankingPoints={trip?.courier.new_ranking_points}
                                    requestsCount={trip?.courier.requests_count}
                                    handlePopoverClose={handlePopoverRankingClose}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="h2">Entregas</Typography>
                </Grid>
                <Grid item xs={12}>
                    {deliveryList &&
                        deliveryList.sort((a, b) => (a.id > b.id) ? 1 : -1).map((delivery, index) => (
                            <React.Fragment>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid container spacing={4} wrap="nowrap" alignItems="center">
                                            <Grid item>
                                                <Typography>
                                                    <b>{index + 1}°. </b>&nbsp;
                                                    <label>
                                                        Consumidor: &nbsp;
                                                        <b>{delivery.consignee}</b>
                                                    </label>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <label>
                                                        Pedido: &nbsp;
                                                        <b>#{delivery.order_number}</b> {delivery.integration_instance ? delivery.integration_instance.initials + " " : ""}-  {delivery.destination_address.district} ({numbro(delivery.distance).format()} km, {delivery.return_type === "none" ? "sem retorno" : <b>com retorno</b>})
                                                    </label>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <Alert
                                                        className={classes.situationAccord}
                                                        severity={mapDeliveryRequestStatusToSeverity(delivery.status)}
                                                        icon={false}
                                                    >
                                                        {t("delivery_request_status." + delivery.status)}
                                                    </Alert>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionActions style={{ paddingTop: 0 }}>
                                        <Grid container spacing={1} xs={12}>

                                            {[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(delivery.status) && deliveryList.length > 1 && (
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={(e) => {
                                                            handleSplitDeliveryRequest(trip.id, delivery.id);
                                                        }}
                                                    >
                                                        Separar Entrega
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionActions>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xl={12} lg={12} md={12} xs={12} spacing={1}>
                                                <Typography variant="h2" className="title">
                                                    Dados do Pedido
                                                </Typography>
                                                <Table className="table-bordered">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Solicitação
                                                                </Typography>
                                                                <Typography>{delivery.request_number}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Tipo de Retorno
                                                                </Typography>
                                                                <Typography>
                                                                    {t("delivery_request_return_type." + delivery.return_type)}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Telefone do Consumidor
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.consignee_phone_number ? formatPhoneNumber(delivery.consignee_phone_number) : "---" }
                                                                    
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell colSpan={2}>
                                                                <Typography variant="caption">
                                                                    Observação
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.delivery_observations ? delivery.destination_address?.delivery_observations : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                        <TableCell>
                                                            <Typography variant="caption">
                                                                    Valor Mínimo
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.minimum_value ? numbro(delivery.minimum_value).formatCurrency() : "---"}
                                                                </Typography>
                                                            </TableCell>    
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Valor Deslocamento
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.additional_km_value ? numbro(delivery.additional_km_value).formatCurrency() : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Incentivos
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.incentive_json ? numbro(delivery.incentive_json.customer_value).formatCurrency() : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Valor de Retorno
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.return_value ? numbro(delivery.return_value).formatCurrency() : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={delivery.value_total !== 0 ? classes.classTotal : ""}>
                                                                <Typography variant="caption">
                                                                    Valor Total
                                                                </Typography>
                                                                <Typography className={delivery.value_total !== 0 ? classes.classTotalText : ""}>
                                                                    {delivery.value_total ? numbro(delivery.value_total).formatCurrency() : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} xs={12} spacing={1}>
                                                <Typography variant="h2" className="title">
                                                    Destino
                                                </Typography>
                                                <Table className="table-bordered">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Endereço
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.street},{" "}
                                                                    {delivery.destination_address?.address_number}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Bairro
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.district}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography variant="caption">
                                                                    CEP
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.postal_code}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    Cidade
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.city} -{" "}
                                                                    {delivery.destination_address?.state}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>                                                           
                                                            <TableCell >
                                                                <Typography variant="caption">
                                                                    Complemento
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address
                                                                        ?.address_complement === ""
                                                                        ? "---"
                                                                        : delivery.destination_address
                                                                            ?.address_complement}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell colSpan={4}>
                                                                <Typography variant="caption">
                                                                    Ponto de Referência
                                                                </Typography>
                                                                <Typography>
                                                                    {delivery.destination_address?.address_reference ? delivery.destination_address.address_reference : "---"}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <ShowIf condition={[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(delivery.status)}>
                                                <Grid item xl={6} lg={6} md={12} xs={12} spacing={1}>
                                                    <Typography variant="h2" className="title">
                                                        Valores Estimados
                                                    </Typography>
                                                    <Table className="table-bordered">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography variant="caption">
                                                                        Bicicleta
                                                                    </Typography>
                                                                    <Typography>{delivery.estimated_values.per_vehicle.BIKE ? numbro(delivery.estimated_values.per_vehicle.BIKE).formatCurrency() : "---"}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="caption">
                                                                        Moto
                                                                    </Typography>
                                                                    <Typography>
                                                                        {delivery.estimated_values.per_vehicle.MOTORCYCLE ? numbro(delivery.estimated_values.per_vehicle.MOTORCYCLE).formatCurrency() : "---"}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="caption">
                                                                        Carro
                                                                    </Typography>
                                                                    <Typography>
                                                                        {delivery.estimated_values.per_vehicle.CAR ? numbro(delivery.estimated_values.per_vehicle.CAR).formatCurrency() : "---"}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="caption">
                                                                        Furgão
                                                                    </Typography>
                                                                    <Typography>
                                                                        {delivery.estimated_values.per_vehicle.VAN ? numbro(delivery.estimated_values.per_vehicle.VAN).formatCurrency() : "---"}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </ShowIf>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>
                        ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: "1rem",
        },
        marginBot: {
            marginBottom: "10px",
        },
        mapWrapper: {
            position: "relative",
            width: "100%",
            borderRadius: "4px",
            overflow: "hidden",
            height: "40vh",
            display: "flex",
            flexDirection: "column",
        },
        photo: {
            width: 40,
            height: 40,
        },
        advancedSpeederStar: {
            color: theme.palette.primary.main
        },
        situationAccord: {
            padding: "0px 10px",
            display: "inline-block"
        },
        classTotal: {
            background: "#f0f0f0",
            border: "1px solid #ddd",
        },
        classTotalText: {
            fontWeight: "bold",
        }
    })
);


