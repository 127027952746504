import React from "react";

const WIDGETCODE = process.env.REACT_APP_ZOHO_SALESIQ_WIDGETCODE;

const Chat = ({ user, activeStore }) => {
  const isInitialized = React.useCallback(() => {
    return (window.$zoho !== undefined);
  }, []);


  const playAudio = React.useCallback(() => {
    var url = '/sound/cellphone-ringing.mp3';
    window.AudioContext = window.AudioContext || window.webkitAudioContext; //fix up prefixing
    var context = new AudioContext(); //context
    var source = context.createBufferSource(); //source node
    source.connect(context.destination); //connect source to speakers so we can hear it
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer'; //the  response is an array of bits
    request.onload = function () {
      context.decodeAudioData(request.response, function (response) {
        source.buffer = response;
        source.start(0); //play audio immediately
      }, function () { console.error('The request failed.'); });
    }
    request.send();
  }, []);

  const onContinue = React.useCallback(() => {
  },[]);

  const onComplete = React.useCallback((data) => {
    window.$zoho.salesiq.floatwindow.visible("hide");
  },[]);

  const onTriggerMessage = React.useCallback(() => {
    playAudio();
  },[playAudio]);

  const onAgentMessage = React.useCallback((data) => {
    if (data.message === ":raising-hand:") {
      playAudio();
    }
  },[playAudio]);

  const onReady = React.useCallback(() => {
    let userId = user?.id ? String(user.id) : "";
    let visitorName = activeStore && user ? activeStore.name + " - " + user.name : "";
    let userEmail = user?.email || "";
    let storeName = activeStore?.name || "";
    let userName = user?.name || "";

    if (window.$zoho.salesiq.visitor) {
      window.$zoho.salesiq.visitor.id(userId);
      window.$zoho.salesiq.visitor.name(visitorName);
      window.$zoho.salesiq.visitor.email(userEmail);
      window.$zoho.salesiq.visitor.info({
        "Loja": storeName,
        "Usuário": userName
      });
    }

    window.$zoho.salesiq.floatbutton?.visible("show");
  }, [user, activeStore]);

  const initialize = React.useCallback(() => {
    if (!isInitialized()) {
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: WIDGETCODE,
        values: {},
        ready: function () {
          if (window.$zoho.salesiq.language) {
            window.$zoho.salesiq.language("pt");
          }
      
          if (window.$zoho.salesiq.chat) {
            if (window.$zoho.salesiq.chat.continue) {
              window.$zoho.salesiq.chat.continue(function () {
                onContinue();
              });
            };
            if (window.$zoho.salesiq.chat.complete) {
              window.$zoho.salesiq.chat.complete(function (param1, param2) {
                let data = param1;
                if (param2 !== undefined) {
                  data = param2;
                }
                onComplete(data);
              });
            };
            if (window.$zoho.salesiq.chat.triggerMessage) {
              window.$zoho.salesiq.chat.triggerMessage(function () {
                onTriggerMessage();
              });
            }
            if (window.$zoho.salesiq.chat.agentMessage) {
              window.$zoho.salesiq.chat.agentMessage(function (param1, param2) {
                let data = param1;
                if (param2 !== undefined) {
                  data = param2;
                }
                onAgentMessage(data);
              });
            }
          }

          window.$zoho.salesiq.floatbutton?.coin.hidetooltip();
          window.$zoho.salesiq.feedback?.visible("hide");
          window.$zoho.salesiq.rating?.visible("hide");
          window.$zoho.salesiq.pastchat?.visible("hide");
            
          onReady();
        },
      };

      const d = document;
      const zsiqscript = d.getElementById('zsiqscript');
      if (zsiqscript) {
        zsiqscript.remove();
      }

      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.id = 'zsiqscript';
      s.defer = true;
      s.src = 'https://salesiq.zoho.com/widget';

      const t = d.getElementsByTagName('script')[0];
      if (t.parentNode) {
        t.parentNode.insertBefore(s, t);
      }
    }
  }, [isInitialized, onReady, onContinue, onComplete, onTriggerMessage, onAgentMessage]);

  const hide = React.useCallback(() => {
    if (isInitialized()) {
      window.$zoho.salesiq.chat?.complete();
      if (window.$zoho.salesiq.visitor) {
        window.$zoho.salesiq.visitor.name("SAIU - " + window.$zoho.salesiq.visitor.name());
        window.$zoho.salesiq.floatbutton.visible("hide");
        window.$zoho.salesiq.floatwindow.visible("hide");
      }
    }
  }, [isInitialized]);

  React.useEffect(() => {
    if ((user !== null) && (activeStore !== null)) {
      if (!isInitialized()) {
        initialize();
      } else {
        onReady();
      }
    } else {
      if (isInitialized()) {
        window.$zoho.salesiq.floatbutton?.visible("hide");
      }
    }
  }, [user, activeStore, isInitialized, initialize, onReady]);

  React.useEffect(() => {
    return function () {
      hide();
    }
  }, [hide]);

  return (
    <React.Fragment>
      <div id='zsiqwidget'></div>
    </React.Fragment>
  );
}

export default Chat;