import React from "react";
import { Button, Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../styles/theme";
import { useHistory } from "react-router-dom";
import useStoreContext from "../../hooks/useStoreContext";
import DashboardColumn from "../Dashboard/customComponents/dashboardColumn";
import DeliveryRequestService from "../../services/deliveryRequestService";
import LeafletMarker from "../../components/LeafletMarker";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import CustomControl from "../../components/maps/CustomControl";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import { OnlineDedicatedCourierDetails } from "../../services/types/deliveryRequest";
import { CourierStatus } from "../../services/types/courier";
import { DirectionsBikeOutlined, DirectionsCarOutlined, MotorcycleOutlined, Storefront } from "@material-ui/icons";
import { CourierCard } from "./customComponents/courierCard";
import StorePopup from "./customComponents/storePopup";
import CourierPopup from "./customComponents/courierPopup";
import { StatsContainer } from "./customComponents/statsContainer";
import { Alert } from "@material-ui/lab";
import useGlobalSearch from "../../hooks/useGlobalSearch";
import { Map } from "leaflet";

const REFRESH_INTERVAL = 10000;
const DEFAULT_MAP_POSITION: [number, number][] = ([[-33.8689056, -73.9830625], [5.2842873, -28.6341164]]); //Brasil

const Fixed: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { activeStore } = useStoreContext();
    const { globalSearchValue, setGlobalSearchValue, showGlobalSearch, hideGlobalSearch } = useGlobalSearch();

    const [couriersOnlineList, setCouriersOnlineList] = React.useState<OnlineDedicatedCourierDetails[]>([]);
    const [filterCouriersOnlineList, setFilterCouriersOnlineList] = React.useState<OnlineDedicatedCourierDetails[]>([]);

    const map = React.useRef<Map>(null);
    const [mapInitialized, setMapInitialized] = React.useState<boolean>(false);

    const [availableCouriersCount, setAvailableCouriersCount] = React.useState<number>(0);
    const [busyCouriersCount, setBusyCouriersCount] = React.useState<number>(0);

    const [selectedCourierId, setSelectedCourierId] = React.useState<number>();

    const refreshCouriers = React.useCallback(() => {
        if (activeStore) {
            DeliveryRequestService.getOnlineDedicatedCouriers(activeStore?.id)
                .then((couriers) => {
                    setCouriersOnlineList(couriers);
                })
                .catch((error) => { });
        }
    }, [activeStore]);

    React.useEffect(() => {
        if (activeStore) {
            refreshCouriers();
        };

        const interval = setInterval(() => {
            if (activeStore) {
                refreshCouriers();
            }
        }, REFRESH_INTERVAL);

        return function cleanup() {
            setCouriersOnlineList([]);
            if (interval) {
                clearInterval(interval);
            };
        };
    }, [activeStore, refreshCouriers]);

    React.useEffect(() => {
        showGlobalSearch("Filtre por nome do Entregador");

        return function () {
            hideGlobalSearch();
        };
    }, [showGlobalSearch, hideGlobalSearch]);

    React.useEffect(() => {
        const filtered = couriersOnlineList.filter((courier) => {
            const searchValue = globalSearchValue.toLocaleLowerCase()
            if (courier.name.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            return false;
        });
        setFilterCouriersOnlineList(filtered);
    }, [globalSearchValue, couriersOnlineList]);

    React.useEffect(() => {
        let totalAvailable = 0;
        let totalBusy = 0;

        if (filterCouriersOnlineList && filterCouriersOnlineList[0]) {
            filterCouriersOnlineList.forEach((courier: OnlineDedicatedCourierDetails) => {
                if (courier.courier_status === CourierStatus.AVAILABLE) {
                    totalAvailable++;
                } else {
                    totalBusy++;
                }
            });
        }

        setAvailableCouriersCount(totalAvailable);
        setBusyCouriersCount(totalBusy);
    }, [filterCouriersOnlineList]);

    const centerMapToStore = () => {
        if (map.current && activeStore) {
            const center: [number, number] = [activeStore.latitude, activeStore.longitude];
            map.current.flyTo(center, 14);
        }
    }

    const toggleCourierSelection = (courier: OnlineDedicatedCourierDetails) => {
        if (selectedCourierId === courier.id) {
            setSelectedCourierId(undefined);
        } else {
            setSelectedCourierId(courier.id);
            if (map.current) {
                map.current.flyTo([courier.latitude, courier.longitude]);
            }
        }
    };

    const deliveryRequestDetailsAction = async (tripRequest: any) => {
        let param =
            typeof tripRequest == "number"
                ? tripRequest
                : tripRequest.id;
        history.push(`/app/trip/${param}`);
    };

    const CenterInStore = () => {
        const map = useMap();
        if (!mapInitialized && activeStore) {
            setMapInitialized(true)
            map.setView([activeStore.latitude, activeStore.longitude], 14);
            map.invalidateSize();
        }

        return <></>;
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                    <Typography variant="h1">Fixos</Typography>
                </Grid>
                <Grid item>
                    <Collapse in={(globalSearchValue !== "")}>
                        <Grid container justify="center">
                            <Grid item>
                                <Alert
                                    severity="warning"
                                    className={classes.warningFilter}
                                    action={
                                        <Button color="inherit" size="small" onClick={(e) => { setGlobalSearchValue(""); }}>
                                            Limpar Filtro
                                        </Button>
                                    }>
                                    <strong>Aviso:</strong> As informações abaixo estão <strong>filtradas!</strong>
                                </Alert>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                className={classes.page}
            >
                <Grid item md={6} lg={7} xl={8} className={classes.dashboardGridColumn}>
                    <div className={classes.mapWrapper}>
                        <MapContainer bounds={DEFAULT_MAP_POSITION} scrollWheelZoom={true} ref={map} zoomControl={true}>
                            <CustomControl position="topright">
                                <IconButton onClick={centerMapToStore} title="Centralizar na Região">
                                    <CenterFocusStrongIcon />
                                </IconButton>
                            </CustomControl>
                            <TileLayer
                                attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <CenterInStore />
                            {filterCouriersOnlineList && filterCouriersOnlineList.map((courier, index) => {
                                let markerClassName =
                                    courier.courier_status === CourierStatus.AVAILABLE
                                        ? classes.availableCouriers
                                        : classes.busyCouriers;

                                let zIndex: number = 0;
                                if (courier.courier_status === CourierStatus.AVAILABLE) {
                                    zIndex = -9999999;
                                }
                                if (courier.id === selectedCourierId) {
                                    markerClassName = classes.selectedCourier;
                                    zIndex = 9999999;
                                }

                                let icon: React.ReactNode;
                                switch (courier.vehicle_type) {
                                    case "CAR":
                                        icon = <DirectionsCarOutlined />
                                        break;
                                    case "BIKE":
                                        icon = <DirectionsBikeOutlined />
                                        break;
                                    default:
                                        icon = <MotorcycleOutlined />
                                        break;
                                }

                                return (
                                    <LeafletMarker
                                        key={index}
                                        position={[Number(courier.latitude), Number(courier.longitude)]}
                                        className={markerClassName}
                                        internalIcon={icon}
                                        riseOnHover={true}
                                        zIndexOffset={zIndex}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                e.target.openPopup();
                                            }
                                        }}
                                    >
                                        <CourierPopup viewDetailsAction={deliveryRequestDetailsAction} info={courier} />
                                    </LeafletMarker>
                                )
                            })}

                            {activeStore && (
                                <LeafletMarker
                                    key={"key_" + activeStore.id}
                                    position={[Number(activeStore.latitude), Number(activeStore.longitude)]}
                                    className={classes.store}
                                    internalIcon={<Storefront />}
                                    riseOnHover={true}
                                    zIndexOffset={-99999999}
                                    eventHandlers={{
                                        mouseover: (e) => {
                                            e.target.openPopup();
                                        }
                                    }}
                                >
                                    <StorePopup info={activeStore} />
                                </LeafletMarker>
                            )}

                            <StatsContainer
                                availableCouriers={availableCouriersCount}
                                busyCouriers={busyCouriersCount}>
                            </StatsContainer>
                        </MapContainer>
                    </div>
                </Grid>
                <Grid item md={6} lg={5} xl={4} className={classes.dashboardGridColumn}>
                    <DashboardColumn
                        title="Entregadores"
                        className={classes.list}
                        titleClassName={classes.courierTitle}
                    >
                        <div className={classes.listContent}>
                            {filterCouriersOnlineList && filterCouriersOnlineList.map(
                                (courier, index) => (
                                    <CourierCard
                                        key={index}
                                        courier={courier}
                                        selected={courier.id === selectedCourierId}
                                        action={toggleCourierSelection}
                                    />
                                )
                            )}
                        </div>
                    </DashboardColumn>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    page: {
        flex: 1,
        display: "flex",
        height: "100vh",
        paddingTop: "8px",
    },
    dashboardGridColumn: {
        height: "100%",
    },
    mapWrapper: {
        position: "relative",
        width: "100%",
        borderRadius: "4px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    availableCouriers: {
        backgroundColor: theme.palette.primary.main,
    },
    busyCouriers: {
        backgroundColor: theme.palette.secondary.main,
    },
    selectedCourier: {
        backgroundColor: theme.palette.warning.main,
    },
    store: {
        backgroundColor: theme.palette.success.main,
    },
    courierTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    list: {
        display: "flex",
        flexFlow: "column",
        flex: 1,
        maxHeight: "100%",
        height: "100%",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        backgroundColor: "#f5f5f5",
    },
    listContent: {
        flex: 1,
        marginTop: 0,
        padding: theme.spacing(1),
        overflow: "auto",
    },
    warningFilter: {
        width: "450px"
    },
});

export default Fixed;
