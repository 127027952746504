// Copiado e adaptado de https://github.com/LiveBy/react-leaflet-control/issues/44#issuecomment-766318568
import React, {
  forwardRef,
  useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import { createElementHook, createControlHook } from '@react-leaflet/core'
import { Control, DomUtil, DomEvent, Map } from 'leaflet'

const DumbControl = Control.extend({
  options: {
      className: "",
      onOff: "",
      handleOff: function noop() {}
    },

  onAdd(/* map */) {
      var _controlDiv = DomUtil.create("div", this.options.className);
      DomEvent.disableClickPropagation(_controlDiv);
      return _controlDiv;
  },
  
  onRemove(map: Map) {
      if (this.options.onOff) {
        map.off(this.options.onOff, this.options.handleOff, this);
      }
  
      return this;
  }
})

const createControl = (props: any, context: any) => {
  const instance = new DumbControl(props)    
  return { instance, context: { ...context, overlayContainer: instance } }

}

const useControlElement = createElementHook(createControl)
const customControl = createControlHook(useControlElement)


//create your forceUpdate hook
const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}


const createLeafletControl = (useElement: any) => {
  const Component = (props: any, ref: any) => {
      const forceUpdate = useForceUpdate();
      const { instance } = useElement(props).current

      useEffect(() => {
          // Origin: https://github.com/LiveBy/react-leaflet-control/blob/master/lib/control.jsx
          // This is needed because the control is only attached to the map in
          // MapControl's componentDidMount, so the container is not available
          // until this is called. We need to now force a render so that the
          // portal and children are actually rendered.
          forceUpdate()
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const contentNode = instance.getContainer()
      return contentNode ? createPortal(props.children, contentNode) : null
  }
  return forwardRef(Component)
}


export default createLeafletControl(customControl)