import queryString from "query-string";

export const API_URI = process.env.REACT_APP_BACKEND_URL;

/*
recebe a URL base e o objeto de parametros a ser consultado
retorna uma URL com os Query params para consulta
*/
export const getURL = (baseUrl: string, params: { [any: string]: any }) => {
  return queryString.stringifyUrl({
    url: baseUrl,
    query: params,
  });
};

export const urls = {
  USER: `${API_URI}customer_users/`,
  USERS: `${API_URI}customer_users/`,
  LOGIN: `${API_URI}customer-token-auth/`,
  TOKEN_REFRESH: `${API_URI}api-refresh-token/`,
  GROUPS: `${API_URI}groups/`,
  STATUS: `${API_URI}user_status/`,
  REQUEST_PASSWORD_RESET: `${API_URI}password/reset/`,
  PASSWORD_RESET: `${API_URI}password/reset/confirm/`,
  PASSWORD_CHANGE: `${API_URI}password/change/`,
  CUSTOMER: `${API_URI}customers/`,
  CUSTOMER_FINANCES: `${API_URI}customer_finances/`,
  CUSTOMER_FINANCES_ENTRIES: `${API_URI}customer_finances/entries/`,
  CUSTOMER_FINANCES_PAY_CREDITS: `${API_URI}customer_finances/pay_credits/`,
  CUSTOMER_FINANCES_PAY_CREDITS_PIX: `${API_URI}customer_finances/pay_credits/pix/`,
  CUSTOMER_FINANCES_PAY_CREDITS_CARD: `${API_URI}customer_finances/pay_credits/card/`,
  STORE: `${API_URI}customers_stores/`,
  REGION: `${API_URI}regions/`,
  COURIER: `${API_URI}courier_users/`,
  CUSTOMER_USERS: `${API_URI}customer_users/`,
  CITIES: `${API_URI}cities/`,
  BRAZILIAN_STATES: `${API_URI}states/`,
  DELIVERY_REQUESTS: `${API_URI}delivery_requests/`,
  TRIP: `${API_URI}trips/`,
  ACTIVATE: `${API_URI}activate/`,
  DELIVERY_SETTINGS: `${API_URI}delivery_settings/`,
  ADDITIONAL_KM: `${API_URI}additional_km/`,
  DEBIT_REPORTS: `${API_URI}debit_reports/`,
  CUSTOMER_STORES: `${API_URI}customers_stores/`,
  CREDITCARD: `${API_URI}cards/`,
  FINANCIAL_SETTINGS: `${API_URI}financial_settings/`,
};
