import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { LoginUser } from "../services/types/user";
import LoadingScreen from "../components/LoadingScreen";
import { urls } from "../utils/urls";

export const ACCESS_TOKEN_STORAGE_KEY = "@speedyCustomer:accessToken";
export const REFRESH_TOKEN_STORAGE_KEY = "@speedyCustomer:refreshToken";
export const TOKEN_EXP_STORAGE_KEY = "@speedyCustomer:tokenExp";
export const USER_DATA_STORAGE_KEY = "@speedyCustomer:userData";
export const USER_STORE_DATA_STORAGE_KEY = "@speedyCustomer:userStoreData";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: LoginUser | null;
}

interface AuthContextValue extends AuthState {
  method: "JWT";
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: LoginUser | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: LoginUser;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const decoded: any = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
  user: LoginUser | null
): void => {
  if (accessToken && refreshToken && user) {
    const jwtDecoded: any = jwtDecode(accessToken);

    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    localStorage.setItem(TOKEN_EXP_STORAGE_KEY, jwtDecoded.exp || 0);
    localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(user));
    //localStorage.setItem(USER_STORE_DATA_STORAGE_KEY, JSON.stringify(user.store));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(TOKEN_EXP_STORAGE_KEY);
    localStorage.removeItem(USER_DATA_STORAGE_KEY);
    localStorage.removeItem(USER_STORE_DATA_STORAGE_KEY);
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isInitialised: true,
        user: user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        isInitialised: true,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string) => {
    const response = await axios.post<{
      access: string;
      refresh: string;
      user: LoginUser;
    }>(urls.LOGIN, { email, password });
    const { access, refresh, user } = response.data;

    setSession(access, refresh, user);
    dispatch({
      type: "LOGIN",
      payload: {
        user: user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null, null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

        const userData = localStorage.getItem(USER_DATA_STORAGE_KEY);
        let user: LoginUser | null = null;

        if (userData) {
          user = JSON.parse(userData);
        }

        if (accessToken && isValidToken(accessToken) && refreshToken && user) {
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          setSession(null, null, null);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        setSession(null, null, null);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
