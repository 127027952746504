import { Popup } from "react-leaflet";
import { Typography, Button } from "@material-ui/core";
import theme from "../../../styles/theme";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Visibility } from "@material-ui/icons";
import { formatPhoneNumber } from "../../../utils/formatters";
import { makeStyles } from "@material-ui/core/styles";
import { OnlineDedicatedCourierDetails } from "../../../services/types/deliveryRequest";
import { ImgLoader } from "../../../components/ImgLoader";

interface IProps {
  info: OnlineDedicatedCourierDetails;
  viewDetailsAction?: (tripId: number) => void;
}

const CourierPopup = ({ info, viewDetailsAction }: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Popup className={classes.infoBoxContainer}>
      <div className={classes.infoBoxHeader}>
        <ImgLoader src={info.photo} />
        <div className={classes.infoBoxHeaderInfo}>
          <Typography variant="h2">
            {info.name}
          </Typography>
          <Typography variant="subtitle2" className={classes.marginTypo}>
            atualizado há  {formatDistanceToNow(info.last_update, { locale: ptBR })}
          </Typography>
        </div>
      </div>
      <div className={classes.infoBoxContent}>
        <div className={classes.infoBoxVehicle}>
          <Typography variant="body2" className={classes.marginTypo}>
            Tipo do veículo:
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">
              {t("vehicle_type." + info.vehicle_type)} {info.vehicle_load_compartment_type ? ` - ${t(
                "vehicle_load_compartment_type." +
                info.vehicle_load_compartment_type
              )}` : ''}
            </Typography>
          </div>
        </div>
        <div className={classes.infoBoxPhone}>
          <Typography variant="body2" className={classes.marginTypo}>
            Celular
            </Typography>
          <Typography variant="h3">
            {formatPhoneNumber(info.phonenumber)}
          </Typography>
        </div>
      </div>

      {(viewDetailsAction && info.trip_id) && (
        <div className={classes.infoBoxContact}>
          <Button variant="outlined" color="primary" type="button"
            startIcon={<Visibility />}
            onClick={() => {
              viewDetailsAction(info.trip_id as number);
            }}>
            Ver Viagem
            </Button>
        </div>
      )}
    </Popup>
  )
}


const useStyles = makeStyles({
  marginTypo: {
    margin: "8px 0px !important",
  },
  info: {
    position: "absolute",
    visibility: "hidden",
    marginLeft: "40px",
    marginTop: "-150px",
    transitionDelay: ".2s",
    zIndex: 99,
    "&:hover": {
      visibility: "visible",
    },
    zoom: 0.8,
  },
  status: {
    position: "absolute",
    bottom: -90,
    left: -5,
    zIndex: -1,
  },
  infoBoxContainer: {
    width: "380px",
    height: "auto",
  },

  infoBoxHeader: {
    display: "flex",

    "& img": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
    },
  },
  infoBoxHeaderInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "20px",
  },

  infoBoxContent: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0px",
  },
  infoBoxVehicle: {},
  infoBoxPhone: {},
  infoBoxContact: {
    marginTop: theme.spacing(1),
    display: "flex",
  },
});

export default CourierPopup;
