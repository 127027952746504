import React from "react";
import { useFormik } from "formik";
import { Grid, TextField } from "@material-ui/core";
import { DEFAULT_LOCALE } from "../../../i18n";
import DatePicker from "react-datepicker";


export interface IDebitNotesFilterParams {
    date_after: Date | null,
    date_before: Date | null,
}

export const INITIAL_FILTER_PARAMS: IDebitNotesFilterParams = {
    date_after: null,
    date_before: null
};

interface IDebitNotesFilterProps {
    params?: IDebitNotesFilterParams;
    onFilterChanged: (params: IDebitNotesFilterParams) => void;
}

export const DebitNotesFilter: React.FC<IDebitNotesFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    onFilterChanged,
}) => {

    const formik = useFormik({
        initialValues: params,
        onSubmit: values => {
            onFilterChanged(values);
        },
        onReset: values => {
            onFilterChanged(values);
            formik.setFieldValue("customerId", "");
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item xl={2} md={3}>
                    <DatePicker
                        id="date_after"
                        name="date_after"
                        onChange={val => {
                            formik.setFieldValue("date_after", val);
                            formik.submitForm();
                        }}
                        maxDate={new Date()}
                        selected={formik.values.date_after}
                        startDate={formik.values.date_after}
                        endDate={formik.values.date_before}
                        selectsStart
                        locale={DEFAULT_LOCALE}
                        isClearable           
                        autoComplete="off"
                        wrapperClassName="MuiFormControl-fullWidth"
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        customInput={<TextField
                            label="Mês e ano de Referência"
                            variant="outlined"
                            size="small"
                            error={!!formik.errors.date_after}
                            helperText={formik.errors.date_after}
                            fullWidth />}
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
