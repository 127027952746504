import React, { createContext, useCallback, useReducer } from "react";
import type { FC, ReactNode } from "react";
import SelectStoreDialog from "../components/SelectStoreDialog";
import { LoginStore, Store, StoreDynamicTariff } from "../services/types/store";
import { USER_STORE_DATA_STORAGE_KEY } from "./AuthContext";
import { useSnackbar } from "notistack";
import CourierQueueDialog, { CourierQueueAction } from "../components/CourierQueueDialog";
import { QueueCourier } from "../services/types/courier";
import CustomerFinancesService from "../services/customerFinancesService";
import useAppContext from "../hooks/useAppContext";
import { AppAlert } from "./AppContext";
import StoreService from "../services/storeService";
import useAuth from "../hooks/useAuth";
import { CURRENT_TIME_DATE_FORMAT } from "../i18n";
import { format } from "date-fns";
import numbro from "numbro";

interface StoreState {
  courierQueueSize: number;
  courierQueue: QueueCourier[],
  isCourierQueueRefreshing: boolean,
  currentBalance: number;
  creditLimit: number;
  hasActiveIncentive: boolean;
  activeStore: LoginStore | null;
}

interface StoreValue extends StoreState {
  setActiveStore: (store?: LoginStore | null) => void;
  selectStore: (force?: boolean) => void;
  showCourierQueue: () => void;
  selectCourierFromQueue: (callback: (courier: QueueCourier) => void) => void;
}

interface StoreProviderProps {
  children: ReactNode;
}

enum StoreActions {
  SELECT_STORE,
  UPDATE_BALANCE,
  UPDATE_COURIER_QUEUE,
  REFRESH_COURIER_QUEUE,
  REFRESH_ACTIVE_INCENTIVE
}

type SelectStoreAction = {
  type: StoreActions.SELECT_STORE;
  payload: {
    selectedStore: LoginStore | null;
  };
};

type UpdateBalanceAction = {
  type: StoreActions.UPDATE_BALANCE;
  payload: {
    currentBalance: number;
    creditLimit: number;
  };
};

type UpdateCourierQueueAction = {
  type: StoreActions.UPDATE_COURIER_QUEUE;
  payload: {
    updatedCourierQueue: QueueCourier[];
  };
};

type RefreshCourierQueueAction = {
  type: StoreActions.REFRESH_COURIER_QUEUE;
  payload: {
    isCourierQueueRefreshing: boolean;
  };
};

type RefreshActiveIncentiveAction = {
  type: StoreActions.REFRESH_ACTIVE_INCENTIVE;
  payload: {
    hasActiveIncentive: boolean;
  };
};

type Action = SelectStoreAction | UpdateBalanceAction | UpdateCourierQueueAction | RefreshCourierQueueAction
  | RefreshActiveIncentiveAction;

const loadUserStore = (): LoginStore | null => {
  const userStoreData = localStorage.getItem(USER_STORE_DATA_STORAGE_KEY);
  let store = null;
  if (userStoreData) {
    store = JSON.parse(userStoreData);
  }
  return store;
};

const initialStoreState: StoreState = {
  courierQueueSize: 0,
  courierQueue: [],
  isCourierQueueRefreshing: false,
  currentBalance: 0,
  creditLimit: 0,
  hasActiveIncentive: false,
  activeStore: null
};

const reducer = (state: StoreState, action: Action): StoreState => {
  switch (action.type) {
    case StoreActions.SELECT_STORE: {
      const { selectedStore } = action.payload;
      return {
        ...state,
        activeStore: selectedStore
      };
    }
    case StoreActions.UPDATE_BALANCE: {
      const { currentBalance, creditLimit } = action.payload;
      return {
        ...state,
        currentBalance,
        creditLimit
      };
    }
    case StoreActions.UPDATE_COURIER_QUEUE: {
      const { updatedCourierQueue } = action.payload;
      return {
        ...state,
        courierQueue: updatedCourierQueue,
        courierQueueSize: updatedCourierQueue.length,
        isCourierQueueRefreshing: false
      };
    }
    case StoreActions.REFRESH_COURIER_QUEUE: {
      const { isCourierQueueRefreshing } = action.payload;
      return {
        ...state,
        isCourierQueueRefreshing
      };
    }
    case StoreActions.REFRESH_ACTIVE_INCENTIVE: {
      const { hasActiveIncentive } = action.payload;
      return {
        ...state,
        hasActiveIncentive
      };
    }
    default: {
      return { ...state };
    }
  }
};

const StoreContext = createContext<StoreValue>({
  ...initialStoreState,
  setActiveStore: (store?: LoginStore | null) => { },
  selectStore: (force?: boolean) => { },
  showCourierQueue: () => { },
  selectCourierFromQueue: (callback: (courier: QueueCourier) => void) => { },
});

export const StoreContextProvider: FC<StoreProviderProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { addAppAlert, removeAppAlert } = useAppContext();
  const { user, isAuthenticated } = useAuth();
  const [balanceAlert, setBalanceAlert] = React.useState<AppAlert>();
  const [incentiveAlert, setIncentiveAlert] = React.useState<AppAlert>();
  const [state, dispatch] = useReducer(reducer, initialStoreState);

  const [availableStores, setAvailableStores] = React.useState<Store[]>([]);
  const [isSelectStoreDialogOpen, showSelectStoreDialog] = React.useState<boolean>(false);
  const [isForceSelectStore, forceSelectStore] = React.useState<boolean>(false);
  const [isCourierQueueDialogOpen, showCourierQueueDialog] = React.useState<boolean>(false);

  const [courierQueueDialogTitle, setCourierQueueDialogTitle] = React.useState<string>();
  const [courierQueueAction, setCourierQueueAction] = React.useState<CourierQueueAction>();

  /*
   * Exposed functions
   */
  const setActiveStore = useCallback((store?: LoginStore | null) => {
    if (store) {
      localStorage.setItem(USER_STORE_DATA_STORAGE_KEY, JSON.stringify(store));
    }

    dispatch({
      type: StoreActions.SELECT_STORE,
      payload: {
        selectedStore: store ? store : null,
      },
    });
  }, []);

  const selectStore = useCallback((force: boolean = false) => {
    forceSelectStore(force);
    showSelectStoreDialog(true);
  }, []);

  const showCourierQueue = useCallback(() => {
    if (state.activeStore?.id) {
      setCourierQueueDialogTitle(undefined);
      setCourierQueueAction(undefined);
      showCourierQueueDialog(true);
    } else {
      enqueueSnackbar(
        "É necessário informar a Loja em que está trabalhando para visualizar a fila de entregadores.",
        { variant: "error" }
      );
    }
  }, [enqueueSnackbar, setCourierQueueDialogTitle, state.activeStore]);

  const selectCourierFromQueue = useCallback((callback: (courier: QueueCourier) => void) => {
    if (state.activeStore?.id) {
      setCourierQueueDialogTitle("Selecione o Entregador da Fila");
      setCourierQueueAction({
        label: "Solicitar",
        action: (courier: QueueCourier) => {
          showCourierQueueDialog(false);
          callback(courier);
        }
      });
      showCourierQueueDialog(true);
    } else {
      enqueueSnackbar(
        "É necessário informar a Loja em que está trabalhando para visualizar a fila de entregadores.",
        { variant: "error" }
      );
    }
  }, [state.activeStore, setCourierQueueDialogTitle, setCourierQueueAction, enqueueSnackbar]);
  /*
   * Exposed functions - END
   */

  const onSelectStore = useCallback((store: LoginStore) => {
    setActiveStore(store);

    enqueueSnackbar("Loja selecionada com sucesso!", {
      variant: "success",
    });
  }, [enqueueSnackbar, setActiveStore]);

  const updateBalanceAlerts = useCallback((currentBalance: number) => {
    if (currentBalance && currentBalance <= 0) {
      if (balanceAlert === undefined) {
        setBalanceAlert(addAppAlert("error", "Seu saldo está negativo, por favor, faça uma recarga."))
      }
    } else if (balanceAlert !== undefined) {
      removeAppAlert(balanceAlert);
      setBalanceAlert(undefined);
    }
  }, [balanceAlert, addAppAlert, removeAppAlert]);

  const updateIncentiveAlerts = useCallback((dynamicTariff: StoreDynamicTariff) => {
    if (dynamicTariff.is_dynamic_active) {
      if (incentiveAlert === undefined) {
        const title = dynamicTariff.incentive.title;
        const date = format(dynamicTariff.incentive?.end_date, CURRENT_TIME_DATE_FORMAT);
        const value = numbro(dynamicTariff.incentive?.customer_value).formatCurrency();

        const message = `${title} até as ${date} no valor de ${value}`;
        setIncentiveAlert(addAppAlert("warning", message))
      }
    } else if (incentiveAlert !== undefined) {
      removeAppAlert(incentiveAlert);
      setBalanceAlert(undefined);
    }
  }, [incentiveAlert, addAppAlert, removeAppAlert]);

  const refreshCustomerFinances = useCallback(() => {
    CustomerFinancesService.getCustomerBalance()
      .then(customerBalanceResponse => {
        dispatch({
          type: StoreActions.UPDATE_BALANCE,
          payload: {
            currentBalance: customerBalanceResponse.currentBalance,
            creditLimit: customerBalanceResponse.creditLimit
          },
        });
        updateBalanceAlerts(customerBalanceResponse.currentBalance);
      })
      .catch(error => { });
  }, [updateBalanceAlerts]);

  const refreshQueue = useCallback(() => {
    if (state.activeStore) {
      dispatch({
        type: StoreActions.REFRESH_COURIER_QUEUE,
        payload: {
          isCourierQueueRefreshing: true,
        },
      });
      StoreService.getStoreCourierQueue(state.activeStore.id)
        .then(queue => {
          dispatch({
            type: StoreActions.UPDATE_COURIER_QUEUE,
            payload: {
              updatedCourierQueue: queue,
            },
          });
        })
        .catch(error => { })
        .finally(() => {
          dispatch({
            type: StoreActions.REFRESH_COURIER_QUEUE,
            payload: {
              isCourierQueueRefreshing: false,
            },
          });
        });
    }
  }, [state.activeStore]);

  const refreshActiveIncentive = useCallback(() => {
    if (state.activeStore) {
      StoreService.getStoreIsInDynamic(state.activeStore.id)
        .then(response => {
          dispatch({
            type: StoreActions.REFRESH_ACTIVE_INCENTIVE,
            payload: {
              hasActiveIncentive: response?.is_dynamic_active ? true : false,
            },
          });
          updateIncentiveAlerts(response);
        })
        .catch(error => { });
    }
  }, [state.activeStore, updateIncentiveAlerts]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setActiveStore(user?.store);
    } else {
      setActiveStore(null);
    }
  }, [user, isAuthenticated, setActiveStore]);

  React.useEffect(() => {
    if (user) {
      StoreService.getStoresByCustomerId(Number(user.customer?.id))
        .then((stores) => {
          setAvailableStores(stores);
        })
        .catch((error) => { });
    }
  }, [user]);

  React.useEffect(() => {
    if ((state.activeStore === null) || (state.activeStore.max_number_of_deliveries === undefined)) {
      if (availableStores.length > 0) {
        let found = false;
        const savedStore = loadUserStore();
        if (savedStore) {
          const foundStore = availableStores.find((store) => store.id === savedStore.id);
          if (foundStore) {
            found = true;
            const activeStore = {
              id: foundStore.id,
              name: foundStore.name,
              latitude: foundStore.latitude,
              longitude: foundStore.longitude,
              region: foundStore.region,
              max_number_of_deliveries: foundStore.max_number_of_deliveries
            };
            setActiveStore(activeStore);
          }
        }
        if (!found) {
          if (availableStores.length === 1) {
            const availableStore = availableStores[0];
            const activeStore = {
              id: availableStore.id,
              name: availableStore.name,
              latitude: availableStore.latitude,
              longitude: availableStore.longitude,
              region: availableStore.region,
              max_number_of_deliveries: availableStore.max_number_of_deliveries
            };
            setActiveStore(activeStore);
          } else {
            selectStore(true);
          }
        }
      }
    }
  }, [availableStores, setActiveStore, state.activeStore, selectStore]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setActiveStore(user?.store);
    } else {
      setActiveStore(null);
    }
  }, [user, isAuthenticated, setActiveStore]);

  React.useEffect(() => {
    if (user) {
      StoreService.getStoresByCustomerId(Number(user.customer?.id))
        .then((stores) => {
          setAvailableStores(stores);
        })
        .catch((error) => { });
    }
  }, [user]);

  React.useEffect(() => {
    if ((availableStores.length > 0) && (state.activeStore === null)) {
      let found = false;
      const savedStore = loadUserStore();
      if (savedStore) {
        const foundStore = availableStores.find((store) => store.id === savedStore.id);
        if (foundStore) {
          found = true;
          const activeStore = {
            id: foundStore.id,
            name: foundStore.name,
            latitude: foundStore.latitude,
            longitude: foundStore.longitude,
            region: foundStore.region
          };
          setActiveStore(activeStore);
        }
      }
      if (!found) {
        selectStore(true);
      }
    }
  }, [availableStores, setActiveStore, state.activeStore, selectStore]);

  React.useEffect(() => {
    refreshCustomerFinances();

    const interval = setInterval(() => {
      refreshCustomerFinances();
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshCustomerFinances]);

  React.useEffect(() => {
    refreshQueue();

    const interval = setInterval(() => {
      refreshQueue();
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshQueue]);

  React.useEffect(() => {
    if (user?.store) {
      setActiveStore(user.store);
    } else {
      setActiveStore(null);
    }
  }, [user, setActiveStore]);

  React.useEffect(() => {
    refreshActiveIncentive();

    const interval = setInterval(() => {
      refreshActiveIncentive();
    }, 30000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshActiveIncentive]);

  return (
    <React.Fragment>
      <StoreContext.Provider
        value={{
          ...state,
          setActiveStore,
          selectStore,
          showCourierQueue,
          selectCourierFromQueue
        }}
      >
        {children}
      </StoreContext.Provider>

      <SelectStoreDialog
        open={isSelectStoreDialogOpen}
        forceSelection={isForceSelectStore}
        currentStore={state.activeStore}
        storeList={availableStores}
        onSelectStore={onSelectStore}
        onCloseRequested={() => showSelectStoreDialog(false)}
      />
      <CourierQueueDialog
        isRefreshing={state.isCourierQueueRefreshing}
        courierQueue={state.courierQueue}
        open={isCourierQueueDialogOpen}
        title={courierQueueDialogTitle}
        action={courierQueueAction}
        onCloseRequested={() => showCourierQueueDialog(false)}
      />
    </React.Fragment>
  );
};

export default StoreContext;
