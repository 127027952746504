import { apiAxios as axios } from "../utils/axios";
import { urls } from "../utils/urls";
import { IPageableParams, PagedResponse } from "./types/common";
import { CourierPosition, DeliveryRequestAction, DeliveryRequestLog, PendingTrip, Trip, TripDeliveryRequest, TripStatus } from "./types/deliveryRequest";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";

export interface ITripService {
  createNewTrip: (storeId: number, deliveryIds: number[]) => Promise<any>;
  addDeliveryToTrip: (trip: PendingTrip | Trip, deliveryId: number) => Promise<any>;
  removeDeliveryFromTrip: (tripId: number, deliveryId: number) => Promise<any>;

  searchCourierForTrip: (tripId: number, courierId?: number | null) => Promise<any>;
  releaseTripById: (id: number) => Promise<any>;
  finishTripById: (id: number) => Promise<any>;
  cancelTripById: (id: number) => Promise<any>;
  resendTrip: (id: number, courierId: number | null) => Promise<any>;
  discardTrip: (id: number) => Promise<any>;
  resetTripById: (tripId: number) => Promise<any>;

  getTripById: (id: number) => Promise<Trip>;
  getPendingTrips: (
    storeId: number | null,
    regionId: number | null,
    status: TripStatus | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<PendingTrip>>;
  
  interveneInTrip: (
    tripId: number,
    action: DeliveryRequestAction,
  ) => Promise<any>;
  
  getLogsByTrip: (id: number) => Promise<DeliveryRequestLog[]>;
}

const TripService: ITripService = {
  createNewTrip: async (storeId: number, deliveryIds: number[]) => {
    return axios
      .post(`${urls.TRIP}mount_trip/`, {
        store: storeId,
        deliveries: deliveryIds
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  removeDeliveryFromTrip: (tripId: number, deliveryId: number) => {
    return axios
      .post(`${urls.TRIP}${tripId}/remove_delivery_request/`, {
        delivery_request_id: deliveryId
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  addDeliveryToTrip: (trip: PendingTrip | Trip, deliveryId: number) => {
    let request = null;
    let validStatus = true;
    switch (trip.status) {
      case TripStatus.ACCEPTED:
      case TripStatus.AWAITING_RELEASE:
      case TripStatus.AWAITING_COLLECTION:
        request = axios
          .post(`${urls.TRIP}${trip.id}/request_delivery_addition/`, {
            delivery_request: deliveryId
          });
        break;
      case TripStatus.NEW:
      case TripStatus.NO_COURIER:
        request = axios
          .post(`${urls.TRIP}${trip.id}/join_delivery_request/`, {
            delivery_request: deliveryId
          });
        break;
      default:
        validStatus = false;
        break;
    }

    let result;
    if (validStatus && request) {
      result = request
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    } else {
      result = Promise.reject({
        response: {
          status: 400,
          data: { status: "Não pode adicionar entregas na situação atual." }
        }
      });
    }

    return result;
  },

  searchCourierForTrip: (tripId: number, courierId: number | null = null) => {
    return axios
      .post(`${urls.TRIP}${tripId}/search_courier/`, { courier: courierId })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  releaseTripById: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/release_orders/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  finishTripById: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/finish/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  cancelTripById: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/cancel/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  discardTrip: (id: number) =>{
    return axios
      .post(`${urls.TRIP}${id}/discard/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  resetTripById: (tripId: number) => {
    const url = `${urls.TRIP}${tripId}/reset/`;
    return axios.post(url)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
  },

  getPendingTrips: (
    storeId: number | null,
    regionId: number | null,
    status: TripStatus | null,
    pageParams: IPageableParams
  ) => {
    return new Promise((resolve, reject) => {
      axios.post(
        `${urls.TRIP}get_pending_trips/?page=${pageParams.page}&page_size=${pageParams.page_size}`,
        {
          ordering: pageParams.ordering,
          store: storeId,
          region: regionId,
          status: status,
        }
      )
        .then((response) => {
          const data: PendingTrip[] = response.data.results.map((item: any) => {
            const deliveries: TripDeliveryRequest[] = item.deliveries.map((item: any) => {
              const destinationAddress = {
                ...item.destination_address,
                latitude: convertToNumber(item.destination_address.latitude),
                longitude: convertToNumber(item.destination_address.longitude)
              };
            
              return {
                ...item,
                created_at: convertToDate(item.created_at),
                requested_time: convertToDate(item.requested_time),
                is_scheduled: convertToBoolean(item.is_scheduled),
                estimated_delivery_time: convertToDate(item.estimated_delivery_time),
                destination_address: destinationAddress,
              }
            });

            return {
              ...item,
              id: convertToNumber(item.id),
              deliveries: deliveries,
              courier_id: convertToNumber(item.courier_id),
              created_at: convertToDate(item.created_at),
              updated_at: convertToDate(item.updated_at),
              start_date: convertToDate(item.start_date),
              end_date: convertToDate(item.end_date),
              store: convertToNumber(item.store),
            };
          });
          const result: PagedResponse<PendingTrip> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  resendTrip: (id: number, courierId: number | null) => {
    return axios
      .post(`${urls.TRIP}${id}/resend/`, { courier: courierId })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getTripById: (id: number) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${urls.TRIP}${id}/`)
        .then((response) => {
          const deliveries: TripDeliveryRequest[] = response.data.deliveries.map((item: any) => {
            
            const PerVehicle = {
              ...item.estimated_values.per_vehicle,
              BIKE: convertToNumber(item.estimated_values.per_vehicle.BIKE),
              CAR: convertToNumber(item.estimated_values.per_vehicle.CAR),
              MOTORCYCLE: convertToNumber(item.estimated_values.per_vehicle.MOTORCYCLE),
              VAN: convertToNumber(item.estimated_values.per_vehicle.VAN),
            }
  
            const estimatedVehicleValues = {
              ...item.estimated_values,
              already_has_commercial_plan: convertToBoolean(item.estimated_values.already_has_commercial_plan),
              per_vehicle: PerVehicle
            }
            return {
              ...item,
              created_at: convertToDate(item.created_at),
              estimated_delivery_time: convertToDate(item.estimated_delivery_time),
              estimated_values: estimatedVehicleValues,
              total_value: item.total_value ? convertToNumber(item.total_value) : null,
              courier_value: item.courier_value ? convertToNumber(item.courier_value) : null,
              canceled: convertToBoolean(item.canceled),
            }
          });
          let courier = null;
          if (response.data.courier) {
            courier = {
              ...response.data.courier,
              id: convertToNumber(response.data.courier.id),
              ranking_points: convertToNumber(response.data.courier.ranking_points),
              requests_count: convertToNumber(response.data.courier.requests_count),
              new_ranking_points: convertToNumber(response.data.courier.new_ranking_points),
            };
          }
          let courierPosition: CourierPosition | null = null;
          if (response.data.courier_position) {
              courierPosition = {
                  ...response.data.courier_position,
                  courier_id: convertToNumber(response.data.courier_position.courier_id),
                  latitude: convertToNumber(response.data.courier_position.latitude),
                  longitude: convertToNumber(response.data.courier_position.longitude),
                  delivery_request_id: convertToNumber(response.data.courier_position.delivery_request_id),
                  trip_id: convertToNumber(response.data.courier_position.trip_id),
                  updated_at: convertToDate(response.data.courier_position.updated_at),
              }
          }

          const result = {
            ...response.data,
            id: convertToNumber(response.data.id),
            deliveries: deliveries,
            courier: courier,
            created_at: convertToDate(response.data.created_at),
            updated_at: convertToDate(response.data.updated_at),
            start_date: convertToDate(response.data.start_date),
            end_date: convertToDate(response.data.end_date),
            store: convertToNumber(response.data.store),
            courier_incentive_value: response.data.courier_incentive_value ? convertToNumber(response.data.courier_incentive_value) : null,
            customer_incentive_value: response.data.customer_incentive_value ? convertToNumber(response.data.customer_incentive_value) : null,
            courier_position: courierPosition,
          }
          resolve(result)
        })
        .catch((error) => reject(error));
    });
  },

  interveneInTrip: (tripId: number, action: DeliveryRequestAction) => {
    const url = `${urls.TRIP}${tripId}/intervein/`;

    let status: string;
    switch (action) {
      case DeliveryRequestAction.CHECK_IN:
        status = "AWAITING_RELEASE"
        break;
      case DeliveryRequestAction.RELEASE_ORDER:
        status = "AWAITING_COLLECTION"
        break;
      case DeliveryRequestAction.CHECK_OUT:
        status = "ON_WAY"
        break;
      case DeliveryRequestAction.REGISTER_CHECK_IN_BACK:
        status = "BACK_TO_STORE"
        break;
      case DeliveryRequestAction.FINISHED:
        status = "FINISHED"
        break;
      default:
        status = "";
        break;
    }

    return axios.post(url, { action: status });
  },

  getLogsByTrip: (id: number) => {
    const url = `${urls.TRIP}${id}/logs/`;
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          const result: DeliveryRequestLog[] = response.data.map((item: any) => {
            return {
              ...item,
              courier: convertToNumber(item.courier),
              manual_action: convertToBoolean(item.manual_action),
              created_at: convertToDate(item.created_at),
              is_courier_action: convertToBoolean(item.is_courier_action),
              made_by: convertToNumber(item.made_by)
            }
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },
};

export default TripService;
