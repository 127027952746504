import React, { useEffect } from "react";
import { useFormik } from "formik";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Terms } from "../../services/types/termsRequest";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import CustomerFinancesService from "../../services/customerFinancesService";
import { USER_DATA_STORAGE_KEY } from "../../contexts/AuthContext";
import { LoginUser } from "../../services/types/user";
import ShowIf from "../../components/ShowIf";

export const INITIAL_VALUES: Terms = {
  accept_terms: false,
};

export const createAcceptTermsRequestValidation = Yup.object().shape({
  accept_terms: Yup.boolean().required("Campo obrigatório"),
});

interface ICreateAcceptTermsRequestProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const AcceptTermsRequest: React.FC<ICreateAcceptTermsRequestProps> = ({
  open,
  setOpen,
}) => {
  const { logout } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = React.useState<LoginUser>();

  useEffect(() => {
    if (open) {
      var userData = localStorage.getItem(USER_DATA_STORAGE_KEY);
      if (userData) {
        var userLocal: LoginUser | null = null;
        userLocal = JSON.parse(userData);
        if (userLocal) {
          setUser(userLocal);
        }
      }
    }
  }, [open]);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values) => {
      if (user) {
        CustomerFinancesService.updateTermsUserRequest(user.id, {
          accepted_terms: true,
        })
          .then((response) => {
            user.accepted_terms = true;
            localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(user));
            handleClose();
          })
          .catch((error) => {});
      }
    },
    validationSchema: createAcceptTermsRequestValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClose = () => {
    formik.resetForm();
    open = false;
    setOpen(false);
  };

  const handleDeclined = () => {
    try {
      logout();
      handleClose();
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.title}>
        TERMO DE ADESÃO À PLATAFORMA SPEEDY
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description">
          <Typography className={classes.description}>
            As partes abaixo identificadas resolvem firmar o presente TERMO DE
            ADESÃO: <br></br>
            Pelo presente instrumento de Termos e Condições Gerais, de um lado
            SPEEDY TECNOLOGIA E INTERMEDIAÇÃO LTDA, pessoa jurídica de direito
            privado, inscrita no CNPJ sob nº 42.782.302/0001-09, com sede na Av.
            Guarabira, nº 932, Bairro Manaíra, João Pessoa/PB, CEP 58038-142,
            doravante denominada CONTRATADA OU SPEEDY, de outro lado,
            Contratante, qualificado no quadro “Dados do Contratante” do
            presente instrumento, tem entre si como justo e acordado o presente
            Termo de Adesão, que se regerá de acordo com as cláusulas e
            condições seguintes:
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA PRIMEIRA – DO OBJETO:
          </Typography>
          <Typography className={classes.description}>
            1.1 O presente contrato tem por objeto a prestação dos serviços
            combinados, através da estruturação de plataformas digitais da
            CONTRATADA (website https://speedy.app.br/ e o aplicativo SPEEDY),
            criando um ambiente de negócios entre o tomador do serviço de
            transporte e os Entregadores Autônomos para efetivação do serviço de
            transporte para disponibilização dos produtos da CONTRATANTE.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA SEGUNDA – DA ADESÃO, DO ACESSO, INTERRUPÇÃO, SUSPENSÃO OU
            CANCELAMENTO DA CONTA:
          </Typography>
          <Typography className={classes.description}>
            2.1 O CONTRATANTE, ao assinar este Termo de Adesão, adere às
            condições estabelecidas no presente instrumento, se dispondo a
            observar todas as suas cláusulas.
          </Typography>
          <Typography className={classes.description}>
            2.2. Após a efetivação do cadastro do CONTRATANTE será criada uma
            conta na Plataforma SPEEDY e seu respectivo painel de controle,
            acessível por meio de login e senha. O Usuário Solicitante se
            compromete a não informar seu login e senha a terceiros,
            responsabilizando-se integralmente pelo uso de sua conta e pelos
            dados fornecidos.
          </Typography>
          <Typography className={classes.description}>
            2.3. O CONTRATANTE que desejar cancelar imediatamente seu cadastro
            junto à SPEEDY e, assim, cessar a utilização dos Serviços, poderá
            fazê-lo a qualquer momento por meio do e-mail contato@speedy.app.br,
            sem qualquer ônus, sem prejuízo do pagamento de eventuais transações
            já realizadas e não cobradas ou pagas, vencidas ou a vencer, ou
            seja, como a cobrança realizada pela SPEEDY é mensal, ainda que o
            pedido de cancelamento seja realizado pelo CONTRATANTE antes do
            fechamento do mês, tais valores correspondentes a transações já
            realizadas e não cobradas ou não pagas permanecem como devidos
            normalmente.
          </Typography>
          <Typography className={classes.description}>
            2.4. A SPEEDY poderá desativar, bloquear ou, de outro modo, encerrar
            unilateralmente o acesso do Usuário Solicitante aos Serviços, com
            rescisão unilateral do presente contrato, sem prejuízo das medidas
            legais cabíveis, caso: seja identificada qualquer violação legal
            e/ou às disposições destes “TERMOS DE USO”; seja identificada a
            prática de atos fraudulentos por meio do uso da Plataforma SPEEDY ,
            seja de forma direta ou indireta; e se o Usuário Solicitante causar
            algum dano direto ou indireto à SPEEDY, seus Usuários Solicitantes
            ou a terceiros; em outras hipóteses, por critério da SPEEDY, caso a
            SPEEDY entenda necessário, por violação de normas jurídicas,
            incompatibilidade com a política da Plataforma, entre outras, a
            critério da SPEEDY.
          </Typography>
          <Typography className={classes.description}>
            2.5. Em caso de término da utilização dos Serviços por qualquer
            motivo, a SPEEDY poderá manter os dados e histórico das transações
            realizadas pelo CONTRATANTE por tempo indeterminado, o que desde já
            fica expressamente autorizado.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA TERCEIRA – DOS DIREITOS E OBRIGAÇÕES DAS PARTES:
          </Typography>
          <Typography className={classes.description}>
            3.1. Manter os dados cadastrais devidamente atualizados, bem como a
            manutenção de sua regularidade perante os competentes órgãos,
            autoridades ou entidades a que esteja legalmente vinculado.
          </Typography>
          <Typography className={classes.description}>
            3.2. Cumprir rigorosamente as condições do presente termo de adesão.
          </Typography>
          <Typography className={classes.description}>
            3.3. O contratante entende e reconhece que os Entregadores Autônomos
            que prestam os serviços de Frete são independentes e não exclusivos,
            que atuam por sua conta própria e utilizam-se de seus próprios
            recursos como: capacete, celular e plano de dados, coletes, capas de
            chuva, entre todos os outros. Assim, o CONTRATANTE reconhece
            expressamente que SPEEDY figura como mera intermediadora de Usuário
            Solicitantes e Entregadores Autônomos e que inexiste qualquer
            vínculo empregatício ou de subordinação entre a SPEEDY e o
            Entregador Autônomo e, por isso, a SPEEDY não se responsabiliza pelo
            Frete, pela carga transportada ou pela conduta do Entregador
            Autônomo.
          </Typography>
          <Typography className={classes.description}>
            3.3.1. O CONTRATANTE estará isento de qualquer responsabilidade
            cível, trabalhista ou previdenciária em casos de acidentes sofridos
            pelos Entregadores Autônomo.
          </Typography>
          <Typography className={classes.description}>
            3.4. O CONTRATANTE entende e reconhece que a Gestão dos Pagamentos
            contratada é de responsabilidade exclusiva da SPEEDY, portanto,
            qualquer problema referente ao pagamento deverá ser reportado
            diretamente a ela, ficando a Provedora de Serviços de Pagamento
            absolutamente isenta de responsabilidade por qualquer decorrência de
            tal serviço.
          </Typography>
          <Typography className={classes.description}>
            3.5. Demais condições e obrigações estão detalhadamente previstas no
            Termo de Uso que será apresentado juntamente com o instrumento.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA QUARTA – DA FORMA DE PAGAMENTO, DA UTILIZAÇÃO DOS CRÉDITOS
            E DA SUSPENSÃO DOS SERVIÇOS.
          </Typography>
          <Typography className={classes.description}>
            4.1. A SPEEDY terá como forma de pagamento a aquisição de créditos
            pré-pagos por parte da CONTRATANTE. Os créditos previamente
            adquiridos terão descontos realizados mediante a conclusão da
            solicitação de frete. Ao concluir a solicitação do Frete, o
            CONTRATANTE terá descontado do seu crédito, o valor total na
            efetivação do pedido. A conclusão da solicitação pressupõe a
            concordância integral do CONTRATANTE com relação ao valor
            determinado e informado na Plataforma.
          </Typography>
          <Typography className={classes.description}>
            4.2. Resta devidamente ciente o CONTRATANTE que o valor da
            solicitação de entrega, prevista no plano negociado entre
            CONTRATANTE e SPEEDY poderá sofrer alterações, nos seguintes casos:
            Necessidade de taxa extra, dada em casos em que a há pouca oferta de
            entregadores autônomos; Adicional de espera do entregador;
            Necessidade de alteração do ponto de entrega;
          </Typography>
          <Typography className={classes.description}>
            4.3. O CONTRATANTE declara estar ciente de que, em razão dos fretes
            contratados diretamente dos Entregadores Autônomos por meio da
            Plataforma SPEEDY, sofrerá descontos dos créditos previamente
            adquiridos. O pagamento englobará tanto o valor dos fretes devido a
            tais Entregadores Autônomos quanto o valor do serviço de
            agenciamento de frete prestado pela SPEEDY. O CONTRATANTE declara,
            ainda, estar ciente de que o valor constante na Nota Fiscal emitida
            pela SPEEDY, em razão dos pedidos realizados por meio da Plataforma,
            fará constar como base cálculo para fins de tributação, o valor
            referente aos serviços de intermediação de fretes prestados pela
            SPEEDY.
          </Typography>
          <Typography className={classes.description}>
            4.4. As despesas de frete incorridas pelo CONTRATANTE estarão
            disponíveis na Plataforma SPEEDY e acessíveis por meio de login e
            senha, mediante Nota de Débito emitida mensalmente discriminando a
            quantidade de entregas realizadas no período. Neste contexto, o
            CONTRATANTE resta ciente de que, como parte do pagamento dos fretes
            realizados é destinado ao Entregador Autônomo, a SPEEDY receberá
            tais valores em nome e conta de terceiros, cabendo a esta, sob sua
            responsabilidade, efetuar o repasse do valor que cabe ao Entregador
            Autônomo referente ao serviço de transporte por ele realizado
            diretamente em favor do CONTRATANTE e retendo o percentual que lhe
            cabe pelo serviço de intermediação.
          </Typography>
          <Typography className={classes.description}>
            4.5. Uma vez que a Plataforma SPEEDY possibilita que CONTRATANTE
            tenha pleno acesso a toda e qualquer solicitação de frete realizada
            em seu favor a qualquer momento, eventuais incongruências ou
            necessidades de alterações ocorridas deverão ser solicitadas em até
            10 (dez) dias da data da solicitação da entrega.
          </Typography>
          <Typography className={classes.description}>
            4.6. O silêncio do CONTRATANTE quanto ao envio de informação de
            quaisquer incongruências ou alterações no prazo acima mencionado
            será considerado como anuência ampla e total quanto às informações
            constantes em seu faturamento.
          </Typography>
          <Typography className={classes.description}>
            4.7. O serviço será prestado mediante a confirmação da aquisição dos
            créditos. Com a utilização integral dos respectivos créditos, o
            serviço ficará indisponível. A reativação do serviço ocorrerá
            mediante a confirmação de novo pagamento.
          </Typography>
          <Typography className={classes.description}>
            4.8. A SPEEDY não oferece reembolso dos valores de pedidos
            cancelados pelos clientes da CONTRATANTE, pelos seguintes motivos:
            Avaria do pedido; Atraso na entrega; Entrega em local indevido;
            Indisponibilidade de entregadores para realizar a entrega; Extravio
            de qualquer natureza;
          </Typography>
          <Typography className={classes.description}>
            4.9. A SPEEDY oferecerá seguro/reembolso para todas as condições
            citadas no item 4.8, apenas quando a entrega estiver marcada como
            “Entrega segurada” ou “Entrega Reembolsável”;
          </Typography>
          <Typography className={classes.description}>
            4.10. O reembolso oferecido pela SPEEDY sempre será na forma de créditos
            dentro da plataforma;
          </Typography>
          <Typography className={classes.description}>
            4.11. A SPEEDY manterá pelo prazo de 05 (cinco) anos, os dados e os
            comprovantes de pagamento realizados em favor de Entregadores
            Autônomos. Caso o CONTRATANTE solicite informações sobre esses
            pagamentos, a SPEEDY terá prazo de 30 (tinta) dias a contar da
            solicitação.
          </Typography>
          <Typography className={classes.description}>
            4.12. Caso o CONTRATANTE esteja utilizando a modalidade de fixos 
            (agendamento de entregadores) e o CONTRATANTE desistir desse 
            agendamento em até 2 (duas) horas antes do início do turno, ou
            durante o turno do entregador agendado, será retirado o percentual
            de 100% do valor garantido ao entregador dos créditos do CONTRATANTE.
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA QUINTA – DA POLÍTICA DE PRIVACIDADE E DIVULGACAO DE
            INFORMAÇÕES:
          </Typography>
          <Typography className={classes.description}>
            5.1. A SPEEDY se compromete a manter protegidos e em
            confidencialidade todos os dados cadastrais, incluindo e-mail
            informado, número de IP de acesso e outras informações pessoais que
            não sejam necessárias à utilização dos Serviços.
          </Typography>
          <Typography className={classes.description}>
            5.2. O CONTRATANTE desde já autoriza a SPEEDY, a seu critério,
            preservar, armazenar todos os dados e informações enviados à
            Plataforma, bem como todos os seus dados pessoais, a exemplo de
            endereços de e-mail, endereços de IP (Internet Protocol),
            informações de data e horário de acesso, entre outras informações. O
            CONTRATANTE também autoriza neste ato a SPEEDY a informar e/ou
            divulgar estes dados em caso de exigência legal ou se razoavelmente
            necessárias para o devido processo legal; fazer cumprir os “TERMOS
            DE USO”; responder a alegações de suposta violação de direitos de
            terceiros e de divulgação indevida de informações para contato de
            terceiros, e para proteger os direitos, a propriedade ou a segurança
            de terceiros ou da própria SPEEDY e de seus Usuário Solicitantes.
          </Typography>
          <Typography className={classes.description}>
            5.3. A utilização da Plataforma implica o consentimento do
            CONTRATANTE para coleta, armazenamento e uso das informações
            pessoais fornecidas e suas atualizações, dados de tráfego, endereços
            IP, entre outros.
          </Typography>
          <Typography className={classes.description}>
            5.4. Com o aceite do presente instrumento, o CONTRATANTE concorda em
            permitir o acesso à sua localização aproximada fornecendo um melhor
            serviço e melhor experiência.
          </Typography>
          <Typography className={classes.description}>
            5.5. O CONTRATANTE declara-se ciente que a SPEEDY pode coletar e
            usar dados técnicos de seu dispositivo tais como especificações,
            configurações, versões de sistema operacional, tipo de conexão à
            internet e afins para melhor adaptar sua plataforma com a finalidade
            de proporcionar a melhor experiência possível.
          </Typography>
          <Typography className={classes.description}>
            5.6. A SPEEDY poderá utilizar as informações e/ou dados fornecidos
            e/ou coletados pelo CONTRATANTE, o que fica desde já autorizado,
            para: aplicar aos “TERMOS DE USO”; acompanhar as atividades do
            CONTRATANTE para administrar mais efetivamente o tráfego na
            Plataforma; prestar serviço; e criar e administrar Contas, em outras
            hipóteses que se fizerem necessárias para a efetividade e manutenção
            e segurança da Plataforma.
          </Typography>
          <Typography className={classes.description}>
            5.7. A SPEEDY se reserva o direito de reter informações pelo período
            que entender necessário para o bom cumprimento de seus negócios,
            mesmo após o encerramento da conta do CONTRATANTE, salvo
            manifestação expressa do CONTRATANTE em sentido contrário.
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL:
          </Typography>
          <Typography className={classes.description}>
            6.1. Os elementos e/ou ferramentas encontrados na Plataforma estão
            sujeitos aos direitos intelectuais de acordo com as leis brasileiras
            e tratados e convenções internacionais dos quais o Brasil seja
            signatário. Apenas a título exemplificativo, entendem-se como tais:
            textos, softwares, scripts, imagens gráficas, fotos, sons, músicas,
            vídeos, recursos interativos e similares, marcas, marcas de
            serviços, logotipos e "look and feel", entre outros, pertencendo de
            forma exclusiva à SPEEDY.
          </Typography>
          <Typography className={classes.description}>
            6.2. O CONTRATANTE está ciente e concorda que a utilização,
            redistribuição, comercialização e/ou reprodução de conteúdo ou parte
            de conteúdo disponibilizado na Plataforma deverão seguir as normas
            concernentes às leis brasileiras de Direitos Autorais, sob pena de
            caracterização de utilização indevida e/ou infração a direitos
            intelectuais de terceiros.
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA SÉTIMA – DO PRAZO E VIGÊNCIA:
          </Typography>
          <Typography className={classes.description}>
            7.1. O presente CONTRATO é celebrado por prazo indeterminado,
            entrando em vigor na data de sua assinatura. Ambas as partes poderão
            rescindir o presente Contrato a qualquer momento durante o período
            de vigência do contrato, mediante comunicação prévia e expressa à
            outra parte no prazo de 30 (trinta) dias, desde eventuais débitos
            existentes (custos, despesas, encargos operacionais ou eventuais
            indenizações decorrentes de lesão a terceiros por conta do mau uso
            dos serviços pela CONTRATANTE) da CONTRATANTE com a CONTRATADA sejam
            quitados. Caso a notificação seja efetuada em um período inferior a
            30 (trinta) dias.
          </Typography>
          <Typography className={classes.description}>
            7.2. Caso, entretanto, o término do presente Contrato ocorra por
            culpa da CONTRATANTE, fica desde já estabelecido que este terá o
            acesso aos serviços imediatamente bloqueados, não assistindo à
            CONTRATANTE, por essa razão, qualquer sorte de indenização ou
            ressarcimento.
          </Typography>
          <Typography className={classes.description}>
            7.3. O presente Contrato poderá ser rescindido, de pleno direito,
            independentemente de qualquer notificação ou interpelação, judicial
            ou extrajudicial, nas seguintes hipóteses:
            <br></br> a) pela quebra de qualquer das disposições do presente
            termo, por qualquer das partes, de forma a impedir a continuidade da
            execução do objeto do mesmo;
            <br></br> b) se a CONTRATANTE, de qualquer forma, comprometer a
            imagem pública da CONTRATADA e/ou de qualquer empresa associada a
            esta na prestação dos serviços;
            <br></br> c) se a CONTRATANTE utilizar de práticas que desrespeitem
            a lei, a ordem pública, a moral, os bons costumes;
            <br></br> d) pelo não pagamento pela CONTRATANTE de uma ou mais das
            remunerações referentes aos serviços;
            <br></br> e) também poderá ser rescindido por justa causa se
            qualquer das partes contratantes se encontrarem em estado de
            insolvência, ou ainda, requerido e/ou homologado pedido de
            recuperação judicial, dissolução, falência, extinção e/ou liquidação
            judicial e/ou extrajudicial.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA OITAVA – DA ISENÇÃO DE RESPONSABILIDADE DA CONTRATADA:
          </Typography>
          <Typography className={classes.description}>
            8.1. A CONTRATADA exime-se de qualquer responsabilidade por
            problemas e falhas contempladas por “casos fortuitos” ou de “força
            maior”, “devidamente comprovados”, conforme previsto pelo artigo 393
            do Código Civil Brasileiro.
          </Typography>
          <Typography className={classes.description}>
            8.2. A CONTRATADA deixa de possuir responsabilidade pelas
            informações armazenadas em seus servidores quando intimada por
            decisão judicial a fornecer informações sigilosas ou arquivos
            armazenados pelo CONTRATANTE, sendo que a CONTRATADA deverá
            comunicar a CONTRATANTE, sobre a ordem judicial, previamente,
            impedindo a imediata transmissão das informações, se assim for
            permitido pelo foro judicial solicitante.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA NONA – DISPOSIÇÕES GERAIS:
          </Typography>
          <Typography className={classes.description}>
            9.1 Caso qualquer disposição for considerada inexequível ou
            inválida, essa disposição será isoladamente invalidada e não
            atingirá o restante das disposições deste, que continuarão
            plenamente vigentes e eficazes.
          </Typography>
          <Typography className={classes.description}>
            9.2. Caso exista Proposta Comercial apresentada pela SPEEDY ao
            CONTRATANTE, tal proposta será parte integrante das disposições
            destes Termos de Adesão e ao Termo de Uso.
          </Typography>
          <Typography className={classes.description}>
            9.3. O CONTRATANTE que se sentir lesado com relação aos Serviços
            poderá entrar em contato por meio do e-mail{" "}
            <a href={"mailto: contato@speedy.com"}>contato@speedy.com</a>.
          </Typography>
          <Typography className={classes.description}>
            9.4. O CONTRATANTE se compromete a utilizar as informações e/ou
            documentos da SPEEDY obtidos em razão da presente prestação de
            serviços única e exclusivamente para alcançar seu objeto, com total
            confidencialidade, sob pena de ser responsabilizado nos termos da
            lei.
          </Typography>
          <Typography className={classes.description}>
            9.5. A tolerância da CONTRATADA com a CONTRATANTE, relativamente a
            descumprimento de qualquer das obrigações ora assumidas, não será
            considerada novação ou renúncia a qualquer direito, constituindo
            mera liberalidade, que não impedirá a CONTRATADA de exigir
            posteriormente o fiel cumprimento deste instrumento, a qualquer
            tempo.
          </Typography>
          <Typography className={classes.description}>
            9.6. O presente contrato vincula inclusive herdeiros e/ou sucessores
            das partes, que a este contrato devem observar integralmente.
          </Typography>
          <Typography className={classes.description}>
            9.7. As partes não poderão transferir, ceder ou onerar os direitos e
            obrigações decorrentes deste instrumento em hipótese alguma sem a
            anuência escrita da parte contrária.
          </Typography>
          <Typography className={classes.description}>
            9.8. A CONTRATANTE declara e concorda expressamente: a) possuir
            personalidade jurídica e não estar impedida de exercer suas
            atividades; b) não utilizar os serviços objeto do presente Contrato
            para quaisquer transações ilícitas ou compra ou venda de produtos
            ilícitos; c) ter condições financeiras de arcar com os pagamentos,
            custos e despesas decorrentes do presente Contrato; d) reconhecer
            que o presente Contrato se formaliza, vinculando as partes, com a
            sua assinatura pela CONTRATANTE; e) que leu e está ciente e de pleno
            acordo com todos os termos e condições do presente Contrato, razão
            pela qual o aceita de livre e espontânea vontade.
          </Typography>
          <Typography className={classes.description}>
            9.9. O presente contrato firmado entre as partes revoga qualquer
            acordo anterior, verbal ou escrito, tendo como o mesmo objeto, que
            porventura tenha existido anteriormente entre as partes.
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            CLÁUSULA DÉCIMA - DO FORO:
          </Typography>
          <Typography className={classes.description}>
            10.1 Fica eleito o foro da cidade de João Pessoa - PB, com renúncia
            expressa de qualquer outro, por mais privilegiado que seja, para
            dirimir quaisquer dúvidas ou questões decorrentes do presente
            contrato.
          </Typography>
          <Typography className={classes.description}>
            10.2 As partes declaram estar plenamente cientes e esclarecidas dos
            termos e condições do contrato e seus anexos, assinando o presente
            em duas vias de igual teor.
          </Typography>
          <br></br>
          <Typography className={classes.description}>
            João Pessoa - PB, 9 de agosto de 2022.
          </Typography>
        </DialogContentText>
        <ShowIf condition={user?.accepted_terms === false}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  id="accept_terms"
                  name="accept_terms"
                  color="primary"
                  checked={formik.values.accept_terms}
                  value={formik.values.accept_terms}
                  onChange={formik.handleChange}
                />
              }
              label="Eu li e concordo com os termos de uso"
            />
          </FormGroup>
        </ShowIf>
      </DialogContent>
      <DialogActions>
        <ShowIf condition={user?.accepted_terms === false}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Button onClick={handleDeclined}>Recusar</Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.values.accept_terms || formik.isSubmitting}
            >
              Aceitar
            </Button>
          </form>
        </ShowIf>
        <ShowIf condition={user?.accepted_terms === true}>
          <Button onClick={handleClose}>Fechar</Button>
        </ShowIf>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptTermsRequest;

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    title: {
      //fontSize: "2rem",
      fontFamily: "Nunito Sans",
      color: "#526272",
      fontWeight: "bold",
      textAlign: "justify",
    },
    subtitle: {
      fontSize: "1rem",
      fontFamily: "Nunito Sans",
      color: "#526272",
      fontWeight: "bolder",
      textAlign: "justify",
    },
    description: {
      fontFamily: "Nunito Sans",
      color: "#526272",
      marginBottom: ".5rem",
      textAlign: "justify",
    },
  },
}));
