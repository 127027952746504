import { Color } from "@material-ui/lab/Alert";
import { FinancialEntryStatus } from "../../services/types/customerFinance";

export const mapFinanceStatusToSeverity = (value: FinancialEntryStatus): Color => {
    switch (value) {
        case FinancialEntryStatus.PAID:
            return "success";
        case FinancialEntryStatus.PROCESSING:
            return "warning";
        case FinancialEntryStatus.CANCELED:
            return "error";
        default:
            return "info";
    }
};