import React, { ReactNode, useState } from "react";
import {
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Divider,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccessTime, CompareArrowsOutlined } from "@material-ui/icons";
import theme from "../../../styles/theme";
import { pink } from "@material-ui/core/colors";
import { format, isPast } from "date-fns";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { DeliveryRequestStatus, IntegrationSource, PendingDeliveryRequest } from "../../../services/types/deliveryRequest";
import ShowIf from "../../../components/ShowIf";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import HighlightText from "../../../components/HighlightText";
import { useTranslation } from "react-i18next";

export enum StatusSeverity {
  WARN,
  INFO,
  SUCCESS,
}

export interface CardStatusChip {
  label: string;
  severity: StatusSeverity;
}

interface IDeliveryRequestCardProps {
  deliveryRequest: PendingDeliveryRequest;
  statusChip: CardStatusChip;
  highlight: string;
  action?: (deliveryRequest: PendingDeliveryRequest) => {};
  showLoading?: boolean;
  children?: ReactNode;
}

export const DeliveryRequestCard: React.FC<IDeliveryRequestCardProps> = ({
  deliveryRequest,
  statusChip,
  highlight,
  action,
  showLoading = false,
  children
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = React.useRef(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const anchorReturnRef = React.useRef(null);
  const [arrowReturnRef, setArrowReturnRef] = React.useState<HTMLElement | null>(null);
  const [openPopoverReturn, setOpenPopoverReturn] = useState<boolean>(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const determineCardClassName = (): string => {
    let className = classes.card;

    /*
      If delayed should show delayed even if "status === NO_COURIER"
    */
    if (isPast(deliveryRequest.estimated_delivery_time)) {
      className = classes.delayedCard;
    }

    return className;
  };


  const getChipClassName = (statusSeverity: StatusSeverity): string => {
    let result;
    switch (statusSeverity) {
      case StatusSeverity.WARN:
        result = classes.warnChip;
        break;
      case StatusSeverity.INFO:
        result = classes.infoChip;
        break;
      default:
        result = classes.statusChip;
        break;
    }
    return result;
  }

  const getIntegrationIcon = (integration_source: IntegrationSource | null): React.ReactNode => {
    let result = <img src="/icons/speedy.png" title="Speedy" alt="Speedy" />;

    if (integration_source) {
      switch (integration_source) {
        case IntegrationSource.IFOOD:
          result = <img src="/icons/ifood.png" title="iFood" alt="iFood" />
          break;
        case IntegrationSource.MEU_XODO:
          result = <img src="/icons/meu_xodo.jpg" title="Meu Xodó" alt="Meu Xodó" />
          break;
        case IntegrationSource.DELIVERY_DIRETO:
          result = <img src="/icons/delivery_direto.png" title="Delivery Direto" alt="Delivery Direto" />
          break;
        case IntegrationSource.NEEMO:
          result = <img src="/icons/neemo.png" title="Neemo" alt="Neemo" />
          break;   
        default:
          break;
      }
    }

    return result;
  }

  const handlePopoverReturnOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpenPopoverReturn(true);
  };

  const handlePopoverReturnClose = () => {
    setOpenPopoverReturn(false);
  };

  return (
    <Box mb={1}>
      <Card variant="outlined" className={determineCardClassName()}>
        <Backdrop className={classes.cardBackdrop} open={showLoading}>
          <CircularProgress size="2rem" />
        </Backdrop>
        <CardContent className={classes.content}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => {
              if (action) {
                action(deliveryRequest);
              }
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm container spacing={0} justify="space-between">
                <Grid item>
                </Grid>
                <Grid item>
                  <Chip
                    size="small"
                    label={statusChip.label}
                    className={getChipClassName(statusChip.severity)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
          <Divider></Divider>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => {
              if (action) {
                action(deliveryRequest);
              }
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  placement="top"
                  className={classes.popper}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: "window",
                    },
                    arrow: {
                      enabled: true,
                      element: arrowRef,
                    },
                  }}
                >
                  <Paper elevation={5}>
                    <span className={classes.arrow} ref={setArrowRef} />
                    <Box className={classes.popoverContent}>
                      <Typography variant="body2" noWrap>
                        {deliveryRequest.requested_time && `Hora da Solicitação: ${format(deliveryRequest.requested_time, CURRENT_DATE_TIME_FORMAT)}`}
                      </Typography>
                      <Typography variant="body2" noWrap>
                        {deliveryRequest.is_scheduled ? "Agendado para: " : "Previsão de Entrega: "}
                        {format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)}
                      </Typography>
                    </Box>
                  </Paper>
                </Popper>
                <Popper
                  open={openPopoverReturn}
                  anchorEl={anchorReturnRef.current}
                  placement="top"
                  className={classes.popper}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: "window",
                    },
                    arrow: {
                      enabled: true,
                      element: arrowReturnRef,
                    },
                  }}
                >
                  <Paper elevation={5}>
                    <span className={classes.arrow} ref={setArrowReturnRef} />
                    <Box className={classes.popoverContent}>
                      <Typography variant="body2" noWrap>
                        {t("delivery_request_return_type." + deliveryRequest.return_type)}
                      </Typography>
                    </Box>
                  </Paper>
                </Popper>
                <Grid container spacing={0}>
                  <Grid item xs={12} container spacing={0} justify="space-between"
                    ref={anchorRef}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <Grid item zeroMinWidth>
                      <Typography variant="body1" className={classes.order} style={{ alignItems: "center", display: "flex" }} noWrap>
                        <HighlightText
                          text={`<strong>#${deliveryRequest.order_number}</strong> ${deliveryRequest.integration_instance ? deliveryRequest.integration_instance.initials + " " : ""}- ${deliveryRequest.destination_address.district}`}
                          words={[highlight]}
                        />
                        {deliveryRequest.return_type && deliveryRequest.return_type !== "none" && (
                        <Grid wrap="nowrap" ref={anchorReturnRef}
                        onMouseEnter={handlePopoverReturnOpen}
                        onMouseLeave={handlePopoverReturnClose} style={{alignItems: "center", display: "flex"}}>
                          <CompareArrowsOutlined style={{marginLeft: "5px"}} fontSize="small" />
                        </Grid>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    wrap="nowrap"
                    spacing={1}
                    ref={anchorRef}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <Grid item>
                      <AccessTime fontSize="small" />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography variant="body2" noWrap>
                        {format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {getIntegrationIcon(deliveryRequest.source)}
                    </Grid>
                  </Grid>

                  <ShowIf condition={deliveryRequest.is_scheduled && (deliveryRequest.status === DeliveryRequestStatus.NEW)}>
                    <Grid
                      item
                      xs={12}
                      container
                      justify="center"
                      wrap="nowrap"
                      spacing={1}
                      ref={anchorRef}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <Grid item xs={12}>
                        <strong>Agendado</strong>
                      </Grid>
                    </Grid>
                  </ShowIf>
                  {![DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status) && (
                    <Grid
                      item
                      xs={12}
                      container
                      wrap="nowrap"
                      spacing={1}
                      title={deliveryRequest.courier_name}
                    >
                      <Grid item>
                        <CustomAvatar
                          size="xs"
                          img={deliveryRequest.courier_photo}
                        />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography variant="body2" noWrap>
                          <HighlightText text={deliveryRequest.courier_name} words={[highlight]} />
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </CardActionArea>
        </CardContent>
        {children}
      </Card>
    </Box>
  );
};

const useStyles = makeStyles({
  order: {
    fontSize: "15px"
  },
  card: {
    position: "relative",
  },
  delayedCard: {
    position: "relative",
    borderTopColor: pink.A400,
    borderTopWidth: theme.shape.borderRadius * 1.5,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  actionArea: {
    padding: theme.spacing(1),
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#384953 !important',
    color: theme.palette.secondary.contrastText,
  },
  infoChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  warnChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff !important',
    border: `1px solid #ff9800 !important`,
    fontWeight: "bold",
    color: "#ff9800",
  },
  popoverContent: {
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: theme.palette.background.paper,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  },
  cardBackdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  paddingItemList: {
    paddingBottom: "3px",
    paddingTop: "3px",
    paddingLeft: "0px"
  }
});
