import React, {
    useCallback,
    useEffect,
    useState,
} from "react";
import { Button, Collapse, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IPageableParams } from "../../services/types/common";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { PendingDeliveryRequest } from "../../services/types/deliveryRequest";
import theme from "../../styles/theme";
import { useHistory } from "react-router-dom";
import useGlobalSearch from "../../hooks/useGlobalSearch";
import PubSub from 'pubsub-js';
import { NEW_SCHEDULED_DELIVERY_REQUEST } from "../../events";
import { format, startOfDay } from "date-fns";
import { DEFAULT_DATE_LOCALE } from "../../i18n";
import { AccessTime } from "@material-ui/icons";
import numbro from "numbro";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { ScheduleDeliveryRequestDialog } from "./ScheduleDeliveryRequest";
import { VisibilityOutlined } from "@material-ui/icons";
import useStoreContext from "../../hooks/useStoreContext";
import { formatPhoneNumber } from "../../utils/formatters";

const DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 500,
    ordering: ["estimated_delivery_time", "district"],
};

const Scheduling: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { globalSearchValue, setGlobalSearchValue, showGlobalSearch, hideGlobalSearch } = useGlobalSearch();
    const { activeStore } = useStoreContext();

    const [deliveryRequestList, setDeliveryRequestList] = useState<PendingDeliveryRequest[]>([]);
    const [filteredDeliveryRequestList, setFilteredDeliveryRequestList] = useState<PendingDeliveryRequest[]>([]);
    const [deliveryRequestMap, setDeliveryRequestMap] = useState<Map<number, PendingDeliveryRequest[]>>(new Map<number, PendingDeliveryRequest[]>());
    const [showScheduleDeliveryRequestDialog, setShowScheduleDeliveryRequestDialog] = React.useState(false);

    const showDeliveryRequestDetails = async (deliveryRequest: PendingDeliveryRequest) => {
        let param =
            typeof deliveryRequest == "number"
                ? deliveryRequest
                : deliveryRequest.id;
        history.push(`/app/requests/detail/${param}`);
    };

    const refreshDeliveryRequests = useCallback(async () => {
        const storeId = activeStore ? activeStore.id : null;

        DeliveryRequestService.getPendingDeliveryRequests(storeId, null, true, new Date(), null, DEFAULT_PAGINATION)
            .then(response => {
                setDeliveryRequestList(response.data);
            })
            .catch(error => { });
    }, [activeStore]);

    useEffect(() => {
        const filtered = deliveryRequestList.filter((delivery) => {
            const searchValue = globalSearchValue.toLocaleLowerCase()
            if (delivery.order_number.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            if (delivery.integration_instance?.name.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            if (delivery.consignee?.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            if (delivery.consignee_phone_number?.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            if (delivery.destination_address?.district.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            return false;
        });
        setFilteredDeliveryRequestList(filtered);
    }, [globalSearchValue, deliveryRequestList]);

    useEffect(() => {
        const _deliveryRequestMap = new Map<number, PendingDeliveryRequest[]>();

        filteredDeliveryRequestList.forEach(deliveryRequest => {
            const dateKey = startOfDay(deliveryRequest.estimated_delivery_time).getTime();
            const deliveryArray = _deliveryRequestMap.get(dateKey);
            if (deliveryArray !== undefined) {
                deliveryArray.push(deliveryRequest);
            } else {
                _deliveryRequestMap.set(dateKey, [deliveryRequest]);
            }
        });
        setDeliveryRequestMap(_deliveryRequestMap);
    }, [filteredDeliveryRequestList]);

    useEffect(() => {
        showGlobalSearch("Filtre por número do pedido, bairro, cliente...");

        return function () {
            hideGlobalSearch();
        };
    }, [showGlobalSearch, hideGlobalSearch]);

    useEffect(() => {
        if (!showScheduleDeliveryRequestDialog) {
            refreshDeliveryRequests();
        }
    }, [showScheduleDeliveryRequestDialog, refreshDeliveryRequests]);

    useEffect(() => {
        refreshDeliveryRequests();

        const interval = setInterval(() => {
            refreshDeliveryRequests();
        }, 10000);

        return function cleanup() {
            setDeliveryRequestList([]);
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [refreshDeliveryRequests]);

    useEffect(() => {
        const onNewDeliveryRequest = PubSub.subscribe(NEW_SCHEDULED_DELIVERY_REQUEST, () => {
            refreshDeliveryRequests();
        });

        return () => PubSub.unsubscribe(onNewDeliveryRequest);
    }, [refreshDeliveryRequests]);

    const openScheduleDeliveryRequestDialog = () => {
        if (activeStore?.id) {
            setShowScheduleDeliveryRequestDialog(true);
        } else {
            enqueueSnackbar(
                "Você só pode Solicitar Entregas após informar a Loja em que está trabalhando.",
                {
                    variant: "error",
                }
            );
        }
    };

    return (
        <React.Fragment>
            <ScheduleDeliveryRequestDialog
                modal={{
                    open: showScheduleDeliveryRequestDialog,
                    setOpen: setShowScheduleDeliveryRequestDialog,
                }}
            />

            <div className={classes.root}>
                <Grid container spacing={2} alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography variant="h1">Agendamentos</Typography>
                    </Grid>
                    <Grid item>
                        <Collapse in={(globalSearchValue !== "")}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Alert
                                        severity="warning"
                                        className={classes.warningFilter}
                                        action={
                                            <Button color="inherit" size="small" onClick={(e) => { setGlobalSearchValue(""); }}>
                                                Limpar Filtro
                                    </Button>
                                        }>
                                        <strong>Aviso:</strong> As informações abaixo estão <strong>filtradas!</strong>
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openScheduleDeliveryRequestDialog}>
                            Agendar Entrega
                        </Button>
                    </Grid>
                </Grid>
                <List className={classes.list} subheader={<li />}>
                    {[...deliveryRequestMap.keys()].map((groupKey) => (
                        <li key={`section-${groupKey}`}>
                            <ul className={classes.ul}>
                                <ListSubheader className={classes.listSection}>
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            <strong>{format(groupKey, "PPPP", { locale: DEFAULT_DATE_LOCALE })}</strong>
                                        </Grid>
                                    </Grid>
                                </ListSubheader>
                                {
                                    deliveryRequestMap.get(groupKey)?.map(delivery => (
                                        <React.Fragment>
                                            <ListItem
                                                key={`item-${delivery.id}`}
                                                className={classes.listItem}
                                            >
                                                <ListItemText>
                                                    <Grid container justify="flex-start" spacing={1}>
                                                        <Grid item style={{ maxWidth: "24px" }}>
                                                            <AccessTime fontSize="small" />
                                                        </Grid>
                                                        <Grid item md={1}>
                                                            <Typography variant="body2" noWrap>
                                                                {format(delivery.estimated_delivery_time, "HH:mm")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <Typography variant="body1" className={classes.order}>
                                                                <strong>#{delivery.order_number}</strong> {delivery.integration_instance ? delivery.integration_instance.initials + " " : ""}- {delivery.destination_address?.district} ({numbro(delivery.distance).format()} Km)
                                                        </Typography>
                                                        </Grid>
                                                        <Grid item md>
                                                            <Typography variant="body1" className={classes.order}>
                                                                {delivery.consignee}
                                                                {(delivery.consignee_phone_number !== null) && (delivery.consignee_phone_number !== "00000000000")
                                                                    ? " - " + formatPhoneNumber(delivery.consignee_phone_number) : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="detalhes" onClick={() => showDeliveryRequestDetails(delivery)}>
                                                        <VisibilityOutlined />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </React.Fragment>
                                    ))
                                }
                            </ul>
                        </li>
                    ))}
                </List>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
        maxHeight: "100%",
    },
    list: {
        flex: 1,
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        border: "1px solid rgba(0, 0, 0, 0.12) !important",
        borderRadius: theme.shape.borderRadius,
        overflow: 'auto',
    },
    listSection: {
        color: "rgba(0, 0, 0, 0.87) !important",
        backgroundColor: '#f5f5f5 !important',
        borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    ul: {
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
    listItem: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    order: {
        fontSize: "15px"
    },
    warningFilter: {
        width: "450px"
    },
});

export default Scheduling;
