import Geocode from "react-geocode";

const key = process.env.REACT_APP_MAPS_KEY;

interface Coordinates {
  readonly latitude: number;
  readonly longitude: number;
}

interface Position {
  readonly coords: Coordinates;
}

export const locationByIP = async () => {
  const getCoords = async () => {
    const pos: Position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return {
      lng: pos.coords.longitude,
      lat: pos.coords.latitude,
    };
  };
  return await getCoords();
};

export const locationByAddress = async (address: string) => {
  try {
    Geocode.setApiKey(key || "");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    Geocode.enableDebug();

    const response = await Geocode.fromAddress(address);
    let location = response.results[0].geometry.location;
    if (response.results.length > 1) {
      const stringSimilarity = require("string-similarity");
      const suggestions: string[] = response.results.map((item: any) => item.formatted_address);
      const matchs = stringSimilarity.findBestMatch(address, suggestions);
      location = response.results[matchs.bestMatchIndex].geometry.location;
    }
    return {
      lat: Number(location.lat),
      lng: Number(location.lng),
    };
  } catch (error) {
    console.error("GEOCODE: ", error);
    return {
      lat: 0,
      lng: 0,
    };
  }
};

export const locationsByAddress = async (address: string) => {
  try {
    Geocode.setApiKey(key || "");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    Geocode.enableDebug(false);

    const response = await Geocode.fromAddress(address);
    const res: any = response.results;
    return res;
  } catch (error) {
    console.error("GEOCODE: ", error);
    return {
      error: null
    };
  }
};

export const locationsByLatLong = async (coords: any) => {
  try {
    Geocode.setApiKey(key || "");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    //Geocode.enableDebug();

    const response = await Geocode.fromLatLng(coords.lat, coords.lng);
    const res: any = response.results;
    return res;
  } catch (error) {
    console.error("GEOCODE: ", error);
    return {
      error: null
    };
  }
};

export default locationByAddress;
