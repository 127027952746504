import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface ThemeOptions {
    [key: string]: any;
  }
}

const palette = {
  primary: {
    main: "#0080FF",
  },
  secondary: {
    main: "#526272",
  },
  background: {
    default: "#f5f5f5",
  },
};

const typography = {
  fontFamily: "Nunito Sans, sans-serif",
  h1: {
    fontWeight: 700,
    fontSize: "24px",
    color: palette.secondary.main,
  },
  h2: {
    fontWeight: 700,
    fontSize: "18px",
    color: palette.secondary.main,
  },
  h3: {
    fontWeight: 700,
    fontSize: "16px",
    color: palette.secondary.main,
  },
  subtitle1: {
    color: palette.secondary.main,
  },
  body1: {
    fontWeight: 400,
    fontSize: "14px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "14px",
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
  },
};

const theme = createMuiTheme({ palette, typography });

export default theme;
