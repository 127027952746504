import React from "react";
import { SvgIcon } from "@material-ui/core";

export const BarcodeIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z" />
    </SvgIcon>
  );
};

export const TransferIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,9L17,5V8H10V10H17V13M7,11L3,15L7,19V16H14V14H7V11Z" />
    </SvgIcon>
  );
};

export const TimerSandIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z" />
    </SvgIcon>
  );
};

export const BullhornIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z" />
    </SvgIcon>
  );
};

export const PixIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M16.5747 15.3911C16.189 15.3925 15.8069 15.3175 15.4504 15.1703C15.0938 15.0232 14.77 14.8069 14.4976 14.5338L11.4973 11.5316C11.3911 11.4301 11.25 11.3735 11.1031 11.3735C10.9563 11.3735 10.8151 11.4301 10.709 11.5316L7.69851 14.5421C7.42625 14.8155 7.10252 15.0323 6.746 15.1799C6.38948 15.3274 6.00725 15.4029 5.62139 15.4019H5.034L8.83591 19.2038C9.40612 19.7728 10.1788 20.0924 10.9843 20.0924C11.7899 20.0924 12.5625 19.7728 13.1327 19.2038L16.9422 15.3937L16.5747 15.3911Z" />
      <path d="M5.62203 4.78127C6.00789 4.78024 6.39013 4.8557 6.74665 5.00329C7.10318 5.15088 7.42691 5.36767 7.69914 5.64114L10.7096 8.65225C10.8143 8.75657 10.956 8.81515 11.1038 8.81515C11.2515 8.81515 11.3932 8.75657 11.4979 8.65225L14.4976 5.65254C14.7696 5.37874 15.0933 5.16168 15.4498 5.01396C15.8064 4.86625 16.1888 4.79081 16.5747 4.79204H16.9359L13.1264 0.982522C12.8443 0.700323 12.5094 0.476464 12.1407 0.323733C11.7721 0.171003 11.377 0.092392 10.978 0.092392C10.579 0.092392 10.1839 0.171003 9.81522 0.323733C9.4466 0.476464 9.11167 0.700323 8.82958 0.982522L5.034 4.78127H5.62203Z" />
      <path d="M20.0871 7.94382L17.785 5.64176C17.7331 5.66298 17.6776 5.67416 17.6216 5.67471H16.5748C16.03 5.67607 15.5079 5.89241 15.1218 6.27668L12.1221 9.27449C11.8519 9.54415 11.4858 9.6956 11.1041 9.6956C10.7224 9.6956 10.3563 9.54415 10.0862 9.27449L7.07505 6.26527C6.68907 5.88077 6.1669 5.6642 5.62208 5.66267H4.33704C4.28408 5.66148 4.23174 5.65097 4.18243 5.63162L1.86579 7.94382C1.29679 8.51403 0.977234 9.28668 0.977234 10.0922C0.977234 10.8978 1.29679 11.6704 1.86579 12.2406L4.17736 14.5522C4.22656 14.5325 4.27897 14.522 4.33197 14.5211H5.62208C6.16688 14.5195 6.68903 14.303 7.07505 13.9185L10.0855 10.908C10.6298 10.3644 11.5784 10.3644 12.1221 10.908L15.1218 13.9071C15.5079 14.2914 16.03 14.5077 16.5748 14.5091H17.6216C17.6777 14.5095 17.7332 14.5207 17.785 14.542L20.0871 12.24C20.3693 11.9579 20.5932 11.623 20.7459 11.2543C20.8986 10.8857 20.9772 10.4906 20.9772 10.0916C20.9772 9.69257 20.8986 9.29746 20.7459 8.92884C20.5932 8.56021 20.3693 8.22528 20.0871 7.94319" />
    </SvgIcon>
  );
};
