import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import PasswordService from "../services/passwordService";

interface IChangeOwnPasswordProps {
  modal: { open: boolean; setOpen(value: boolean): void };
}

const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(6, "Mínimo de 6 caracteres")
    .max(32, "Senha muito longa")
    .required("Campo obrigatório"),
  new_password1: Yup.string()
    .notOneOf(
      [Yup.ref("old_passwrod"), ""],
      "A Nova Senha deve ser diferente da Senha Atual"
    )
    .min(6, "Mínimo de 6 caracteres")
    .max(32, "Senha muito longa")
    .required("Campo obrigatório"),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), ""], "As senhas não conferem")
    .required("Campo obrigatório"),
});

export const ChangeOwnPassword: React.FC<IChangeOwnPasswordProps> = ({
  modal,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword1, setShowNewPassword1] = useState<boolean>(false);
  const [showNewPassword2, setShowNewPassword2] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: { old_password: "", new_password1: "", new_password2: "" },
    onSubmit: async (values) => {
      try {
        await PasswordService.changePassword(
          values.old_password,
          values.new_password1,
          values.new_password2
        );
        handleClose();
        enqueueSnackbar("Senha alterada com sucesso!", { variant: "success" });
      } catch (error) {
        const errorItems = error.response?.data;
        for (var key in errorItems) {
          var value = errorItems[key];
          formik.setFieldError(key, value);
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClose = () => {
    setShowOldPassword(false);
    setShowNewPassword1(false);
    setShowNewPassword2(false);
    formik.resetForm();
    modal.setOpen(false);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword1 = () => {
    setShowNewPassword1(!showNewPassword1);
  };

  const handleClickShowNewPassword2 = () => {
    setShowNewPassword2(!showNewPassword2);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Dialog
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogTitle id="form-dialog-title">Alteração de Senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter
            pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um)
            número.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="old_password"
                label="Senha Atual"
                variant="outlined"
                type={showOldPassword ? "text" : "password"}
                value={formik.values.old_password}
                onChange={formik.handleChange}
                error={!!formik.errors.old_password}
                helperText={formik.errors.old_password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="alterna a visibilidade da senha"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="new_password1"
                label="Nova Senha"
                variant="outlined"
                type={showNewPassword1 ? "text" : "password"}
                value={formik.values.new_password1}
                onChange={formik.handleChange}
                error={!!formik.errors.new_password1}
                helperText={formik.errors.new_password1}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="alterna a visibilidade da senha"
                        onClick={handleClickShowNewPassword1}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="new_password2"
                label="Confirmar Nova Senha"
                variant="outlined"
                type={showNewPassword2 ? "text" : "password"}
                value={formik.values.new_password2}
                onChange={formik.handleChange}
                error={!!formik.errors.new_password2}
                helperText={formik.errors.new_password2}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="alterna a visibilidade da senha"
                        onClick={handleClickShowNewPassword2}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="primary" type="submit" disabled={formik.isSubmitting}>
            Alterar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeOwnPassword;
