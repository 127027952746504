import { parseISO } from "date-fns";
import { isNull, isUndefined } from "lodash";
import numbro from "numbro";

export const convertToBoolean = (
  value: any,
  defaultValue: boolean | null = null
): boolean | null => {
  let result: boolean | null = defaultValue;

  if (value !== null) {
    if (typeof value === "boolean") {
      result = value;
    } else if (typeof value === "string") {
      const strValue = value.trim().toLowerCase();
      if (strValue === "true") {
        result = true;
      } else if (strValue === "false") {
        result = false;
      }
    } else if (typeof value === "number") {
      if (value === 0) {
        result = false;
      } else {
        result = true;
      }
    }
  }

  return result;
};

export const convertToNumber = (
  value: any,
  defaultValue: number | null = null
): number | null => {
  let result: number | null = defaultValue;

  if (!isNull(value) && !isUndefined(value)) {
    if (typeof value === "string" && value.trim() === "") {
      result = defaultValue;
    } else {
      result = Number(value);
      if (isNaN(result)) {
        result = numbro.unformat(value);

        if (result === undefined) {
          result = defaultValue;
        }
      }
    }
  }

  return result;
};

const isValidDate = (value: any): boolean => {
  return value instanceof Date && isFinite(value.getTime());
};

export const convertToDate = (
  value: any,
  defaultValue: Date | null = null
): Date | null => {
  let result: Date | null = defaultValue;

  if (value) {
    if (typeof value === "string") {
      result = parseISO(value);
    } else if (typeof value === "number") {
      result = new Date(value);
    } else if (value instanceof Date) {
      result = value;
    }

    if (!isValidDate(result)) {
      result = defaultValue;
    }
  }

  return result;
};

export const distance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    let radlat1 = (Math.PI * lat1) / 180;
    let radlat2 = (Math.PI * lat2) / 180;
    let theta = lon1 - lon2;
    let radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    if (unit === "m") {
      dist = dist * 1609.344;
    }
    return dist;
  }
};

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export const calcNeSwBounds = (
  coords: Coordinate[]
): { ne: Coordinate; sw: Coordinate } | null => {
  let result: { ne: Coordinate; sw: Coordinate } | null = null;

  if (coords && coords.length) {
    const latitudes: number[] = [];
    const longitudes: number[] = [];
    coords.forEach(coord => {
      latitudes.push(coord.latitude);
      longitudes.push(coord.longitude);
    });

    let latNE = Math.max(...latitudes);
    let lngNE = Math.max(...longitudes);
    let latSW = Math.min(...latitudes);
    let lngSW = Math.min(...longitudes);

    result = {
      ne: { latitude: latNE, longitude: lngNE },
      sw: { latitude: latSW, longitude: lngSW },
    };
  }

  return result;
};

export function isOnlyZeros(value: string | null | undefined): boolean {
  let result = true;
  if (value === null || value === undefined) {
    result = false;     
  } else {
    for(let i = 0; i < value.length; i++) {
      if (value[i] !== '0') {
        result = false;
        break;
      }
    }
  }

  return result;
}

export function getOnlyNumbers(value: string | null | undefined): string {
  return (value !== null && value !== undefined) ? (value.match(/\d+/g) || []).join('') : '';
}

export function clearStreetName(name: string): string {
  let result = name;
  if (name.includes(",")) {
      result = name.split(",")[0];
  }
  if (name.includes(" - ")) {
      result = name.split(" - ")[0];
  }
  return result.trim();
}
