import { formatISO } from "date-fns";
import { apiAxios as axios } from "../utils/axios";
import { getURL, urls } from "../utils/urls";
import { convertToDate, convertToNumber } from "../utils/utils";
import { DateQuantity, DeliveriesPerDayResponse, DeliveriesPerDistrictResponse, StatisticsExtraDetalsResponse } from "./types/statistics";

export interface IStatisticsService {
    getExtraDetailsPanel(id_customer: number, date_after: Date, date_before: Date): Promise<StatisticsExtraDetalsResponse[]>;

    getQuantityDeliveriesPanel(id_customer: number, date_after: Date, date_before: Date): Promise<DeliveriesPerDayResponse[]>;

    getDeliveriesPerDistrictPanel(id_customer: number, date_after: Date, date_before: Date): Promise<DeliveriesPerDistrictResponse[]>;

}

class StatisticsServiceImpl implements IStatisticsService {

    getExtraDetailsPanel(id_customer: number, date_after: Date, date_before: Date): Promise<StatisticsExtraDetalsResponse[]> {
        const url = getURL(`${urls.CUSTOMER_STORES}${id_customer}/extra_details/`, {  
            start_date: formatISO(date_after, { representation: "date" }),
            end_date: formatISO(date_before, { representation: "date" })
        });
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    const result: StatisticsExtraDetalsResponse[] = response.data.map(
                        (item: any) => {
                            return {
                                ...item,
                                average_daily_quantity: convertToNumber(item.average_daily_quantity),
                                average_daily_value: convertToNumber(item.average_daily_value),
                                average_ticket: convertToNumber(item.average_ticket),
                                deliveries_quantity: convertToNumber(item.deliveries_quantity),
                                name: item.name,
                                total_value: convertToNumber(item.total_value)
                            };
                        }
                    );
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    };

    getQuantityDeliveriesPanel(id_customer: number, date_after: Date, date_before: Date): Promise<DeliveriesPerDayResponse[]> {
        const url = getURL(`${urls.CUSTOMER_STORES}${id_customer}/integration_deliveries/`, {  
            start_date: formatISO(date_after, { representation: "date" }),
            end_date: formatISO(date_before, { representation: "date" })
        });
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    const result: DeliveriesPerDayResponse[] = response.data.map(
                        (item: any) => {
                            const dates: DateQuantity[] = item.dates.map((d: any) => {
                                return {
                                    date: convertToDate(d.date),
                                    quantity: convertToNumber(d.quantity)
                                }
                            });
                            return {
                                ...item,
                                dates: dates,
                                name: item.name,
                            };
                        }
                    );
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    };

    getDeliveriesPerDistrictPanel(id_customer: number, date_after: Date, date_before: Date): Promise<DeliveriesPerDistrictResponse[]> {
        const url = getURL(`${urls.CUSTOMER_STORES}${id_customer}/deliveries_per_district/`, {  
            start_date: formatISO(date_after, { representation: "date" }),
            end_date: formatISO(date_before, { representation: "date" })
        });
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    const result: DeliveriesPerDistrictResponse[] = response.data.map(
                        (item: any) => {
                            return {
                                district: item.district_upper,
                                total: convertToNumber(item.total),
                            };
                        }
                    );
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    };

}

const StatisticsService = new StatisticsServiceImpl();

export default StatisticsService;
