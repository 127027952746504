import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

import { ReactComponent as Logo } from "../../assets/img/logo-azul.svg";

interface UnauthenticatedLayoutProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    maxWidth: "400px",
  },
  logo: {
    textAlign: "center",
    paddingBottom: "2rem",
  },
  content: {
    background: theme.palette.background.paper,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    padding: "2rem",
  },
  footer: {
    background: theme.palette.background.paper,
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "1rem",
  },
}));

const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
      <div className={classes.footer}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="body1">
              Todos os direitos reservados
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Versão {process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node,
};

export default UnauthenticatedLayout;
