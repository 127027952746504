import React, { useState, useEffect } from "react";
import cep, { AvailableProviders, CEP } from "cep-promise";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { $enum } from "ts-enum-util";
import { useTranslation } from "react-i18next";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    DeliveryRequestReturnType,
    DeliveryValues,
} from "../services/types/deliveryRequest";
import { locationByAddress } from "../utils/getLocation";
import { PhoneInput, PostalCodeInput } from "./InputMasks";
import { useHistory } from "react-router-dom";
import RegionService from "../services/regionService";
import DeliveryRequestService from "../services/deliveryRequestService";
import * as Yup from "yup";
import { postalCodeMaskRegex, noSpecialCharactersRegex } from "../utils/masks";
import usePlacesAutocomplete, { getDetails, Suggestion } from "use-places-autocomplete";
import { calcNeSwBounds, clearStreetName, getOnlyNumbers, isOnlyZeros } from "../utils/utils";
import ShowIf from "./ShowIf";
import { DirectionsBikeOutlined, MotorcycleOutlined } from "@material-ui/icons";
import numbro from "numbro";
import { LoadingOverlay } from "./LoadingOverlay";
import { CURRENT_DATE_TIME_FORMAT } from "../i18n";
import { VehicleLoadCompartmentType, VehicleType } from "../services/types/courier";
import PubSub from 'pubsub-js';
import { NEW_DELIVERY_REQUEST } from "../events";
import useStoreContext from "../hooks/useStoreContext";
import CloseIcon from '@material-ui/icons/Close';

const validationCreateDeliveryRequest = Yup.object().shape({
    order_number: Yup.string()
        .min(1, 'Mínimo de 1 caracterer')
        .max(8, "Máximo de 8 caracteres excedido")
        .matches(noSpecialCharactersRegex, "Número do pedido inválido")
        .required("Campo obrigatório"),
    consignee: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(
            noSpecialCharactersRegex,
            "O nome não pode ter caracteres especiais"
        )
        .required("Campo obrigatório"),
    return_type: Yup.string().required("Campo obrigatório"),
    postal_code: Yup.string().nullable().optional()
        .matches(postalCodeMaskRegex, "CEP inválido"),
    street: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .required("Campo obrigatório"),
    city: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(
            noSpecialCharactersRegex,
            "Caracteres especiais não são permitidos"
        )
        .required("Campo obrigatório"),
    district: Yup.string()
        .min(3, "Mínimo de 3 caracteres")
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(
            noSpecialCharactersRegex,
            "Caracteres especiais não são permitidos"
        )
        .required("Campo obrigatório"),
    address_number: Yup.string().max(10, "Máximo de 10 caracteres excedido"),
    state: Yup.string()
        .min(2, "Mínimo de 2 caracteres")
        .max(2, "Máximo de 2 caracteres excedido")
        .required("Campo obrigatório"),
    address_complement: Yup.string().max(
        120,
        "Máximo de 120 caracteres excedido"
    ),
    consignee_phone_number: Yup.string()
        .test(
            'isNonZeros',
            'Número de telefone inválido',
            value => value !== null && value !== undefined ? !isOnlyZeros(getOnlyNumbers(value)) : true
        ),
});

const INITIAL_VALUES = {
    vehicle_types: [
        VehicleType.BIKE,
        VehicleType.MOTORCYCLE,
        VehicleType.CAR,
        VehicleType.VAN,
    ],
    vehicle_compartment: [
        VehicleLoadCompartmentType.BAG,
        VehicleLoadCompartmentType.TRUNK,
    ],
    order_number: "",
    consignee: "",
    consignee_phone_number: "",
    return_type: DeliveryRequestReturnType.NONE,
    order_value: 0,
    postal_code: "",
    street: "",
    address_number: "",
    address_complement: "",
    address_reference: "",
    district: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
};

interface ICreateDeliveryRequestProps {
    modal: { open: boolean; setOpen(value: boolean): void };
}

export const CreateDeliveryRequestDialog: React.FC<ICreateDeliveryRequestProps> = ({
    modal,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { activeStore } = useStoreContext();

    const [regionBounds, setRegionBounds] = useState<google.maps.LatLngBounds>();
    const [gMapsSessionToken, setGMapsSessionToken] = React.useState<google.maps.places.AutocompleteSessionToken>();
    const [selectedAddrSuggestion, setSelectedAddrSuggestion] = useState<Suggestion | string | null>(null);
    const [isSearchValues, setIsSearchValues] = useState(false);
    const [isShowLoading, showLoading] = React.useState(false);

    const [cachedCoords, setCachedCoords] = useState<any>(null);
    const [deliveryValues, setDeliveryValues] = useState<DeliveryValues>();
    const [deliveryValuesDate, setDeliveryValuesDate] = useState<Date>();

    const {
        suggestions: { data: addressSuggestions },
        setValue: setAddrSearchValue,
        clearSuggestions: clearAddrSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'br' },
            bounds: regionBounds,
            types: ["address"],
            sessionToken: gMapsSessionToken
        },
        debounce: 300
    });

    useEffect(() => {
        if (modal.open) {
            clearDeliveryValues();
            if (activeStore) {
                RegionService.getRegion(activeStore.region)
                    .then(response => {
                        const bounds = calcNeSwBounds(response.coordinates);
                        if (bounds) {
                            const swPoint = new google.maps.LatLng(bounds.sw.latitude, bounds.sw.longitude);
                            const nePoint = new google.maps.LatLng(bounds.ne.latitude, bounds.ne.longitude);
                            setRegionBounds(new google.maps.LatLngBounds(swPoint, nePoint));
                        }
                    });
            }
        }
    }, [modal.open, activeStore]);

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: async values => {
            try {
                if ((values.postal_code !== null)
                    && (values.postal_code !== undefined)
                    && (values.postal_code.trim().length === 0)) {
                    values.postal_code = "00000000";
                }

                if ((values.consignee_phone_number !== null)
                    && (values.consignee_phone_number !== undefined)
                    && (values.consignee_phone_number.trim().length === 0)) {
                    values.consignee_phone_number = "00000000000";
                }

                let coords = cachedCoords;
                if (coords === undefined) {
                    const fullAddress = {
                        cep: values.postal_code,
                        street: values.street,
                        number: values.address_number,
                        complement: values.address_complement,
                        district: values.district,
                        city: values.city,
                        state: values.state,
                    };

                    coords = await locationByAddress(
                        getFormattedAddress(fullAddress)
                    );
                }

                let addressNumber = "S/N";
                if ((values.address_number !== null)
                    && (values.address_number !== undefined)
                    && (values.address_number.trim().length !== 0)) {
                    addressNumber = values.address_number.trim();
                }

                await DeliveryRequestService.createDeliveryRequest({
                    ...values,
                    latitude: coords.lat,
                    longitude: coords.lng,
                    address_number: addressNumber,
                    desired_vehicles: {
                        vehicle_types: values.vehicle_types,
                        vehicle_compartment: values.vehicle_compartment,
                    },
                    store: activeStore?.id,
                });
                handleClose();
                history.push("/app/dashboard");
                PubSub.publish(NEW_DELIVERY_REQUEST);
                enqueueSnackbar("Nova Entrega cadastrada com sucesso!", {
                    variant: "success",
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)} aria-label="close" size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    )
                });
            } catch (error: any) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errors: string[] = [];
                    for (var key in data) {
                        var value = data[key];
                        if (key in formik.values) {
                            formik.setFieldError(key, value);
                        } else {
                            errors.push(value);
                        }
                    }
                    enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                } else {
                    enqueueSnackbar(
                        "Ocorreu um erro ao cadastrar a Entrega!",
                        {
                            variant: "error",
                        }
                    );
                }
            }
        },
        onReset: values => {
            setSelectedAddrSuggestion(null);
            clearAddrSuggestions();
        },
        validationSchema: validationCreateDeliveryRequest,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleCepChange = (cepRequested: string) => {
        if (cepRequested?.trim()) {
            cep(cepRequested, { providers: ["brasilapi", "viacep"] as AvailableProviders[] })
                .then((cepResponse: CEP) => {
                    setSelectedAddrSuggestion({ description: cepResponse.street } as Suggestion);
                    formik.setFieldError("postal_code", "");
                    formik.setFieldValue("street", clearStreetName(cepResponse.street));
                    formik.setFieldValue("district", cepResponse.neighborhood);
                    formik.setFieldValue("city", cepResponse.city);
                    formik.setFieldValue("state", cepResponse.state);
                })
                .catch((error: any) => {
                    formik.setFieldError("postal_code", "CEP inválido");
                    formik.setFieldValue("street", "");
                    formik.setFieldValue("district", "");
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("state", "");
                });
        }
    };

    const getFormattedAddress = (address: any): string => {
        const addressArray: string[] = [];

        if (address.street && address.street.trim().length) {
            addressArray.push(address.street.trim())
        }
        if (address.number && address.number.trim().length) {
            addressArray.push(address.number.trim());
        }
        if (address.complement && address.complement.trim().length) {
            // Complemento retirado por preenchimento errado pelos usuários e consequente erro de localização do Google
            //addressArray.push(address.complement.trim());
        }
        if (address.district && address.district.trim().length) {
            addressArray.push(address.district.trim());
        }
        if (address.city && address.city.trim().length) {
            addressArray.push(address.city.trim());
        }

        let result = addressArray.join(", ");
        if (address.state && address.state.trim().length) {
            result += ` - ${address.state}`;
        }
        return result + ", Brasil";
    };

    const handleClose = () => {
        formik.resetForm();
        modal.setOpen(false);
    };

    const clearDeliveryValues = () => {
        setCachedCoords(undefined);
        setDeliveryValues(undefined);
        setDeliveryValuesDate(undefined);
    }

    const getDeliveryValues = async () => {
        let fullAddress = {
            cep: formik.values.postal_code,
            street: formik.values.street,
            number: formik.values.address_number,
            complement: formik.values.address_complement,
            district: formik.values.district,
            city: formik.values.city,
            state: formik.values.state,
        };

        try {
            showLoading(true);
            const coords = await locationByAddress(getFormattedAddress(fullAddress));
            if (coords) {
                setCachedCoords(coords);
                if (activeStore) {
                    const deliveryValues = await DeliveryRequestService.getDeliveryValues(activeStore.id,
                        formik.values.return_type, coords.lat, coords.lng);
                    setDeliveryValuesDate(new Date());
                    setDeliveryValues(deliveryValues);
                }
            }
        } finally {
            showLoading(false);
        }
    };

    useEffect(() => {
        if (formik.values.street &&
            formik.values.district &&
            formik.values.city &&
            formik.values.state) {
            setIsSearchValues(true);
        } else {
            setIsSearchValues(false);
        }
    }, [formik.values.street, formik.values.district, formik.values.city, formik.values.state]);

    const handleAddressChange = (address: string) => {
        formik.setFieldValue("street", address);
        if (address && address.length > 3) {
            if (modal.open && (gMapsSessionToken === undefined)) {
                setGMapsSessionToken(new google.maps.places.AutocompleteSessionToken());
            }
            setAddrSearchValue(address);
        }
    };

    const clearAddressFields = () => {
        formik.setFieldValue("postal_code", "");
        formik.setFieldValue("district", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
    }

    const setSelectedPlaceId = (placeId: string) => {
        clearAddressFields();
        getDetails({
            fields: ["address_components"],
            placeId: placeId,
            sessionToken: gMapsSessionToken
        }).then((response) => {
            if ((typeof response !== "string") && response.address_components) {
                response.address_components.forEach(addressComponent => {
                    addressComponent.types.forEach(type => {
                        switch (type) {
                            case "route":
                                formik.setFieldValue("street", addressComponent.long_name);
                                break;
                            case "postal_code":
                                formik.setFieldValue("postal_code", addressComponent.long_name);
                                break;
                            case "administrative_area_level_1":
                                formik.setFieldValue("state", addressComponent.short_name);
                                break;
                            case "administrative_area_level_2":
                                formik.setFieldValue("city", addressComponent.long_name);
                                break;
                            case "sublocality_level_1" || "sublocality":
                                formik.setFieldValue("district", addressComponent.long_name);
                                break;
                        }
                    })
                })
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setGMapsSessionToken(undefined);
        });
    }

    const filterAddrSuggestions = (item: Suggestion): boolean => {
        if (item.distance_meters !== undefined) {
            return item.distance_meters < 50000;
        }
        return true;
    }

    const sortAddrSuggestions = (a: Suggestion, b: Suggestion): number => {
        let result = 0;

        if (a.distance_meters !== undefined && b.distance_meters !== undefined) {
            result = a.distance_meters - b.distance_meters;
        }

        return result;
    }

    return (
        <Dialog
            open={modal.open}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <LoadingOverlay open={isShowLoading} />
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <DialogTitle id="form-dialog-title">
                    Nova Entrega
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="order_number"
                                label="Pedido"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.order_number}
                                error={!!formik.errors.order_number}
                                helperText={formik.errors.order_number}
                                InputLabelProps={{ required: true }}
                                fullWidth
                                inputProps={{ maxLength: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="consignee"
                                label="Consumidor"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.consignee}
                                error={!!formik.errors.consignee}
                                helperText={formik.errors.consignee}
                                InputLabelProps={{ required: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="consignee_phone_number"
                                label="Telefone"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.consignee_phone_number}
                                error={!!formik.errors.consignee_phone_number}
                                helperText={formik.errors.consignee_phone_number}
                                fullWidth
                                InputProps={{
                                    inputComponent: PhoneInput,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="postal_code"
                                label="CEP"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    clearDeliveryValues();
                                }}
                                onBlur={e => handleCepChange(e.target.value)}
                                value={formik.values.postal_code}
                                error={!!formik.errors.postal_code}
                                helperText={formik.errors.postal_code}
                                InputLabelProps={{ required: false }}
                                fullWidth
                                InputProps={{
                                    inputComponent: PostalCodeInput,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                id="street"
                                size="small"
                                value={selectedAddrSuggestion}
                                options={addressSuggestions.filter(filterAddrSuggestions).sort(sortAddrSuggestions)}
                                getOptionLabel={suggestion => suggestion.description}
                                freeSolo={true}
                                clearText="Limpar"
                                autoComplete
                                includeInputInList
                                autoHighlight
                                onChange={(event, newValue, reason) => {
                                    setSelectedAddrSuggestion(newValue);
                                    clearDeliveryValues();
                                    if (["remove-option", "clear"].includes(reason)) {
                                        handleAddressChange("");
                                    }
                                    if ((newValue !== null) && (typeof newValue !== "string")) {
                                        setSelectedPlaceId(newValue.place_id);
                                    }
                                }}
                                inputValue={formik.values.street}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="street"
                                        label="Endereço"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => {
                                            clearDeliveryValues();
                                            handleAddressChange(event.target.value);
                                        }}
                                        error={!!formik.errors.street}
                                        helperText={formik.errors.street}
                                        InputLabelProps={{ required: true }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="address_number"
                                label="Número"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    clearDeliveryValues();
                                }}
                                value={formik.values.address_number}
                                error={!!formik.errors.address_number}
                                helperText={formik.errors.address_number}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="address_complement"
                                label="Complemento"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.address_complement}
                                error={!!formik.errors.address_complement}
                                helperText={formik.errors.address_complement}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                id="address_reference"
                                label="Ponto de Referência"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.address_reference}
                                error={!!formik.errors.address_reference}
                                helperText={formik.errors.address_reference}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="district"
                                label="Bairro"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    clearDeliveryValues();
                                }}
                                value={formik.values.district}
                                error={!!formik.errors.district}
                                helperText={formik.errors.district}
                                InputLabelProps={{ required: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="city"
                                label="Cidade"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    clearDeliveryValues();
                                }}
                                value={formik.values.city}
                                error={!!formik.errors.city}
                                helperText={formik.errors.city}
                                InputLabelProps={{ required: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <TextField
                                id="state"
                                label="UF"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    clearDeliveryValues();
                                }}
                                value={formik.values.state}
                                error={!!formik.errors.state}
                                helperText={formik.errors.state}
                                InputLabelProps={{ required: true }}
                                fullWidth
                            />
                        </Grid>
                        <ShowIf condition={false}>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    error={!!formik.errors.vehicle_types}
                                    fullWidth
                                >
                                    <InputLabel id="vehicle_types-label">
                                        Veículos desejados
                                    </InputLabel>
                                    <Select
                                        labelId="vehicle_types-label"
                                        id="vehicle_types"
                                        name="vehicle_types"
                                        multiple
                                        value={formik.values.vehicle_types}
                                        onChange={formik.handleChange}
                                        input={
                                            <OutlinedInput id="vehicle_types" label="Veículos desejados" />
                                        }
                                        renderValue={selected => (
                                            <div>
                                                {(selected as string[]).map(
                                                    value => (
                                                        <Chip
                                                            className="chip"
                                                            key={value}
                                                            label={t("vehicle_type." + value)}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        )}
                                    >
                                        {$enum(VehicleType).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {t("vehicle_type." + type)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.errors.vehicle_types && (
                                        <FormHelperText>
                                            {formik.errors.vehicle_types}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    error={!!formik.errors.vehicle_compartment}
                                    fullWidth
                                >
                                    <InputLabel id="vehicle_compartment-label">
                                        Compartimentos desejados
                                    </InputLabel>
                                    <Select
                                        labelId="vehicle_compartment-label"
                                        id="vehicle_compartment"
                                        name="vehicle_compartment"
                                        multiple
                                        value={formik.values.vehicle_compartment}
                                        onChange={formik.handleChange}
                                        input={
                                            <OutlinedInput
                                                id="vehicle_compartment"
                                                label="Compartimentos desejados"
                                            />
                                        }
                                        renderValue={selected => (
                                            <div>
                                                {(selected as string[]).map(
                                                    value => (
                                                        <Chip
                                                            className="chip"
                                                            key={value}
                                                            label={t(
                                                                "vehicle_load_compartment_type." +
                                                                value
                                                            )}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        )}
                                    >
                                        {$enum(VehicleLoadCompartmentType).map(
                                            type => (
                                                <MenuItem key={type} value={type}>
                                                    {t(
                                                        "vehicle_load_compartment_type." +
                                                        type
                                                    )}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    {formik.errors.vehicle_compartment && (
                                        <FormHelperText>
                                            {formik.errors.vehicle_compartment}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </ShowIf>
                        <Grid item xs={12} md={3}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                error={!!formik.errors.return_type}
                                fullWidth
                            >
                                <InputLabel id="return_type-label">
                                    Tipo de retorno
                                </InputLabel>
                                <Select
                                    label="Tipo de retorno"
                                    labelId="return_type-label"
                                    id="return_type"
                                    name="return_type"
                                    value={formik.values.return_type}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        clearDeliveryValues();
                                    }}
                                >
                                    {$enum(DeliveryRequestReturnType).map(
                                        type => {
                                            return (
                                                <MenuItem
                                                    key={type}
                                                    value={type}
                                                >
                                                    {t(
                                                        "delivery_request_return_type." +
                                                        type
                                                    )}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                                {formik.errors.return_type && (
                                    <FormHelperText>
                                        {formik.errors.return_type}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button color="primary" disabled={!isSearchValues} onClick={getDeliveryValues}>
                                Consultar Valores
                            </Button>
                        </Grid>
                        <ShowIf condition={deliveryValues !== undefined}>
                            <Grid item xs={12}>
                                <Typography className="margin-typograp">Distância: <b>{deliveryValues ? `${numbro(deliveryValues.distance).format()} Km` : "---"}</b></Typography>
                                <Chip icon={<DirectionsBikeOutlined />} className="margin-chip" variant="outlined"
                                    label={`Bicicleta: ${deliveryValues ? numbro(deliveryValues.prices.BIKE).formatCurrency() : "---"}`} />
                                <Chip icon={<MotorcycleOutlined />} className="margin-chip" variant="outlined"
                                    label={`Moto: ${deliveryValues ? numbro(deliveryValues.prices.MOTORCYCLE).formatCurrency() : "---"}`} />
                                <ShowIf condition={deliveryValues?.incentive !== null}>
                                    <Chip className="margin-chip" variant="outlined"
                                        label={`Incentivo: ${deliveryValues && deliveryValues.incentive ? numbro(deliveryValues.incentive.customer_value).formatCurrency() : "---"}`} />
                                </ShowIf>
                                <Typography className="margin-typograp">{`Consulta realizada em ${deliveryValuesDate ? format(deliveryValuesDate, CURRENT_DATE_TIME_FORMAT) : "---"}`} </Typography>
                            </Grid>
                        </ShowIf>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateDeliveryRequestDialog;
