import React from "react";
import { Box, Grid, TextField, Button, useMediaQuery, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../../i18n";
import { FinancialEntryType } from "../../../services/types/customerFinance";

export interface IFinancialEntryFilterParams {
    createdAtAfter: Date | null;
    createdAtBefore: Date | null;
    type: FinancialEntryType | null;
}

export const INITIAL_FILTER_PARAMS: IFinancialEntryFilterParams = {
    createdAtAfter: null,
    createdAtBefore: null,
    type: null
};

interface IFinancialEntryFilterProps {
    params?: IFinancialEntryFilterParams;
    onFilterChanged: (params: IFinancialEntryFilterParams) => void;
}

const FinancialEntryFilter: React.FC<IFinancialEntryFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    onFilterChanged
}) => {
    const materialTheme = useTheme();
    const smallScreen = useMediaQuery(materialTheme.breakpoints.down("md"));

    const formik = useFormik({
        initialValues: params,
        onSubmit: (values) => {
            onFilterChanged(values);
        },
        onReset: (values) => {
            onFilterChanged(values);
        },
    });

    return (
        <Box mt={1}>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    {smallScreen ? (<>
                        <Grid item xs={12}>
                            <TextField
                                id="createdAtAfter"
                                name="createdAtAfter"
                                label="Data Inicial"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true }}
                                onChange={formik.handleChange}
                                value={formik.values.createdAtAfter}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="createdAtBefore"
                                name="createdAtBefore"
                                label="Data Final"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true }}
                                onChange={formik.handleChange}
                                value={formik.values.createdAtBefore}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                    </>) : (<>
                        <Grid item md={3}>
                            <DatePicker
                                id="createdAtAfter"
                                name="createdAtAfter"
                                onChange={date => formik.setFieldValue("createdAtAfter", date)}
                                selected={formik.values.createdAtAfter}
                                startDate={formik.values.createdAtAfter}
                                endDate={formik.values.createdAtBefore}
                                maxDate={new Date()}
                                locale={DEFAULT_LOCALE}
                                dateFormat={CURRENT_DATE_TIME_FORMAT}
                                timeCaption="Hora"
                                isClearable
                                selectsStart
                                showTimeSelect
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                    label="Data Inicial"
                                    variant="outlined"
                                    size="small"
                                    fullWidth />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <DatePicker
                                id="createdAtBefore"
                                name="createdAtBefore"
                                onChange={date => formik.setFieldValue("createdAtBefore", date)}
                                selected={formik.values.createdAtBefore}
                                startDate={formik.values.createdAtAfter}
                                endDate={formik.values.createdAtBefore}
                                minDate={formik.values.createdAtAfter}
                                maxDate={new Date()}
                                locale={DEFAULT_LOCALE}
                                dateFormat={CURRENT_DATE_TIME_FORMAT}
                                timeCaption="Hora"
                                isClearable
                                selectsEnd
                                showTimeSelect
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                    label="Data Final"
                                    variant="outlined"
                                    size="small"
                                    fullWidth />}
                            />
                        </Grid>
                    </>)}
                    <Grid item md={2} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="type">Tipo do Lançamento</InputLabel>
                            <Select
                                label="Tipo do Lançamento"
                                id="type"
                                name="type"
                                value={formik.values.type}
                                onChange={formik.handleChange}
                            >
                                <MenuItem selected value="">Todos</MenuItem>
                                <MenuItem value="DEBIT">Débito</MenuItem>
                                <MenuItem value="CREDIT">Crédito</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={false}
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" type="reset">
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default FinancialEntryFilter;