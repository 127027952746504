import { Divider, Popover, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SpeedyRankingType } from "../../../services/types/courier";
import theme from "../../../styles/theme";
import { makeStyles } from "@material-ui/core/styles";


interface PopoverRankingProps {
  openPopover: boolean;
  anchorEl: HTMLElement | null;
  speedyRanking?: SpeedyRankingType | undefined;
  newRankingPoints?: number | undefined;
  requestsCount?: number | undefined;
  handlePopoverClose: (value: string) => void;
}

const PopoverRanking: FC<PopoverRankingProps> = ({
  openPopover,
  anchorEl,
  speedyRanking,
  newRankingPoints,
  requestsCount,
  handlePopoverClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography className={classes.cardTitle} variant="h6">
        Ranking
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Nível:{" "}
        <strong>
          {speedyRanking ? t(`speedy_ranking.${speedyRanking}`) : "---"}
        </strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Quantidade de Pontos:{" "}
        <strong>{newRankingPoints ? newRankingPoints : "---"}</strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Quantidade de Entregas:{" "}
        <strong>{requestsCount ? requestsCount : "---"}</strong>
      </Typography>
    </Popover>
  );
};

export default PopoverRanking;


const useStyles = makeStyles({
    paper: {
        padding: theme.spacing(2),
      },
      popover: {
        pointerEvents: "none",
      },
      cardTitle: {
        fontSize: "20px",
        marginBottom: "8px",
        fontWeight: "bold",
      },
  });