import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import PasswordService from '../../services/passwordService';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .max(50, 'Senha muito longa')
    .required('Campo obrigatório'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'As senhas não conferem')
    .required('Campo obrigatório'),
});

const FirstPassword: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { params }: { params: { token: string; uid: string } } = useRouteMatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: { password: '', password_confirmation: '' },
    onSubmit: async (values: {
      password: string;
      password_confirmation: string;
    }) => {
      try {
        await PasswordService.setFirstPassword(params.uid, params.token, values.password);
        enqueueSnackbar("Usuário ativado com sucesso", { variant: 'success' });
        history.push({ pathname: "/" });
      } catch (error) {
        if (error.response.status === 400) {
          if (error.response.data) {
            if (Array.isArray(error.response.data)) {
              const errorMsgs: [] = error.response.data;
              formik.setFieldError("password", errorMsgs.join(". "));
            } else {
              const errorKeys = Object.keys(error.response.data);
              console.warn("errorKeys", errorKeys);
              errorKeys.forEach(key => {
                if (key === "user") {
                  enqueueSnackbar(error.response.data[key], { variant: 'error' });
                  history.push({ pathname: "/" });
                } else if (key === "uid" || key === "token") {
                  enqueueSnackbar("Não foi possível ativar seu Usuário! O link utilizado expirou, repita todo o procedimento novamente.", { variant: 'error' });
                  history.push({ pathname: "/password/forgot" });
                } else if (key === "non_field_errors") {
                  const errorMsg: string[] = error.response.data[key];
                  formik.setFieldError("password", errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
                } else {
                  const errorMsg: string[] = error.response.data[key];
                  formik.setFieldError(key, errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
                }
              });
            }
          }
        } else {
          enqueueSnackbar("Falha ao tentar ativar seu usuário, repita o procedimento em alguns minutos. Se o problema persistir entre em contato com o suporte.", { variant: 'error' });
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Ativação de Usuário</Typography>
          <Alert severity="info">A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.</Alert>
          <Typography variant="subtitle1">
            Digite uma nova senha e em seguida digite novamente para confirmar.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nova senha"
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="alterna a visibilidade da senha"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirmar nova senha"
            id="password_confirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            value={formik.values.password_confirmation}
            error={!!formik.errors.password_confirmation}
            helperText={formik.errors.password_confirmation}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="alterna a visibilidade da senha"
                  onClick={handleClickShowPasswordConfirmation}
                  onMouseDown={handleMouseDownPassword}>
                  {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Ativar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FirstPassword;
