import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { HistoryTimeline } from "./timeline";
import DeliveryRequestService from "../../services/deliveryRequestService";
import {
  DeliveryRequestStatus,
  DeliveryRequest,
} from "../../services/types/deliveryRequest";

import { LoadingOverlay } from "../../components/LoadingOverlay";

import { MapDetail } from "./mapDetail";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </div>
  );
}

interface IParams {
  requestId: string;
}

const RequestsDetail: React.FC = () => {
  const { requestId } = useParams<IParams>();
  const history = useHistory();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [openLoadingOverlay, showLoadingOverlay] = React.useState(false);
  const [deliveryRequest, setDeliveryRequest] = React.useState<DeliveryRequest>({} as DeliveryRequest);
  const [deliveryRequestLogs, setDeliveryRequestLogs] = React.useState<any[]>([]);


  const goToTrip = useCallback((idTrip: number) => {
    if (idTrip) {
      history.push(`/app/trip/${idTrip}`);
    }
  }, [history]);

  const loadData = useCallback((deliveryRequestId: number, goTrip: boolean) => {
    return Promise.all([
      DeliveryRequestService.getDeliveryRequestById(deliveryRequestId)
        .then((deliveryRequest) => {
          setDeliveryRequest(deliveryRequest);
          if (goTrip && deliveryRequest.trip_id) {
            goToTrip(deliveryRequest.trip_id);
          }
        })
        .catch((error) => { }),

      DeliveryRequestService.getLogsByDeliveryRequestId(deliveryRequestId)
        .then((logs) => {
          setDeliveryRequestLogs(logs);
        })
        .catch((error) => { })
    ]);
  }, [goToTrip]);

  const refresh = useCallback(() => {
    showLoadingOverlay(true);
    loadData(Number(requestId), false)
      .finally(() => {
        showLoadingOverlay(false);
      });
  }, [requestId, loadData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (deliveryRequest) {
        if (![DeliveryRequestStatus.FINISHED, DeliveryRequestStatus.CANCELED].includes(deliveryRequest.status)) {
          loadData(Number(requestId), false);
        }
      }
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [requestId, deliveryRequest, loadData]);

  useEffect(() => {
    refresh();
  }, [refresh]);



  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const loadRefresh = (requestId: number, goTrip: boolean) => {
    showLoadingOverlay(true);
    loadData(Number(requestId), goTrip)
      .finally(() => {
        showLoadingOverlay(false);
      });
  };

  const changeLoading = (loading: boolean) => {
    if (loading) {
      showLoadingOverlay(loading);
    }
  };


  return (
    <React.Fragment>
      <LoadingOverlay open={openLoadingOverlay} />

      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h1">Detalhes da Solicitação</Typography>
        </Grid>
      </Grid>
      <Box my={1}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          className="bordered-tab"
        >
          <Tab label="Informações" />
          <Tab label="Linha do Tempo" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <MapDetail requestId={Number(requestId)} deliveryRequest={deliveryRequest} loadData={loadRefresh} changeLoading={changeLoading} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <HistoryTimeline data={deliveryRequestLogs} />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};


export default RequestsDetail;
