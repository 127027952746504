import React from "react";
import { Button, Card, Grid, Icon, Typography } from "@material-ui/core";
//import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ReactPlayer from 'react-player'


const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  export enum EnumTutorials  {
    NOVA_ENTREGA = "NOVA_ENTREGA",
    CONSULTAR_SOLICITACOES = "CONSULTAR_SOLICITACOES",
    JUNTAR_ENTREGAS = "JUNTAR_ENTREGAS",
    LANCAMENTOS = "LANCAMENTOS",
    RECARGA = "RECARGA",
    AGENDAMENTO = "AGENDAMENTO"
  }

const Tutorials: React.FC = () => {
    const [expanded, setExpanded] = React.useState<EnumTutorials | false>(EnumTutorials.NOVA_ENTREGA);
    const [urlTutorial, setUrlTutorial] = React.useState("https://www.youtube.com/watch?v=yrl3ryq1TbQ&ab_channel=AcesseTecnologia");

    const handleChange = (panel: EnumTutorials) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      switch(panel) {
        case (EnumTutorials.NOVA_ENTREGA):
          setUrlTutorial("https://www.youtube.com/watch?v=yrl3ryq1TbQ&ab_channel=AcesseTecnologia");
          break;
        case (EnumTutorials.CONSULTAR_SOLICITACOES):
          setUrlTutorial("https://www.youtube.com/watch?v=MXpECYXRPM0");
          break
        case (EnumTutorials.JUNTAR_ENTREGAS):
          setUrlTutorial("https://www.youtube.com/watch?v=TlTBe3l_EJs&ab_channel=AcesseTecnologia");
          break
        case (EnumTutorials.LANCAMENTOS):
          setUrlTutorial("https://www.youtube.com/watch?v=r88JXi46uyk&ab_channel=AcesseTecnologia");
          break
        case (EnumTutorials.RECARGA):
          setUrlTutorial("https://www.youtube.com/watch?v=YKbXd5vw7r0&ab_channel=AcesseTecnologia");
          break
        case (EnumTutorials.AGENDAMENTO):
          setUrlTutorial("https://www.youtube.com/watch?v=HTHEPn6K5T8&ab_channel=AcesseTecnologia");
          break
        default:
          setUrlTutorial("https://www.youtube.com/watch?v=yrl3ryq1TbQ&ab_channel=AcesseTecnologia");
          break
      };
      setExpanded(newExpanded ? panel : false);
    };

    const downloadD = (nameFile: string) => {
      window.open(`/documents/${nameFile}`, '_blank');

      //Download
      /* fetch(`/documents/${nameFile}`).then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = nameFile;
            alink.click();
        })
      }); */
    }

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Ajuda</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item md={9} sm={8} xs={12}>
                  <Card>
                    <ReactPlayer 
                      controls={true}
                      key={urlTutorial}
                      url={
                        urlTutorial
                      } 
                      width='100%'
                      height='600px'
                    />
                  </Card>
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                  <Accordion square expanded={expanded === EnumTutorials.NOVA_ENTREGA} onChange={handleChange(EnumTutorials.NOVA_ENTREGA)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography>Nova Entrega</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                            Passo a passo para realizar uma Nova Entrega.
                        </Typography>
                      </AccordionDetails>
                      {/* <Button
                        onClick={()=>downloadD("Nova entrega.pdf")}
                        variant="text"
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
                  <Accordion square expanded={expanded === EnumTutorials.JUNTAR_ENTREGAS} onChange={handleChange(EnumTutorials.JUNTAR_ENTREGAS)}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography>Juntar/Separar Entregas</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                          Passo a passo para Juntar/Separar Entregas.
                      </Typography>
                      </AccordionDetails>
                      {/* <Button
                        variant="text"
                        onClick={()=>downloadD("Juntar entrega.pdf")}
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
                  <Accordion square expanded={expanded === EnumTutorials.CONSULTAR_SOLICITACOES} onChange={handleChange(EnumTutorials.CONSULTAR_SOLICITACOES)}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography>Consultar Solicitações</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                          Passo a passo para Consultar Solicitações.
                      </Typography>
                      </AccordionDetails>
                      {/* <Button
                        variant="text"
                        onClick={()=>downloadD("Consultar Solicitações Informar Entrega.pdf")}
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
                  <Accordion square expanded={expanded === EnumTutorials.LANCAMENTOS} onChange={handleChange(EnumTutorials.LANCAMENTOS)}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography>Lançamentos/Notas de Débito</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                          Passo a passo para Lançamentos/Notas de Débito.
                      </Typography>
                      </AccordionDetails>
                      {/* <Button
                        variant="text"
                        onClick={()=>downloadD("Lançamentos.pdf")}
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
                  <Accordion square expanded={expanded === EnumTutorials.RECARGA} onChange={handleChange(EnumTutorials.RECARGA)}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography>Recarga</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                          Passo a passo para realizar a Recarga.
                      </Typography>
                      </AccordionDetails>
                      {/* <Button
                        variant="text"
                        onClick={()=>downloadD("Recarga.pdf")}
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
                  <Accordion square expanded={expanded === EnumTutorials.AGENDAMENTO} onChange={handleChange(EnumTutorials.AGENDAMENTO)}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography>Agendamentos</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography>
                          Passo a passo para criar Agendamentos.
                      </Typography>
                      </AccordionDetails>
                      {/* <Button
                        variant="text"
                        onClick={()=>downloadD("Agendamentos.pdf")}
                        color="primary"
                        style={{margin: "0px 15px 0px 15px"}}
                        endIcon={<Icon>download</Icon>}
                      >
                        Baixar Instruções
                      </Button> */}
                  </Accordion>
              </Grid>
            </Grid>

        </div>
    );
};
export default Tutorials;

