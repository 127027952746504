import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface LoadingOverlayProps {
    open: boolean;
}

export function LoadingOverlay(props: LoadingOverlayProps) {
    const { open } = props;
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress />
        </Backdrop>
    );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    }
  }),
);
