import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import PasswordService from '../../services/passwordService';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const validationSchema = Yup.object().shape({
  new_password1: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .max(50, 'Senha muito longa')
    .required('Campo obrigatório'),
  new_password2: Yup.string()
    .oneOf([Yup.ref('new_password1'), ''], 'As senhas não conferem')
    .required('Campo obrigatório'),
});

const PasswordReset: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { params }: { params: { token: string; uid: string } } = useRouteMatch();

  const [showNewPassword1, setShowNewPassword1] = useState<boolean>(false);
  const [showNewPassword2, setShowNewPassword2] = useState<boolean>(false);

  const handleClickShowNewPassword1 = () => {
    setShowNewPassword1(!showNewPassword1);
  };

  const handleClickShowNewPassword2 = () => {
    setShowNewPassword2(!showNewPassword2);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: { new_password1: '', new_password2: '' },
    onSubmit: async (values: {
      new_password1: string;
      new_password2: string;
    }) => {
      try {
        await PasswordService.resetPassword(params.uid, params.token, values.new_password1, values.new_password2);
        enqueueSnackbar("Senha alterada com sucesso", { variant: 'success' });
        history.push({ pathname: "/" });
      } catch (error) {
        if (error.response.data) {
          const errorKeys = Object.keys(error.response.data);
          errorKeys.forEach(key => {
            if (key === "uid" || key === "token") {
              enqueueSnackbar("Não foi possível ativar seu Usuário! O link utilizado expirou, repita todo o procedimento novamente.", { variant: 'error' });
              history.push({ pathname: "/password/forgot" });
            } else if (key === "non_field_errors") {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError("new_password1", errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            } else {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError(key, errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            }
          });
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Definir uma nova senha</Typography>
          <Alert severity="info">A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.</Alert>
          <Typography variant="subtitle1">
            Digite uma nova senha e em seguida digite novamente para confirmar.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nova senha"
            id="new_password1"
            type={showNewPassword1 ? 'text' : 'password'}
            value={formik.values.new_password1}
            error={!!formik.errors.new_password1}
            helperText={formik.errors.new_password1}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="alterna a visibilidade da senha"
                  onClick={handleClickShowNewPassword1}
                  onMouseDown={handleMouseDownPassword}>
                  {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirmar nova senha"
            id="new_password2"
            type={showNewPassword2 ? 'text' : 'password'}
            value={formik.values.new_password2}
            error={!!formik.errors.new_password2}
            helperText={formik.errors.new_password2}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="alterna a visibilidade da senha"
                  onClick={handleClickShowNewPassword2}
                  onMouseDown={handleMouseDownPassword}>
                  {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordReset;
