import React from "react";
import { useFormik } from "formik";
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@material-ui/core";
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";
import { endOfMonth, format, startOfMonth, subDays } from "date-fns";
import { endOfDay } from "date-fns/esm";


export interface IStatisticsFilterParams {
    date_after: Date,
    date_before: Date,
}
const defaultDays = 15;
var today = new Date();
//var lastDay = new Date(today.getFullYear(), today.getMonth(), 1); last day month
var defaultDaySearch = new Date(today.setDate(today.getDate() - defaultDays)); //default 15 days before

export const INITIAL_FILTER_PARAMS: IStatisticsFilterParams = {
    date_after: defaultDaySearch,
    date_before: new Date(),
};

interface IStatisticsFilterProps {
    params?: IStatisticsFilterParams;
    onFilterChanged: (params: IStatisticsFilterParams) => void;
}


export const StatisticsFilter: React.FC<IStatisticsFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    onFilterChanged,
}) => {

    const [tableTypeList] = React.useState([
        { label: "Últimos 7 dias", value: 7 },
        { label: "Últimos 15 dias", value: 15 },
        { label: "Selecione o Mês e Ano", value: 999 },
    ]);

    const [tableType, setTableType] = React.useState<number>(() => tableTypeList[1].value);
    const [changeFilter, setChangeFilter] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(changeFilter){     
            const today = new Date();
            if(tableType !== 999){
                formik.setFieldValue("date_after", subDays(today, tableType -1));
                formik.setFieldValue("date_before", endOfDay(today));
                formik.submitForm();
            }else{
                formik.setFieldValue("date_after", startOfMonth(today));
                formik.setFieldValue("date_before", endOfDay(today));
                formik.submitForm();
            }     
        }    
    }, [tableType]);

    const formik = useFormik({
        initialValues: params,
        onSubmit: values => {
            onFilterChanged(values);
        },
        onReset: values => {
            onFilterChanged(values);
            formik.setFieldValue("customerId", "");
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item xl={12} md={12}>
                        <Grid item style={{display: 'flex', alignItems: 'center'}}>
                            <Grid item xl={2} lg={3} md={4} sm={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    >
                                    <InputLabel id="table-range-label">
                                        Filtro
                                    </InputLabel>
                                    <Select
                                    labelId="table-range-label"
                                    id="table-range"
                                    value={tableType}
                                    onChange={(e) => {setChangeFilter(true); setTableType(e.target.value as number)}}
                                    disableUnderline
                                    input={
                                        <OutlinedInput id="table-range" label="Filtro" />
                                    }
                                    >
                                    {tableTypeList.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            {
                                tableType === 999 ? (
                                <React.Fragment>
                                    <Grid item xl={2} lg={3} md={4} sm={12}>
                                    <Typography style={{ marginLeft: 20 }}>                                    
                                    <DatePicker
                                    id="date_after"
                                    name="date_after"                                  
                                    onChange={(value: Date) => {
                                        const startDate = startOfMonth(value);
                                        let endDate = endOfMonth(value);
                                        const today = new Date();
                                        if (endDate.getTime() > today.getTime()) {
                                            endDate = endOfDay(today);
                                        }
                                        formik.setFieldValue("date_after", startDate);
                                        formik.setFieldValue("date_before", endDate);
                                        formik.submitForm();
                                    }}
                                    maxDate={new Date()}
                                    selected={formik.values.date_after}
                                    startDate={formik.values.date_after}
                                    endDate={formik.values.date_before}
                                    selectsStart
                                    locale={DEFAULT_LOCALE}
                                    todayButton="Mês e Ano"           
                                    autoComplete="off"
                                    wrapperClassName="MuiFormControl-fullWidth"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    customInput={<TextField
                                        label="Mês e Ano"
                                        variant="outlined"
                                        size="small"
                                        error={!!formik.errors.date_after}
                                        helperText={formik.errors.date_after}
                                        fullWidth
                                         />}
                                    />
                                    </Typography>
                                    </Grid>
                                    
                                </React.Fragment>
                                ) : (
                                <Typography style={{ fontSize: 12, marginLeft: 20 }}>
                                    {`${format(subDays(new Date(), tableType - 1), CURRENT_DATE_FORMAT)} - ${format(new Date(), CURRENT_DATE_FORMAT)}`}
                                </Typography>
                                )
                            }
                        </Grid>
                    
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
