import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ImgLoader } from "../../components/ImgLoader";
import DeliveryRequestService from "../../services/deliveryRequestService";
import StoreService from "../../services/storeService";
import * as Yup from "yup";
import { DeliveryRequest } from "../../services/types/deliveryRequest";
import { useHistory } from "react-router-dom";
import useStoreContext from "../../hooks/useStoreContext";
import { QueueCourier } from "../../services/types/courier";

const validationResendDeliveryRequest = Yup.object().shape({
    current_courier: Yup.number()
        .when("auto_search", {
            is: true,
            then: Yup.number().nullable().optional(),
            otherwise: Yup.number().nullable().required("Campo obrigatório"),
        }),
});

interface FormData {
    auto_search: boolean;
    current_courier: number | null;
}

const INITIAL_VALUES: FormData = {
    auto_search: true,
    current_courier: null
};

interface ResendDeliveryRequestProps {
    modal: { open: boolean; setOpen(value: boolean): void };
    deliveryRequest: DeliveryRequest;
}

export const ResendDeliveryRequestDialog: React.FC<ResendDeliveryRequestProps> = ({
    modal, deliveryRequest
}) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { activeStore } = useStoreContext();

    const [listCouriers, setListCouriers] = useState<QueueCourier[]>([]);
    const [selectedCourier, setSelectedCourier] = useState<QueueCourier | null>(null);

    useEffect(() => {
        if (modal.open && activeStore) {
            StoreService.getStoreCourierQueue(activeStore.id)
                .then(response => {
                    setListCouriers(response);
                })
                .catch(error => console.log(error));
        }
    }, [modal.open, activeStore]);

    useEffect(() => {
        if (deliveryRequest) {
            if (deliveryRequest.current_courier && listCouriers.length > 0) {
                const currentCurrier = listCouriers.filter((courier) => {
                    return (courier.id === deliveryRequest.current_courier.id);
                });
                if (currentCurrier && currentCurrier[0]) {
                    setSelectedCourier(currentCurrier[0]);
                    INITIAL_VALUES.current_courier = currentCurrier[0].id;
                } else {
                    setSelectedCourier(null);
                    INITIAL_VALUES.current_courier = null;
                }
            }
        }
    }, [deliveryRequest, listCouriers]);

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: async values => {
            try {
                const courierId = values.auto_search ? null : values.current_courier;
                await DeliveryRequestService.resendDeliveryRequest(deliveryRequest.id, courierId);
                handleClose();
                enqueueSnackbar("Solicitação reenviada com sucesso!", {
                    variant: "success",
                });
                history.push("/app/dashboard");
            } catch (error) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errors: string[] = [];
                    for (var key in data) {
                        var value = data[key];
                        if (key in formik.values) {
                            formik.setFieldError(key, value);
                        } else {
                            errors.push(value);
                        }
                    }
                    enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                } else {
                    enqueueSnackbar("Ocorreu um erro ao reenviar a solicitação!", {
                        variant: "error",
                    });
                }
            }
        },
        validationSchema: validationResendDeliveryRequest,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleClose = () => {
        formik.resetForm();
        modal.setOpen(false);
    };

    return (
        <Dialog
            open={modal.open}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <DialogTitle id="form-dialog-title">
                    Reenviar Solicitação de Entrega
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                error={!!formik.errors.auto_search}
                                fullWidth
                            >
                                <InputLabel id="auto_search-label">
                                    Busca de Entregador
                                </InputLabel>
                                <Select
                                    label="Busca de Entregador"
                                    labelId="auto_search-label"
                                    id="auto_search"
                                    name="auto_search"
                                    value={formik.values.auto_search ? "true" : "false"}
                                    onChange={(e) => {
                                        if (e.target.value === "true") {
                                            formik.setFieldValue("auto_search", true);
                                        } else {
                                            formik.setFieldValue("auto_search", false);
                                        }
                                    }}
                                >
                                    <MenuItem value="true">
                                        Automática
                                    </MenuItem>
                                    <MenuItem value="false">
                                        Direcionada
                                    </MenuItem>
                                </Select>
                                {formik.errors.auto_search && (
                                    <FormHelperText>
                                        {formik.errors.auto_search}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        {!formik.values.auto_search && (
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="current_courier"
                                    size="small"
                                    value={selectedCourier}
                                    options={listCouriers}
                                    getOptionLabel={courier => `${courier.name}`}
                                    loadingText="Aguarde ..."
                                    noOptionsText="Sem opções"
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setSelectedCourier(newValue);
                                            formik.setFieldValue("current_courier", newValue.id);
                                        } else {
                                            setSelectedCourier(null);
                                            formik.setFieldValue("current_courier", null);
                                        }
                                    }}
                                    renderOption={courier => (
                                        <React.Fragment>
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <ImgLoader
                                                        src={courier.picture}
                                                        width={40}
                                                        height={40}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {`${courier.name}`}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Entregador"
                                            error={!!formik.errors.current_courier}
                                            helperText={formik.errors.current_courier}
                                            InputLabelProps={{ required: true }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        Reenviar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ResendDeliveryRequestDialog;
