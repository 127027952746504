import React, { Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import UnauthenticatedLayout from "./layouts/UnauthenticatedLayout";
import Dashboard from "./pages/Dashboard";
import Scheduling from "./pages/Scheduling";
import FirstPassword from "./pages/FirstPassword";
import Login from "./pages/Login";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";
import Requests from "./pages/Requests";
import RequestsDetail from "./pages/Requests/detail";
import FinancesRecharge from "./pages/Finances/Recharge";
import FinancesReleases from "./pages/Finances/Releases";
import RequestsTrip from "./pages/Requests/detailTrip";
import DebitNotes from "./pages/Finances/DebitNotes";
import Fixed from "./pages/Fixed";
import Statistics from "./pages/Statistics";
import Tutorials from "./pages/Tutorials";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Redirect path="*" to="/app" />
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: "/login",
    guard: GuestGuard,
    layout: UnauthenticatedLayout,
    component: Login,
  },
  {
    path: "/password",
    guard: GuestGuard,
    layout: UnauthenticatedLayout,
    routes: [
      {
        exact: true,
        path: "/password/forgot",
        component: PasswordForgot,
      },
      {
        exact: true,
        path: "/password/reset/:uid/:token",
        component: PasswordReset,
      },
      {
        exact: true,
        path: "/password/activate/:uid/:token",
        component: FirstPassword,
      },
    ],
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    routes: [
      {
        exact: true,
        path: "/app/dashboard",
        component: Dashboard,
      },
      {
        exact: true,
        path: "/app/scheduling/",
        component: Scheduling,
      }, 
      {
        exact: true,
        path: "/app/fixed/",
        component: Fixed,
      },
      {
        exact: true,
        path: "/app/statistics/",
        component: Statistics,
      },
      {
        exact: true,
        path: "/app/requests",
        component: Requests,
      },
      {
        exact: true,
        path: "/app/requests/detail/:requestId",
        component: RequestsDetail,
      },
      {
        exact: true,
        path: "/app/trip/:tripId",
        component: RequestsTrip,
      },
      {
        exact: true,
        path: "/app/finances/recharge/",
        component: FinancesRecharge,
      },
      {
        exact: true,
        path: "/app/finances/releases/",
        component: FinancesReleases,
      },
      {
        exact: true,
        path: "/app/finances/debitnotes/",
        component: DebitNotes,
      },
      {
        exact: true,
        path: "/app/tutorials/",  
        component: Tutorials,
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/dashboard" />,
      },
    ],
  },
];

export default routes;
