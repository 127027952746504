import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DeliveryRequestFilter, IDeliveryRequestFilterParams, INITIAL_FILTER_PARAMS } from "./DeliveryRequestFilter";
import CreateFinishedDeliveryRequest from "./createFinishedDeliveryRequestDialog";
import { useSnackbar } from "notistack";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DeliveryRequestResponse } from "../../services/types/deliveryRequest";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { VisibilityOutlined } from "@material-ui/icons";
import { mapDeliveryRequestStatusToSeverity } from "./deliveryRequestUtils";
import useStoreContext from "../../hooks/useStoreContext";

const Requests: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { activeStore } = useStoreContext();

  const [filterParams, setFilterParams] = useState<IDeliveryRequestFilterParams>(INITIAL_FILTER_PARAMS);
  const tableRef = React.useRef<MaterialTableProps<DeliveryRequestResponse>>();

  const [showCreateFinishedDeliveryRequestDialog, setShowCreateFinishedDeliveryRequestDialog] = React.useState(false);

  const loadData = (query: Query<DeliveryRequestResponse>) =>
    new Promise<QueryResult<DeliveryRequestResponse>>((resolve, reject) => {
      if (activeStore) {
        let manualCreation: boolean | null = null;
        if (filterParams.manualCreation === "true") {
          manualCreation = true;
        } else if (filterParams.manualCreation === "false") {
          manualCreation = false;
        } 

        DeliveryRequestService.getDeliveryRequestHistory(
          filterParams.requestNumber, filterParams.orderNumber,
          filterParams.requestedTimeAfter, filterParams.requestedTimeBefore,
          filterParams.courierName, activeStore.name,
          filterParams.status, manualCreation, PageableParams.fromQuery(query))
          .then(response => {
            resolve({
              data: response.data,
              page: query.page,
              totalCount: response.count,
            })
          })
      } else {
        reject("Você só pode Consultar o Histórico de Solicitações após informar a loja em que está trabalhando.");
      }
    });

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<DeliveryRequestResponse>);
    }
  }

  const applyFilter = (params: IDeliveryRequestFilterParams) => {
    setFilterParams(params);
    refresh();
  }

  const showDetails = async (item: DeliveryRequestResponse) => {
    history.push(`/app/requests/detail/${item.id}`);
  };

  const openCreateFinishedDeliveryRequestDialog = () => {
    if (activeStore?.id) {
      setShowCreateFinishedDeliveryRequestDialog(true);
    } else {
      enqueueSnackbar(
        "Você só pode Informar Entregas Realizadas após informar a loja em que está trabalhando.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      <CreateFinishedDeliveryRequest
        open={showCreateFinishedDeliveryRequestDialog}
        setOpen={setShowCreateFinishedDeliveryRequestDialog}
        onCreated={refresh} />
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h1">Histórico de Solicitações</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={openCreateFinishedDeliveryRequestDialog}>
              Informar Entrega
          </Button>
          </Grid>
        </Hidden>
      </Grid>

      <DeliveryRequestFilter onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 160 },
          { 
            ...DATE_TIME_COLUMN_DEFAULTS,
            field: "requested_time", title: "Data e Hora", defaultSort: "desc"
          },
          { field: "request_number", title: "Solicitação" },
          { field: "order_number", title: "Pedido" },
          { field: "courier_name", title: "Entregador" },
          {
            field: "manual_creation", title: "Tipo de Solicitação",
            render: (rowData, type) => rowData.manual_creation ? "Manual" : "Busca"
          },
          {
            field: "status", title: "Situação",
            render: (rowData, type) => (
              <Alert severity={mapDeliveryRequestStatusToSeverity(rowData.status)} icon={false}>
                {t("delivery_request_status." + rowData.status)}
              </Alert>
            )
          },
        ]}
        data={loadData}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: 'Ver Detalhes',
            onClick: (event, rowData) => showDetails(rowData as DeliveryRequestResponse)
          }
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS, 
          draggable: false
        }}
      />
    </>
  );
};

export default Requests;
