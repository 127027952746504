import { apiAxios as axios } from "../utils/axios";
import { getURL, urls } from "../utils/urls";
import { AccountStatus } from "./types/courier";

export interface ICourierService {
  getActiveCouriersByNameAndRegion: (name: string, regionId: number) => Promise<any>;
}

const CourierService: ICourierService = {
  getActiveCouriersByNameAndRegion: async (name: string, regionId: number) => {
    const url = getURL(`${urls.COURIER}list_couriers/`, {
      page: 1,
      page_size: 75,
      ordering: 'name',
      name: name,
      region: regionId,
      courier_account_status: [AccountStatus.ACTIVE, AccountStatus.DATA_UPDATED]
    });
    return axios
      .get(url)
      .then((response) => Promise.resolve(response.data.results))
      .catch((error) => Promise.reject(error));
  },
};

export default CourierService;
