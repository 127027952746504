import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Column, Components, Localization, Options, Query } from "@material-table/core";
import { IPageableParams } from "../services/types/common";
import { CURRENT_DATE_FORMAT, CURRENT_DATE_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../i18n";
import numbro from 'numbro';

export const TABLE_L10N_PTBR = {
  body: {
    dateTimePickerLocalization: CURRENT_DATE_LOCALE,
    emptyDataSourceMessage: 'Nenhum registro para exibir',
    addTooltip: 'Adicionar Novo',
    deleteTooltip: 'Remover',
    editTooltip: 'Editar'
  },
  toolbar: {
    searchTooltip: 'Pesquisar'
  },
  header: {
    actions: "Ações"
  },
  pagination: {
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
    labelDisplayedRows: '{from} a {to} de {count}',
    labelRowsSelect: 'registros',
  }
};

export const DEFAULT_TABLE_OPTIONS = {
  actionsColumnIndex: -1,
  emptyRowsWhenPaging: false,
  toolbar: false,
  sorting: true,
  thirdSortClick: false,
  columnResizable: false,
  tableLayout: "fixed" as "fixed" | "auto" | undefined,
  fixedColumns: {
    right: 0
  },
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  draggable: false
};

export const DEFAULT_TABLE_COMPONENTS = {
  Container: (props: any) => <TableContainer {...props} />
}

export const TABLE_DEFAULT_PROPS = {
  components: { DEFAULT_TABLE_COMPONENTS } as Components,
  localization: { TABLE_L10N_PTBR } as Localization,
  options: { DEFAULT_TABLE_OPTIONS } as Options<any>,
}


const currentNumbroLanguageData = numbro.languageData(numbro.language());
export const CURRENCY_COLUMN_DEFAULTS: Column<any> = {
  align: "right",
  type: "currency",
  currencySetting: {
    locale: numbro.language(),
    currencyCode: currentNumbroLanguageData.currency.code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
}

export const DATE_COLUMN_DEFAULTS: Column<any> = {
  type: "date",
  dateSetting:{
    locale: CURRENT_DATE_LOCALE.code,
    format: CURRENT_DATE_FORMAT
  }
}

export const DATE_TIME_COLUMN_DEFAULTS: Column<any> = {
  type: "datetime",
  dateSetting:{
    locale: CURRENT_DATE_LOCALE.code,
    format: CURRENT_DATE_TIME_FORMAT
  }
}

export type Order = "asc" | "desc";

export class PageableParams implements IPageableParams {
  page: number;
  page_size: number;
  ordering: string | null;

  constructor(page: number = 0, pageSize: number = 10, ordering: string | null = null) {
    this.page = page;
    this.page_size = pageSize;
    this.ordering = ordering;
  }

  static fromQuery(query: Query<any>): IPageableParams {
    const result = new PageableParams(query.page + 1, query.pageSize);
    if (query.orderBy?.field) {
      result.setOrderBy(String(query.orderBy.field));
      result.setOrder(query.orderDirection);
    }

    return result;
  }

  getOrder(): Order {
    let result: Order = "asc";
    if (this.ordering && this.ordering.startsWith("-")) {
      result = "desc";
    }
    return result;
  }

  setOrder(order: Order) {
    if (order === "desc") {
      this.ordering = "-" + this.getOrderBy();
    } else {
      this.ordering = this.getOrderBy();
    }
  }

  getOrderBy(): string {
    let result = "";

    if (this.ordering) {
      if (this.ordering.startsWith("-")) {
        result = this.ordering.substr(1);
      } else {
        result = this.ordering;
      }
    }

    return result;
  }

  setOrderBy(orderBy: string) {
    const order = this.getOrder();

    if (order === "asc") {
      this.ordering = orderBy;
    } else {
      this.ordering = "-" + orderBy;
    }
  }
}

interface ITableContainerProps {
  children: any;
  customStyles?: {};
}

export const TableContainer: React.FC<ITableContainerProps> = ({
  children,
  customStyles,
}) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        maxWidth: "100%",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        //padding: theme.spacing(2),
        //paddingBottom: theme.spacing(0),
        flex: 1,
        overflow: "auto",
      },
    })
  );

  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root} style={customStyles}>
      {children}
    </Paper>
  );
};

