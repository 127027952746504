import React, {
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { Button, Grid, CircularProgress, Collapse, useMediaQuery, FormGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DashboardColumn from "./customComponents/dashboardColumn";
import { IPageableParams } from "../../services/types/common";
import DeliveryRequestService from "../../services/deliveryRequestService";
import TripService from "../../services/tripService";
import { DeliveryRequestStatus, PendingDeliveryRequest, PendingTrip, TripDeliveryRequest, TripStatus } from "../../services/types/deliveryRequest";
import { CardStatusChip, DeliveryRequestCard, StatusSeverity } from "./customComponents/deliveryRequestCard";
import { TripCard } from "./customComponents/tripCard";
import theme from "../../styles/theme";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "./customComponents/joinDeliveriesOnTripDialog";
import ShowIf from "../../components/ShowIf";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import useGlobalSearch from "../../hooks/useGlobalSearch";
import { Alert } from "@material-ui/lab";
import PubSub from 'pubsub-js';
import { NEW_DELIVERY_REQUEST } from "../../events";
import { differenceInMinutes } from "date-fns";
import useStoreContext from "../../hooks/useStoreContext";
import { debounce } from "lodash";

const DELIVERY_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 500,
    ordering: "estimated_delivery_time",
};

const TRIP_DEFAULT_PAGINATION: IPageableParams = {
    page: 1,
    page_size: 200,
    ordering: "created_at",
};

const Dashboard: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { enqueueSnackbar } = useSnackbar();
    const { activeStore } = useStoreContext();
    const [maxNumberOfDeliveries, setMaxNumberOfDeliveries] = useState<number>(1);

    const { globalSearchValue, setGlobalSearchValue, showGlobalSearch, hideGlobalSearch } = useGlobalSearch();
    const { selectCourierFromQueue } = useStoreContext();

    const [newDeliveryList, setNewDeliveryList] = useState<PendingDeliveryRequest[]>([]);
    const [filteredDeliveryRequestList, setFilteredDeliveryRequestList] = useState<PendingDeliveryRequest[]>([]);
    const [tripList, setTripList] = useState<PendingTrip[]>([]);
    const [filteredTripList, setFilteredTripList] = useState<PendingTrip[]>([]);

    const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const [tripsToBlockActions] = useState<number[]>([]);
    const [deliveriesToBlockActions] = useState<number[]>([]);

    const [noCourierList, setNoCourierList] = useState<PendingTrip[]>([]);
    const [waitingList, setWaitingList] = useState<PendingTrip[]>([]);
    const [outList, setOutList] = useState<PendingTrip[]>([]);
    const [returningList, setReturningList] = useState<PendingTrip[]>([]);

    const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();

    const showDeliveryRequestDetails = async (deliveryRequest: any) => {
        let param =
            typeof deliveryRequest == "number"
                ? deliveryRequest
                : deliveryRequest.id;
        history.push(`/app/requests/detail/${param}`);
    };

    const showTripRequestDetails = async (trip: any) => {
        let param =
            typeof trip == "number"
                ? trip
                : trip.id;
        history.push(`/app/trip/${param}`);
    };

    const refreshDeliveryRequests = React.useCallback(async () => {
        const storeId = activeStore ? activeStore.id : null;
        const regionId = activeStore ? activeStore.region : null;

        if (storeId) {
            const [tripsResponse, deliveriesResponse] = await Promise.all([
                TripService.getPendingTrips(storeId, regionId, null, TRIP_DEFAULT_PAGINATION),
                DeliveryRequestService.getNewAndSearchingCourierDeliveries(storeId, DELIVERY_DEFAULT_PAGINATION)
            ]);
            setTripList(tripsResponse.data);
            setNewDeliveryList(deliveriesResponse.data);
        }
    }, [activeStore]);

    const handleReleaseDeliveryRequest = React.useMemo(() => debounce(async (deliveryRequest: PendingDeliveryRequest) => {
        try {
            deliveriesToBlockActions.push(deliveryRequest.id);
            forceUpdate();
            await DeliveryRequestService.releaseDeliveryRequestById(deliveryRequest.id);
            enqueueSnackbar("Coleta liberada com sucesso!", { variant: "success" });
            await refreshDeliveryRequests();
        } catch (error: any) {
            const { status, data } = error.response;
            if (status === 400) {
                let errors: string[] = [];
                for (var key in data) {
                    var value = data[key];
                    errors.push(value);
                }
                enqueueSnackbar(errors.join(".\n"), { variant: "error" });
            } else {
                enqueueSnackbar("Não foi possível liberar a coleta!", { variant: "error" });
            }
        } finally {
            const index = deliveriesToBlockActions.indexOf(deliveryRequest.id, 0);
            if (index > -1) {
                deliveriesToBlockActions.splice(index, 1);
                forceUpdate();
            }
        }
    }, 500, { 'leading': true }), [deliveriesToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    const handleSetCourierForDeliveryRequest = (deliveryRequest: PendingDeliveryRequest) => {
        selectCourierFromQueue(debounce(async (courier) => {
            deliveriesToBlockActions.push(deliveryRequest.id);
            forceUpdate();
            try {
                await DeliveryRequestService.requestCourierToDeliveryRequestById(deliveryRequest.id, courier.id);
                enqueueSnackbar("Entregador solicitado com sucesso!", { variant: "success" });
                await refreshDeliveryRequests();
            } catch (error: any) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errors: string[] = [];
                    for (var key in data) {
                        var value = data[key];
                        errors.push(value);
                    }
                    enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                } else {
                    enqueueSnackbar("Não foi possível solicitar entregador!", {
                        variant: "error",
                    });
                }
            } finally {
                const index = deliveriesToBlockActions.indexOf(deliveryRequest.id, 0);
                if (index > -1) {
                    deliveriesToBlockActions.splice(index, 1);
                    forceUpdate();
                }
            }
        }, 500, { 'leading': true }));
    };

    const handleFinishDeliveryConfirmation = (deliveryRequest: PendingDeliveryRequest) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Finalizar Entrega",
            "Tem certeza que deseja finalizar essa entrega? Ao finalizar a corrida será concluída, e o valor repassado ao entregador.",
            "Finalizar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleFinishDeliveryRequest(deliveryRequest);
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleFinishDeliveryRequest = React.useMemo(() => debounce(async (deliveryRequest: PendingDeliveryRequest) => {
        try {
            deliveriesToBlockActions.push(deliveryRequest.id);
            forceUpdate();
            if (deliveryRequest.canceled) {
                await DeliveryRequestService.finishCanceledDeliveryRequestById(deliveryRequest.id);
            } else {
                await DeliveryRequestService.finishDeliveryRequestById(deliveryRequest.id);
            }
            await refreshDeliveryRequests();
            enqueueSnackbar("Solicitação finalizada com sucesso!", { variant: "success" });
        } catch (error: any) {
            const { status, data } = error.response;
            if (status === 400) {
                let errors: string[] = [];
                for (var key in data) {
                    var value = data[key];
                    errors.push(value);
                }
                enqueueSnackbar(errors.join(".\n"), { variant: "error" });
            } else {
                enqueueSnackbar("Não foi possível finalizar a solicitação!", { variant: "error" });
            }
        } finally {
            const index = deliveriesToBlockActions.indexOf(deliveryRequest.id, 0);
            if (index > -1) {
                deliveriesToBlockActions.splice(index, 1);
                forceUpdate();
            }
        }
    }, 500, { 'leading': true }), [deliveriesToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    const handleSearchCourierForDeliveryRequest = React.useMemo(() => debounce(async (deliveryRequest: PendingDeliveryRequest) => {
        if (activeStore) {
            try {
                deliveriesToBlockActions.push(deliveryRequest.id);
                forceUpdate();
                await DeliveryRequestService.requestCourierToDeliveryRequestById(deliveryRequest.id);
                await refreshDeliveryRequests();
                enqueueSnackbar("Entregador solicitado com sucesso!", {
                    variant: "success",
                });
            } catch (error) {
                enqueueSnackbar("Não foi possível solicitar entregador!", {
                    variant: "error",
                });
            } finally {
                const index = deliveriesToBlockActions.indexOf(deliveryRequest.id, 0);
                if (index > -1) {
                    deliveriesToBlockActions.splice(index, 1);
                    forceUpdate();
                }
            }
        }
    }, 500, { 'leading': true }), [activeStore, deliveriesToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    const handleSearchCourierForTrip = React.useMemo(() => debounce(async (trip: PendingTrip) => {
        tripsToBlockActions.push(trip.id);
        forceUpdate();
        try {
            await TripService.searchCourierForTrip(trip.id)
            await refreshDeliveryRequests();
            enqueueSnackbar("Entregador solicitado com sucesso!", {
                variant: "success",
            });
        } catch (error: any) {
            const { status, data } = error.response;
            if (status === 400) {
                let errors: string[] = [];
                for (var key in data) {
                    var value = data[key];
                    errors.push(value);
                }
                enqueueSnackbar(errors.join(".\n"), { variant: "error" });
            } else {
                enqueueSnackbar("Não foi possível solicitar entregador!", {
                    variant: "error",
                });
            }
        } finally {
            const index = tripsToBlockActions.indexOf(trip.id, 0);
            if (index > -1) {
                tripsToBlockActions.splice(index, 1);
                forceUpdate();
            }
        }
    }, 500, { 'leading': true }), [tripsToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    const handleSetCourierForTrip = (trip: PendingTrip) => {
        selectCourierFromQueue(debounce(async (courier) => {
            tripsToBlockActions.push(trip.id);
            forceUpdate();
            try {
                await TripService.searchCourierForTrip(trip.id, courier.id);
                enqueueSnackbar("Entregador solicitado com sucesso!", { variant: "success" });
                await refreshDeliveryRequests();
            } catch (error: any) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errors: string[] = [];
                    for (var key in data) {
                        var value = data[key];
                        errors.push(value);
                    }
                    enqueueSnackbar(errors.join(".\n"), { variant: "error" });
                } else {
                    enqueueSnackbar("Não foi possível solicitar entregador!", {
                        variant: "error",
                    });
                }
            } finally {
                const index = tripsToBlockActions.indexOf(trip.id, 0);
                if (index > -1) {
                    tripsToBlockActions.splice(index, 1);
                    forceUpdate();
                }
            }
        }, 500, { 'leading': true }));
    };

    const handleReleaseTrip = React.useMemo(() => debounce(async (trip: PendingTrip) => {
        try {
            tripsToBlockActions.push(trip.id);
            forceUpdate();
            await TripService.releaseTripById(trip.id);
            await refreshDeliveryRequests();
            enqueueSnackbar("Entrega liberada com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Não foi possível liberar a entrega!", {
                variant: "error",
            });
        } finally {
            const index = tripsToBlockActions.indexOf(trip.id, 0);
            if (index > -1) {
                tripsToBlockActions.splice(index, 1);
                forceUpdate();
            }
        }
    }, 500, { 'leading': true }), [tripsToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    const handleFinishTripConfirmation = (trip: PendingTrip) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Finalizar Viagem",
            "Tem certeza que deseja finalizar essa viagem? Ao finalizar a viagem será concluída, e o valor repassado ao entregador.",
            "Finalizar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleFinishTrip(trip);
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleFinishTrip = React.useMemo(() => debounce(async (trip: PendingTrip) => {
        try {
            tripsToBlockActions.push(trip.id);
            forceUpdate();
            await TripService.finishTripById(trip.id);
            await refreshDeliveryRequests();
            enqueueSnackbar("Entrega liberada com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Não foi possível liberar a entrega!", {
                variant: "error",
            });
        } finally {
            const index = tripsToBlockActions.indexOf(trip.id, 0);
            if (index > -1) {
                tripsToBlockActions.splice(index, 1);
                forceUpdate();
            }
        }
    }, 500, { 'leading': true }), [tripsToBlockActions, enqueueSnackbar, refreshDeliveryRequests]);

    useLayoutEffect(() => {
        const _noCourierList: PendingTrip[] = [];
        const _waitingList: PendingTrip[] = [];
        const _outList: PendingTrip[] = [];
        const _returningList: PendingTrip[] = [];

        if (!smallScreen) {
            filteredTripList.forEach(trip => {
                switch (trip.status) {
                    case TripStatus.NEW:
                    case TripStatus.NO_COURIER:
                    case TripStatus.SEARCHING_COURIER:
                        _noCourierList.push(trip);
                        break;
                    case TripStatus.ACCEPTED:
                    case TripStatus.AWAITING_RELEASE:
                    case TripStatus.AWAITING_COLLECTION:
                        _waitingList.push(trip);
                        break;
                    case TripStatus.ON_WAY:
                        let containsNotDelivered = false;
                        for (let i = 0; i < trip.deliveries.length; i++) {
                            let delivery = trip.deliveries[i];
                            if ([DeliveryRequestStatus.ON_WAY,
                            DeliveryRequestStatus.AT_DESTINY].includes(delivery.status)) {
                                containsNotDelivered = true;
                            }
                        }
                        if (containsNotDelivered) {
                            _outList.push(trip);
                        } else {
                            _returningList.push(trip);
                        }
                        break;
                    default:
                        _returningList.push(trip);
                        break;
                }
            });
        }

        setNoCourierList(_noCourierList);
        setWaitingList(_waitingList);
        setOutList(_outList);
        setReturningList(_returningList);
    }, [filteredTripList, smallScreen]);

    useEffect(() => {
        showGlobalSearch("Filtre por número do pedido, bairro, entregador...");

        return function () {
            hideGlobalSearch();
        };
    }, [showGlobalSearch, hideGlobalSearch]);

    useEffect(() => {
        const filtered = newDeliveryList.filter((delivery) => {
            // retira os agendamentos que devem ser entregues em mais de uma hora
            if (delivery.is_scheduled) {
                if (differenceInMinutes(delivery.estimated_delivery_time, new Date()) > 60) {
                    return false;
                }
            }
            const searchValue = globalSearchValue.trim().toLocaleLowerCase()
            if (delivery.order_number.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            if (delivery.integration_instance?.name.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            /*if (delivery.courier_name?.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }*/
            if (delivery.destination_address?.district.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            return false;
        });
        setFilteredDeliveryRequestList(filtered);
    }, [globalSearchValue, newDeliveryList]);

    useEffect(() => {
        const filtered = tripList.filter((trip) => {
            const searchValue = globalSearchValue.trim().toLocaleLowerCase()
            if (![TripStatus.NEW, TripStatus.NO_COURIER, TripStatus.SEARCHING_COURIER].includes(trip.status)) {
                if (trip.courier_name?.toLocaleLowerCase().includes(searchValue)) {
                    return true;
                }
            }
            if (trip.number?.toLocaleLowerCase().includes(searchValue)) {
                return true;
            }
            for (let i = 0; i < trip.deliveries.length; i++) {
                const delivery = trip.deliveries[i];
                if (delivery.order_number.toLocaleLowerCase().includes(searchValue)) {
                    return true;
                }
                if (delivery.integration_instance?.name.toLocaleLowerCase().includes(searchValue)) {
                    return true;
                }
                if (delivery.destination_address?.district.toLocaleLowerCase().includes(searchValue)) {
                    return true;
                }
            }
            return false;
        });
        setFilteredTripList(filtered);
    }, [globalSearchValue, tripList]);

    useEffect(() => {
        refreshDeliveryRequests();

        const interval = setInterval(() => {
            refreshDeliveryRequests();
        }, 10000);

        return function cleanup() {
            setNewDeliveryList([]);
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [refreshDeliveryRequests]);

    useEffect(() => {
        if (activeStore?.max_number_of_deliveries !== undefined) {
            setMaxNumberOfDeliveries(activeStore.max_number_of_deliveries);
        }
    }, [activeStore]);

    const handleSplitDeliveryRequest = (trip: PendingTrip, delivery: TripDeliveryRequest) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Separar Pedido de Entrega",
            "Você tem certeza que deseja Separar esse Pedido de Entrega?",
            "Separar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleConfirmSplitTrip(Number(trip.id), Number(delivery.id));
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleConfirmSplitTrip = React.useMemo(() => debounce(async (tripIdSplit: number, deliveryIdSplit: number) => {
        if (tripIdSplit && deliveryIdSplit) {
            try {
                await TripService.removeDeliveryFromTrip(tripIdSplit, deliveryIdSplit);
                await refreshDeliveryRequests();
                enqueueSnackbar("Entrega separada com sucesso!", {
                    variant: "success",
                });
            } catch (error) {
                enqueueSnackbar("Não foi possível separar a entrega!", {
                    variant: "error",
                });
            }
        }
    }, 500), [enqueueSnackbar, refreshDeliveryRequests]);

    const handleJoinDelivery = (deliveryRequest: PendingDeliveryRequest) => {
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((trip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)) {
                availableTrips.push(trip);
            }
        })
        joinDeliveriesDialog.current?.joinDeliveries(deliveryRequest, newDeliveryList, availableTrips);
    };

    const handleJoinTrips = (trip: PendingTrip) => {
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((trip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)) {
                availableTrips.push(trip);
            }
        })

        joinDeliveriesDialog.current?.addToTrip(trip, newDeliveryList, availableTrips);
    };

    useEffect(() => {
        const onNewDeliveryRequest = PubSub.subscribe(NEW_DELIVERY_REQUEST, () => {
            refreshDeliveryRequests();
        });

        return () => PubSub.unsubscribe(onNewDeliveryRequest);
    }, [refreshDeliveryRequests]);

    const getCardStatusChip = (deliveryRequest: PendingDeliveryRequest): CardStatusChip => {
        let label = t("delivery_request_status." + deliveryRequest.status);
        let severity = StatusSeverity.SUCCESS;

        if (deliveryRequest.status === DeliveryRequestStatus.NEW) {
            severity = StatusSeverity.INFO;
        }

        if (deliveryRequest.status === DeliveryRequestStatus.NO_COURIER) {
            if (deliveryRequest.courier_id) {
                label = t("delivery_request_status.SEARCHING_COURIER");
            } else {
                severity = StatusSeverity.WARN;
            }
        }
        return { label: label, severity: severity };
    }

    if (smallScreen) {
        return (
            <DashboardColumn
                title="Solicitações"
                className={classes.list}
                titleClassName={classes.noCourierTitle}
            >
                <div className={classes.listContent}>
                    {filteredDeliveryRequestList.map(
                        (deliveryRequest, rowIndex) => (
                            <DeliveryRequestCard
                                key={rowIndex}
                                deliveryRequest={deliveryRequest}
                                highlight={globalSearchValue.trim()}
                                statusChip={getCardStatusChip(deliveryRequest)}
                                action={showDeliveryRequestDetails}
                            >
                                {
                                    [
                                        DeliveryRequestStatus.AWAITING_RELEASE,
                                        DeliveryRequestStatus.BACK_TO_STORE
                                    ].includes(deliveryRequest.status) && (
                                        <Grid container spacing={0} justify="center" className={classes.cardButtonArea}>
                                            <Grid item>
                                                {deliveryRequest.status === DeliveryRequestStatus.AWAITING_RELEASE && (
                                                    <Button
                                                        color="primary"
                                                        disabled={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                                        onClick={() => {
                                                            handleReleaseDeliveryRequest(deliveryRequest);
                                                        }}>
                                                        Liberar Coleta
                                                    </Button>
                                                )}
                                                {deliveryRequest.status === DeliveryRequestStatus.BACK_TO_STORE && (
                                                    <Button
                                                        color="primary"
                                                        disabled={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                                        onClick={() => {
                                                            handleFinishDeliveryConfirmation(deliveryRequest);
                                                        }}>
                                                        Finalizar Entrega
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </DeliveryRequestCard>
                        )
                    )}
                </div>
            </DashboardColumn >
        );
    } else {
        return (
            <React.Fragment>
                <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={refreshDeliveryRequests} />
                <ConfirmationDialog ref={confirmationDialog} />

                <div className={classes.root}>
                    <Collapse in={(globalSearchValue.trim() !== "")}>
                        <Grid container justify="center">
                            <Grid item>
                                <Alert
                                    severity="warning"
                                    className={classes.warningFilter}
                                    action={
                                        <Button color="inherit" size="small" onClick={(e) => { setGlobalSearchValue(""); }}>
                                            Retirar Filtro
                                        </Button>
                                    }>
                                    <strong>Aviso:</strong> As informações abaixo estão <strong>filtradas!</strong>
                                </Alert>
                            </Grid>
                        </Grid>
                    </Collapse>
                    <Grid
                        container
                        spacing={2}
                        justify="center"
                        alignItems="stretch"
                        className={classes.dashboardContent}
                        style={{ maxHeight: `calc(100% + ${theme.spacing(2)}px ${(globalSearchValue.trim() !== "") ? "- 64px" : ""})` }}
                    >
                        <Grid item md={3} className={classes.dashboardGridColumn}>
                            <DashboardColumn
                                title="Em aberto"
                                className={classes.list}
                                titleClassName={classes.noCourierTitle}
                                headerActions={
                                    <FormGroup row className={classes.positionHeaderAction}>
                                        <Typography variant="body2" className={classes.styleCount} ><b>{noCourierList.length + filteredDeliveryRequestList.length}</b></Typography>
                                    </FormGroup>
                                }
                            >
                                <div className={classes.listContent}>
                                    {filteredDeliveryRequestList.map((deliveryRequest) => (
                                        <DeliveryRequestCard
                                            key={deliveryRequest.id}
                                            deliveryRequest={deliveryRequest}
                                            highlight={globalSearchValue.trim()}
                                            statusChip={getCardStatusChip(deliveryRequest)}
                                            action={showDeliveryRequestDetails}
                                            showLoading={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                        >
                                            {(deliveryRequest.status === DeliveryRequestStatus.NEW) &&
                                                (
                                                    <Grid container spacing={0} justify="space-evenly" alignItems="center" className={classes.cardButtonArea}>
                                                        {(maxNumberOfDeliveries > 1)
                                                            && <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                                                    title="Juntar Entregas"
                                                                    onClick={() => { handleJoinDelivery(deliveryRequest) }}>
                                                                    Juntar
                                                                </Button>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <Button
                                                                color="primary"
                                                                disabled={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                                                title="Solicitar Entregador"
                                                                onClick={() => {
                                                                    handleSearchCourierForDeliveryRequest(deliveryRequest);
                                                                }}>
                                                                Solicitar
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                color="primary"
                                                                disabled={deliveriesToBlockActions.includes(deliveryRequest.id)}
                                                                title="Direcionar para Entregador"
                                                                onClick={() => {
                                                                    handleSetCourierForDeliveryRequest(deliveryRequest);
                                                                }}>
                                                                Direcionar
                                                            </Button>
                                                        </Grid>
                                                        {/*<Grid item>
                                                            <IconButton color="primary">
                                                                <MoreHorizIcon />
                                                            </IconButton>
                                                        </Grid>*/}
                                                    </Grid>
                                                )}
                                        </DeliveryRequestCard>
                                    ))}
                                    {noCourierList.map((trip) => (
                                        <TripCard
                                            key={trip.id}
                                            trip={trip}
                                            highlight={globalSearchValue.trim()}
                                            showDeliveryStatus={false}
                                            action={showTripRequestDetails}
                                            showLoading={tripsToBlockActions.includes(trip.id)}
                                            splitTripAction={[TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status) && (maxNumberOfDeliveries > 1) ? handleSplitDeliveryRequest : undefined}
                                        >
                                            <ShowIf condition={[TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)}>
                                                <Grid container spacing={0} justify="space-evenly" className={classes.cardButtonArea}>
                                                    {(maxNumberOfDeliveries > 1)
                                                        && <Grid item>
                                                            <Button
                                                                color="primary"
                                                                title="Juntar Entregas"
                                                                disabled={tripsToBlockActions.includes(trip.id)}
                                                                onClick={() => {
                                                                    handleJoinTrips(trip);
                                                                }}>
                                                                Juntar
                                                            </Button>
                                                        </Grid>
                                                    }
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            title="Solicitar Entregador"
                                                            disabled={tripsToBlockActions.includes(trip.id)}
                                                            onClick={() => {
                                                                handleSearchCourierForTrip(trip);
                                                            }}>
                                                            Solicitar
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            disabled={tripsToBlockActions.includes(trip.id)}
                                                            title="Direcionar para Entregador"
                                                            onClick={() => {
                                                                handleSetCourierForTrip(trip);
                                                            }}>
                                                            Direcionar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </ShowIf>
                                        </TripCard>
                                    ))}
                                </div>
                            </DashboardColumn>
                        </Grid>
                        <Grid item md={3} className={classes.dashboardGridColumn}>
                            <DashboardColumn
                                title="Aguardando saída"
                                className={classes.list}
                                titleClassName={classes.waitingTitle}
                                headerActions={
                                    <FormGroup row className={classes.positionHeaderAction}>
                                        <Typography className={classes.styleCount}><b>{waitingList.length}</b></Typography>
                                    </FormGroup>
                                }
                            >
                                <div className={classes.listContent}>
                                    {waitingList.map((trip) => (
                                        <TripCard
                                            key={trip.id}
                                            trip={trip}
                                            highlight={globalSearchValue.trim()}
                                            showDeliveryStatus={false}
                                            action={showTripRequestDetails}
                                            showLoading={tripsToBlockActions.includes(trip.id)}>
                                            <Grid container spacing={0} justify="center" className={classes.cardButtonArea}>
                                                <ShowIf condition={[TripStatus.ACCEPTED, TripStatus.AWAITING_RELEASE, TripStatus.AWAITING_COLLECTION].includes(trip.status)}>
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            title="Adicionar Entrega"
                                                            disabled={tripsToBlockActions.includes(trip.id)}
                                                            onClick={() => {
                                                                handleJoinTrips(trip);
                                                            }}>
                                                            <ShowIf condition={tripsToBlockActions.includes(trip.id)}>
                                                                <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                                                            </ShowIf>
                                                            Adicionar Entrega
                                                        </Button>
                                                    </Grid>
                                                </ShowIf>
                                                <ShowIf condition={trip.status === TripStatus.AWAITING_RELEASE}>
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            disabled={tripsToBlockActions.includes(trip.id)}
                                                            onClick={() => {
                                                                handleReleaseTrip(trip);
                                                            }}>
                                                            <ShowIf condition={tripsToBlockActions.includes(trip.id)}>
                                                                <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                                                            </ShowIf>
                                                            Liberar Coleta
                                                        </Button>
                                                    </Grid>
                                                </ShowIf>
                                            </Grid>
                                        </TripCard>
                                    ))}
                                </div>
                            </DashboardColumn>
                        </Grid>
                        <Grid item md={3} className={classes.dashboardGridColumn}>
                            <DashboardColumn title="Em rota" className={classes.list} titleClassName={classes.outTitle}
                                headerActions={
                                    <FormGroup row className={classes.positionHeaderAction}>
                                        <Typography className={classes.styleCount}><b>{outList.length}</b></Typography>
                                    </FormGroup>
                                }
                            >
                                <div className={classes.listContent}>
                                    {outList.map((trip) => (
                                        <TripCard
                                            key={trip.id}
                                            trip={trip}
                                            highlight={globalSearchValue.trim()}
                                            showTripStatus={false}
                                            action={showTripRequestDetails}
                                            showLoading={tripsToBlockActions.includes(trip.id)}
                                        />
                                    ))}
                                </div>
                            </DashboardColumn>
                        </Grid>
                        <Grid item md={3} className={classes.dashboardGridColumn}>
                            <DashboardColumn title="Retornando" className={classes.list} titleClassName={classes.returningTitle}
                                headerActions={
                                    <FormGroup row className={classes.positionHeaderAction}>
                                        <Typography className={classes.styleCount}><b>{returningList.length}</b></Typography>
                                    </FormGroup>
                                }>
                                <div className={classes.listContent}>
                                    {returningList.map((trip,) => (
                                        <TripCard
                                            key={trip.id}
                                            trip={trip}
                                            highlight={globalSearchValue.trim()}
                                            showTripStatus={false}
                                            action={showTripRequestDetails}
                                            showLoading={tripsToBlockActions.includes(trip.id)}
                                            refresh={refreshDeliveryRequests}>
                                        </TripCard>
                                    ))}
                                </div>
                            </DashboardColumn>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
        maxHeight: "100%",
    },
    dashboardContent: {
        flex: 1,
        height: "auto",
        //maxHeight: `calc(100% + ${theme.spacing(2)}px)`,
        transition: "max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    dashboardGridColumn: {
        height: "100%",
    },
    warningFilter: {
        marginBottom: theme.spacing(2),
        width: "450px"
    },
    list: {
        display: "flex",
        flexFlow: "column",
        minHeight: "100%",
        maxHeight: "100%",
        backgroundColor: theme.palette.background.default,
    },
    listContent: {
        flex: 1,
        marginTop: 0,
        padding: theme.spacing(1),
        overflow: "auto",
    },
    cardButtonArea: {
        marginTop: (theme.spacing(1) * -1),
    },
    noCourierTitle: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    searchingCourier: {
        backgroundColor: "#f44336",
        color: theme.palette.primary.contrastText,
    },
    waitingTitle: {
        backgroundColor: "#d99528",
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    outTitle: {
        backgroundColor: "#44af07",
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    returningTitle: {
        backgroundColor: "#747474",
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    positionHeaderAction: {
        position: "absolute",
        right: "12px",
        color: "white",
    },
    styleCount: {
        color: "white",
        fontSize: "12px"
    },
});

export default Dashboard;
