import { apiAxios as axios } from "../utils/axios";
import { urls } from '../utils/urls';

export interface IPasswordService {
    changePassword: (old_password: string, new_password1: string, new_password2: string) => Promise<any>;
    requestPasswordReset: (email: string) => Promise<any>;
    resetPassword: (uid: string, token: string, new_password1: string, new_password2: string) => Promise<any>;
    setFirstPassword: (uid: string, token: string, password: string) => Promise<any>;
}

const PasswordService: IPasswordService = {

    changePassword: async (old_password: string, new_password1: string, new_password2: string) => {
        return await axios.post(urls.PASSWORD_CHANGE, {
            old_password: old_password,
            new_password1: new_password1,
            new_password2: new_password2
        });
    },

    requestPasswordReset: async (email: string) => {
        return await axios.post(urls.REQUEST_PASSWORD_RESET, { email });
    },

    resetPassword: async (uid: string, token: string, new_password1: string, new_password2: string) => {
        return await axios.post(urls.PASSWORD_RESET, {
            uid: uid,
            token: token,
            new_password1: new_password1,
            new_password2: new_password2
        });
    },

    setFirstPassword: (uid: string, token: string, password: string) => {
        return axios.post(urls.ACTIVATE, {
            uid: uid,
            token: token,
            password: password
        });
    }
}

export default PasswordService;