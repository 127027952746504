import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import useAuth from "../../../hooks/useAuth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Box,
  Divider,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CreditCard, DeleteOutline } from "@material-ui/icons";
import { BarcodeIcon, TimerSandIcon, PixIcon } from "../../../components/Icons";
import numbro from "numbro";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  CurrencyInput,
  PhoneInput,
  PostalCodeInput,
  CnpjNumberMask,
  CpfNumberInput,
  ValidThruInput,
  NumberCardInput,
  CvvCardInput,
} from "../../../components/InputMasks";
import { Customer } from "../../../services/types/customer";
import { CustomerService } from "../../../services/customerService";
import CustomerFinancesService from "../../../services/customerFinancesService";
import { CreditCardCreate, CreditCardResponse, CustomerFinancialStats, FinancialEntryOrigin, FinancialTaxResponse } from "../../../services/types/customerFinance";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { convertToNumber } from "../../../utils/utils";
import { CPFCNPJInput } from "../../../components/CpfCnpjInput";
import { ValidCpfCnpjValue } from "../../../components/ValidCpfCnpjValue";

interface IInsertCredits {
  open: boolean;
  setOpen: (value: boolean) => void;
  updateFinances: () => void;
  setPixDetails: (value: any) => void;
  setOpenDialog: (value: boolean) => void;
}

interface FinancesInsertCredit {
  value: number;
  payment_method?: FinancialEntryOrigin.BANK_SLIP | FinancialEntryOrigin.PIX | FinancialEntryOrigin.CREDIT_CARD;
  numberCard: string;
  nameCard: string;
  expiryCard: string;
  cvvCard: string;
  titularRegistration: string;
  cardSelected: number | null;
  isNewCard: boolean;
  tabIndex: number
}

const INITIAL_VALUES: FinancesInsertCredit = {
  value: 0,
  numberCard: "",
  nameCard: "",
  expiryCard: "",
  cvvCard: "",
  titularRegistration: "",
  cardSelected: null,
  isNewCard: false,
  tabIndex: 0

};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </div>
  );
}

const card_list_tab_index = 0;
const new_card_tab_index = 1;

export const insertCreditValidation = Yup.object().shape({
  value: Yup.number()
    .required("Campo obrigatório")
    .transform((currentValue, originalValue) => numbro.unformat(originalValue))
    .min(100, "O valor mínimo da recarga é R$ 100,00")
    .max(99999, "O valor máximo da recarga é R$ 99.999,00"),
  payment_method: Yup.string().required("Selecione a forma de pagamento"),
  tabIndex: Yup.number().nullable().required(),
  numberCard: Yup.number()
    .when(["tabIndex", "payment_method"], {
      is: (value1, value2) => value1 === new_card_tab_index && value2 === FinancialEntryOrigin.CREDIT_CARD,
      then: Yup.number().nullable(false).required("Necessário informar o número do cartão").test(
        'len',
        'Informe um número válido',
        (val) => val !== undefined && val !== null && (val.toString().trim().length === 0 || (val.toString().trim().length >= 13 && val.toString().trim().length <= 19))
      ),
      otherwise: Yup.number().nullable(false).optional()
    }),
  nameCard: Yup.string()
    .when(["tabIndex", "payment_method"], {
      is: (value1, value2) => value1 === new_card_tab_index && value2 === FinancialEntryOrigin.CREDIT_CARD,
      then: Yup.string().nullable(false).required("Necessário informar o nome no cartão").matches(/^[a-zA-Z\s]*$/, "Informe o nome corretamente").min(1, "Informe o nome corretamente").max(64, "Informe o nome corretamente"),
      otherwise: Yup.string().nullable().optional()
    }),
  expiryCard: Yup.string()
    .when(["tabIndex", "payment_method"], {
      is: (value1, value2) => value1 === new_card_tab_index && value2 === FinancialEntryOrigin.CREDIT_CARD,
      then: Yup.string().nullable().required("Necessário informar a data de expiração").test('len', 'Informe a data de expiração corretamente', val => val?.trim().length === 7),
      otherwise: Yup.string().nullable().optional()
    }),
  cvvCard: Yup.string()
    .when(["tabIndex", "payment_method"], {
      is: (value1, value2) => value1 === new_card_tab_index && value2 === FinancialEntryOrigin.CREDIT_CARD,
      then: Yup.string().nullable(false).required("Necessário informar o CVV do cartão").test('len', 'Informe um CVV válido', val => val?.trim().length === 3 || val?.trim().length === 4),
      otherwise: Yup.string().nullable().optional()
    }),
  titularRegistration: Yup.string()
    .when(["tabIndex", "payment_method"], {
      is: (value1, value2) => value1 === new_card_tab_index && value2 === FinancialEntryOrigin.CREDIT_CARD,
      then: Yup.string().nullable().required("Necessário informar o CPF/CNPJ do titular").test('len', 'Informe um CPF/CNPJ válido', val => val?.trim().length === 14 || val?.trim().length === 18),
      otherwise: Yup.string().nullable().optional()
    }),
});

const InsertCredit: React.FC<IInsertCredits> = ({
  open,
  setOpen,
  updateFinances,
  setPixDetails,
  setOpenDialog,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [customer, setCustomer] = useState<Customer>();
  const [customerStats, setCustomerStats] = useState<CustomerFinancialStats>();
  const [estimatedCreditDuration, setEstimatedCreditDuration] = useState<number>(0);
  const { user } = useAuth();
  const [inputFocus, setInputFocus] = useState<any>();
  const [cardValid, setCardValid] = useState<boolean>(false);
  const [cards, setCards] = useState<CreditCardResponse[]>([]);
  const [saveCard, setSaveCard] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(card_list_tab_index);
  const [checked, setChecked] = React.useState([0]);
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const [financialTax, setFinancialTax] = useState<FinancialTaxResponse>();
  const [totalValueCredit, setTotalValueCredit] = useState<number>(0);
  const [totalValuePix, setTotalValuePix] = useState<number>(0);
  const [totalValueBank, setTotalValueBank] = useState<number>(0);


  const updateEstimatedDuration = useCallback(
    (credit: number, customerStats: CustomerFinancialStats | undefined) => {
      if (customerStats) {
        let duration: number =
          (credit + customerStats.current_balance) /
          (customerStats.average_weekly_expenses / 7);
        if (isNaN(duration)) {
          duration = 0;
        }
        setEstimatedCreditDuration(Math.max(Math.trunc(duration), 0));
      } else {
        setEstimatedCreditDuration(0);
      }
    },
    []
  );

  const loadData = useCallback((customerId: number) => {
    CustomerFinancesService.getFinancialTax()
      .then(tax => {
        setFinancialTax(tax);
      })
      .catch(error => { });

    CustomerService.getCustomerById(customerId)
      .then(customer => {
        setCustomer(customer);
      })
      .catch(error => { });

    CustomerFinancesService.getCustomerStats(customerId, null)
      .then(stats => {
        setCustomerStats(stats);
        updateEstimatedDuration(0, stats);
      })
      .catch(error => { });

    CustomerService.getCreditCards()
      .then(cards => {
        setCards(cards);
        if (cards.length > 0) {
          formik.setFieldValue("isNewCard", false);
        } else {
          formik.setFieldValue("isNewCard", true);
          setTabIndex(new_card_tab_index);
        }
      })
      .catch(error => { });
  }, [updateEstimatedDuration]);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async values => {
      try {
        if (user?.customer && values.payment_method) {
          const cardSelected = values.cardSelected;
          const expire = cardSelected ? [undefined, undefined] : values.expiryCard.split("/");
          const expireMonth = expire[0];
          const expireYear = expire[1];
          if (formik.values.payment_method === FinancialEntryOrigin.CREDIT_CARD) {
            if (card_list_tab_index === tabIndex && cardSelected === null) {
              formik.setFieldError("cardSelected", "Necessário selecionar um carão");
              return;
            }
            if (new_card_tab_index === tabIndex) {
              if (!cardValid) {
                formik.setFieldError("numberCard", "Necessário informar um cartão válido");
                return;
              }
              var minYear = new Date().getFullYear();
              if ((expireMonth !== undefined) && (expireYear !== undefined)) {
                if ((Number(expireMonth) > 12) || (Number(expireMonth) < 1) || (Number(expireYear) < minYear)) {
                  formik.setFieldError("expiryCard", "Informe uma data de expiração válida");
                  return;
                }
              } else {
                formik.setFieldError("expiryCard", "Informe uma data de expiração válida");
                return;
              }
              if(!ValidCpfCnpjValue(values.titularRegistration)){
                formik.setFieldError("titularRegistration", "Informe um CPF/CNPJ válido.");
                return;
              }else{
                formik.setFieldError("titularRegistration", undefined);
              }
            }
          }
          const cardCreate: CreditCardCreate = {
            number: values.numberCard.replace(/[^\d]/g, ""),
            holder_name: values.nameCard,
            holder_document: values.titularRegistration.replace(/[^\d]/g, ""),
            exp_month: expireMonth ? expireMonth.replace(/[^\d]/g, "") : "",
            exp_year: expireYear ? expireYear.replace(/[^\d]/g, "") : "",
            cvv: values.cvvCard.replace(/[^\d]/g, ""),
            save_card: saveCard
          };
          const response = await CustomerFinancesService.addCredit(
            user.customer.id,
            numbro.unformat(String(values.value)),
            values.payment_method,
            cardSelected,
            cardCreate
          );
          handleClose();
          updateFinances();

          if (values.payment_method === FinancialEntryOrigin.PIX) {
            enqueueSnackbar("PIX gerado com sucesso!", { variant: "success" });

            setPixDetails({
              bank_slip_barcode: response.bank_slip_barcode,
              value: Number(response.value),
              tax: Number(response.tax),
              total: Number(response.total),
            });
            setOpenDialog(true);
          } else if (values.payment_method === FinancialEntryOrigin.BANK_SLIP) {
            enqueueSnackbar("Boleto gerado com sucesso!", { variant: "success" });

            const bankSlip =
              await CustomerFinancesService.getBankSlipDocument(response.id);

            const newWindow: any = window.open("about:blank");
            newWindow.document.open();
            newWindow.document.write(bankSlip);
            newWindow.document.close();
          } else if (values.payment_method === FinancialEntryOrigin.CREDIT_CARD) {
            enqueueSnackbar("Pagamento efetuado com sucesso!", { variant: "success" });
          }
        }
      } catch (error) {
        let errorMsg = "Ocorreu um erro ao emitir o Boleto.";
        if (values.payment_method === FinancialEntryOrigin.PIX) {
          errorMsg = "Ocorreu um erro ao gerar o PIX.";
        } else if (values.payment_method === FinancialEntryOrigin.CREDIT_CARD) {
          errorMsg = "Ocorreu um erro ao tentar efetuar o pagamento com o Cartão de Crédito.";
        }

        const { status, data } = error.response;
        const errorMsgs: string[] = [];
        if (status === 400) {
          let errorData = data.card_data ? data.card_data : data;
          for (let key in errorData) {
            let value = errorData[key];
            if (key in values) {
              formik.setFieldError(key, value);
              errorMsgs.push(value);
            } else {
              errorMsgs.push(value);
            }
          }

          if (errorMsgs.length > 0) {
            errorMsg = errorMsgs.join(". \n");
          }
        }
        enqueueSnackbar(errorMsg, { variant: "error" });
      }
    },
    validationSchema: insertCreditValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handlePayment = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value !== null) {
      formik.setFieldValue("payment_method", value);
      formik.setFieldError("payment_method", undefined);
    }
    setSaveCard(false);
    formik.setFieldValue("cardSelected", null);
    setChecked([0]);
    setTabIndex(card_list_tab_index);
  };

  const handleClose = () => {
    setOpen(false);
    setTabIndex(card_list_tab_index);
    formik.resetForm();
    setTotalValueCredit(0);
    setTotalValueBank(0);
    setTotalValuePix(0);
  };

  function isCNPJ(value: string | null | undefined): boolean {
    if (value) {
      return value.length > 11;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (user?.customer && open) {
      loadData(user.customer.id);
    }
  }, [user, open, loadData]);

  const handleInputFocus = (e: any) => {
    setInputFocus(e);
  }

  const returnCard = (e: any, verify: boolean) => {
    setCardValid(verify);
    if (!verify) {
      formik.setFieldError("numberCard", "Informe um cartão válido.");
    } else {
      formik.setFieldError("numberCard", undefined);
    }
  }

  const handleDeleteCard = (idCard: number) => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Remover Cartão",
      "Tem certeza que deseja Remover o Cartão?.",
      "Remover",
      "Cancelar"
    ).then(async () => {
      try {
        CustomerService.deleteCard(idCard)
          .then(response => {
            if (user?.customer && open) {
              loadData(user.customer.id);
              enqueueSnackbar("Cartão removido com sucesso!", { variant: "success" });
              dialog.hide();
            }
          }).catch(error => {
            enqueueSnackbar("Falha na remoção do cartão.", { variant: "error" });
            dialog.hide();
          });
      } finally { }
    }).catch(() => { });

  };


  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
    formik.setFieldValue("tabIndex", newIndex);
    setChecked([]);
    formik.setFieldValue("cardSelected", null)
  };

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked: any[] = [];

    if (currentIndex === -1) {
      newChecked.push(value);
      formik.setFieldValue("cardSelected", value)
    } else {
      newChecked.splice(currentIndex, 1);
      formik.setFieldValue("cardSelected", null)
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    var value = convertToNumber(formik.values.value);
    if (value && financialTax) {
      setTotalValueCredit(value / (1 - ((financialTax.credit_card_percentage_tax) / 100)));
      setTotalValueBank(value + financialTax.bank_slip_tax);
      setTotalValuePix(value + financialTax.pix_tax);
    } else {
      setTotalValueCredit(0);
      setTotalValueBank(0);
      setTotalValuePix(0);
    }
  }, [formik.values.value, financialTax]);

  return (
    <>
      <ConfirmationDialog ref={confirmationDialog} />
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <LoadingOverlay open={formik.isSubmitting} />
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <DialogTitle id="form-dialog-title">
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h1">Pagamento</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ width: "800px" }}>
              <Grid item xs={9}>
                <Typography variant="h6" className="dark">
                  Média de consumo semanal:
                  <strong className="ml-2">
                    {numbro(customerStats ? customerStats?.average_weekly_expenses : 0).formatCurrency()}
                  </strong>
                </Typography>
                <Typography variant="h6" className="dark">
                  Saldo atual:
                  <strong
                    className={
                      customerStats?.current_balance
                        ? customerStats?.current_balance > 0
                          ? "primary ml-2"
                          : "error ml-2"
                        : ""
                    }
                  >
                    {numbro(customerStats ? customerStats?.current_balance : 0).formatCurrency()}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  p={3}
                  bgcolor="info.main"
                  style={{ backgroundColor: "#ecf0f5", borderRadius: "8px" }}
                >
                  <Grid container direction="row">
                    <Grid xs={6}>
                      <Typography component="div" variant="body1">
                        <label htmlFor="value" className="dark bold">
                          Valor da recarga
                        </label>
                      </Typography>
                      <TextField
                        id="value"
                        style={{ marginTop: "5px", textAlign: "right" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                          if (e.target) {
                            let credit = 0;
                            if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                              credit = numbro.unformat(String(e.target.value));
                            }
                            updateEstimatedDuration(credit, customerStats);
                          }
                          formik.handleChange(e);
                        }}
                        value={formik.values.value}
                        error={!!formik.errors.value}
                        helperText={
                          formik.errors.value ||
                          "O valor mínimo da recarga é R$ 100,00"
                        }
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="secondary"
                            >
                              <strong>R$</strong>
                            </InputAdornment>
                          ),
                          inputComponent: CurrencyInput,
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography
                        component="div"
                        variant="body1"
                        className="secondary"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <TimerSandIcon />
                        Duração estimada:
                        <strong className="dark ml-1">
                          {estimatedCreditDuration} dia(s).
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} style={{ marginTop: "5px" }}>
                    <Grid item md={4}>
                      <Typography component="div" variant="body1">
                        <label htmlFor="taxBank" className="dark bold">
                          Valor com taxa do boleto
                        </label>
                      </Typography>
                      <TextField
                        id="taxBank"
                        style={{ marginTop: "5px", textAlign: "right" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={totalValueBank}
                        disabled
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="secondary"
                            >
                              <strong>R$</strong>
                            </InputAdornment>
                          ),
                          inputComponent: CurrencyInput,
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Typography component="div" variant="body1">
                        <label htmlFor="taxPix" className="dark bold">
                          Valor com taxa do pix
                        </label>
                      </Typography>
                      <TextField
                        id="taxPix"
                        style={{ marginTop: "5px", textAlign: "right" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={totalValuePix}
                        disabled
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="secondary"
                            >
                              <strong>R$</strong>
                            </InputAdornment>
                          ),
                          inputComponent: CurrencyInput,
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Typography component="div" variant="body1">
                        <label htmlFor="taxCredit" className="dark bold">
                          Valor com taxa do cartão ({financialTax ? `${parseFloat(financialTax.credit_card_percentage_tax.toString()).toFixed(2)}%` : 0})
                        </label>
                      </Typography>
                      <TextField
                        id="taxCredit"
                        style={{ marginTop: "5px", textAlign: "right" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={totalValueCredit}
                        disabled
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="secondary"
                            >
                              <strong>R$</strong>
                            </InputAdornment>
                          ),
                          inputComponent: CurrencyInput,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <fieldset style={{ border: "0px solid #ddd", borderTop: "1px solid #ddd", width: "100%" }}>
                  <legend style={{ marginLeft: "-12px" }}>
                    <Typography variant="body1">Pagar com</Typography>
                  </legend>
                  <ToggleButtonGroup
                    value={formik.values.payment_method}
                    onChange={handlePayment}
                    size="small"
                    exclusive
                    aria-label="payment"
                  >
                    <ToggleButton value={FinancialEntryOrigin.BANK_SLIP} aria-label="boleto bancário" style={{ padding: "5px 8px", fontSize: "13px" }}>
                      <BarcodeIcon className="mr-2" />Boleto
                    </ToggleButton>
                    <ToggleButton value={FinancialEntryOrigin.PIX} aria-label="pix" style={{ padding: "5px 8px", fontSize: "13px" }}>
                      <PixIcon className="mr-2" />Pix
                    </ToggleButton>
                    <ToggleButton value={FinancialEntryOrigin.CREDIT_CARD} aria-label="cartão de crédito" style={{ padding: "5px 8px", fontSize: "13px" }}>
                      <CreditCard className="mr-2" />Cartão de Crédito
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormHelperText className="error">{formik.errors.payment_method}</FormHelperText>
                </fieldset>
              </Grid>

              {formik.values.payment_method === FinancialEntryOrigin.BANK_SLIP && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography component="div" variant="body1">
                      <label htmlFor="corporate_name" className="dark bold">
                        Nome completo
                      </label>
                    </Typography>
                    <TextField
                      id="corporate_name"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.corporate_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body1">
                      <label htmlFor="registration_number" className="dark bold">
                        Documento CPF/CNPJ
                      </label>
                    </Typography>
                    <TextField
                      id="registration_number"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.registration_number}
                      InputProps={{
                        inputComponent: isCNPJ(customer?.registration_number)
                          ? CnpjNumberMask
                          : CpfNumberInput,
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body1">
                      <label htmlFor="phonenumber" className="dark bold">
                        Telefone
                      </label>
                    </Typography>
                    <TextField
                      id="phonenumber"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.phonenumber}
                      InputProps={{
                        inputComponent: PhoneInput,
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_postal_code"
                        className="dark bold"
                      >
                        CEP
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_postal_code"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.fiscal_address_postal_code || ""}
                      InputProps={{
                        inputComponent: PostalCodeInput,
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_street"
                        className="dark bold"
                      >
                        Endereço
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_street"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.fiscal_address_street || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_number"
                        className="dark bold"
                      >
                        Nº
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_number"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.fiscal_address_number || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_district"
                        className="dark bold"
                      >
                        Bairro
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_district"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={customer?.fiscal_address_district || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_city_name"
                        className="dark bold"
                      >
                        Cidade
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_city_name"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        customer?.fiscal_address_city
                          ? customer?.fiscal_address_city?.name || ""
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component="div" variant="body1">
                      <label
                        htmlFor="fiscal_address_city_state_initials"
                        className="dark bold"
                      >
                        UF
                      </label>
                    </Typography>
                    <TextField
                      id="fiscal_address_city_state_initials"
                      className="mt-1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        customer?.fiscal_address_city
                          ? customer?.fiscal_address_city?.state_initials || ""
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              )}
              {formik.values.payment_method === FinancialEntryOrigin.PIX && (
                <Grid container justify="center">
                  <Grid item>
                    <Alert severity="info" style={{ width: "330px" }}>
                      Clique em <strong>"Confirmar"</strong> para gerar o PIX.
                    </Alert>
                  </Grid>
                </Grid>
              )}
              {formik.values.payment_method === FinancialEntryOrigin.CREDIT_CARD && (
                <>
                  <Grid container md={12} style={{ display: "block" }}>
                    <Tabs
                      value={tabIndex}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleTabChange}
                      className="bordered-tab"
                      variant="fullWidth"
                      centered
                      style={{ width: "100%" }}
                    >
                      <Tab disabled={cards.length === 0} label="Cartões" />
                      <Tab label="Novo Cartão" />
                    </Tabs>
                    <TabPanel value={tabIndex} index={card_list_tab_index}>
                      <Grid container md={12}>
                        {cards && cards.length > 0 ? (
                          <Grid item xs={6} md={6} sm={12}>
                            <List style={{ width: '100%' }}>
                              {cards.map((card) => {
                                const labelId = `checkbox-list-label-${card.id}`;
                                return (
                                  <>
                                    <ListItem key={card.id} role={undefined} dense button onClick={handleToggle(card.id)}>
                                      <ListItemIcon>
                                        <Checkbox
                                          edge="start"
                                          color="primary"
                                          checked={checked.indexOf(card.id) !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        id={labelId}
                                        primary={
                                          <React.Fragment>**** **** **** <b>{card.last_numbers}</b></React.Fragment>
                                        }
                                        secondary={
                                          <React.Fragment><b>{card.brand ? card.brand : "---"}</b></React.Fragment>
                                        }

                                      />
                                      <ListItemSecondaryAction>
                                        <Tooltip title="Remover" arrow placement="right">
                                          <IconButton edge="end" onClick={() => handleDeleteCard(card.id)} aria-label="remove">
                                            <DeleteOutline />
                                          </IconButton>
                                        </Tooltip>
                                      </ListItemSecondaryAction>

                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </>
                                );
                              })}
                            </List>
                            {!!formik.errors.cardSelected && (
                              <Typography variant="caption" color="error" display="block" gutterBottom>
                                {formik.errors.cardSelected}
                              </Typography>
                            )}

                          </Grid>
                        ) : (
                          <Typography>Nenhum Cartão Encontrado.</Typography>
                        )}
                      </Grid>

                    </TabPanel>
                    <TabPanel value={tabIndex} index={new_card_tab_index}>
                      <Grid container>
                        <div id="PaymentForm" style={{ display: "contents" }}>
                          <Grid item md={6} style={{ paddingLeft: "6px" }}>
                            <Grid item style={{ marginTop: "10px" }}>
                              <TextField
                                type="tel"
                                id="numberCard"
                                name="numberCard"
                                className="mt-1"
                                variant="outlined"
                                fullWidth
                                placeholder="Número do Cartão"
                                value={formik.values.numberCard}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue("numberCard", value);
                                }}

                                onFocus={() => handleInputFocus("number")}
                                onBlur={() => {
                                  handleInputFocus("");
                                }}
                                InputProps={{
                                  inputComponent: NumberCardInput,
                                }}
                                error={!!formik.errors.numberCard}
                                helperText={formik.errors.numberCard}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                id="nameCard"
                                name="nameCard"
                                className="mt-1"
                                variant="outlined"
                                fullWidth
                                placeholder="Nome no Cartão"
                                value={formik.values.nameCard}
                                onChange={(e) => {
                                  let nameInput = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                                  let name = nameInput.replace(/[^a-zA-Z\s]/g, "");
                                  formik.setFieldValue("nameCard", name); 
                                }}
                                onFocus={() => handleInputFocus("name")}
                                onBlur={() => handleInputFocus("")}
                                error={!!formik.errors.nameCard}
                                helperText={formik.errors.nameCard}
                              />
                            </Grid>
                            <Grid container md={12}>
                              <Grid item md={6}>
                                <TextField
                                  id="expiryCard"
                                  name="expiryCard"
                                  className="mt-1"
                                  variant="outlined"
                                  placeholder="MM/YYYY"
                                  fullWidth
                                  value={formik.values.expiryCard}
                                  onChange={(e) => { formik.setFieldValue("expiryCard", e.target.value) }}
                                  onFocus={() => handleInputFocus("expiry")}
                                  onBlur={() => handleInputFocus("")}
                                  error={!!formik.errors.expiryCard}
                                  helperText={formik.errors.expiryCard}
                                  InputProps={{
                                    inputComponent: ValidThruInput,
                                  }}
                                />
                              </Grid>

                              <Grid item md={6} style={{ paddingLeft: "4px" }}>
                                <TextField
                                  id="cvvCard"
                                  name="cvvCard"
                                  className="mt-1"
                                  variant="outlined"
                                  placeholder="CVV"
                                  fullWidth
                                  value={formik.values.cvvCard}
                                  onChange={(e) => { formik.setFieldValue("cvvCard", e.target.value) }}
                                  onFocus={() => handleInputFocus("cvc")}
                                  onBlur={() => handleInputFocus("")}
                                  InputProps={{
                                    inputComponent: CvvCardInput,
                                  }}
                                  error={!!formik.errors.cvvCard}
                                  helperText={formik.errors.cvvCard}
                                />
                              </Grid>
                            </Grid>
                            <Grid item>
                              <TextField
                                id="titularRegistration"
                                name="titularRegistration"
                                className="mt-1"
                                variant="outlined"
                                placeholder="CPF ou CNPJ do Titular"
                                fullWidth
                                value={formik.values.titularRegistration}
                                onChange={(e) => { formik.setFieldValue("titularRegistration", e.target.value) }}
                                InputProps={{
                                  inputComponent: CPFCNPJInput,
                                }}
                                error={!!formik.errors.titularRegistration}
                                helperText={formik.errors.titularRegistration}
                              />
                            </Grid>

                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox name="saveCard" color="primary" checked={saveCard}
                                    onChange={(event) => {
                                      setSaveCard(event.target.checked);
                                    }} />
                                }
                                label="Salvar o cartão"
                                style={{ marginTop: "10px", marginLeft: "-7px" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Cards
                              cvc={formik.values.cvvCard}
                              expiry={formik.values.expiryCard}
                              focused={inputFocus}
                              name={formik.values.nameCard}
                              number={formik.values.numberCard}
                              placeholders={{ name: 'Nome no Cartão' }}
                              callback={returnCard}
                              acceptedCards={["visa", "mastercard", "elo", "maestro", "amex", "hipercard", "dinersclub", "discover", "jcb"]}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </TabPanel>
                  </Grid>
                  <Grid container justify="center">
                    <Grid item style={{ width: "100%" }}>
                      <Alert severity="info" >
                        Clique em <strong>"Confirmar"</strong> para efetuar o pagamento com o Cartão de Crédito.
                      </Alert>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Confirmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InsertCredit;
