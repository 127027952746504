import { AxiosRequestConfig } from "axios";
import { apiAxios as axios } from "../utils/axios";
import { urls } from "../utils/urls";

export interface IAuthService {
    getNewToken: (refreshToken: string | null) => Promise<any>;
}

const AuthService: IAuthService = {
    getNewToken: (refreshToken: string | null) => {
        return axios
            .post(urls.TOKEN_REFRESH, 
                { refresh: refreshToken }, 
                { skipAuthRefresh: true } as AxiosRequestConfig);
    },
}

export default AuthService;