import React from "react";
import type { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  MuiThemeProvider,
  StylesProvider,
  useTheme,
} from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import theme from "./styles/theme";
import routes, { renderRoutes } from "./routes";
import { ACCESS_TOKEN_STORAGE_KEY, AuthProvider, TOKEN_EXP_STORAGE_KEY } from "./contexts/AuthContext";
import { SnackbarProvider } from "notistack";
import useAuth from "./hooks/useAuth";
import { configExpiredAccessTokenInterceptor } from "./utils/axios";
import { AppContextProvider } from "./contexts/AppContext";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

const history = createBrowserHistory();

const App: FC = () => {
  const [isAuthenticated, userHasAuthenticated] = React.useState(false);
  const { logout } = useAuth() as any;

  const onLoad = async () => {
    const accessToken = await localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const expToken = await localStorage.getItem(TOKEN_EXP_STORAGE_KEY);
    const tokenExpired = Date.now().toString().slice(0, 10) >= (expToken || "0");
    userHasAuthenticated(!!accessToken && !tokenExpired);
  };

  const handleDisconnect = () => {
    userHasAuthenticated(false);
    logout();
    history.push("/");
  }

  if (isAuthenticated) {
    configExpiredAccessTokenInterceptor(handleDisconnect);
  }

  React.useEffect(() => {
    onLoad();
  }, []);

  const checkUpdateVersionApp = React.useCallback(async () => {
    console.log("Check Version");
    // check for sw updates on page change
    if(navigator.serviceWorker){
      navigator.serviceWorker
      .getRegistrations()
      .then((regs) => regs.forEach((reg) => reg.update()));
    };
  }, []);

  React.useEffect(() => {
    var waitTime = (30 * 60) * 1000; // = 30min.
    checkUpdateVersionApp();

    const interval = setInterval(() => {
      checkUpdateVersionApp();
    }, waitTime); // 30min

    return function cleanup() {
        if (interval) {
            clearInterval(interval);
        }
    };
  }, [checkUpdateVersionApp]);

  const materialTheme = useTheme();
  const smallScreen = useMediaQuery(materialTheme.breakpoints.down("md"));
  return (
    <Router history={history}>
      <AuthProvider>
        <AppContextProvider>
          <MuiThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <SnackbarProvider
                preventDuplicate
                dense={smallScreen}
                maxSnack={3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: smallScreen ? "center" : "right",
                }}
              >
                {renderRoutes(routes)}
                <ServiceWorkerWrapper/>
              </SnackbarProvider>
            </StylesProvider>
          </MuiThemeProvider>
        </AppContextProvider>
      </AuthProvider>
      
    </Router>
    
  );
};

export default App;
